<template>
  <div v-if="project">
    <fp-templates-manager :projectId="project.ProjectId" />
  </div>
</template>
<script>
export default {
  name: "PartnerTemplates",
  props: {
    project: Object,
  },
   mounted() {
    this.$store.dispatch("setPageTitle", this.$t("partner.menu.templates"));
  },
};
</script>
