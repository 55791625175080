<template>
  <div v-if="patients && subordinatesProgress">
    <fp-table-list :striped="false" :items="patients" :fields="patientsFields">
      <template #Progress="row">
        <div
          v-if="
            subordinatesProgress[row.item.UserId] != null ||
            subordinatesProgress[row.item.UserId] !== undefined
          "
        >
          <b-progress
            :max="1"
            :value="subordinatesProgress[row.item.UserId]"
          ></b-progress>
          <div style="text-align: center">
            <b>{{ Number.parseFloat(subordinatesProgress[row.item.UserId] * 100).toFixed(2) }}%</b>
          </div>
        </div>
      </template>
      <template #PatientDateOfBirth="row">
        {{ $convertDateToString(row.item.DateOfBirth, "YYYY.MM.DD") }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
export default {
  name: "Patients",
  props: {
    patients: Array,
    subordinatesProgress: Object,
  },
  data() {
    return {
      patientsFields: [
        {
          key: "Name",
          label: this.$t("healthProfessional.projects.patientName"),
        },
        {
          key: "Ssn",
          label: this.$t("healthProfessional.projects.patientSsn"),
        },
        {
          key: "PatientDateOfBirth",
          label: this.$t("healthProfessional.projects.patientBirth"),
        },
        {
          key: "Progress",
          label: this.$t("healthProfessional.projects.tasksProgress"),
        },
      ],
      patientList: null,
    };
  },
  methods: {
    getProgress(partId) {
      return this.subordinatesProgress.filter(
        (sub) => sub.UserId == partId
      );
    },
  },
};
</script>