<template>
  <div class="page-background">
    <div>
      <div class="listbox1">
        <h4 style="text-align: center; margin: 10px">
          {{ $t("client.moduleSettings.enabledModules") }}
        </h4>
        <ejs-listbox
          id="listbox1"
          :dataSource="enabledModules"
          :allowDragAndDrop="true"
          :fields="fields"
          scope="combined-list"
          :drop="enabledListChange"
        ></ejs-listbox>
      </div>

      <div class="listbox2">
        <h4 style="text-align: center; margin: 10px">
          {{ $t("client.moduleSettings.availableModules") }}
        </h4>
        <ejs-listbox
          id="listbox2"
          :dataSource="disabledModules"
          :allowDragAndDrop="true"
          :fields="fields"
          scope="combined-list"
          :drop="disabledListChange"
        ></ejs-listbox>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { UserPreferencesLogic } from "@/Logic/Backend/user-preferences";

Vue.use(ListBoxPlugin);

export default {
  name: "ModuleSettings",
  props: {
    enabledProjectModules: Array,
  },
  data() {
    return {
      enabledModules: [],
      disabledModules: [],
      userModules: null,
      fields: { text: "DisplayName" },
    };
  },
  watch: {
    enabledProjectModules() {
      this.setModules();
    },
  },
  methods: {
    async enabledListChange(args) {
      // move between lists
      if (args.destination) {
        // array manipulation
        this.enabledModules.splice(
          this.enabledModules.indexOf(args.items[0]),
          1
        );
        this.disabledModules = args.destination.currentData;
      }
      // move in the same list
      else {
        this.enabledModules = args.source.currentData;
      }
      //TODO: mentés kiemelése külön metódusba?
      // saving changes
      const setModeulesResponse = await UserPreferencesLogic.setModulesByOrder(
        this.enabledModules,
        this.disabledModules
      );
      if (!setModeulesResponse.Code || setModeulesResponse.Code == 0) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setModeulesResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async disabledListChange(args) {
      // move between lists
      if (args.destination) {
        // array manipulation
        this.disabledModules.splice(
          this.disabledModules.indexOf(args.items[0]),
          1
        );
        this.enabledModules = args.destination.currentData;
      }
      // move in the same list
      else {
        this.disabledModules = args.source.currentData;
      }
      // saving changes
      const setModeulesResposne = await UserPreferencesLogic.setModulesByOrder(
        this.enabledModules,
        this.disabledModules
      );
      if (!setModeulesResposne.Code || setModeulesResposne.Code == 0) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setModeulesResposne.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getModules() {
      const getUserPreferencesReponse =
        await UserPreferencesLogic.getUserPreferences();
      if (
        !getUserPreferencesReponse.Code &&
        getUserPreferencesReponse.Modules
      ) {
        this.userModules = getUserPreferencesReponse.Modules;
        this.setModules();
      } else {
        this.$bvToast.toast(getUserPreferencesReponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.modules.module"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    setModules() {
      this.getEnabledModules(this.userModules);
      this.getDisabledModules(this.userModules);
    },
    getEnabledModules(modules) {
      var temp = UserPreferencesLogic.getEnabledModules(modules);

      if (this.enabledProjectModules) {
        temp = temp.filter((x) => this.enabledProjectModules.includes(x.Name));
      }

      temp.forEach((module) => {
        module.DisplayName = this.$t("module.modules." + module.Name);
      });

      this.enabledModules = temp;
    },
    getDisabledModules(modules) {
      var temp = UserPreferencesLogic.getDisabledModules(modules);

      if (this.enabledProjectModules) {
        temp = temp.filter((x) => this.enabledProjectModules.includes(x.Name));
      }

      temp.forEach((module) => {
        module.DisplayName = this.$t("module.modules." + module.Name);
      });

      this.disabledModules = temp;
    },
  },
  async mounted() {
    await this.getModules();
    this.$store.dispatch(
      "setPageTitle",
      this.$t("client.moduleSettings.title")
    );
  },
};
</script>
<style>
.selected-modul-box {
  background: #fff;
  width: 535px;
  height: 430px;
  padding: 0px;
  margin: 5px;
  position: relative;
  display: inline-block;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(139, 166, 182, 1);
  -moz-box-shadow: 2px 2px 2px 0px rgba(139, 166, 182, 1);
  box-shadow: 2px 2px 2px 0px rgba(139, 166, 182, 1);
}
.selected-modul-header {
  background: #cddbe5;
  height: 60px;
  width: 535px;
  text-align: left;
  color: #698393;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
}
.selected-program-img {
  width: 70px;
  margin: 10px;
}
.selected-modul-title {
  margin-top: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.listbox1 {
  float: right;
  width: 48%;
  margin-bottom: 20px;
}
.listbox2 {
  float: left;
  width: 48%;
  margin-bottom: 20px;
}
</style>
