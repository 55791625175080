var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eh-client-home"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"eh-flex-center-container",attrs:{"cols":"12","lg":"8"}},[_c('label',{staticClass:"citizen-home-modules-title"},[_vm._v(_vm._s(_vm.$t("client.home.enabledModules")))])]),_c('b-col',{staticClass:"eh-flex-center-container",attrs:{"cols":"12","lg":"4"}},[_c('router-link',{attrs:{"to":{ name: 'ModuleSettings' }}},[_c('button',{staticClass:"eh-action-button",staticStyle:{"margin":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("client.home.settingModules"))+" ")])])],1)],1),_c('table',{staticClass:"eh-client-home-table"},_vm._l((_vm.enabledModules),function(module){return _c('td',{key:module.Name,staticClass:"eh-client-home-module-box"},[_c('router-link',{staticStyle:{"text-decoration-line":"none"},attrs:{"to":{
            name: 'ClientModule',
            params: { openedModuleName: module.Name },
          }}},[_c('tr',[_c('div',{staticClass:"module-header"},[_c('div',{staticStyle:{"display":"flex"}},[(module.IconURL)?_c('img',{staticClass:"program-img",style:('background:' + module.Color + '; border-radius: 50px;'),attrs:{"src":require('@/Assets/' + module.IconURL),"basic":""}}):_c('div',{staticStyle:{"width":"70px","height":"70px","margin":"10px"},style:('background:' + module.Color + '; border-radius: 50px;')}),_c('label',{staticClass:"module-title"},[_vm._v(_vm._s(_vm.$t("module.modules." + module.Name)))])]),_c('div',[(
                    module.Charts &&
                    Object.keys(module.Charts).length &&
                    _vm.getFirstChart(module)
                  )?_c(_vm.getFirstChart(module).ComponentName,{tag:"component",staticStyle:{"margin-top":"-25px"},attrs:{"IsDashboard":true,"Interval":'Month',"ChartType":'chart',"Chart":_vm.getFirstChart(module)}}):_c('div',{staticStyle:{"magin":"auto","text-align":"center"}},[_vm._v(" Fejlesztés alatt ")])],1)])])])],1)}),0),_c('div',{staticClass:"eh-flex-center-container"},[_c('table',{staticStyle:{"margin":"20px auto"}},[_c('router-link',{attrs:{"to":"Badges"}},[_c('td',{staticClass:"program-box"},[_c('button',{staticClass:"eh-action-button"},[_vm._v(" "+_vm._s(_vm.$t("client.menu.badges"))+" ")])])]),_c('router-link',{attrs:{"to":"DiseaseGuide"}},[_c('td',{staticClass:"program-box"},[_c('button',{staticClass:"eh-action-button"},[_vm._v(" "+_vm._s(_vm.$t("client.menu.diseaseGuide"))+" ")])])]),_c('router-link',{attrs:{"to":"ServiceFinder"}},[_c('td',{staticClass:"program-box"},[_c('button',{staticClass:"eh-action-button"},[_vm._v(" "+_vm._s(_vm.$t("client.menu.serviceFinder"))+" ")])])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }