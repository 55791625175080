<template>
  <div id="fp-image-component" v-if="!params.Hidden">
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span v-if="params.Required" style="color: red; font-size: 14px">
      * {{ $t("Base.required") }}
    </span>
    <div v-if="params.Description">
      <div class="fp-description" v-html="params.Description[language]"></div>
    </div>
    <div class="fp-image-container">
      <!-- TODO: visszatenni ha megint választós lesz, értéket is vissza kell adni -->
      <!-- <div
      :style="disabled || !params.Enabled ? '' : 'cursor:pointer'"
      @click="changeValue"
      class="fp-image-container"
    > -->
      <img :src="imgUrl" class="fp-image" />
      <div>
        <!-- TODO: visszatenni ha megint választós lesz, értéket is vissza kell adni -->
        <!-- <div
        :class="
          isSelected
            ? 'fp-image-selected'
            : disabled && params.Enabled
            ? ''
            : 'fp-image-unselected'
        "
      > -->
        <!-- TODO: kijelöléshez checkbox -->
        <!-- <div style="margin: auto">
          <b-icon
            style="color: white; font-size: 60px"
            icon="check2-square"
          ></b-icon>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { AttachmentLogic } from "../../../../Logic/Backend/attachment";
export default {
  name: "ImageComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: Boolean,
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      imgUrl: null,
      isSelected: this.value ? this.value : this.params.DefaultValue,
    };
  },
  watch: {
    isSelected(input) {
      //console.log(input);
      this.$emit("change", input);
    },
    value(input) {
      this.isSelected = input;
    },
  },
  methods: {
    async loadImage() {
      if (this.params.SourceType == "Attachment") {
        await this.getImageFromAttachment(this.params.Path);
      } else {
        this.imgUrl = require("../../../../Assets/Default/photo-not-found.jpeg");
      }
    },
    async getImageFromAttachment(id) {
      const getAttachmentResponse = await AttachmentLogic.downloadAttachment(
        id
      );
      if (!getAttachmentResponse.Code) {
        const imgBlob = await getAttachmentResponse.blob();
        this.imgUrl = URL.createObjectURL(imgBlob);
      } else {
        this.imgUrl = require("../../../../Assets/Default/photo-not-found.jpeg");
      }
    },
    changeValue() {
      if (!(this.disabled && this.params.Enabled)) {
        this.isSelected = !this.isSelected;
      }
    },
  },
  async mounted() {
    await this.loadImage();
    this.$emit("change", this.isSelected);
  },
};
</script>
<style scoped>
#fp-image-component .fp-image {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--eh-primary-5);
}
#fp-image-component .fp-description {
  font-size: 14px;
  color: #00000099;
}
#fp-image-component .fp-image-container {
  position: relative;
}
#fp-image-component .fp-image-container .fp-image-unselected,
#fp-image-component .fp-image-container .fp-image-selected {
  position: absolute;
  top: 0;
  border-radius: 10px;
  opacity: 0;
  width: 100%;
  height: 100%;
}
#fp-image-component .fp-image-container:hover .fp-image-unselected,
#fp-image-component .fp-image-container:hover .fp-image-selected {
  /* background: #00000044; */
  background: var(--eh-secondary-4);
  opacity: 0.4;
}
#fp-image-component .fp-image-container .fp-image-selected {
  /* background: #00000088; */
  background: var(--eh-primary-5);
  opacity: 0.6;
}
</style>
