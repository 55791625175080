<template>
  <div v-if="PrescriptionProducts">
    <div
      v-for="(product, index) in PrescriptionProducts"
      :key="'prescription-products' + index"
    >
      <div style="display: flex">
        <fp-svg-icon
          icon="medical-product"
          color="black"
          style="background: transparent; margin: 0px"
        />
        <div class="m-1">
          <div style="font-size: 20px">
            {{
              product.ProductName
                ? product.ProductName
                : product.Ttt
                ? product.Ttt.Name
                : product.Iso
                ? product.Iso.Name
                : null
            }}
            <div style="font-weight: 500">
              {{
                product.Amount.Amount +
                " " +
                product.Amount.Unit +
                ", " +
                (product.PrescriptionTitle
                  ? product.PrescriptionTitle.Name
                  : product.DispenseTitle
                  ? product.DispenseTitle.Name
                  : "")
              }}
            </div>
          </div>
          <div style="font-size: 15px">
            {{
              product.Ttt && product.Iso
                ? "(TTT kód: " +
                  product.Ttt.Name +
                  ", ISO kód: " +
                  product.Iso.Name +
                  ")"
                : product.Ttt
                ? "(TTT kód: " + product.Ttt.Code + ")"
                : product.Iso
                ? "(ISO kód: " + product.Iso.Code + ")"
                : ""
            }}
          </div>
          <div>
            {{ getReportNumbers(product) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrescriptionProducts",
  props: {
    PrescriptionProducts: Array,
    DispenseProducts: Array,
    FinanceProdutcs: Array,
  },
  methods: {
    getReportNumbers(product) {
      if (this.FinanceProdutcs) {
        const uniqueId = product.UniqueId;
        const dispenseProd = this.DispenseProducts.find(
          (x) => x.PrescriptionProductUniqueId == uniqueId
        );
        if (dispenseProd) {
          const financeProd = this.FinanceProdutcs.find(
            (x) => x.UniqueId == dispenseProd.UniqueId
          );
          if (financeProd && financeProd.FinanceReportIds && financeProd.FinanceReportIds.length) {
            return financeProd.FinanceReportIds.join(", ") + " azonosítójú jelentés(ek)en szerepel";
          }
        }
      }
      return null;
    },
  },
};
</script>
