<template>
  <!-- TODO: átdolgozás -->
  <fp-form-modal
    v-model="showNewRole"
    @hidden="clearNewRole"
    :title="
      isNew
        ? $t('partner.newProject.roles.addRole')
        : $t('partner.newProject.roles.table.fields.permissions')
    "
  >
    <template #content>
      <fp-input
        :label="$t('partner.newProject.roles.table.fields.name')"
        v-model="newRole.RoleName"
        :disabled="!isNew && !permissionEditable"
      />
      <b-form-group
        :label="$t('partner.newProject.roles.table.fields.baseRoles')"
        :disabled="!isNew && !permissionEditable"
      >
        <b-form-checkbox-group
          id="checkbox-group-baseRoles"
          v-model="newRole.BaseRoles"
          :options="baseRoleOptions"
          name="baseRoles"
        ></b-form-checkbox-group>
      </b-form-group>
      <p class="font-weight-bold">
        {{ $t("partner.newProject.roles.permissions") }}
      </p>
      <fp-select
        label="Olvasási jogosultságok"
        type="multi"
        :disabled="!isNew && !permissionEditable"
        v-model="structuredPermissions.See"
        :items="seeOptions"
        valueKey="Value"
        :textKeyConverter="
          (e) => {
            return $t(e.Text);
          }
        "
      >
        <template #element="e">
          {{ $t(e.Text) }}
        </template>
      </fp-select>
      <div
        v-if="isNew || permissionEditable"
        class="select-all-permission"
        @click="selectAllSeePermission"
      >
        Összes olvasási jogosultság kijelölése
      </div>
      <fp-select
        label="Hozzáadási jogosultságok"
        type="multi"
        :disabled="!isNew && !permissionEditable"
        v-model="structuredPermissions.Add"
        :items="addOptions"
        valueKey="Value"
        :textKeyConverter="
          (e) => {
            return $t(e.Text);
          }
        "
      >
        <template #element="e">
          {{ $t(e.Text) }}
        </template>
      </fp-select>
      <div
        v-if="isNew || permissionEditable"
        class="select-all-permission"
        @click="selectAllAddPermission"
      >
        Összes hozzáadási jogosultság kijelölése
      </div>
      <fp-select
        label="Szerkesztési jogosultságok"
        type="multi"
        :disabled="!isNew && !permissionEditable"
        v-model="structuredPermissions.Edit"
        :items="editOptions"
        valueKey="Value"
        :textKeyConverter="
          (e) => {
            return $t(e.Text);
          }
        "
      >
        <template #element="e">
          {{ $t(e.Text) }}
        </template>
      </fp-select>
      <div
        v-if="isNew || permissionEditable"
        class="select-all-permission"
        @click="selectAllEditPermission"
      >
        Összes szerkesztési jogosultság kijelölése
      </div>
      <fp-select
        label="Törlési jogosultságok"
        type="multi"
        :disabled="!isNew && !permissionEditable"
        v-model="structuredPermissions.Remove"
        :items="removeOptions"
        valueKey="Value"
        :textKeyConverter="
          (e) => {
            return $t(e.Text);
          }
        "
      >
        <template #element="e">
          {{ $t(e.Text) }}
        </template>
      </fp-select>
      <div
        v-if="isNew || permissionEditable"
        class="select-all-permission"
        @click="selectAllRemovePermission"
      >
        Összes törlési jogosultság kijelölése
      </div>
      <b-button v-if="isNew" style="margin-top: 10px" @click="addRole()"
        >{{ $t("base.basic.add") }}
      </b-button>
      <b-button
        v-if="!isNew && !permissionEditable && !roleReadonly"
        @click="permissionEditable = true"
        >Szerkesztés</b-button
      >
      <b-button v-if="permissionEditable" @click="saveEdit">Mentés</b-button>
      <b-button v-if="permissionEditable" @click="cancelEdit">Mégsem</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
export default {
  name: "PermissionsForm",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    projectRoleList: Array,
    editable: Boolean,
    isNew: Boolean,
    role: Object,
    roleReadonly: Boolean,
  },
  data() {
    return {
      showNewRole: this.visible,
      permissionEditable: false,
      defaultRole: null,
      emptyNewRole: {
        RoleName: "",
        BaseRoles: [],
        Permissions: [],
        Parameters: [],
      },
      newRole: {
        RoleName: "",
        BaseRoles: [],
        Permissions: [],
        Parameters: [],
      },
      emptyStructuredPermissions: {
        See: [],
        Edit: [],
        Add: [],
        Remove: [],
      },
      structuredPermissions: {
        See: [],
        Edit: [],
        Add: [],
        Remove: [],
      },
      baseRoleOptions: [
        {
          text: this.$t("partner.newProject.roles.newRole.baseRoles.Manager"),
          value: "Manager",
        },
        {
          text: this.$t("partner.newProject.roles.newRole.baseRoles.Doctor"),
          value: "Doctor",
        },
        {
          text: this.$t("partner.newProject.roles.newRole.baseRoles.Patient"),
          value: "Patient",
        },
      ],
      seeOptions: Object.values(this.$enums.ProjectPermission).filter(
        (p) => p.Type == "SeeOption"
      ),
      addOptions: Object.values(this.$enums.ProjectPermission).filter(
        (p) => p.Type == "AddOption"
      ),
      removeOptions: Object.values(this.$enums.ProjectPermission).filter(
        (p) => p.Type == "RemoveOption"
      ),
      editOptions: Object.values(this.$enums.ProjectPermission).filter(
        (p) => p.Type == "EditOption"
      ),
    };
  },
  watch: {
    visible(value) {
      this.showNewRole = value;
    },
    showNewRole(input) {
      this.$emit("change", input);
    },
    role(input) {
      if (input) {
        this.newRole = input;
        this.defaultRole = JSON.parse(JSON.stringify(input));
        this.structuredPermissions = this.createStructuredPermissions(
          input.Permissions
        );
      }
    },
  },
  methods: {
    saveEdit() {
      this.showNewRole = false;
      this.permissionEditable = false;
      this.newRole.Permissions = this.parseStructuredPermissions(
        this.structuredPermissions
      );
      this.$emit("update:role", this.newRole);
      this.$emit("roleEdit", this.newRole);
    },
    cancelEdit() {
      this.newRole = JSON.parse(JSON.stringify(this.defaultRole));
      this.structuredPermissions = this.createStructuredPermissions(
        this.newRole.Permissions
      );
      this.showNewRole = false;
      this.permissionEditable = false;
    },
    //új résztvevő felvétele a projektbe
    async addRole() {
      this.newRole.Permissions = this.parseStructuredPermissions(
        this.structuredPermissions
      );
      this.$emit("addRole", this.newRole);

      //új beteg kapcsolat modal megjelenítő változó false-ra állítása
      this.showNewRole = false;
      //új kapcsolat tároló ürítése
      this.newRole = JSON.parse(JSON.stringify(this.emptyNewRole));
      this.structuredPermissions = JSON.parse(
        JSON.stringify(this.emptyStructuredPermissions)
      );
    },
    //beteg hozzáadása űrlap ürítése
    clearNewRole() {
      this.newRole = JSON.parse(JSON.stringify(this.emptyNewRole));
      this.structuredPermissions = JSON.parse(
        JSON.stringify(this.emptyStructuredPermissions)
      );
    },
    createStructuredPermissions(permissionArray) {
      let structuredPermissions = {
        See: [],
        Edit: [],
        Add: [],
        Remove: [],
      };
      permissionArray.forEach((permission) => {
        const p = this.$enums.ProjectPermission[permission];
        if (p.Type == "SeeOption") {
          structuredPermissions.See.push(permission);
        } else if (p.Type == "AddOption") {
          structuredPermissions.Add.push(permission);
        } else if (p.Type == "EditOption") {
          structuredPermissions.Edit.push(permission);
        } else if (p.Type == "RemoveOption") {
          structuredPermissions.Remove.push(permission);
        }
      });
      return structuredPermissions;
    },
    parseStructuredPermissions(structuredPermissions) {
      let newPermissionArray = [];
      for (const permissionArray of Object.values(structuredPermissions)) {
        newPermissionArray = newPermissionArray.concat(permissionArray);
      }
      return newPermissionArray;
    },
    selectAllSeePermission() {
      this.structuredPermissions.See = this.seeOptions.map((o) => o.Value);
    },
    selectAllAddPermission() {
      this.structuredPermissions.Add = this.addOptions.map((o) => o.Value);
    },
    selectAllEditPermission() {
      this.structuredPermissions.Edit = this.editOptions.map((o) => o.Value);
    },
    selectAllRemovePermission() {
      this.structuredPermissions.Remove = this.removeOptions.map(
        (o) => o.Value
      );
    },
  },
  mounted() {
    if (this.role) {
      this.newRole = this.role;
      this.structuredPermissions = this.createStructuredPermissions(
        this.role.Permissions
      );
    }
  },
};
</script>
<style scoped>
.select-all-permission {
  color: var(--eh-primary-5);
  cursor: pointer;
  font-size: 12px;
  margin-top: -10px;
  width: fit-content;
}

.select-all-permission:hover {
  color: var(--eh-primary-4);
}
</style>
