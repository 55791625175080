<template>
  <div v-if="editableTag" ref="patientTag" class="eh-tag-box">
    <div
      :style="
        'background: ' +
        editableTag.ColorCode +
        '70;border-color:' +
        editableTag.ColorCode +
        ';' +
        (editable ? ' cursor:pointer; ' : '')
      "
      :class="editable ? 'editable-tag' : ''"
      class="tag-content"
      @dblclick="isEditting = true"
    >
      {{ $getLanguagedText(editableTag.Name) }}
    </div>
    <div class="tag-edit-box" v-if="editable && isEditting">
      <div style="position: relative">
        {{ $t("healthProfessional.patientList.tag.edit") }}
        <b-icon
          @click="closeTagWithoutSave()"
          class="tag-edit-box-x"
          icon="x"
        />
      </div>
      <fp-input
        v-for="lang in Object.keys(editableTag.Name)"
        :key="lang"
        :label="
          $t('healthProfessional.patientList.tag.tagName') + ' (' + lang + ')'
        "
        v-model="editableTag.Name[lang]"
        :error="validation.firstError('editableTag.Name')"
        :state="!validation.hasError('editableTag.Name')"
      />
      <fp-input
        type="color"
        :label="$t('healthProfessional.patientList.tag.tagColor')"
        v-model="editableTag.ColorCode"
      />
      <!-- <b-form-radio-group
          style="margin-bottom: 20px"
          id="radio-group-tag"
          v-model="editableTag.Type"
          name="radio-sub-component"
      >
        <b-form-radio value="ProjectTag">Projekt</b-form-radio>
        <b-form-radio value="PatientTag">Saját</b-form-radio>
      </b-form-radio-group> -->
      <b-button @click="saveTag">{{ $t("base.basic.save") }}</b-button>
      <b-icon
        @click="deleteTag"
        class="tag-edit-trash"
        scale="1.5"
        icon="trash"
      />
    </div>
  </div>
</template>
<script>
import { TagLogic } from "@/Logic/Backend/tag";
export default {
  name: "fp-tag",
  props: {
    tag: Object,
    editable: Boolean,
  },
  data() {
    return {
      isEditting: false,
      editableTag: this.tag ? JSON.parse(JSON.stringify(this.tag)) : null,
      defaultTag: this.tag,
    };
  },
  validators: {
    "editableTag.Name": function (value) {
      return this.$validator.value(value).required();
    },
  },
  watch: {
    tag(value) {
      this.editableTag = value ? JSON.parse(JSON.stringify(value)) : null;
      this.defaultTag = value;
    },
  },
  methods: {
    closeTagWithoutSave() {
      this.editableTag = JSON.parse(JSON.stringify(this.defaultTag));
      this.isEditting = false;
    },
    async saveTag() {
      //console.log(this.defaultTag);
      this.defaultTag = this.editableTag;
      const setTagResponse = await TagLogic.setTag(
        this.editableTag.TagId,
        this.editableTag
      );
      if (!setTagResponse.Code) {
        this.$emit("getList");
        this.isEditting = false;
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        //hiba
        this.$bvToast.toast(setTagResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    clickOutOfBoxEvent(e) {
      if (
        this.$refs &&
        this.$refs.patientTag &&
        !this.$refs.patientTag.contains(e.target)
      ) {
        this.isEditting = false;
      }
    },
    async deleteTag() {
      const deleteTagResponse = await TagLogic.deleteTag(this.tag.TagId);
      if (!deleteTagResponse.Code) {
        //sikeres
        this.$emit("getList");
        this.$bvToast.toast(this.$t("requestResponse.basic.successDelete"), {
          title: this.$t("base.basic.delete"),
          variant: "info",
          solid: true,
        });
      } else {
        //hiba
        this.$bvToast.toast(deleteTagResponse.Message, {
          title: this.$t("requestResponse.basic.errorDelete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  beforeMount() {
    window.addEventListener("mousedown", this.clickOutOfBoxEvent);
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.clickOutOfBoxEvent);
  },
};
</script>
<style scoped>
.tag-edit-box-x {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.tag-edit-box {
  position: absolute;
  z-index: 1000;
  border: solid 3px var(--eh-primary-5);
  border-radius: 10px;
  background: white;
  padding: 10px;
  min-width: 150px;
}
.tag-content {
  margin: 5px 5px;
  padding: 5px 10px;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  cursor: default;
  /* duplaklikk szöveg kijelölés tiltás */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.tag-edit-box .tag-edit-trash {
  cursor: pointer;
  color: red;
  right: 10px;
  position: inherit;
  bottom: 12px;
}
/* .editable-tag {
  cursor: pointer;
} */
</style>
