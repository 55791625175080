<template>
  <div v-if="module">
    <b-button class="eh-action-button" @click="showCreateModal = true"
      >Új egészségcél</b-button
    >
    <create-health-goal-form-modal
      style="margin-bottom: 10px"
      v-model="showCreateModal"
      :UserId="UserId"
      :Module="module"
      @SetSelectedPlan="SetSelectedPlan"
    />
    <fp-tabs
      key="main-many-tabs-container"
      v-model="tabMainIndex"
      class="eh-main-tab"
      v-if="Object.keys(module.HealthPlans).length > 1"
    >
      <fp-tab
        v-for="(value, key, index) in module.HealthPlans"
        :key="index"
        :title="$t('module.plans.types.' + key)"
        @click="SelectPlanTab(value[0].Value, key)"
      >
        <!-- Ha több egészségcél típusa és több egészségcélja van a modulnak -->
        <fp-tabs
          key="many-and-many-tabs-container"
          v-model="tabSubIndex"
          class="eh-sub-tab"
          v-if="
            module.HealthPlans[Object.keys(module.HealthPlans)[0]].length > 1
          "
        >
          <fp-tab
            v-for="(v, vIndex) in value"
            @click="SelectPlanTab(v.Value, key)"
            :title="$t('module.plans.' + key + '.' + v.Name)"
            :key="index + '-' + v.Value + '-' + vIndex"
          >
            <health-goal-table
              :items="items"
              :planType="key"
              :goalType="v.Value"
              @delete-item="DeleteHealthGoal"
            />
          </fp-tab>
        </fp-tabs>
        <!-- Ha több egészségcél típsa, de azokban csak egy egészségcélja van a modulnak -->
        <fp-tabs
          key="more-and-one-tabs-container"
          v-model="tabMainIndex"
          class="eh-sub-tab"
          v-else
        >
          <fp-tab
            :title="
              $t('module.plans.' + key + '.' + module.HealthPlans[key][0].Name)
            "
          >
            <health-goal-table
              :items="items"
              :planType="key"
              :goalType="module.HealthPlans[key][0].Value"
              @delete-item="DeleteHealthGoal"
            />
          </fp-tab>
        </fp-tabs>
      </fp-tab>
    </fp-tabs>
    <div v-else>
      <!-- Ha csak egy egészségcél típusa, de több egészcégcélja van a modulnak -->
      <fp-tabs
        key="one-and-more-tabs-container"
        v-model="tabSubIndex"
        v-if="module.HealthPlans[Object.keys(module.HealthPlans)[0]].length > 1"
      >
        <fp-tab
          v-for="(value, index) in module.HealthPlans[
            Object.keys(module.HealthPlans)[0]
          ]"
          :key="index + '-' + value"
          :title="
            $t(
              'module.plans.' +
                Object.keys(module.HealthPlans)[0] +
                '.' +
                value.Name
            )
          "
          @click="
            SelectPlanTab(value.Value, Object.keys(module.HealthPlans)[0])
          "
        >
          <health-goal-table
            :items="items"
            :planType="Object.keys(module.HealthPlans)[0]"
            :goalType="value.Value"
            @delete-item="DeleteHealthGoal"
          />
        </fp-tab>
      </fp-tabs>
      <!-- Ha csak egyetlen egészségcélja van a mondulnak -->
      <fp-tabs key="one-and-one-tabs-container" v-else>
        <fp-tab
          :title="
            $t(
              'module.plans.' +
                Object.keys(module.HealthPlans)[0] +
                '.' +
                module.HealthPlans[Object.keys(module.HealthPlans)[0]][0].Name
            )
          "
        >
          <health-goal-table
            :items="items"
            :planType="Object.keys(module.HealthPlans)[0]"
            :goalType="module.HealthPlans[Object.keys(module.HealthPlans)[0]][0].Value"
            @delete-item="DeleteHealthGoal"
          />
        </fp-tab>
      </fp-tabs>
    </div>
  </div>
</template>

<script>
import HealthGoalTable from "./Components/HealthGoalTable.vue";
import CreateHealthGoalFormModal from './Components/Inputs/CreateHealthGoalFormModal.vue';
import { HealthPlanLogic } from "../../Logic/Backend/health-plan";
import { UserPreferencesLogic } from "../../Logic/Backend/user-preferences";

export default {
  name: "HealthGoal",
  components: {
    HealthGoalTable,
    CreateHealthGoalFormModal,
  },
  props: {
    UserId: Number,
    ModuleName: String,
  },
  data() {
    return {
      items: null,
      planTypes: {
        [this.$enums.HealthGoalType.interval]: this.$t(
          "client.healthGoals.goalInterval"
        ),
        [this.$enums.HealthGoalType.daily]: this.$t(
          "client.healthGoals.goalDaily"
        ),
        [this.$enums.HealthGoalType.property]: this.$t(
          "client.healthGoals.goalProperty"
        ),
      },
      module: null,
      tabSubIndex: 0,
      tabMainIndex: 0,
      activeTab: null,
      showCreateModal: false,
    };
  },
  watch: {
    async ModuleName() {
      await this.GetModules();
      this.tabSubIndex = 0;
      this.tabMainIndex = 0;
    },
    async UserId() {
      await this.GetModules();
    },
  },
  methods: {
    async SetSelectedPlan(payload) {
      await this.SelectPlanTab(payload.Type, payload.PlanType);
    },
    async SelectPlanTab(value, key) {
      this.activeTab = {
        Plan: key,
        Type: value,
      };

      this.tabMainIndex = Object.keys(this.module.HealthPlans).findIndex(
        (e) => e == this.activeTab.Plan
      );
      this.tabSubIndex = this.module.HealthPlans[this.activeTab.Plan].findIndex(
        (e) => e.Value == this.activeTab.Type
      );

      await this.GetPlansByPlanType(this.activeTab.Type, this.activeTab.Plan);
    },
    async GetPlansByPlanType(type, plan) {
      this.items = null;
      var getPlanResponse = null;
      if (plan == "all") {
        getPlanResponse = await HealthPlanLogic.getHealthPlanList(plan, {
          UserId: this.UserId,
        });
      } else {
        getPlanResponse = await HealthPlanLogic.getHealthPlanList(plan, {
          Type: type,
          UserId: this.UserId,
        });
      }
      if (!getPlanResponse.Code) {
        this.items = getPlanResponse;
      } else {
        this.$bvToast.toast(getPlanResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.modules." + this.module.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async DeleteHealthGoal(isDelete) {
      if (isDelete) {
        await this.GetPlansByPlanType(this.activeTab.Type, this.activeTab.Plan);
      }
    },
    async GetModules() {
      const getUserPreferenceResponse =
        await UserPreferencesLogic.getUserPreferences();
      if (!getUserPreferenceResponse.Code) {
        this.module = getUserPreferenceResponse.Modules[this.ModuleName];
        this.activeTab = {
          Plan: Object.keys(this.module.HealthPlans)[0],
          Type: this.module.HealthPlans[
            Object.keys(this.module.HealthPlans)[0]
          ][0].Value,
        };

        await this.GetPlansByPlanType(this.activeTab.Type, this.activeTab.Plan);
      } else {
        this.$bvToast.toast(getUserPreferenceResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.modules." + this.module.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.GetModules();
  },
};
</script>
<style>
.row.plan-form {
  width: 30%;
  margin: auto;
}
.diary-title {
  margin-top: 20px;
  text-align: center;
}
.input-group
  > .input-group-append:not(:last-child)
  > .target-date-append-button.btn-group
  > .btn {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
@media (max-width: 870px) {
  .row.plan-form {
    width: 90%;
  }
}
</style>
