import LegalManager from "./LegalManager.vue";
import LegalStatementFormModal from "./LegalStatementFormModal.vue";

export default {
  install(Vue) {
    //Modul komponensek regisztrálása
    Vue.component("fp-legal-manager", LegalManager);
    Vue.component("fp-legal-statement-form-modal", LegalStatementFormModal);
  },
};
