<template>
  <div>
    <b-button class="eh-action-button" @click="refreshList(true)"
      >Jelentés lista frissítés</b-button
    >
    <div style="display: flex">
      <fp-input
        style="width: 250px"
        label="Jelentés azonosító"
        v-model="filterReportId"
        @change="filterList"
      />
      <fp-input
        style="width: 250px; margin: 0px 10px"
        label="Leírás"
        v-model="filterDescription"
        @change="filterList"
      />
      <fp-select
        style="width: 250px"
        label="Állapot"
        v-model="filterState"
        :items="filterStateList"
        valueKey="Value"
        textKey="Text"
        @change="filterList"
      />
    </div>
    <fp-pagination
      v-if="reportListSize > 10"
      :itemsPerPage.sync="reportPerPage"
      :listSize.sync="reportListSize"
      :currentPageNumber.sync="currentPage"
    />
    <fp-table-list
      :bordered="true"
      :items="reportList"
      :fields="reportListField"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
    >
      <template #ReportId="row">
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés azonosító'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="file-binary" />
            <b-icon stacked icon="text-left" scale="0.5" shift-v="3" />
          </b-iconstack>
          {{ row.item.ReportId }}
        </div>
        <!-- DateRange -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés időszak'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar3-range" />
          </b-iconstack>
          {{ $convertDateToString(row.item.StartDate, "YYYY-MM-DD") }} -
          {{ $convertDateToString(row.item.EndDate, "YYYY-MM-DD") }}
        </div>
        <!-- CreatedAt -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés létrehozás dátuma'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar" />
            <b-icon stacked icon="pencil-fill" scale="0.6" shift-v="-1.5" />
          </b-iconstack>
          {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
        </div>
        <!-- State -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés állapota'"
            :variant="getVariantByState(row.item.State)"
            font-scale="2.5"
          >
            <b-icon stacked icon="arrow-clockwise"></b-icon>
            <b-icon
              stacked
              icon="x"
              scale="0.5"
              shift-v="1.5"
              shift-h="-1"
            ></b-icon>
            <b-icon
              stacked
              icon="check"
              scale="0.6"
              shift-v="-2"
              shift-h="1.2"
            ></b-icon>
          </b-iconstack>
          <div
            :class="'text-' + getVariantByState(row.item.State)"
            :style="
              getVariantByState(row.item.State)
                ? 'font-weight:900;text-transform: uppercase;'
                : ''
            "
          >
            {{
              $enums.Prescription.NeakReportStatus[row.item.State]
                ? $enums.Prescription.NeakReportStatus[row.item.State].Text
                : row.item.State
            }}
          </div>
        </div>
        <!-- Transaction -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Tranzakció azonosító'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="file-font" />
          </b-iconstack>
          {{ row.item.TransactionId }}
        </div>
      </template>
      <!-- <template #DateRange="row">
        {{ $convertDateToString(row.item.StartDate, "YYYY-MM-DD") }} -
        {{ $convertDateToString(row.item.EndDate, "YYYY-MM-DD") }}
      </template>
      <template #CreatedAt="row">
        {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
      </template> -->
      <template #Operations="row">
        Jelentés műveletek:
        <div>
          <b-icon-eye-fill
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Megtekintés'"
            @click="openNeakModal(row.item, 'showFormModal', true)"
          />
          <b-icon-trash-fill
            v-if="
              (row.item.State == 'New' ||
                row.item.State == 'WrongAndMustBeChanged') &&
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission)
            "
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés törlése'"
            class="h4"
            variant="danger"
            @click="deleteReport(row.item.ReportId)"
          />
          <b-icon
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              (row.item.State == 'New' ||
                row.item.State == 'OutDated' ||
                row.item.State == 'WrongAndMustBeChanged' ||
                row.item.State == 'WrongAndCanBeChanged')
            "
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés formai ellenőrzése'"
            class="h4"
            icon="check2-circle"
            @click="checkReport(row.item.ReportId)"
          />
          <b-icon
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés felküldése'"
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              (row.item.State == 'New' ||
                row.item.State == 'OutDated' ||
                row.item.State == 'WrongAndMustBeChanged')
            "
            icon="cloud-upload"
            @click="openNeakModal(row.item, 'showSubmitForm')"
          />
          <b-icon
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés javítás'"
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              (row.item.State == 'WrongAndCanBeChanged' ||
                row.item.State == 'Accepted')
            "
            icon="tools"
            @click="openNeakModal(row.item, 'showChangeForm')"
          />
          <b-icon
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Érvényesítés/érvénytelenítés'"
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              row.item.State == 'WaitingForValidation'
            "
            icon="tools"
            @click="openNeakModal(row.item, 'showValidateForm')"
          />
          <!-- TODO: Hibás jelentés nyugtáható ezért hiba üzit dobjunk!!!! -->
          <b-icon
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés nyugtázás'"
            v-if="
              row.item.State == 'WrongAndCanBeChanged' ||
              row.item.State == 'Accepted'
            "
            icon="receipt"
            @click="openNeakModal(row.item, 'showAcknowledgeForm')"
          />
          <report-form-modal
            v-model="row.item.showFormModal"
            :reportId="row.item.ReportId"
            @refreshList="filterList"
            @change="modalVisibilityChange"
          />
          <neak-report
            @refreshiList="filterList"
            @change="modalVisibilityChange"
            v-model="row.item.showSubmitForm"
            :reportId="row.item.ReportId"
            type="Submit"
          />
          <neak-report
            @refreshiList="filterList"
            @change="modalVisibilityChange"
            v-model="row.item.showChangeForm"
            :reportId="row.item.ReportId"
            type="Change"
          />
          <neak-report
            @refreshiList="filterList"
            @change="modalVisibilityChange"
            v-model="row.item.showAcknowledgeForm"
            :reportId="row.item.ReportId"
            type="Acknowledge"
          />
          <neak-report
            @refreshiList="filterList"
            @change="modalVisibilityChange"
            v-model="row.item.showValidateForm"
            :reportId="row.item.ReportId"
            type="Validate"
          />
        </div>
        <hr />
        Fájl letöltések:
        <div>
          <b-iconstack
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              row.item.State == 'New'
            "
            @click="downloadHeader(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Fejrész letöltése'"
          >
            <b-icon
              stacked
              icon="card-heading"
              scale="0.35"
              shift-h="-8"
              shift-v="4"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
          <b-iconstack
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              row.item.State == 'New'
            "
            @click="downloadBody(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Törzsrész letöltése'"
          >
            <b-icon
              stacked
              icon="file-post"
              scale="0.5"
              shift-h="4"
              shift-v="5"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
          <b-iconstack
            @click="excelExport(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Excel export'"
          >
            <b-icon
              stacked
              icon="file-ruled"
              scale="0.5"
              shift-h="4"
              shift-v="5"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
          <b-iconstack
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission) &&
              (row.item.State == 'Accepted' ||
                row.item.State == 'WrongAndMustBeChanged' ||
                row.item.State == 'WrongAndCanBeChanged' ||
                row.item.State == 'AcknowledgedWrong' ||
                row.item.State == 'AcknowledgedGood')
            "
            @click="downloadAttachment(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Válasz csatolmány letöltése'"
          >
            <b-icon
              stacked
              icon="cloud-check"
              scale="0.5"
              shift-h="4"
              shift-v="5"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
        </div>
      </template>
    </fp-table-list>
    <fp-form-modal
      title="Jelentés hiányosságok"
      v-model="showReportValidateErrors"
    >
      <template #content>
        <li
          v-for="(error, index) in reportValidateErrors"
          :key="'report-validate-error' + index"
        >
          {{ error }}
        </li>
      </template>
    </fp-form-modal>
    <b-toast
      v-model="neakConnectionToast"
      title="NEAK kapcsolat"
      solid
      no-auto-hide
      variant="primary"
    >
      <b-spinner small /> Csatlakozás...
    </b-toast>
  </div>
</template>
<script>
import moment from "moment";
import ReportFormModal from "../Report/ReportFormModal.vue";
import NeakReport from "../Report/NeakReport.vue";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import { AuthLogic } from "../../../../Logic/Backend/auth";

export default {
  name: "BeverReportList",
  components: { ReportFormModal, NeakReport },
  props: {
    refreshing: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      reportPerPage: 10,
      reportListSize: null,
      reportList: null,
      sortDesc: true,
      sortBy: "ReportId",
      reportListField: [
        { key: "ReportId", label: "Jelentés adatok", sortable: true },
        { key: "Description", label: "Leírás" },
        { key: "Operations", label: "Műveletek" },
      ],
      filterReportId: null,
      filterDescription: null,
      filterState: null,
      showReportValidateErrors: false,
      reportValidateErrors: null,
      filterStateList: [
        { Text: "Minden állapot", Value: null },
        ...Object.values(this.$enums.Prescription.NeakReportStatus),
      ],
      prescriptionPermission: "WriteMedicalDevicePrescription",
      neakConnectionToast: false,
      progressOperation: false,
    };
  },
  watch: {
    async refreshing(input) {
      if (input) {
        await this.refreshList();
        this.$emit("update:refreshing", false);
      }
    },
    async currentPage() {
      if (this.reportListSize && this.currentPage) {
        if (
          this.$route.query.PerPage != this.reportPerPage ||
          this.$route.query.Page != this.currentPage
        ) {
          this.$router.replace({
            name: this.$route.name,
            query: { PerPage: this.reportPerPage, Page: this.currentPage },
          });
        }
        await this.refreshList();
      }
    },
    async reportPerPage() {
      console.log("REPORT_PER_PAGE CHANGE", this.reportPerPage);
      if (this.reportListSize && this.currentPage) {
        if (
          this.$route.query.PerPage != this.reportPerPage ||
          this.$route.query.Page != this.currentPage
        ) {
          this.$router.replace({
            name: this.$route.name,
            query: { PerPage: this.reportPerPage, Page: this.currentPage },
          });
        }
        await this.refreshList();
      }
    },
    reportListSize(input) {
      if (input <= this.reportPerPage) {
        if (
          this.$route.query.PerPage != this.reportPerPage ||
          this.$route.query.Page != this.currentPage
        ) {
          this.$router.replace({
            name: this.$route.name,
            query: { PerPage: this.reportPerPage, Page: this.currentPage },
          });
        }
        this.currentPage = 1;
      }
    },
    async sortDesc() {
      await this.refreshList();
    },
    async sortBy() {
      await this.refreshList();
    },
  },
  computed: {
    userProjectPermissions() {
      if (this.$store.state.loggedUser) {
        return this.$store.state.loggedUser.ProjectPermissions;
      }
      return null;
    },
  },
  methods: {
    async modalVisibilityChange(visible) {
      if (visible == false) {
        await this.filterList();
      }
    },
    openNeakModal(rowItem, neakOperationMethodName) {
      this.progressOperation = true;
      this.$set(rowItem, neakOperationMethodName, true);
    },
    async filterList() {
      this.progressOperation = false;
      await this.refreshList();
    },
    async downloadHeader(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const headerResponse =
        await MedicalDeviceLogic.downloadFinanceReportHeader(report.ReportId);
      if (!headerResponse.Code) {
        const blob = await headerResponse.Data.blob();
        const contentDisposition = headerResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_Fej_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(headerResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés fejrészének letöltése során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    async downloadBody(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const bodyResponse = await MedicalDeviceLogic.downloadFinanceReportBody(
        report.ReportId
      );
      if (!bodyResponse.Code) {
        const blob = await bodyResponse.Data.blob();
        const contentDisposition = bodyResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_Torzs_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(bodyResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés törzsrészének letöltése során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    async excelExport(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const getResponse = await MedicalDeviceLogic.downloadFinanceReportExcel(
        report.ReportId
      );
      if (!getResponse.Code || getResponse.Code == 0) {
        const blob = await getResponse.Data.blob();
        const contentDisposition = getResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_osszesito_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(getResponse.Message, {
          variant: "danger",
          title: "Hiba történt a excel importálása során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    async downloadAttachment(report) {
      this.neakConnectionToast = true;
      var loginToken = await this.loginNeak();
      if (loginToken) {
        const body = {
          NeakLoginToken: loginToken.LoginToken,
        };
        document.getElementById("app").style.cursor = "wait !important";
        const getResponse =
          await MedicalDeviceLogic.downloadFinanceReportAttachment(
            report.ReportId,
            body
          );
        if (!getResponse.Code || getResponse.Code == 0) {
          const blob = await getResponse.Data.blob();
          const contentDisposition = getResponse.Headers.get(
            "content-disposition"
          );
          const filenameParam = contentDisposition
            .split(";")
            .find((x) => x.includes("filename="));
          const filename = filenameParam
            ? filenameParam.split("=")[1]
            : "Jelentes_osszesito_" +
              moment(report.From).format("YYYYMMDD") +
              "_" +
              moment(report.To).format("YYYYMMDD");
          this.downloadFile(blob, filename);
          await this.logoutNeak(loginToken);
        } else {
          this.$bvToast.toast(getResponse.Message, {
            variant: "danger",
            title: "Hiba történt a válasz csatolmány letöltése során!",
            solid: true,
          });
        }
        document.getElementById("app").style.cursor = "default";
      }
      this.neakConnectionToast = false;
    },
    downloadFile(blob, filename) {
      var file = new File([blob], filename);
      var fileURL = URL.createObjectURL(file);
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.download = filename;
      fileLink.target = "_blank";
      fileLink.click();
    },
    async getReportList(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceReport(params);
      if (!getResponse.Code) {
        return getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés lista betöltése!",
          solid: true,
        });
        return null;
      }
    },
    async deleteReport(reportId) {
      if (
        confirm(
          "Bitosan törölni szeretné a " + reportId + " azonosítójú jelentést?"
        )
      ) {
        const deleteResponse = await MedicalDeviceLogic.deleteReport(reportId);
        if (!deleteResponse.Code || deleteResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen törölte a " + reportId + " azonosítójú jelentést!",
            {
              variant: "info",
              title: "Sikeres törlés!",
              solid: true,
            }
          );
          await this.refreshList();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            variant: "danger",
            title:
              "Hiba történt a " +
              reportId +
              " azonosítójú jelentés törlése során!",
            solid: true,
          });
        }
      }
    },
    async checkReport(reportId) {
      const checkResponse = await MedicalDeviceLogic.getReportValidate(
        reportId
      );
      if (!checkResponse.Code) {
        if (checkResponse && checkResponse.length) {
          this.showReportValidateErrors = true;
          this.reportValidateErrors = checkResponse.map((v) => v.ErrorMessage);
        } else {
          this.$bvToast.toast(
            "A " + reportId + " azonosítójú jelentés adatai helyesek!",
            {
              variant: "success",
              title: "Sikeres ellenőrzés!",
              solid: true,
            }
          );
        }
      } else {
        this.$bvToast.toast(checkResponse.Message, {
          variant: "danger",
          title:
            "Hiba történt a " +
            reportId +
            " azonosítójú jelentés ellenőrzése során!",
          solid: true,
        });
      }
    },
    getVariantByState(state) {
      switch (state) {
        case "WrongAndMustBeChanged":
          return "danger";
        case "WrongAndCanBeChanged":
          return "warning";
        case "AcknowledgedWrong":
          return "warning";
        case "Accepted":
          return "success";
        case "AcknowledgedGood":
          return "success";
        default:
          return null;
      }
    },
    async getReportCount(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceReportCount(
        params
      );
      if (!getResponse.Code) {
        return getResponse.ElementCount;
      } else {
        return null;
      }
    },
    async getReportListByCurrentPage() {
      const listFilterParams = {
        ReportId: this.filterReportId ? this.filterReportId : null,
        "Description-contains": this.filterDescription,
        reportState: this.filterState,
        ["-orderby" + (this.sortDesc ? "desc" : "")]: this.sortBy,
      };

      this.reportListSize = await this.getReportCount(listFilterParams);

      const listParams = {
        ...listFilterParams,
        "-offset":
          this.currentPage &&
          this.reportListSize &&
          this.reportListSize > this.reportPerPage
            ? (this.currentPage - 1) * this.reportPerPage
            : null,
        "-limit": this.reportPerPage,
      };

      this.reportList = await this.getReportList(listParams);
    },
    async refreshList(returnMsg = false) {
      await this.getReportListByCurrentPage();
      if (
        this.userProjectPermissions &&
        this.userProjectPermissions.includes(this.prescriptionPermission)
      ) {
        const loginToken = await this.loginNeak();
        if (loginToken && !this.progressOperation) {
          const isSuccess = await this.updateFromNeak(loginToken);
          if (isSuccess && returnMsg) {
            this.$bvToast.toast(
              "Sikeresen frissítésre kerültek a jelentés státuszai a NEAK rendszerből.",
              {
                variant: "success",
                title: "Sikeres jelentés NEAK csatlakozás!",
                solid: true,
              }
            );
          }
          await this.getReportListByCurrentPage();
          await this.logoutNeak(loginToken);
        }
      }
    },
    async loginNeak() {
      const loginResponse = await AuthLogic.loginNeak();
      if (!loginResponse.Code) {
        return loginResponse;
      } else {
        this.$bvToast.toast(loginResponse.Message, {
          variant: "danger",
          title: "Hiba történt a NEAK-hoz való csatlakozás során!",
          solid: true,
        });
      }
    },
    async logoutNeak(loginToken) {
      const query = {
        neakLoginToken: loginToken.LoginToken,
      };
      const logoutResponse = await AuthLogic.logoutNeak(query);
      if (logoutResponse.Code && logoutResponse.Code != 0) {
        this.$bvToast.toast(logoutResponse.Message, {
          variant: "danger",
          title: "Hiba történt a NEAK-tól való lecsatlakozás során!",
          solid: true,
        });
      }
    },
    async updateFromNeak(loginToken) {
      if (loginToken) {
        const body = {
          NeakLoginToken: loginToken.LoginToken,
        };
        const updateResponse = await MedicalDeviceLogic.reportUpdateAll(body);
        if (!updateResponse.Code || updateResponse.Code == 0) {
          return true;
        } else {
          this.$bvToast.toast(updateResponse.Message, {
            variant: "danger",
            title:
              "Hiba történt a NEAK-ból való jelentés státusz frissítése során!",
            solid: true,
          });
          return false;
        }
      }
    },
  },
  async mounted() {
    this.reportPerPage = this.$route.query.PerPage
      ? Number.parseInt(this.$route.query.PerPage)
      : 10;
    this.currentPage = this.$route.query.Page
      ? Number.parseInt(this.$route.query.Page)
      : 1;

    await this.refreshList();
  },
};
</script>
<style scoped>
.report-element-data {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}
</style>
