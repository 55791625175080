<template>
  <div v-if="cssVariables">
    <b-button v-if="isCreated && !isEditable" @click="isEditable = true"
      >Szerkesztés
    </b-button>
    <b-button v-if="isCreated && isEditable" @click="setEdit">Mentés </b-button>
    <b-button v-if="isCreated && isEditable" @click="cancelEdit"
      >Mégsem
    </b-button>
    <div>
      <div style="text-align: center" class="h3">Stílus beállítások</div>
      <!-- Főszínek -->
      <b-row class="nopadding" cols="1" cols-md="2">
        <!-- Elsődleges színek -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <fp-input
              label="Elsődleges szín 1. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.primary1"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Elsődleges szín 2. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.primary2"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Elsődleges szín 3. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.primary3"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Elsődleges szín 4. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.primary4"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Elsődleges szín 5. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.primary5"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
        <!-- Másodlagos színek -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <fp-input
              label="Másodlagos szín 1. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.secondary1"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Másodlagos szín 2. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.secondary2"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Másodlagos szín 3. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.secondary3"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Másodlagos szín 4. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.secondary4"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Másodlagos szín 5. árnyalat"
              type="color"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.secondary5"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
        <!-- Elemek sugarai (input, kártya, táblázat, lapozó, lista, címke) -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <fp-slider
              title="Inputok sugara"
              :min="0"
              :max="30"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.inputRadius"
              @change="changeSytle"
            />
          </b-col>

          <b-col>
            <fp-slider
              title="Kártya panel sugara"
              :min="0"
              :max="35"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.panelCardRadius"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Táblázat sugara"
              :min="0"
              :max="30"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listTableRadius"
              @change="changeSytle"
            />
          </b-col>

          <b-col>
            <fp-slider
              title="Táblázat lapozó sugara"
              :min="0"
              :max="20"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listTablePaginationRadius"
              @change="changeSytle"
            />
          </b-col>

          <b-col>
            <fp-slider
              title="Lista doboz sugara"
              :min="0"
              :max="30"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listBoxRadius"
              @change="changeSytle"
            />
          </b-col>

          <b-col>
            <fp-slider
              title="Címke sugara"
              :min="0"
              :max="20"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.tagRadius"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
        <!-- Elemek szegély méretei -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <fp-slider
              title="Inputok szegély mérete"
              :min="1"
              :max="7"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.inputBorderSize"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Kártya panel szegély mérete"
              :min="1"
              :max="10"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.panelCardBorderSize"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Táblázat szegély mérete"
              :min="1"
              :max="20"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listTableBorderSize"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Táblázat lapozó szegély mérete"
              :min="1"
              :max="15"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listTablePaginationBorderSize"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Lista doboz szegély mérete"
              :min="1"
              :max="25"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.listBoxBorderSize"
              @change="changeSytle"
            />
          </b-col>
          <b-col>
            <fp-slider
              title="Címke szegély mérete"
              :min="1"
              :max="10"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.tagBorderSize"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
        <!-- Egyéb elem sugarak -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <fp-slider
              title="Gombok sugara"
              :min="0"
              :max="20"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.buttonRadius"
              @change="changeSytle"
            />
          </b-col>
          <!-- <b-col>
            <fp-slider
              title="Modul dobozok sugara"
              :min="0"
              :max="30"
              v-model="cssVariables.moduleBoxRadius"
              @change="changeSytle"
            />
          </b-col> -->
          <b-col>
            <fp-slider
              title="Előugró panel sugara"
              :min="0"
              :max="30"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.formModalRadius"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
        <!-- Logok -->
        <b-row class="nopadding" cols="1">
          <b-col>
            <!-- <fp-input
              style="margin-bottom:30px"
              type="file"
              label="Fejléc logó"
              @change="changeStyleLogo"
            /> -->
            <fp-select
              label="Fejléc logó"
              @change="changeSytle"
              :items="headerLogoImgs"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.headerLogo"
              textKey="Text"
              valueKey="Url"
            >
              <template #element="e">
                <img style="height: 30px" :src="require('@/Assets' + e.Url)" />
              </template>
            </fp-select>
          </b-col>
          <b-col>
            <fp-slider
              title="Logó magassága"
              :min="10"
              :max="300"
              :disabled="isCreated && !isEditable"
              v-model="cssVariables.headerLogoHeight"
              @change="changeSytle"
            />
          </b-col>
        </b-row>
      </b-row>
    </div>
    <!-- Teszt komponensek -->
    <div class="eh-project-styles">
      <div style="text-align: center" class="h3">Példa komponensek</div>
      <fp-tabs>
        <fp-tab title="Példa tab - Háttér színek">
          <div>Fejléc háttér</div>
          <div class="eh-header-backround-style">
            <div class="eh-test-logo"></div>
          </div>
          <div>Törzsrész háttér</div>
          <div class="eh-content-backround-style"></div>
        </fp-tab>
        <fp-tab title="Példa tab - Inputok">
          <b-row class="nopadding" cols="1" cols-md="2">
            <b-col>
              <fp-input
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-text-area
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-select
                :searchable="true"
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
                :items="fpFormSelectItems"
              />
            </b-col>
            <b-col>
              <fp-select
                :searchable="true"
                type="multi"
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
                :items="fpFormSelectItems"
              />
            </b-col>
            <b-col>
              <fp-date-picker
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-time-picker
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-date-time-picker
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-slider title="Cím" description="Leírás" error="Hiba üzenet" />
            </b-col>
            <b-col>
              <fp-toggle-switch
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <fp-toggle-switch
                type="round"
                label="Kúszó cím"
                placeholder="Súgó"
                description="Leírás"
                error="Hiba üzenet"
              />
            </b-col>
            <b-col>
              <!-- TODO: label check -->
              <div style="width: fit-content">
                <fp-date-range-button
                  label="Cím"
                  placeholder="Súgó"
                  description="Leírás"
                  error="Hiba üzenet"
                />
              </div>
            </b-col>
            <b-col>
              <fp-bouble-modal title="Teszt buborék" :length="1" />
            </b-col>
          </b-row>
        </fp-tab>
        <fp-tab title="Példa tab - Megjelenítések">
          <b-row class="nopadding" cols="1" cols-md="2">
            <!-- Tag, modal -->
            <b-col>
              <tag
                :editable="false"
                :tag="{ Name: { hu: 'Teszt címke' }, ColorCode: '#987654' }"
              />
              <b-button @click="formModelOpen = true"
                >Előugró panel megjelenítés</b-button
              >
              <fp-form-modal
                otherClass="test-modal-style"
                title="Cím"
                v-model="formModelOpen"
              >
                <template #content> Előugró panel </template>
              </fp-form-modal>
            </b-col>
            <!-- Panel card -->
            <b-col>
              <fp-panel-card title="Összecsukható fejléc" description="Leírás">
                <template #content> Panel Törzs része </template>
              </fp-panel-card>
            </b-col>
            <!-- Listbox -->
            <b-col>
              <div class="eh-listbox-list">
                <div class="eh-listbox-list-header" href="#">Fejléc gomb</div>
                <div
                  class="eh-listbox-list-item"
                  v-for="item in listboxItems"
                  :key="item.ItemId"
                  :class="item.Active ? 'eh-listbox-list-item-active' : ''"
                  href="#"
                >
                  <template>
                    {{ item.Value }}
                  </template>
                </div>
              </div>
            </b-col>
            <!-- Table -->
            <b-col>
              <fp-table-list :hasPagination="true" :items="tableListItems" />
            </b-col>
          </b-row>
        </fp-tab>
        <fp-tab title="Példa tab - Gombok">
          <b-button class="eh-header-button">Fejléc fő gomb</b-button>
          <div class="router-link-active router-link-exact-active">
            <b-button class="eh-header-button">Fejléc fő gomb aktív</b-button>
          </div>
          <b-button class="eh-header-view-button">Fejléc gomb</b-button>
          <div class="router-link-active router-link-exact-active">
            <b-button class="eh-header-view-button">Fejléc gomb aktív</b-button>
          </div>
          <b-button class="eh-action-button">Akció gomb</b-button>
        </fp-tab>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
import Tag from "@/Modules/Tag/Tag.vue";
import { AttachmentLogic } from "@/Logic/Backend/attachment";
import { ProjectLogic } from "@/Logic/Backend/project";
//import {eventBus } from "../../main";
export default {
  components: { Tag },
  name: "ProjectStyle",
  props: {
    projectStyle: [String, Object],
    isImportedStyle: Boolean,
    isCreated: Boolean,
    createdProjectId: Number,
  },
  data() {
    return {
      formModelOpen: false,
      fpFormSelectItems: ["EGY", "KETTŐ", "HÁROM"],
      tableListItems: [
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
        { Adat1: "Adat1", Adat2: "Adat2" },
      ],
      listboxItems: [
        { ItemId: 1, Value: "Adat1 (aktív)", Active: true },
        { ItemId: 2, Value: "Adat2" },
        { ItemId: 3, Value: "Adat3" },
        { ItemId: 4, Value: "Adat4" },
        { ItemId: 5, Value: "Adat5" },
        { ItemId: 6, Value: "Adat6" },
        { ItemId: 7, Value: "Adat7" },
        { ItemId: 8, Value: "Adat8" },
        { ItemId: 9, Value: "Adat9" },
        { ItemId: 10, Value: "Adat10" },
        { ItemId: 11, Value: "Adat11" },
        { ItemId: 12, Value: "Adat12" },
        { ItemId: 13, Value: "Adat13" },
        { ItemId: 14, Value: "Adat14" },
        { ItemId: 15, Value: "Adat15" },
        { ItemId: 16, Value: "Adat16" },
        { ItemId: 17, Value: "Adat17" },
      ],
      headerLogoImgs: [
        {
          Text: "Fókusz Program",
          Url: "/ImgIcons/Logo/Fp/fp-logo.png",
          Value: "/img/fp-logo",
        },
        {
          Text: "eHealth Software Solutions",
          Url: "/ImgIcons/Logo/Ehss/ehealth-logo.png",
          Value: "/img/ehealth-logo",
        },
        /* {
          Text: "COVID Triage",
          Url: "/ImgIcons/Logo/covid-triage-logo.png",
          Value: "/img/covid-triage-logo",
        },
        {
          Text: "COVID FollowApp",
          Url: "/ImgIcons/Logo/covid-follow-app-logo.png",
          Value: "/img/covid-follow-app-logo",
        },
        {
          Text: "OncoGenomic",
          Url: "/ImgIcons/Logo/Og/og_1_white.png",
          Value: "/img/og_1_white",
        },
        {
          Text: "TMKK",
          Url: "/ImgIcons/Logo/Tmkk/tmkk_logo.png",
          Value: "/img/tmkk_logo",
        },
        {
          Text: "Moodwave",
          Url: "/ImgIcons/Logo/Moodwave/moodwave-logo.png",
          Value: "/img/moodwave-logo",
        }, */
        {
          Text: "ÚtAzEgészséghez",
          Url: "/ImgIcons/Logo/SzentFerenc/uae-logo.png",
          Value: "/img/uae2-logo",
        },
        {
          Text: "GOKVI",
          Url: "/ImgIcons/Logo/Gokvi/gokvi-logo.png",
          Value: "/img/gokvi-logo",
        },
        {
          Text: "Medtronic",
          Url: "/ImgIcons/Logo/Medtronic/medtronic-logo.png",
          Value: "/img/medtronic-logo",
        },
      ],
      defaultCssVariables: {
        primary5: "#668293",
        primary4: "#afc5d3",
        primary3: "#cddbe5",
        primary2: "#dde7ee",
        primary1: "#edf3f7",

        secondary5: "#ff8400",
        secondary4: "#ffa500",
        secondary3: "#fcb913",
        secondary2: "#fecf6a",
        secondary1: "#ffe9b2",

        buttonRadius: 10,
        inputRadius: 10,
        inputBorderSize: 3,
        moduleBoxRadius: 10,
        panelCardRadius: 10,
        panelCardBorderSize: 3,
        listTableRadius: 5,
        listTableBorderSize: 1,
        listTablePaginationRadius: 10,
        listTablePaginationBorderSize: 3,
        listBoxRadius: 10,
        listBoxBorderSize: 3,
        tagRadius: 10,
        tagBorderSize: 4,
        tagBoxRadius: 10,
        tagBoxBorderSize: 3,
        formModalRadius: 10,
        headerLogo: "/logo/fp/fp-logo.png",
        headerLogoHeight: 80,
      },
      importedCssVariables: null,
      cssVariables: null,
      isEditable: false,
    };
  },
  watch: {
    isImportedStyle(input) {
      if (input) {
        this.setCssVariable();
        this.$emit("update:isImportedStyle", false);
      }
    },
    isEditable(input) {
      if (input) {
        this.$emit("editingTab", true);
      } else {
        this.$emit("editingTab", false);
      }
    },
  },
  methods: {
    setCssVariable() {
      if (this.projectStyle) {
        this.cssVariables = this.setStyleSheet();
        this.importedCssVariables = JSON.parse(
          JSON.stringify(this.projectStyle)
        );
      } else {
        this.cssVariables = JSON.parse(
          JSON.stringify(this.defaultCssVariables)
        );
      }
    },
    setStyleSheet() {
      const styleSheetTextToConvertJSON = JSON.parse(
        JSON.stringify(this.projectStyle)
      )
        .replace(":root ", "")
        .replaceAll(";", ",")
        .replaceAll("--", '"--')
        .replaceAll(":", '":')
        .replaceAll(":", ':"')
        .replaceAll(",", '",')
        .replace("}", '"}')
        .replaceAll('""', '"');
      const styleSheetJSON = JSON.parse(styleSheetTextToConvertJSON);
      const styleSheet = this.convertCssToValues(styleSheetJSON);
      return styleSheet;
    },
    convertCssToValues(styleSheetJSON) {
      return {
        primary5: styleSheetJSON["--eh-primary-5"],
        primary4: styleSheetJSON["--eh-primary-4"],
        primary3: styleSheetJSON["--eh-primary-3"],
        primary2: styleSheetJSON["--eh-primary-2"],
        primary1: styleSheetJSON["--eh-primary-1"],

        secondary5: styleSheetJSON["--eh-secondary-5"],
        secondary4: styleSheetJSON["--eh-secondary-4"],
        secondary3: styleSheetJSON["--eh-secondary-3"],
        secondary2: styleSheetJSON["--eh-secondary-2"],
        secondary1: styleSheetJSON["--eh-secondary-1"],

        buttonRadius: Number.parseInt(
          styleSheetJSON["--eh-button-radius"].replace("px", "")
        ),
        inputRadius: Number.parseInt(
          styleSheetJSON["--eh-input-radius"].replace("px", "")
        ),
        inputBorderSize: Number.parseInt(
          styleSheetJSON["--eh-input-border-size"].replace("px", "")
        ),
        moduleBoxRadius: Number.parseInt(
          styleSheetJSON["--eh-module-box-radius"].replace("px", "")
        ),
        panelCardRadius: Number.parseInt(
          styleSheetJSON["--eh-panel-card-radius"].replace("px", "")
        ),
        panelCardBorderSize: Number.parseInt(
          styleSheetJSON["--eh-panel-card-border-size"].replace("px", "")
        ),
        listTableRadius: Number.parseInt(
          styleSheetJSON["--eh-list-table-radius"].replace("px", "")
        ),
        listTableBorderSize: Number.parseInt(
          styleSheetJSON["--eh-list-table-border-size"].replace("px", "")
        ),
        listTablePaginationRadius: Number.parseInt(
          styleSheetJSON["--eh-list-table-pagination-radius"].replace("px", "")
        ),
        listTablePaginationBorderSize: Number.parseInt(
          styleSheetJSON["--eh-list-table-pagination-border-size"].replace(
            "px",
            ""
          )
        ),
        listBoxRadius: Number.parseInt(
          styleSheetJSON["--eh-list-box-radius"].replace("px", "")
        ),
        listBoxBorderSize: Number.parseInt(
          styleSheetJSON["--eh-list-box-border-size"].replace("px", "")
        ),
        tagRadius: Number.parseInt(
          styleSheetJSON["--eh-tag-radius"].replace("px", "")
        ),
        tagBorderSize: Number.parseInt(
          styleSheetJSON["--eh-tag-border-size"].replace("px", "")
        ),
        tagBoxRadius: Number.parseInt(
          styleSheetJSON["--eh-tag-box-radius"].replace("px", "")
        ),
        tagBoxBorderSize: Number.parseInt(
          styleSheetJSON["--eh-tag-box-border-size"].replace("px", "")
        ),
        formModalRadius: Number.parseInt(
          styleSheetJSON["--eh-form-modal-radius"].replace("px", "")
        ),
        headerLogo: this.headerLogoImgs.find(
          (l) =>
          styleSheetJSON["--eh-header-logo"].split(".")[0].split("(")[1].includes(l.Value)
        ).Url,
        headerLogoHeight: Number.parseInt(
          styleSheetJSON["--eh-header-logo-height"].replace("px", "")
        ),
      };
    },
    async changeStyleLogo(args) {
      const baseData = await this.blobToBase64(args.target.files[0]);
      this.cssVariables.headerLogo = baseData;
      this.changeSytle();
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    changeSytle() {
      const stringCss = this.convertValuesToCss();
      if (!this.isCreated) {
        this.updateCssStyle(stringCss);
      }
      this.setCss(
        ":root " + stringCss.replaceAll('"', "").replaceAll(",", ";")
      );
    },
    convertValuesToCss() {
      return JSON.stringify({
        "--ps-primary-5": this.cssVariables.primary5,
        "--ps-primary-4": this.cssVariables.primary4,
        "--ps-primary-3": this.cssVariables.primary3,
        "--ps-primary-2": this.cssVariables.primary2,
        "--ps-primary-1": this.cssVariables.primary1,

        "--ps-secondary-5": this.cssVariables.secondary5,
        "--ps-secondary-4": this.cssVariables.secondary4,
        "--ps-secondary-3": this.cssVariables.secondary3,
        "--ps-secondary-2": this.cssVariables.secondary2,
        "--ps-secondary-1": this.cssVariables.secondary1,

        "--eh-disabled-color": "lightgrey",

        "--ps-button-radius": this.cssVariables.buttonRadius + "px",
        "--ps-input-radius": this.cssVariables.inputRadius + "px",
        "--ps-input-border-size": this.cssVariables.inputBorderSize + "px",
        "--ps-module-box-radius": this.cssVariables.moduleBoxRadius + "px",

        "--ps-panel-card-radius": this.cssVariables.panelCardRadius + "px",
        "--ps-panel-card-title-radius":
          this.cssVariables.panelCardRadius -
          this.cssVariables.panelCardBorderSize +
          "px",
        "--ps-panel-card-border-size":
          this.cssVariables.panelCardBorderSize + "px",

        "--ps-list-table-radius": this.cssVariables.listTableRadius + "px",
        "--ps-list-table-border-size":
          this.cssVariables.listTableBorderSize + "px",
        "--ps-list-table-pagination-radius":
          this.cssVariables.listTablePaginationRadius + "px",
        "--ps-list-table-pagination-border-size":
          this.cssVariables.listTablePaginationBorderSize + "px",
        "--ps-list-table-pagination-first-end-radius":
          this.cssVariables.listTablePaginationRadius -
          this.cssVariables.listTablePaginationBorderSize +
          "px",

        "--ps-list-box-radius": this.cssVariables.listBoxRadius + "px",
        "--ps-list-box-border-size": this.cssVariables.listBoxBorderSize + "px",

        "--ps-tag-radius": this.cssVariables.tagRadius + "px",
        "--ps-tag-border-size": this.cssVariables.tagBorderSize + "px",

        "--ps-tag-box-radius": this.cssVariables.tagBoxRadius + "px",
        "--ps-tag-box-border-size": this.cssVariables.tagBoxBorderSize + "px",

        "--ps-form-modal-radius": this.cssVariables.formModalRadius + "px",

        "--ps-header-logo":
          "url(" + require("@/Assets" + this.cssVariables.headerLogo) + ")",
        "--ps-header-logo-height": this.cssVariables.headerLogoHeight + "px",
      });
    },
    updateCssStyle(cssSheet) {
      const exportingCss = cssSheet.replaceAll("--ps-", "--eh-");
      this.$emit("update:projectStyle", exportingCss);
    },
    setCss(stringCss) {
      var testStyle = document.getElementById("test-project-style");
      if (!testStyle) {
        testStyle = document.createElement("style");
        testStyle.id = "test-project-style";
        testStyle.textContent = stringCss;
        document.head.append(testStyle);
      } else {
        testStyle.textContent = stringCss;
      }
    },
    async setEdit() {
      const cssObject = this.convertValuesToCss();
      const css =
        ":root " +
        cssObject
          .replaceAll('"', "")
          .replaceAll(",", ";")
          .replaceAll("--ps-", "--eh-");
      const styleSheetId = await this.uploadStyleSheet(css);
      const setProjectResponse = await ProjectLogic.setProject(
        { StyleSheetId: styleSheetId },
        this.createdProjectId
      );
      if (!setProjectResponse.Code) {
        this.isEditable = false;
        this.$emit("update:projectStyle", css);
        this.importedCssVariables = JSON.parse(JSON.stringify(css));
        this.$eventBus.$emit("projectChanged", this.createdProjectId);
      } else {
        //TODO: hiba
      }
    },
    async uploadStyleSheet(style) {
      const uploadResponse = await AttachmentLogic.uploadAttachment(
        style,
        this.$enums.AttachmentTypeName.ProjectStyleSheet.TypeName,
        this.createdProjectId
      );
      if (!uploadResponse.Code) {
        return uploadResponse.AttachmentId;
      } else {
        //TODO: hiba
      }
    },
    cancelEdit() {
      this.isEditable = false;
      this.projectStyle = JSON.parse(JSON.stringify(this.importedCssVariables));
      this.setCssVariable();
    },
  },
  mounted() {
    this.setCssVariable();
    this.changeSytle();
  },
};
</script>
<style scoped></style>
