<template>
  <fp-form-modal
    hide-footer
    id="blood-glucose-input-modal"
    v-model="showInputModal"
    :title="$t('module.diaries.bloodGlucose.addNewPost')"
  >
    <template #content>
      <b-form>
        <fp-input
          :state="!validation.hasError('newEntry.BloodGlucoseValue')"
          :error="validation.firstError('newEntry.BloodGlucoseValue')"
          :label="$t('module.diaries.bloodGlucose.value')"
          :required="true"
          :description="$t('module.diaries.bloodGlucose.valueDescription')"
          v-model="newEntry.BloodGlucoseValue"
        />
        <fp-select
          v-model="newEntry.MealRelation"
          :items="Object.values(mealRelations)"
          :label="$t('module.diaries.bloodGlucose.mealRelation')"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-select
          v-model="newEntry.MealQuantity"
          :items="Object.values(mealQuantities)"
          :label="$t('module.diaries.bloodGlucose.mealQuantity')"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-date-time-picker
          :state="!validation.hasError('newEntry.MeasuredAt')"
          :error="validation.firstError('newEntry.MeasuredAt')"
          :requited="true"
          :label="$t('module.diaries.measureDate')"
          v-model="newEntry.MeasuredAt"
        />
        <div style="text-align: right">
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>

<script>
import moment from "moment";
import { DiaryLogic } from '../../../../Logic/Backend/diary';

export default {
  name: "BloodGlucoseDiaryInput",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    TargetId: Number,
  },
  data() {
    return {
      newEntry: {
        BloodGlucoseValue: null,
        Circumstances: null,
        SourceName: "UserInput",
        MeasuredAt: moment().format(),
      },
      showInputModal: null,
      mealQuantities: Object.values(this.$enums.MealQuantity),
      mealRelations: this.$enums.MealRelation,
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.BloodGlucoseValue": function (value) {
      return this.$validator
        .value(value)
        .greaterThan(0)
        .required(this.$t("validate.requiredField"));
    },
    "newEntry.MeasuredAt": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredDate"));
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const getDiaryResponse = await this.setDiaryByUser([this.newEntry]);
        if (!getDiaryResponse.Code || getDiaryResponse.Code == 0) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(getDiaryResponse.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async setDiaryByUser(data) {
      if (this.TargetId) {
        return await DiaryLogic.addDiaryEntryToTarget(
          "blood_glucose",
          this.TargetId,
          data
        );
      } else {
        return await DiaryLogic.addDiaryEntry("blood_glucose", data);
      }
    },
  },
};
</script>
<style></style>
