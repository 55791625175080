<template>
  <div v-if="address">
    <code-name-pair
      label="Ország"
      v-model="address.Country"
      :disabled="disabled"
      @changeValue="changeValue('Country', $event)"
      :items="eesztCountryList"
      listValueKey="EntryId"
      listTextKey="HungarianName"
      :searchable="true"
      @search="searchCountry"
    />
    <fp-input
      label="Irányítószám"
      description="EESZT beküldéshez kötelező mező!"
      v-model="address.PostalCode"
      :disabled="disabled"
      @change="changeValue('PostalCode', $event)"
    />
    <fp-input
      label="Város"
      description="EESZT beküldéshez kötelező mező!"
      v-model="address.City"
      :disabled="disabled"
      @change="changeValue('City', $event)"
    />
    <fp-input
      label="Utca, közterület"
      description="EESZT beküldéshez kötelező mező!"
      v-model="address.StreetAddress"
      :disabled="disabled"
      @change="changeValue('StreetAddress', $event)"
    />
  </div>
</template>
<script>
import CodeNamePair from "./CodeNamePair.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
export default {
  components: { CodeNamePair },
  name: "FormPatientAddress",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      address: null,
      changedAddressValue: {},
      defaultAddress: {
        Country: null,
        PostalCode: null,
        City: null,
        StreetAddress: null,
      },
      eesztCountryList: null,
    };
  },
  watch: {
    async value() {
      this.setAddress();
      await this.setList({ "-orderby": "HungarianName", ["-limit"]: 10 });
    },
  },
  methods: {
    setAddress() {
      if (this.value) {
        this.address = this.value;
      } else {
        this.address = JSON.parse(JSON.stringify(this.defaultAddress));
      }
    },
    changeValue(key, value) {
      if (
        Object.values(this.address).some(
          (x) => x !== null && x !== undefined && x !== ""
        )
      ) {
        this.changedAddressValue[key] = value;
        this.$emit("changeValue", this.changedAddressValue);
      } else {
        this.$emit("changeValue", { Path: "", Value: null });
      }
    },
    async searchCountry(input) {
      if (input && input != "") {
        if (input.length > 2) {
          await this.setList({ ["HungarianName-contains"]: input });
        } else {
          this.eesztCountryList = [];
        }
      } else {
        await this.setList({ "-orderby": "HungarianName", ["-limit"]: 10 });
      }
    },
    async setList(params) {
      this.eesztCountryList = await this.getBase(params);
      if (
        this.address.Country &&
        this.address.Country.Id &&
        !this.eesztCountryList.find((e) => e.EntryId == this.address.Country.Id)
      ) {
        const selectedValue = await this.getBase({
          EntryId: this.address.Country.Id,
        });
        if (selectedValue) {
          this.eesztCountryList.push(selectedValue[0]);
        }
      }
      this.eesztCountryList.splice(0, 0, {
        EntryId: null,
        HungarianName: "Nincs megadva",
      });
    },
    async getBase(params) {
      const getBaseResponse = await BaseDataLogic.getBaseData(
        this.$enums.BaseName.eeszt_country.Value,
        params
      );
      if (!getBaseResponse.Code) {
        getBaseResponse.sort((a, b) =>
          a.HungarianName.localeCompare(b.HungarianName)
        );
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    this.setAddress();
    await this.setList({ "-orderby": "HungarianName", ["-limit"]: 10 });
  },
};
</script>
