<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <!-- Résztvevő kezelő sáv -->
        <div class="col-xl-3 col-md-4" style="margin: 0px 0px 25px">
          <fp-participant-manager
            :listTitle="$t('Patient.patientList')"
            :project="project"
            :managerType="
              $enums.SystemParameters.ParticipantManagerType.Specialist
            "
            :refreshingList.sync="refreshingList"
            @changedSelectedParticipant="selectParticipant"
          />
        </div>
        <!-- Kiválasztott beteg adatai -->
        <div class="col-xl-9 col-md-8" v-if="selectedParticipant">
          <fp-tabs :tabs="availableTabs">
            <template #title="Component">{{ Component.Title }}</template>
            <template #content="Component">
              <component
                @refreshList="refreshList"
                :is="Component.ComponentName"
                :participant="selectedParticipant"
                :project="project"
              />
            </template>
          </fp-tabs>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "SpecialistPatientList",
  props: {
    project: Object,
  },
  data() {
    return {
      patientCardComponents: [
        {
          ComponentName: "fp-participant-personal-profile",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.personalData"
          ),
          EnableUiElementName: "HealthProfessional.PatientPersonalProfile",
        },
        {
          ComponentName: "fp-participant-medical-profile",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.medicalData"
          ),
          EnableUiElementName: "HealthProfessional.PatientMedicalProfile",
        },
        {
          ComponentName: "fp-participant-chart",
          Title: this.$t("healthProfessional.patientList.patientTabs.charts"),
          EnableUiElementName: "HealthProfessional.PatientDiagram",
        },
        {
          ComponentName: "fp-participant-health-goal",
          //TODO: szótár
          Title: "Egészségcél",
          EnableUiElementName: "HealthProfessional.PatientDiagram",
        },
        {
          ComponentName: "fp-participant-medicine-plan",
          //TODO: szótár
          Title: "Gyógyszerszedési terv",
          EnableUiElementName: "HealthProfessional.PatientDiagram",
        },
        {
          ComponentName: "fp-participant-workflow",
          Title: this.$t("healthProfessional.patientList.patientTabs.workflow"),
          EnableUiElementName: "Client.Workflow",
        },
        {
          ComponentName: "fp-participant-document",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.documents"
          ),
          EnableUiElementName: "HealthProfessional.PatientDocument",
        },
        {
          ComponentName: "fp-participant-questionnaire",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.questionnaires"
          ),
          EnableUiElementName: "Client.Questionnaire",
        },
        {
          ComponentName: "fp-participant-form",
          Title: this.$t("healthProfessional.patientList.patientTabs.forms"),
          EnableUiElementName: "HealthProfessional.Form",
        },
        {
          ComponentName: "fp-participant-communication",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.communication"
          ),
          EnableUiElementName: "Client.Communication",
        },
        {
          ComponentName: "fp-participant-legal",
          Title: "Nyilatkozatok",
          EnableUiElementName: null,
        },
        {
          ComponentName: "fp-participant-prescription",
          Title: "Receptek",
          EnableUiElementName: "HealthProfessional.MedicalDevicePrescription",
          //TODO: this.$t("healthProfessional.patientList.patientTabs.recipes")
        },
      ],
      selectedParticipant: null,
      refreshingList: false,
    };
  },
  computed: {
    availableTabs() {
      return this.patientCardComponents.filter((c) => {
        if (!c.EnableUiElementName) {
          return true;
        }
        const roleAndUiElementName = c.EnableUiElementName.split(".");
        const role = roleAndUiElementName[0];
        const uiElementName = roleAndUiElementName[1];
        return this.project.EnabledUiElements[role].includes(uiElementName);
      });
    },
  },
  watch: {
    async project() {
      this.refreshingList = true;
    },
  },
  methods: {
    selectParticipant(newParticipant) {
      this.selectedParticipant = newParticipant;
    },
    refreshList() {
      this.refreshingList = true;
    },
  },
  //első betöltés kör fut le
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.patientList")
    );
  },
};
</script>
<style>
#new-patient-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
div#new-patient-modal div.modal-content header.modal-header,
div#new-patient-modal div.modal-content > header.modal-header {
  padding: 0px;
}
#patient-list .list-group-item.active {
  background: darkorange;
  border-color: darkorange;
  font-weight: 700;
}
</style>
