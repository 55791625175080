<template>
  <!-- TODO statikus oldalak leválasztása -->
  <div id="app" class="eh-main">
    <router-view id="app-main" class="eh-content" />
    <fp-footer id="app-footer" />
    <!-- TODO másolás még egy változón van -->
    <div v-if="toastError">
      <b-toast
        v-for="toast in toastError"
        :key="toast.Id"
        :visible="true"
        :no-auto-hide="toast.NoAutoHide"
        :variant="toast.Variant"
        solid
        :title="toast.Title"
        @hide="removeToast(toast.Id)"
      >
        <div style="overflow-wrap: break-word">
          <div v-if="toast.Messages && toast.Messages.length > 1">
            <li
              v-for="(e, index) in toast.Messages"
              :key="'toast-' + toast.Id + '-error-' + index"
            >
              {{ e }}
            </li>
          </div>
          <div v-if="toast.Messages && toast.Messages.length">
            {{ toast.Messages[0] }}
          </div>
        </div>
        <!-- //TODO nem kijelölhető a szöveg -->
        <div
          style="cursor: pointer; font-weight: bold"
          @click="copyError(toast)"
          v-if="toast.CopyableResponse"
        >
          <div v-if="!toast.SuccessCopy">
            <b-icon-clipboard-data />
            Teljes hibaüzenet másolása
          </div>
          <div style="color: green" v-else>
            <b-icon-clipboard-data />Hiba üzenet vágólapra másolva
          </div>
        </div>
      </b-toast>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { AttachmentLogic } from "@/Logic/Backend/attachment";

export default {
  name: "App",
  data() {
    return {
      copiedToast: null,
    };
  },
  computed: {
    toastError() {
      return this.$store.state.toast.toastError;
    },
  },
  created() {
    this.$eventBus.$on("projectChanged", async (projectId) => {
      const projectStyleText = await this.getProjectStyle(projectId);
      const defaultCssId = "project-style-default";
      const importedCssId = "project-style-imported";
      var defaultCss = document.getElementById(defaultCssId);
      var importedCss = document.getElementById(importedCssId);
      if (projectStyleText) {
        if (importedCss) {
          importedCss.textContent = projectStyleText;
        } else {
          importedCss = document.createElement("style");
          importedCss.id = importedCssId;
          importedCss.textContent = projectStyleText;
          document.head.append(importedCss);
        }
        if (defaultCss) {
          defaultCss.remove();
        }
      } else {
        if (defaultCss) {
          defaultCss.href = this.$productSettings.Style;
        } else {
          defaultCss = document.createElement("link");
          defaultCss.id = defaultCssId;
          defaultCss.rel = "stylesheet";
          defaultCss.type = "text/css";
          defaultCss.href = this.$productSettings.Style;
          defaultCss.media = "all";
          document.head.append(defaultCss);
        }
        if (importedCss) {
          importedCss.remove();
        }
      }
    });
  },
  methods: {
    copyError(toast) {
      if (this.copiedToast) {
        this.$set(this.copiedToast, "SuccessCopy", false);
      }
      console.log(toast.CopyableResponse.Curl);
      navigator.clipboard.writeText(
        toast.CopyableResponse.Curl +
          "" +
          JSON.stringify(toast.CopyableResponse.Response)
      );
      this.$set(toast, "SuccessCopy", true);
      this.copiedToast = toast;
    },
    removeToast(toastId) {
      this.$store.dispatch("removeToastError", toastId);
    },
    async getProjectStyle(projectId) {
      if (!projectId) {
        return null;
      }
      const project = await this.getProject(projectId);
      if (project && project.StyleSheetId) {
        const styleFileResponse = await this.getStyle(project.StyleSheetId);
        const styleText = await styleFileResponse.text();
        return styleText;
      } else {
        return null;
      }
    },
    async getProject(projectId) {
      const getProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: projectId,
      });
      if (!getProjectResponse.Code) {
        return getProjectResponse[0];
      } else {
        //TODO: hiba?
      }
    },
    async getStyle(fileId) {
      const getAttachmentResponse = await AttachmentLogic.downloadAttachment(
        fileId
      );
      if (!getAttachmentResponse.Code) {
        return getAttachmentResponse;
      }
    },
  },
  async beforeMount() {
    //await this.getProjectStyle();
    this.$eventBus.$emit("projectChanged");
  },
  beforeUpdate() {
    window.scrollTo(0, 0);
  },
};
</script>
<style>
@import "./Style/eh-base.bootstrap.css";
@import "./Style/ps-base.bootstrap.css";
@import "./Style/eh-static.bootstrap.css";
@import "./Style/syncfusion-components-import.css";
@import "./Style/syncfusion-dropdown-list.css";
@import "./Style/user-profile.css";

html,
body {
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  height: 100%;
  flex-flow: column;
}

#app-footer {
  background-color: black;
}

#app-main {
  flex: 1 1 auto;
}

:focus {
  outline: none;
}
</style>
