<template>
  <div>
    <b-button style="margin-top: 10px" @click="$router.go(-1)">
      {{ $t("base.basic.back") }}
    </b-button>
    <fp-panel-card
      v-if="subjectData"
      style="background: white; width: fit-content; margin: auto"
      :title="$t('healthProfessional.questionnaires.userData')"
    >
      <template #content>
        <div v-if="subjectData">
          <div style="margin-top: 15px; display: flex; flex-wrap: wrap">
            <div v-for="(tag, index) in subjectData.Tags" :key="'tag-' + index">
              <tag :tag="tag" />
            </div>
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.name") }}:</b>
            {{ subjectData.Name }}
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.ssn") }}:</b>
            {{ subjectData.Ssn }}
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.birthday") }}:</b>
            {{ $convertDateToString(subjectData.DateOfBirth, "YYYY-MM-DD") }}
          </div>
          <div v-if="subjectData.Contact.Address">
            <b>{{ $t("healthProfessional.questionnaires.address") }}:</b>
            {{
              (subjectData.Contact.Address.ZipCode
                ? subjectData.Contact.Address.ZipCode + " "
                : "") +
              (subjectData.Contact.Address.City
                ? subjectData.Contact.Address.City + ", "
                : "") +
              (subjectData.Contact.Address.Street
                ? subjectData.Contact.Address.Street + " "
                : "") +
              (subjectData.Contact.Address.Building
                ? subjectData.Contact.Address.Building + ". "
                : "") +
              (subjectData.Contact.Address.SubBuilding
                ? subjectData.Contact.Address.SubBuilding
                : "")
            }}
          </div>
        </div>
      </template>
    </fp-panel-card>
    <fp-form-schema-builder
      v-if="selectedQuestionnaire"
      :instance="selectedQuestionnaire"
      :disabled="isSelectedQuestionnaireIsFilled"
      @closeForm="closeQuestionaire"
    />
    <div
      v-else-if="!selectedQuestionnaire && loaded"
      style="text-align: center"
    >
      {{ $t("Form.notFound") }}
    </div>
  </div>
</template>
<script>
import { FormLogic } from "../../Logic/Backend/form";
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "Form",
  data() {
    return {
      questionnaireId: null,
      selectedQuestionnaire: null,
      isSelectedQuestionnaireIsFilled: false,
      subjectData: null,
      loaded: null,
    };
  },
  methods: {
    //mentés nélül visszalépés a kérdőív listába
    closeQuestionaire() {
      this.$router.go(-1);
    },
    //kiválasztott kérdőív adat kérés
    async getSelectesQuestionannaire() {
      this.loaded = false;
      //kérés indítás
      const questionnaireListResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.questionnaireId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kapott lista első elemének tárolása
        this.selectedQuestionnaire = questionnaireListResponse[0];
        if (
          this.selectedQuestionnaire &&
          this.selectedQuestionnaire.SubjectId != this.$loggedUser.UserId
        ) {
          await this.getSubjectData();
        }
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.loaded = true;
      if (this.selectedQuestionnaire) {
        //a kérdőív kitöltött érték tárolása
        this.isSelectedQuestionnaireIsFilled = ![
          this.$enums.QuestionnaireStatus.InProgress.Value,
        ].includes(this.selectedQuestionnaire.Status);
      }
    },
    async getSubjectData() {
      const getUserDataResponse = await UserLogic.getUserData({
        userIds: [this.selectedQuestionnaire.SubjectId],
      });
      if (!getUserDataResponse.Code) {
        this.subjectData = getUserDataResponse[0];
      } else {
        this.$bvToast.toast(getUserDataResponse.Message, {
          title: "Hiba történt a felhasználó adatok betöltése során!",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kérdőív id tárolás
    const taskParams = this.$route.query.taskParams;
    if (taskParams) {
      this.questionnaireId = JSON.parse(taskParams).FormInstanceId;
    } else {
      this.questionnaireId = this.$route.query.Id;
    }
    //nincs kérdőív id
    if (!this.questionnaireId) {
      //nincs, visszaküld a listába
      this.$router.go(-1);
    }
    //oldal címének beállítása
    this.$emit(
      "title-changed",
      this.$t("client.questionnaires.questionnaire.title")
    );
    //kiválasztott kérdőív betöltése

    await this.getSelectesQuestionannaire();
  },
};
</script>
