<template>
  <div v-if="arrayData && groupedArrays" class="medical-profile-data">
    <div class="fp-boubles-title">
      {{ $t("client.profile.medicalData.vaccination") }}
    </div>
    <div class="fp-empty" v-if="groupedArrays.length == 0 && disabled">
      {{ $t("base.noData") }}
    </div>
    <div v-else>
      <div class="fp-boubles" v-for="group in groupedArrays" :key="group.key">
        <fp-bouble-modal
          :title="group.key"
          :length="group.value.length"
          @openDetails="$set(group, 'OpenDetails', true)"
        />
        <!-- Lista megtekintés -->
        <fp-form-modal v-model="group.OpenDetails" :title="group.key">
          <template #content>
            <b-list-group
              v-if="group.value.length > 0"
              class="fp-list-box-group"
            >
              <b-list-group-item
                v-for="(element, index) in group.value"
                :key="index"
                class="fp-group-item"
                :class="
                  group.value.length == 1
                    ? ' single-element '
                    : (index == group.value.length - 1
                        ? ' last-element '
                        : ' not-last-element ') + ' multiple-element '
                "
              >
                <div>
                  <b>{{ $t("client.profile.medicalData.name") }}: </b
                  >{{ getDisplayData(element.Name) }}<br />
                  <b
                    >{{
                      $t("client.profile.medicalData.administrationDate")
                    }}: </b
                  >{{
                    getDisplayData(
                      $convertDateToString(element.Date, "YYYY-MM-DD")
                    )
                  }}<br />
                  <!-- convert -->
                </div>
                <div
                  class="item-delete-icon"
                  v-b-tooltip.hover="$t('base.basic.delete')"
                  :style="
                    disabled
                      ? 'display:none'
                      : 'vertical-align: bottom; margin: auto 0px auto 40px'
                  "
                >
                  <b-icon
                    icon="x-circle"
                    style="cursor: pointer"
                    @click="deleteElement(element)"
                  />
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </fp-form-modal>
      </div>
      <div v-if="!disabled" class="fp-plus-icon">
        <b-icon @click="showCreateNew = true" icon="plus-circle-fill" />
      </div>
    </div>
    <!-- Új elem felvétele -->
    <fp-form-modal
      v-model="showCreateNew"
      :title="$t('client.profile.medicalData.newVaccination')"
    >
      <template #content>
        <fp-input
          :state="!validation.hasError('newElement.Name')"
          :error="validation.firstError('newElement.Name')"
          :label="$t('client.profile.medicalData.name')"
          v-model="newElement.Name"
        />
        <fp-input
          :label="$t('client.profile.medicalData.administrationDate')"
          v-model="newElement.Date"
          :state="!validation.hasError('newElement.Date')"
          :error="validation.firstError('newElement.Date')"
        />
        <b-button @click="addNewElement">{{ $t("base.basic.save") }}</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import moment from "moment";
//import { HelperMethods } from "@/Logic/Ui/helpers";
export default {
  name: "Vaccination",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      arrayData: this.value ? this.value : [],
      groupedArrays: [],
      showCreateNew: false,
      newElement: {
        Name: null,
        Date: null,
      },
      defaultNewElement: {
        Name: null,
        Date: null,
      },
    };
  },
  validators: {
    "newElement.Name": function (value) {
      return this.$validator.value(value).required();
    },
    "newElement.Date": function (value) {
      const validator = this.$validator;
      const m = moment(value);
      return validator.custom(function () {
        if (value && !m.isValid()) {
          return "Hibás dátum formátum";
        }
      });
    },
  },
  watch: {
    value(input) {
      this.arrayData = input ? input : [];
      this.groupedArrays = this.checkDuplicateName();
    },
  },
  methods: {
    /* Ellenőrzi és rendezi a diplukált csoportokat a bouble nevek alapján.
       Ellátja az elemeke ElementId-val, ami a törléshez szükséges az elem beazonosításához. */
    //duplikáció vizsgálat
    checkDuplicateName() {
      //index alapból 0
      var index = 0;
      //végig megyünk a lista minden elemén és adunk nekik Element Id-t
      this.arrayData.forEach((x) => (x.ElementId = index++));
      //csoportosítjuk a lista elemeket megadott változó alapján
      var tempMap = this.$groupByMostEfficient(
        this.arrayData,
        (listE) => listE.Name
      );
      //a kapott csoportosított map átalakítása listává
      var tempArray = [];
      tempMap.forEach((value, key) => {
        if (key) {
          tempArray.push({ key: key, value: value });
        }
      });
      //vissza érünk a generált listával
      return tempArray;
    },
    deleteElement(elem) {
      const index = this.arrayData.indexOf(elem);
      if (index >= 0) {
        this.arrayData.splice(index, 1);
        this.groupedArrays = this.checkDuplicateName();
        this.$emit("change", this.arrayData);
      }
    },
    getDisplayData(data, emptyText) {
      if (data) {
        return data;
      } else if (emptyText) {
        return emptyText;
      }
      return this.$t("client.profile.medicalData.noData");
    },
    async addNewElement() {
      const valid = await this.$validate();
      if (valid) {
        this.newElement.Date = this.$convertDateToString(this.newElement.Date);
        this.arrayData.push(this.newElement);
        this.groupedArrays = this.checkDuplicateName();
        this.$emit("change", this.arrayData);
        this.showCreateNew = false;
        this.newElement = JSON.parse(JSON.stringify(this.defaultNewElement));
        this.validation.reset();
      }
    },
  },
  beforeMount() {
    this.groupedArrays = this.checkDuplicateName();
  },
};
</script>