// Hungarian locale
// import { HU } from './hu'
import { HuBase } from "./hu/base";
import { HuAdmin } from "./hu/admin";
import { HuComponents } from "./hu/components";
import { HuEnums } from "./hu/enums";
import { HuModule } from "./hu/module";
import { HuValidate } from "./hu/validate";
import { HuRequestResponse } from "./hu/requestResponse";
import { HuHeader } from "./hu/header";
import { HuFooter } from "./hu/footer";
import { HuClient } from "./hu/client";
import { HuHealthProfessional } from "./hu/healthProfessional";
import { HuPartner } from "./hu/partner";
import { HuEeszt } from "./hu/eeszt";

// English locale
// import { EN } from './en'
import { EnBase } from "./en/base";
import { EnAdmin } from "./en/admin";
import { EnComponents } from "./en/components";
import { EnEnums } from "./en/enums";
import { EnModule } from "./en/module";
import { EnValidate } from "./en/validate";
import { EnRequestResponse } from "./en/requestResponse";
import { EnHeader } from "./en/header";
import { EnFooter } from "./en/footer";
import { EnClient } from "./en/client";
import { EnHealthProfessional } from "./en/healthProfessional";
import { EnPartner } from "./en/partner";

// German locale
// import { DE } from './de'
import { DeBase } from "./de/base";
import { DeAdmin } from "./de/admin";
import { DeComponents } from "./de/components";
import { DeEnums } from "./de/enums";
import { DeModule } from "./de/module";
import { DeValidate } from "./de/validate";
import { DeRequestResponse } from "./de/requestResponse";
import { DeHeader } from "./de/header";
import { DeFooter } from "./de/footer";
import { DeClient } from "./de/client";
import { DeHealthProfessional } from "./de/healthProfessional";
import { DePartner } from "./de/partner";

export const hu = {
  ...HuAdmin,
  ...HuBase,
  ...HuComponents,
  ...HuEnums,
  ...HuModule,
  ...HuValidate,
  ...HuRequestResponse,
  ...HuHeader,
  ...HuFooter,
  ...HuClient,
  ...HuHealthProfessional,
  ...HuPartner,
  ...HuEeszt,
};

export const en = {
  ...EnBase,
  ...EnAdmin,
  ...EnComponents,
  ...EnEnums,
  ...EnModule,
  ...EnValidate,
  ...EnRequestResponse,
  ...EnHeader,
  ...EnFooter,
  ...EnClient,
  ...EnHealthProfessional,
  ...EnPartner,
};

export const de = {
  ...DeBase,
  ...DeAdmin,
  ...DeComponents,
  ...DeEnums,
  ...DeModule,
  ...DeValidate,
  ...DeRequestResponse,
  ...DeHeader,
  ...DeFooter,
  ...DeClient,
  ...DeHealthProfessional,
  ...DePartner,
};
