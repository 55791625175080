<template>
  <div class="eh-menu-content">
    <div>
      <b-container fluid="true" class="eh-specialist-documents-tab-container">
        <b-row align-v="baseline">
          <b-col cols="12" sm="6" class="eh-flex-container">
            <fp-input
              class="eh-specialist-documents-filter eh-fix-filter-input-width"
              :label="$t('base.basic.filter')"
              @change="searchDocumentList"
              v-model="filter"
            />
            <fp-select
              class="eh-fix-filter-input-width eh-fix-filter-input-width"
              :label="$t('healthProfessional.documents.documentType')"
              :items="Object.values($enums.OtherDocumentCategory)"
              valueKey="Value"
              v-model="filterType"
              @change="searchDocumentList"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </b-col>
          <b-col cols="12" sm="6">
            <fp-document-operation-buttons
              :disableEESZT="true"
              :disableShare="true"
              :isUploaded="isUploaded"
              :uploadByCategory="true"
              @selectAll="selectAll"
              @clearSelected="clearSelected"
              @onUploadedDocumentList="onUploadedDocumentList"
              @downloadDocuments="downloadDocuments"
              @deletedDocuments="deletedDocuments"
              @onClose="getDocuments"
            />
          </b-col>
        </b-row>
      </b-container>
      <fp-document-table
        @onRowSelected="onOtherRowSelected"
        :items="filteredDocumentList"
        :fields="documentFields"
        :hasCategoryFilter="true"
        :isSelectAll.sync="isSelectAll"
        :isSelectClear.sync="isSelectClear"
        :refId="'selectableTable'"
      />
    </div>
  </div>
</template>
<script>
import { TableUiLogic } from "@/Logic/Ui/table";

export default {
  name: "SpecialistDocuments",
  data() {
    return {
      documentTableList: [],
      filteredDocumentList: [],
      selectedDocumentsList: [],
      uploadedDocumentList: null,
      isSelectAll: null,
      isSelectClear: false,
      isUploaded: true,
      filter: null,
      filterType: null,
      documentFields: [
        {
          key: "Selected",
          label: this.$t("base.basic.select"),
        },
        {
          key: "DocumentName",
          label: this.$t("components.table.name"),
          /* sortable: true, */
        },
        {
          key: "FileUploadedAt",
          label: this.$t("components.table.uploadDate"),
          /* sortable: true, */
        },
        {
          key: "FileType",
          label: this.$t("components.table.format"),
          /* sortable: true, */
        },
        { key: "Category", label: this.$t("components.table.type") },
      ],
    };
  },
  methods: {
    async onUploadedDocumentList(list, category) {
      this.uploadedDocumentList = list;
      this.isUploaded = false;
      var response = await TableUiLogic.uploadDocuments(
        category,
        this.uploadedDocumentList
      );
      response.forEach((res) => {
        if (res.Code != 0) {
          this.$bvToast.toast(res.Message, {
            //TODO: uploadByName
            title: this.$t("requestResponse.document.errorUpload"),
            variant: "warning",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      });
      this.isUploaded = true;
      this.getDocuments();
    },
    searchDocumentList() {
      //megadott input, és változó kulcsok alapján szűrt lista tárolása
      this.filteredDocumentList = this.$filterList(
        this.filter,
        this.documentTableList,
        ["FileName", "DocumentName", "FileUploadedAt"]
      );
      if (this.filterType) {
        this.filteredDocumentList = this.filteredDocumentList.filter(
          (doc) => doc.Category == this.filterType
        );
      }
    },
    selectAll() {
      this.isSelectAll = true;
    },
    clearSelected() {
      this.isSelectClear = true;
    },
    onOtherRowSelected(items) {
      this.selectedDocumentsList = items;
    },
    async downloadDocuments() {
      const response = await TableUiLogic.downloadSelectedDocuments(
        this.selectedDocumentsList
      );
      response.forEach((res) => {
        if (res.Code != 0) {
          this.$bvToast.toast(res.Message, {
            //TODO: downloadByName
            title: this.$t("requestResponse.document.errorDownload"),
            variant: "warning",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      });
    },
    async deletedDocuments() {
      const response = await TableUiLogic.deleteSelectedDocuments(
        this.selectedDocumentsList
      );
      response.forEach((res) => {
        if (res.Code != 0) {
          this.$bvToast.toast(res.Message, {
            //TODO: deleteByName
            title: this.$t("requestResponse.document.errorDelete"),
            variant: "warning",
            solid: true,
            AutoHideDelay: 10000,
          });
        } else {
          this.$bvToast.toast(
            this.$t("requestResponse.document.successDelete"),
            {
              title: this.$t("base.basic.delete"),
              variant: "info",
              solid: true,
            }
          );
        }
      });
      await this.getDocuments();
    },
    async getDocuments() {
      this.filteredDocumentList = [];
      const response = await TableUiLogic.getDocuments();
      if (!response.Code) {
        this.documentTableList = this.filteredDocumentList = response;
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.documents.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    this.$store.dispatch("setPageTitle", this.$t("healthProfessional.menu.documents"));
    await this.getDocuments();
  },
};
</script>
<style>
.documents {
  padding: 20px;
}
.specialist-document-table-buttons {
  cursor: pointer;
  margin: 5px;
  width: 35px;
}
.document-filter-input {
  width: 200px;
}
</style>
