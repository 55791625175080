var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.usage)?_c('div',[_c('code-name-pair',{attrs:{"label":"Felírt termék felhasználás típusa","disabled":_vm.disabled,"items":_vm.eesztDosageTypeList,"listValueKey":"EntryId","listTextKey":"Name","required":true},on:{"changeValue":_vm.changeDosageType},model:{value:(_vm.usage.Type),callback:function ($$v) {_vm.$set(_vm.usage, "Type", $$v)},expression:"usage.Type"}}),(
      (_vm.usage.Type &&
        (_vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByPartOfDay ||
          _vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByFrequence)) ||
      _vm.showUsageFrequency
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási gyakoriság","description":"pl.: 3x1 esetén 3, vagy Reggel (R), Délben (D), Este (E), Lefekvés előtt (LE)","disabled":_vm.disabled},on:{"change":function($event){return _vm.changeValue('Frequency', $event)}},model:{value:(_vm.usage.Frequency),callback:function ($$v) {_vm.$set(_vm.usage, "Frequency", $$v)},expression:"usage.Frequency"}}):_vm._e(),(
      (_vm.usage.Type &&
        (_vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByPartOfDay ||
          _vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByFrequence)) ||
      _vm.showUsageAmount
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási mennyiség, gyakoriság szerint","disabled":_vm.disabled},on:{"change":function($event){return _vm.changeValue('Amount', $event)}},model:{value:(_vm.usage.Amount),callback:function ($$v) {_vm.$set(_vm.usage, "Amount", $$v)},expression:"usage.Amount"}}):_vm._e(),(
      (_vm.usage.Type &&
        (_vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByPartOfDay ||
          _vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByFrequence)) ||
      _vm.showUsageUnit
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási gyakoriság egység","disabled":_vm.disabled},on:{"change":function($event){return _vm.changeValue('Unit', $event)}},model:{value:(_vm.usage.Unit),callback:function ($$v) {_vm.$set(_vm.usage, "Unit", $$v)},expression:"usage.Unit"}}):_vm._e(),(
      (_vm.usage.Type && _vm.usage.Type.Id == _vm.$enums.Prescription.EesztDosageCondition.ByText) ||
      _vm.showUsageDescription
    )?_c('fp-text-area',{attrs:{"label":"Felírt termék felhasználása szövegesen","disabled":_vm.disabled},on:{"change":function($event){return _vm.changeValue('UsageDescription', $event)}},model:{value:(_vm.usage.UsageDescription),callback:function ($$v) {_vm.$set(_vm.usage, "UsageDescription", $$v)},expression:"usage.UsageDescription"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }