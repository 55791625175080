<template>
  <div class="data-protection page-background">
    <div>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Általánosságban</span>
        </strong>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Személyes adatokat csak a vonatkozó hatályos jogszabályoknak megfelelően gyűjtünk és kezelünk.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Adatai védelme érdekében azokat a lehető legbiztonságosabban tároljuk.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >A személyes adatok törlését a solution@ehealthss.hu címre küldött levélben kérheti, illetve az erre a címre küldött írásbeli kérése esetén felvilágosítást küldünk az Önről tárolt adatokról.</p>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Bevezetés</span>
        </strong>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >E-Health Software Solutions Kft. (székhely/postázási cím: 1037 Budapest Szépvölgyi út 39. fszt. 2., cégjegyzékszám: 01 09 905190 adószám: 12022739-2-41) (a továbbiakban: Szolgáltató, adatkezelő) aláveti magát a következő tájékoztatónak.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény 20. § (1) kimondja, hogy az érintettel (jelen esetben a honlap felhasználóval, a továbbiakban: felhasználó) az adatkezelés megkezdése előtt közölni kell, hogy az adatkezelés hozzájáruláson alapul vagy kötelező.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Az érintettet az adatkezelés megkezdése előtt egyértelműen és részletesen tájékoztatni kell az adatai kezelésével kapcsolatos minden tényről, így különösen az adatkezelés céljáról és jogalapjáról, az adatkezelésre és az adatfeldolgozásra jogosult személyéről, valamint az adatkezelés időtartamáról.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Tájékoztatni kell az érintettet az Info tv. 6. § (1) bekezdése alapján arról is, hogy személyes adat kezelhető akkor is, ha az érintett hozzájárulásának beszerzése lehetetlen vagy aránytalan költséggel járna, és a személyes adat kezelése</p>
      <ul
        style="list-style-type: disc;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>az adatkezelőre vonatkozó jogi kötelezettség teljesítése céljából szükséges, vagy</li>
        <li>az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából szükséges, és ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll.</li>
      </ul>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >A tájékoztatásnak ki kell terjednie az érintett adatkezeléssel kapcsolatos jogaira és jogorvoslati lehetőségeire is.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Ha az érintettek személyes tájékoztatása lehetetlen vagy aránytalan költséggel járna (mint például jelen esetben a honlapon), a tájékoztatás megtörténhet az alábbi információk nyilvánosságra hozatalával is:</p>
      <ol
        start="1"
        style="list-style-type: lower-alpha;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>az adatgyűjtés ténye,</li>
        <li>az érintettek köre,</li>
        <li>az adatgyűjtés célja,</li>
        <li>az adatkezelés időtartama,</li>
        <li>az adatok megismerésére jogosult lehetséges adatkezelők személye,</li>
        <li>az érintettek adatkezeléssel kapcsolatos jogainak és jogorvoslati lehetőségeinek ismertetése, valamint</li>
        <li>ha az adatkezelés adatvédelmi nyilvántartásba vételének van helye, az adatkezelés nyilvántartási száma.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;">
        Jelen adatkezelési tájékoztató az alábbi weboldal adatkezelését szabályozza:
        <a style="color:#ED7D31;" href="http://www.fokuszprogram.hu/">http://www.fokuszprogram.hu/</a>, és a fentiekben ismertetett tartalmi előíráson alapul.
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"
      >A tájékoztató módosításai a fenti címen történő közzététellel lépnek hatályba.</p>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Értelmező rendelkezések (Info tv 3.§ alapján)</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>
          <em>érintett/Felhasználó</em> (Info tv. 3.§ 1.): bármely meghatározott, személyes adat alapján azonosított vagy – közvetlenül, vagy közvetve – azonosítható természetes személy;
        </li>
        <li>
          <em>személyes adat</em> (Info tv. 3.§ 2.): az érintettel kapcsolatba hozható adat – különösen az érintett neve, azonosító jele, valamint egy vagy több fizikai, fiziológiai, mentális, gazdasági, kulturális vagy szociális azonosságára jellemző ismeret -, valamint az adatból levonható, az érintettre vonatkozó következtetés;
        </li>
        <li>
          <em>hozzájárulás</em> (Info tv. 3.§ 7.): az érintett akaratának önkéntes és határozott kinyilvánítása, amely megfelelő tájékoztatáson alapul, és amellyel félreérthetetlen beleegyezését adja a rá vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő – kezeléséhez;
        </li>
        <li>
          <em>tiltakozás</em> (Info tv. 3.§ 8.): az érintett nyilatkozata, amellyel személyes adatainak kezelését kifogásolja, és az adatkezelés megszüntetését, illetve a kezelt adatok törlését kéri;
        </li>
        <li>
          <em>adatkezelő</em> (Info tv. 3.§ 9.): az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely önállóan vagy másokkal együtt az adatok kezelésének célját meghatározza, az adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket meghozza és végrehajtja, vagy az általa megbízott adatfeldolgozóval végrehajtatja;
        </li>
        <li>
          <em>adatkezelés</em> (Info tv. 3.§ 10.): az alkalmazott eljárástól függetlenül az adatokon végzett bármely művelet vagy a műveletek összessége, így különösen gyűjtése, felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, felhasználása, lekérdezése, továbbítása, nyilvánosságra hozatala, összehangolása vagy összekapcsolása, zárolása, törlése és megsemmisítése, valamint az adatok további felhasználásának megakadályozása, fénykép-, hang- vagy képfelvétel készítése, valamint a személy azonosítására alkalmas fizikai jellemzők (pl. ujj- vagy tenyérnyomat, DNS-minta, íriszkép) rögzítése;
        </li>
        <li>adatt továbbítás (Info tv. 3.§ 11.) : az adat meghatározott harmadik személy számára történő hozzáférhetővé tétele;</li>
        <li>
          <em>nyilvánosságra hozatal</em> (Info tv. 3.§ 12.): az adat bárki számára történő hozzáférhetővé tétele;
        </li>
        <li>
          <em>adattörlés</em> (Info tv. 3.§ 13.): az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé nem lehetséges;
        </li>
        <li>
          <em>adatmegjelölés</em> (Info tv. 3.§ 14.): az adat azonosító jelzéssel ellátása annak megkülönböztetése céljából;
        </li>
        <li>
          <em>adatzárolás</em> (Info tv. 3.§ 15.): az adat azonosító jelzéssel ellátása további kezelésének végleges vagy meghatározott időre történő korlátozása céljából;
        </li>
        <li>
          <em>adatmegsemmisítés</em> (Info tv. 3.§ 16.): az adatokat tartalmazó adathordozó teljes fizikai megsemmisítése;
        </li>
        <li>
          <em>adatfeldolgozás</em> (Info tv. 3.§ 18.): az adatkezelési műveletekhez kapcsolódó technikai feladatok elvégzése, függetlenül a műveletek végrehajtásához alkalmazott módszertől és eszköztől, valamint az alkalmazás helyétől feltéve, hogy a technikai feladatot az adatokon végzik;
        </li>
        <li>
          <em>adatfeldolgozó</em> (Info tv. 3.§ 18.): az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely az adatkezelővel kötött szerződése alapján – beleértve a jogszabály rendelkezése alapján történő szerződéskötést is – adatok feldolgozását végzi;
        </li>
        <li>
          <em>adatfelelős</em> (Info tv. 3.§ 19.): az a közfeladatot ellátó szerv, amely az elektronikus úton kötelezően közzéteendő közérdekű adatot előállította, illetve amelynek a működése során ez az adat keletkezett;
        </li>
        <li>
          <em>adatközlő</em> (Info tv. 3.§ 20.): az a közfeladatot ellátó szerv, amely – ha az adatfelelős nem maga teszi közzé az adatot – az adatfelelős által hozzá eljuttatott adatait honlapon közzéteszi;
        </li>
        <li>
          <em>adatállomány</em> (Info tv. 3.§ 21.): az egy nyilvántartásban kezelt adatok összessége;
        </li>
        <li>
          <em>harmadik személy</em> (Info tv. 3.§ 22.): olyan természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely nem azonos az érintettel, az adatkezelővel vagy az adatfeldolgozóval.
        </li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Az adatkezelés jogalapja (Info tv. 5-6.§)</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>
          Személyes adat akkor kezelhető, ha
          <ul
            style="list-style-type: disc;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
          >
            <li>ahhoz az érintett hozzájárul, vagy</li>
            <li>azt törvény vagy – törvény felhatalmazása alapján, az abban meghatározott körben – helyi önkormányzat rendelete közérdeken alapuló célból elrendeli (kötelező adatkezelés).</li>
          </ul>
        </li>
        <li>
          Személyes adat kezelhető akkor is, ha az érintett hozzájárulásának beszerzése lehetetlen vagy aránytalan költséggel járna, és a személyes adat kezelése
          <ol
            start="1"
            style="list-style-type: lower-alpha;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
          >
            <li>az adatkezelőre vonatkozó jogi kötelezettség teljesítése céljából szükséges, vagy</li>
            <li>az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából szükséges, és ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll.</li>
          </ol>
        </li>
        <li>Ha az érintett cselekvőképtelensége folytán vagy más elháríthatatlan okból nem képes hozzájárulását megadni, akkor a saját vagy más személy létfontosságú érdekeinek védelméhez, valamint a személyek életét, testi épségét vagy javait fenyegető közvetlen veszély elhárításához vagy megelőzéséhez szükséges mértékben a hozzájárulás akadályainak fennállása alatt az érintett személyes adatai kezelhetőek.</li>
        <li>A 16. életévét betöltött kiskorú érintett hozzájárulását tartalmazó jognyilatkozatának érvényességéhez törvényes képviselőjének beleegyezése vagy utólagos jóváhagyása nem szükséges.</li>
        <li>Ha a hozzájáruláson alapuló adatkezelés célja az adatkezelővel írásban kötött szerződés végrehajtása, a szerződésnek tartalmaznia kell minden olyan információt, amelyet a személyes adatok kezelése az érintettnek ismernie kell, így különösen a kezelendő adatok meghatározását, az adatkezelés időtartamát, a felhasználás célját, az adatok továbbításának tényét, címzettjeit, adatfeldolgozó igénybevételének tényét. A szerződésnek félreérthetetlen módon tartalmaznia kell, hogy az érintett aláírásával hozzájárul adatainak a szerződésben meghatározottak szerinti kezeléséhez.</li>
        <li>Ha a személyes adat felvételére az érintett hozzájárulásával került sor, az adatkezelő a felvett adatokat törvény eltérő rendelkezésének hiányában</li>
      </ol>
      <ul
        style="list-style-type: disc;margin-left:43.45px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>a rá vonatkozó jogi kötelezettség teljesítése céljából, vagy</li>
        <li>az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából, ha ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll</li>
      </ul>
      <p
        style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"
      >további külön hozzájárulás nélkül, valamint az érintett hozzájárulásának visszavonását követően is kezelheti.</p>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Az adatkezelés célhoz kötöttsége (Info tv. 4.§ (1)-(2))</span>
        </strong>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Személyes adat kizárólag meghatározott célból, jog gyakorlása és kötelezettség teljesítése érdekében kezelhető. Az adatkezelésnek minden szakaszában meg kell felelnie az adatkezelés céljának, az adatok felvételének és kezelésének tisztességesnek és törvényesnek kell lennie.</p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >Csak olyan személyes adat kezelhető, amely az adatkezelés céljának megvalósulásához elengedhetetlen, a cél elérésére alkalmas. A személyes adat csak a cél megvalósulásához szükséges mértékben és ideig kezelhető.</p>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Az adatkezelés egyéb elvei (Info tv. 4.§ (3)-(4))</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>A személyes adat az adatkezelés során mindaddig megőrzi e minőségét, amíg kapcsolata az érintettel helyreállítható. Az érintettel akkor helyreállítható a kapcsolat, ha az adatkezelő rendelkezik azokkal a technikai feltételekkel, amelyek a helyreállításhoz szükségesek.</li>
        <li>Az adatkezelés során biztosítani kell az adatok pontosságát, teljességét és – ha az adatkezelés céljára tekintettel szükséges – naprakészségét, valamint azt, hogy az érintettet csak az adatkezelés céljához szükséges ideig lehessen azonosítani.</li>
      </ol>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      ></p>
      <p
        style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:center;"
      >
        <strong>
          <span style="color:#ED7D31;">Funkcionális adatkezelés</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>
          Az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény 20. § (4) bekezdése alapján, meg kell határozni a weboldal funkcionalitásának (megrendelés) működése körében a következőket:
          <ol
            start="1"
            style="list-style-type: lower-alpha;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
          >
            <li>az adatgyűjtés ténye,</li>
            <li>az érintettek köre,</li>
            <li>az adatgyűjtés célja,</li>
            <li>az adatkezelés időtartama,</li>
            <li>az adatok megismerésére jogosult lehetséges adatkezelők személye,</li>
            <li>az érintettek adatkezeléssel kapcsolatos jogainak ismertetése,</li>
            <li>amennyiben az adatkezelés adatvédelmi nyilvántartásba vételének van helye, az adatkezelés nyilvántartási száma.</li>
          </ol>
        </li>
        <li>
          <strong>Az adatgyűjtés ténye, a kezelt adatok köre</strong>: Vezeték- és keresztnév, cégnév, e-mail cím, telefonszám, másodlagos telefonszám, székhely, szállítási cím, szállítási név, számlázási cím, számlázási név, fizetendő összeg.
        </li>
        <li>
          <strong>Az érintettek köre</strong>: A weboldalon feltüntetett bármely szolgáltatást igénybevevő (ideértve az árajánlatkérés céljából történő kapcsolatfelvételt is) felhasználó, továbbá valamennyi érintett.
        </li>
        <li>
          <strong>Az adatgyűjtés célja</strong>: Szolgáltató a weboldalon feltüntetett bármely szolgáltatás nyújtására irányuló szerződés létrehozása, árajánlatkérés teljesítése, bármely szolgáltatásra irányuló szerződés tartalmának meghatározása, módosítása, teljesítésének figyelemmel kísérése, az abból származó díjak számlázása, valamint az azzal kapcsolatos követelések érvényesítése céljából kezeli a felhasználóknak a fentiekben meghatározott személyes adatait.
        </li>
        <li>
          <strong>Az adatkezelés időtartama, az adatok törlésének határideje</strong>: A számviteli bizonylatok esetén a számvitelről szóló 2000. évi C. törvény 169. § (2) bekezdése alapján 8 évig meg kell őrizni az adatokat.
          <p>A könyvviteli elszámolást közvetlenül és közvetetten alátámasztó számviteli bizonylatot (ideértve a főkönyvi számlákat, az analitikus, illetve részletező nyilvántartásokat is), legalább 8 évig kell olvasható formában, a könyvelési feljegyzések hivatkozása alapján visszakereshető módon megőrizni.</p>
        </li>
        <li>
          <strong>Az adatok megismerésére jogosult lehetséges adatkezelők személye</strong>: A személyes adatokat az adatkezelő/ Szolgáltató kezeli, a fentiekben meghatározott alapelvek tiszteletben tartásával.
        </li>
        <li>
          <strong>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése</strong>: A személyes adatok törlését, vagy módosítását az alábbi módokon tudja érintett kezdeményezni:
          <ul style="list-style-type: disc;">
            <li>postai úton a 1037 Budapest Szépvölgyi út 39. fszt. 2. címen,</li>
            <li>e-mail útján az solution@ehealthss.hu e-mail címen.</li>
          </ul>
        </li>
        <li>
          <strong>Az adatkezelés jogalapja</strong>: a Felhasználó hozzájárulása, az Info tv. 5. § (1) bekezdése, illetve az elektronikus kereskedelemi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Elker tv.) 13/A. § (3) bekezdése alapján:
        </li>
      </ol>
      <p
        style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:30.0pt;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <em>A Szolgáltató a szolgáltatás nyújtása céljából kezelheti azon személyes adatokat, amelyek a szolgáltatás nyújtásához technikailag elengedhetetlenül szükségesek. A szolgáltatónak az egyéb feltételek azonossága esetén úgy kell megválasztania és minden esetben oly módon kell üzemeltetnie az információs társadalommal összefüggő szolgáltatás nyújtása során alkalmazott eszközöket, hogy személyes adatok kezelésére csak akkor kerüljön sor, ha ez a szolgáltatás nyújtásához és az e törvényben meghatározott egyéb célok teljesüléséhez feltétlenül szükséges, azonban ebben az esetben is csak a szükséges mértékben és ideig.</em>
      </p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span
            style="color:#ED7D31;"
          >A szolgáltató funkcionális adatkezelésre vonatkozó alapelvei (Elker tv. 13/A. §-t alapul véve)</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>A Szolgáltató az információs társadalommal összefüggő szolgáltatás nyújtására irányuló szerződésből származó díjak számlázása céljából kezelheti az információs társadalommal összefüggő szolgáltatás igénybevételével kapcsolatos természetes személyazonosító adatokat, lakcímet, valamint a szolgáltatás igénybevételének időpontjára, időtartamára, a szolgáltatásnyújtás helyére vonatkozó adatokat.</li>
        <li>A Szolgáltató a szolgáltatás nyújtása céljából kezelheti azon személyes adatokat, amelyek a szolgáltatás nyújtásához technikailag elengedhetetlenül szükségesek. A Szolgáltatónak az egyéb feltételek azonossága esetén úgy kell megválasztania és minden esetben oly módon kell üzemeltetnie az információs társadalommal összefüggő szolgáltatás nyújtása során alkalmazott eszközöket, hogy személyes adatok kezelésére csak akkor kerüljön sor, ha ez a szolgáltatás nyújtásához és az Elker törvényben meghatározott egyéb célok teljesüléséhez feltétlenül szükséges, azonban ebben az esetben is csak a szükséges mértékben és ideig.</li>
        <li>A Szolgáltató a szolgáltatás igénybevételével kapcsolatos adatokat bármely egyéb célból – így különösen szolgáltatása hatékonyságának növelése, az igénybe vevőnek címzett elektronikus hirdetés vagy egyéb címzett tartalom eljuttatása, piackutatás céljából – csak az adatkezelési cél előzetes meghatározása mellett és az igénybe vevő hozzájárulása alapján kezelhet.</li>
        <li>Az igénybe vevőnek az információs társadalommal összefüggő szolgáltatás igénybevételét megelőzően és a szolgáltatás igénybevétele során is folyamatosan biztosítani kell, hogy az adatkezelést megtilthassa.</li>
        <li>A kezelt adatokat törölni kell a szerződés létrejöttének elmaradását, a szerződés megszűnését, valamint a számlázást követően. Az adatokat törölni kell, ha az adatkezelési cél megszűnt, vagy az igénybe vevő így rendelkezik. Törvény eltérő rendelkezése hiányában az adattörlést haladéktalanul el kell végezni.</li>
        <li>A Szolgáltatónak biztosítania kell, hogy az igénybe vevő az információs társadalommal összefüggő szolgáltatás igénybevétele előtt és az igénybevétel során bármikor megismerhesse, hogy a Szolgáltató mely adatkezelési célokból mely adatfajtákat kezel, ideértve az igénybe vevővel közvetlenül kapcsolatba nem hozható adatok kezelését is.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Adatfeldolgozás</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>
          Az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény 20. § (1) bekezdése alapján, meg kell határozni a weboldal adatfeldolgozási tevékenysége körében a következőket:
          <ol
            start="1"
            style="list-style-type: lower-alpha;margin-left:43.45px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
          >
            <li>az adatgyűjtés ténye,</li>
            <li>az érintettek köre,</li>
            <li>az adatgyűjtés célja,</li>
            <li>az adatkezelés időtartama,</li>
            <li>az adatok megismerésére jogosult lehetséges adatkezelők személye,</li>
            <li>az érintettek adatkezeléssel kapcsolatos jogainak ismertetése.</li>
          </ol>
        </li>
        <li>
          <strong>Az adatkezelés ténye, a kezelt adatok köre</strong>: A látogatók valamennyi adata.
        </li>
        <li>
          <strong>Az érintettek köre</strong>: A weboldalt látogató valamennyi érintett.
        </li>
        <li>
          <strong>Az adatkezelés célja</strong>: A weboldal működésének zavartalan biztosítása.
        </li>
        <li>
          <strong>Az adatkezelés időtartama, az adatok törlésének határideje</strong>: A látogatás befejezéséig tart.
        </li>
        <li>
          <strong>Az adatok megismerésére jogosult lehetséges adatkezelők személye</strong>: A személyes adatokat az eHealth Software Solutions Kft. kezelheti, a fenti alapelvek tiszteletben tartásával.
        </li>
        <li>
          <strong>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése</strong>: Az érintett kérheti az adatkezelőtől a személyes adatainak mielőbbi törlését a fenti elérhetőségeken.
        </li>
        <li>
          <strong>Az adattovábbítás jogalapja</strong>: a Felhasználó hozzájárulása, az Infotv. 5. § (1) bekezdése, illetve az elektronikus kereskedelemi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény 13/A. § (3) bekezdése.
        </li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Adatbiztonság</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>Az adatkezelő köteles az adatkezelési műveleteket úgy megtervezni és végrehajtani, hogy biztosítsa az érintettek magánszférájának védelmét.</li>
        <li>Az adatkezelő, illetve tevékenységi körében az adatfeldolgozó köteles gondoskodni az adatok biztonságáról, köteles továbbá megtenni azokat a technikai és szervezési intézkedéseket és kialakítani azokat az eljárási szabályokat, amelyek az Info tv., valamint az egyéb adat- és titokvédelmi szabályok érvényre juttatásához szükségesek.</li>
        <li>Az adatokat megfelelő intézkedésekkel védeni kell különösen a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</li>
        <li>A különböző nyilvántartásokban elektronikusan kezelt adatállományok védelme érdekében megfelelő technikai megoldással biztosítani kell, hogy a nyilvántartásokban tárolt adatok – kivéve, ha azt törvény lehetővé teszi – közvetlenül ne legyenek összekapcsolhatók és az érintetthez rendelhetők.</li>
        <li>Az adatkezelőnek és az adatfeldolgozónak az adatok biztonságát szolgáló intézkedések meghatározásakor és alkalmazásakor tekintettel kell lenni a technika mindenkori fejlettségére. Több lehetséges adatkezelési megoldás közül azt kell választani, amely a személyes adatok magasabb szintű védelmét biztosítja, kivéve, ha az aránytalan nehézséget jelentene az adatkezelőnek.</li>
        <li>Az adatkezelő a személyes adatokat zárt helyiségben jelszóval védett számítógépen tárolja, melyhez csak az adatkezelő fér hozzá.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Az érintettek jogai</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>Az érintett kérelmezheti az Szolgáltatónál, hogy az adjon tájékoztatását személyes adatai kezeléséről, kérheti személyes adatainak helyesbítését, valamint kérheti személyes adatainak – a kötelező adatkezelés kivételével – törlését vagy zárolását.</li>
        <li>Az érintett kérelmére az adatkezelő tájékoztatást ad az érintett általa kezelt, illetve az általa megbízott adatfeldolgozó által feldolgozott adatairól, azok forrásáról, az adatkezelés céljáról, jogalapjáról, időtartamáról, az adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő tevékenységéről, továbbá – az érintett személyes adatainak továbbítása esetén – az adattovábbítás jogalapjáról és címzettjéről.</li>
        <li>Az adatkezelő az adattovábbítás jogszerűségének ellenőrzése, valamint az érintett tájékoztatása céljából adattovábbítási nyilvántartást vezet, amely tartalmazza az általa kezelt személyes adatok továbbításának időpontját, az adattovábbítás jogalapját és címzettjét, a továbbított személyes adatok körének meghatározását, valamint az adatkezelést előíró jogszabályban meghatározott egyéb adatokat.</li>
        <li>Az adatkezelő köteles a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 30 napon belül, közérthető formában, az érintett erre irányuló kérelmére írásban megadni a tájékoztatást. A tájékoztatás ingyenes.</li>
        <li>Felhasználó kérelmére Szolgáltató tájékoztatást ad az általa kezelt adatokról, azok forrásáról, az adatkezelés céljáról, jogalapjáról, időtartamáról, az esetleges adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő tevékenységéről, továbbá – az érintett személyes adatainak továbbítása esetén – az adattovábbítás jogalapjáról és címzettjéről. Szolgáltató a kérelem benyújtásától számított legrövidebb idő alatt, legfeljebb azonban 30 napon belül írásban, közérthető formában adja meg a tájékoztatást. A tájékoztatás ingyenes.</li>
        <li>Szolgáltató, amennyiben a személyes adat a valóságnak nem felel meg, és a valóságnak megfelelő személyes adat az adatkezelő rendelkezésére áll, a személyes adatot helyesbíti.</li>
        <li>Törlés helyett Szolgáltató zárolja a személyes adatot, ha a Felhasználó ezt kéri, vagy ha a rendelkezésére álló információk alapján feltételezhető, hogy a törlés sértené a Felhasználó jogos érdekeit. A zárolt személyes adat kizárólag addig kezelhető, ameddig fennáll az az adatkezelési cél, amely a személyes adat törlését kizárta.</li>
        <li>Szolgáltató törli a személyes adatot, ha kezelése jogellenes, a Felhasználó kéri, a kezelt adat hiányos vagy téves – és ez az állapot jogszerűen nem orvosolható – feltéve, hogy a törlést törvény nem zárja ki, az adatkezelés célja megszűnt, vagy az adatok tárolásának törvényben meghatározott határideje lejárt, azt a bíróság vagy a Nemzeti Adatvédelmi és Információszabadság Hatóság elrendelte.</li>
        <li>Az adatkezelő megjelöli az általa kezelt személyes adatot, ha az érintett vitatja annak helyességét vagy pontosságát, de a vitatott személyes adat helytelensége vagy pontatlansága nem állapítható meg egyértelműen.</li>
        <li>A helyesbítésről, a zárolásról, a megjelölésről és a törlésről az érintettet, továbbá mindazokat értesíteni kell, akiknek korábban az adatot adatkezelés céljára továbbították. Az értesítés mellőzhető, ha ez az adatkezelés céljára való tekintettel az érintett jogos érdekét nem sérti.</li>
        <li>Ha az adatkezelő az érintett helyesbítés, zárolás vagy törlés iránti kérelmét nem teljesíti, a kérelem kézhezvételét követő 30 napon belül írásban közli a helyesbítés, zárolás vagy törlés iránti kérelem elutasításának ténybeli és jogi indokait. A helyesbítés, törlés vagy zárolás iránti kérelem elutasítása esetén az adatkezelő tájékoztatja az érintettet a bírósági jogorvoslat, továbbá a Hatósághoz fordulás lehetőségéről.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Jogorvoslat</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>
          Felhasználó tiltakozhat személyes adatának kezelése ellen, ha
          <ol style="list-style-type: lower-alpha;">
            <li>a személyes adatok kezelése vagy továbbítása kizárólag az Szolgáltatóra vonatkozó jogi kötelezettség teljesítéséhez, vagy az Szolgáltató, adatátvevő vagy harmadik személy jogos érdekének érvényesítéséhez szükséges, kivéve, ha az adatkezelést törvény rendelte el;</li>
            <li>a személyes adat felhasználása vagy továbbítása közvetlen üzletszerzés, közvélemény-kutatás vagy tudományos kutatás céljára történik;</li>
            <li>törvényben meghatározott egyéb esetben.</li>
          </ol>
        </li>
        <li>Szolgáltató a tiltakozást a kérelem benyújtásától számított legrövidebb időn belül, de legfeljebb 15 nap alatt megvizsgálja, annak megalapozottsága kérdésében döntést hoz, és döntéséről a kérelmezőt írásban tájékoztatja. Ha az Szolgáltató az érintett tiltakozásának megalapozottságát megállapítja, az adatkezelést – beleértve a további adatfelvételt és adattovábbítást is – megszünteti, és az adatokat zárolja, valamint a tiltakozásról, továbbá az annak alapján tett intézkedésekről értesíti mindazokat, akik részére a tiltakozással érintett személyes adatot korábban továbbította, és akik kötelesek intézkedni a tiltakozási jog érvényesítése érdekében.</li>
        <li>Amennyiben a Felhasználó Szolgáltatónak a meghozott döntésével nem ért egyet, az ellen – annak közlésétől számított 30 napon belül – bírósághoz fordulhat. A bíróság soron kívül jár el.</li>
        <li>Az adatkezelő esetleges jogsértése ellen panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet élni, melynek elérhetősége:</li>
      </ol>
      <p
        style="line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;margin-bottom:0cm;margin-top:5px;text-align:justify;"
      >
        <em>Nemzeti Adatvédelmi és Információszabadság Hatóság</em>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;text-align:justify;"
      >
        <em>1125 Budapest, Szilágyi Erzsébet fasor 22/C.</em>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;text-align:justify;"
      >
        <em>Levelezési cím: 1530 Budapest, Postafiók: 5.</em>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;text-align:justify;"
      >
        <em>Telefon: +36 -1-391-1400</em>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;text-align:justify;"
      >
        <em>Fax: +36-1-391-1410</em>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;margin-left:30pt;text-align:justify;"
      >
        <em>
          E-mail:
          <span><a style="color:#ED7D31;" href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a></span>
        </em>
      </p>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Bírósági jogérvényesítés</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>Azt, hogy az adatkezelés a jogszabályban foglaltaknak megfelel, az adatkezelő köteles bizonyítani. Az adattovábbítás jogszerűségét az adatátvevő köteles bizonyítani.</li>
        <li>A per elbírálása a törvényszék hatáskörébe tartozik. A per – az érintett választása szerint – az érintett lakóhelye vagy tartózkodási helye szerinti törvényszék előtt is megindítható.</li>
        <li>A perben fél lehet az is, akinek egyébként nincs perbeli jogképessége. A perbe a Hatóság az érintett pernyertessége érdekében beavatkozhat.</li>
        <li>Ha a bíróság a kérelemnek helyt ad, az adatkezelőt a tájékoztatás megadására, az adat helyesbítésére, zárolására, törlésére, az automatizált adatfeldolgozással hozott döntés megsemmisítésére, az érintett tiltakozási jogának figyelembevételére, illetve az adatátvevő által kért adat kiadására kötelezi.</li>
        <li>Ha a bíróság az adatátvevő kérelmét elutasítja, az adatkezelő köteles az érintett személyes adatát az ítélet közlésétől számított 3 napon belül törölni. Az adatkezelő köteles az adatokat akkor is törölni, ha az adatátvevő a meghatározott határidőn belül nem fordul bírósághoz.</li>
        <li>A bíróság elrendelheti ítéletének – az adatkezelő azonosító adatainak közzétételével történő – nyilvánosságra hozatalát, ha azt az adatvédelem érdekei és nagyobb számú érintett védett jogai megkövetelik.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Kártérítés és sérelemdíj</span>
        </strong>
      </p>
      <ol
        style="list-style-type: decimal;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>Ha az adatkezelő az érintett adatainak jogellenes kezelésével vagy az adatbiztonság követelményeinek megszegésével másnak kárt okoz, köteles azt megtéríteni.</li>
        <li>Ha az adatkezelő az érintett adatainak jogellenes kezelésével vagy az adatbiztonság követelményeinek megszegésével az érintett személyiségi jogát megsérti, az érintett az adatkezelőtől sérelemdíjat követelhet.</li>
        <li>Az érintettel szemben az adatkezelő felel az adatfeldolgozó által okozott kárért és az adatkezelő köteles megfizetni az érintettnek az adatfeldolgozó által okozott személyiségi jogsértés esetén járó sérelemdíjat is. Az adatkezelő mentesül az okozott kárért való felelősség és a sérelemdíj megfizetésének kötelezettsége alól, ha bizonyítja, hogy a kárt vagy az érintett személyiségi jogának sérelmét az adatkezelés körén kívül eső elháríthatatlan ok idézte elő.</li>
        <li>Nem kell megtéríteni a kárt és nem követelhető a sérelemdíj annyiban, amennyiben a kár a károsult vagy a személyiségi jog megsértésével okozott jogsérelem az érintett szándékos vagy súlyosan gondatlan magatartásából származott.</li>
      </ol>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
      <p style="margin:20px 0px;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align: center;">
        <strong>
          <span style="color:#ED7D31;">Zárszó</span>
        </strong>
      </p>
      <p
        style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >A jelen tájékoztatóban nem szabályozott kérdések tekintetében az alábbi jogszabályokat kell alkalmazni:</p>
      <ul
        style="list-style-type: disc;margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;text-align:justify;"
      >
        <li>évi CXII. törvény – az információs önrendelkezési jogról és az információszabadságról;</li>
        <li>évi CVIII. törvény – az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről;</li>
        <li>évi XLVII. törvény – a fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat tilalmáról;</li>
        <li>évi XLVIII. törvény – a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól</li>
        <li>évi XC. törvény az elektronikus információszabadságról</li>
        <li>évi C. törvény az elektronikus hírközlésről 16/2011. sz. vélemény a viselkedésalapú online reklám bevált gyakorlatára vonatkozó EASA/IAB-ajánlásról</li>
      </ul>
      <p style="margin:0cm;line-height:18.0pt;font-size:16px;font-family:'Calibri',sans-serif;"></p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.data-protection {
  padding: 50px 0px 100px 0px;
}
</style>