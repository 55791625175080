var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"fit-content"}},[(_vm.$productSettings[_vm.managerType].ManageUser.CanRemoveProjectRelation &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.removeRelationPermission))?_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.$t('ProjectParticipant.removeProjectRelation')),expression:"$t('ProjectParticipant.removeProjectRelation')",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer","background":"#dd0305"},attrs:{"icon":"remove-user-relation"},on:{"click":_vm.removeProjectRelation}}):_vm._e(),(_vm.$productSettings[_vm.managerType].ManageUser.CanRemoveParticipantFromProject &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.removeSubordinatePermission))?_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.$t('ProjectParticipant.removeFromProject')),expression:"$t('ProjectParticipant.removeFromProject')",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer","background":"#dd0305"},attrs:{"icon":"user-remove"},on:{"click":_vm.removeFromProject}}):_vm._e(),(
      _vm.$productSettings[_vm.managerType].ManageUser.CanDeleteParticipant &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.removePatientPermission)
    )?_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.$t('ProjectParticipant.deleteParticipant')),expression:"$t('ProjectParticipant.deleteParticipant')",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer","background":"#dd0305"},attrs:{"icon":"user-delete"},on:{"click":_vm.deleteUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }