<template>
  <fp-form-modal
    size="xl"
    title="ESSZT Recept Rögzítés"
    v-model="visible"
    :closable="false"
  >
    <template #content>
      <!-- Extra Vonalkód Beolvasás -->
      <div v-if="!userPatientId">
        <b-row align-v="start" class="bv-example-row">
          <b-col>
            <div class="client-profile-inputs">
              <fp-input
                style="margin: 0px 0px 10px 0px"
                :disabled="false"
                label="Extra vonalkód"
                @change="changeValue('extraBarcode', $event)"
                v-model="extraBarcode"
                description="Extra vonalód a vény alján"
              />
            </div>
          </b-col>
          <!--Nyomógomb ha kell!-->
          <!--<b-col>
                        <b-button style="margin:22px 0px 0px 0px;" class="eh-action-button float-right" @click="addRecipe">Recept hozzáadása</b-button>
                    </b-col>-->
        </b-row>
      </div>
      <div>
        <!-- DOKI -->
        <fp-panel-card
          title="Felíró orvos adatai"
          description="EESZT beküldéshez kötelező mező!"
          :closedCard="true"
          :error="errorDoctor"
        >
          <template #content>
            <prescription-doctor
              ref="createPrescriptionDoctor"
              v-model="prescription.Doctor"
            />
          </template>
        </fp-panel-card>
        <!-- Páciens -->
        <div style="margin-top: 10px">
          <fp-panel-card
            title="Páciens adatai"
            :required="true"
            :error="errorPatient"
          >
            <template #content>
              <div v-if="!userPatientId" style="margin: 0px 10px">
                <fp-select
                  label="Páciens lista"
                  :required="true"
                  description="Keresés felhasználónév szerint"
                  v-model="selectedPatientId"
                  valueKey="UserId"
                  :items="filteredPatientList"
                  :searchable="true"
                  @search="searchUser"
                >
                  <template #element="e">
                    {{
                      e.Name +
                      " ( " +
                      (e.Username ? e.Username : "") +
                      (e.Username && e.Ssn ? ", " : "") +
                      (e.Ssn ? e.Ssn : "") +
                      " )"
                    }}
                  </template>
                </fp-select>
              </div>
              <patient
                ref="createPrescriptionPatient"
                :disabled="!(userPatientId || selectedPatientId)"
                v-model="prescription.Patient"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Recept alap adatai -->
        <div style="margin-top: 10px">
          <fp-panel-card
            title="Recept alap adatai"
            :error="errorBaseData"
            :required="true"
          >
            <template #content>
              <b-row cols="1" cols-md="2">
                <b-col>
                  <fp-input
                    label="Papíralapú NEAK vényazonosító"
                    description="EESZT beküldéshez kötelező mező!"
                    v-model="prescription.NeakId"
                  />
                </b-col>
                <b-col>
                  <fp-input
                    label="Forgalmi / ambuláns napló száma"
                    description="EESZT beküldéshez kötelező mező!"
                    v-model="prescription.OutpatientLogNumber"
                  />
                </b-col>
                <b-col>
                  <fp-select
                    label="BNO szerinti betegség kód"
                    :required="true"
                    v-model="prescription.BnoId"
                    :items="eesztBNOList"
                    valueKey="EntryId"
                    :searchable="true"
                    @search="searchBNO"
                    :state="!validation.hasError('prescription.BnoId')"
                    :error="validation.firstError('prescription.BnoId')"
                  >
                    <template #element="e">
                      <div>{{ e.Name }}</div>
                      <div>({{ e.Code }})</div>
                    </template>
                  </fp-select>
                </b-col>
                <b-col>
                  <fp-date-picker
                    label="Felirás dátuma"
                    :required="true"
                    v-model="prescription.PrescriptionTimestamp"
                    :state="
                      !validation.hasError('prescription.PrescriptionTimestamp')
                    "
                    :error="
                      validation.firstError(
                        'prescription.PrescriptionTimestamp'
                      )
                    "
                  />
                </b-col>
                <b-col>
                  <fp-select
                    label="Vény típusa"
                    :required="true"
                    v-model="prescription.PrescriptionTypeId"
                    :items="eesztPrescriptionTypeList"
                    valueKey="EntryId"
                    textKey="Name"
                    :state="
                      !validation.hasError('prescription.PrescriptionTypeId')
                    "
                    :error="
                      validation.firstError('prescription.PrescriptionTypeId')
                    "
                  />
                </b-col>
                <b-col>
                  <fp-select
                    label="Termék típusa"
                    :required="true"
                    v-model="prescription.ProductTypeId"
                    :items="eesztProductTypeList"
                    valueKey="EntryId"
                    textKey="Name"
                    :state="!validation.hasError('prescription.ProductTypeId')"
                    :error="validation.firstError('prescription.ProductTypeId')"
                  />
                </b-col>
              </b-row>
            </template>
          </fp-panel-card>
        </div>
        <!-- Termékek -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Termékek"
            :error="errorProducts"
            :required="true"
          >
            <template #content>
              <fp-panel-card
                style="margin: 20px 0px"
                v-for="(product, index) in prescription.Products"
                :key="'eeszt-recipe-product-' + productIds[index]"
                :title="index + 1 + '. termék'"
                :closedCard="index != 0"
                :isShowAddButton="true"
                :isShowDeleteButton="prescription.Products.length > 1"
                :error="errorProduct.includes(index)"
                @addNewElement="addNewProductToRecipe(index)"
                @deleteElement="deleteProductFromRecipe(index)"
              >
                <template #content>
                  <product
                    ref="createPrescriptionProduct"
                    v-model="prescription.Products[index]"
                  />
                </template>
              </fp-panel-card>
            </template>
          </fp-panel-card>
        </div>
        <!-- Ellenjegyzés -->
        <!-- <div style="margin: 10px 0px">
      <fp-panel-card
        title="Ellenjegyzés"
        description="EESZT beküldéshez kötelező mező!"
        :closedCard="true"
      >
        <template #content>
          <countersign
            ref="createPrescriptionCountersign"
            v-model="prescription.Countersign"
          />
        </template>
      </fp-panel-card>
    </div> -->
        <!-- Szakorvosi javaslat -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Szakorvosi javaslat"
            :error="errorSpecialistRecommendation"
            :closedCard="true"
          >
            <template #content>
              <fp-input
                label="Szakorvosi javaslat azonosító"
                v-model="prescription.SpecialistRecommendationObjectId"
              />
              <specialist-recommendation
                ref="createPrescriptionSpecialistRecommendation"
                v-model="prescription.SpecialistRecommendation"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Kihordási időn belüli módosítás -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Állapot"
            :error="errorStatement"
            :closedCard="true"
          >
            <template #content>
              <statement
                ref="createPrescriptionStatement"
                v-model="prescription.Statement"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Dokumentum -->
        <div style="margin: 10px 0px">
          <fp-panel-card title="Dokumentum adatai" :closedCard="true">
            <template #content>
              <fp-input
                label="Dokumentum EHR azonosítója"
                description="Elbíráláshoz szükséges dokumentum EHR azonosítója"
                v-model="prescription.DocumentId"
              />
              <fp-text-area
                label="Dokumentum leírása"
                description="Elbíráláshoz szükséges dokumentum leírása, ha papíralapú"
                v-model="prescription.DocumentDescription"
              />
            </template>
          </fp-panel-card>
        </div>

        <b-button @click="savePrescription">Mentés</b-button>
        <b-button @click="visible = false">Mégsem</b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
/* Components */
import Patient from "../Components/create/Patient.vue";
import Product from "../Components/create/PrescriptionProduct.vue";
import PrescriptionDoctor from "../Components/create/PrescriptionDoctor.vue";
import SpecialistRecommendation from "../Components/common/SpecialistRecommendation.vue";
import Statement from "../Components/common/Statement.vue";
/* Logic */
import moment from "moment";
import { BaseDataLogic } from "../../../Logic/Backend/base-data";
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import { UserLogic } from "../../../Logic/Backend/user";
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";

export default {
  components: {
    Product,
    Patient,
    PrescriptionDoctor,
    SpecialistRecommendation,
    Statement,
  },
  name: "NewPrescriptionForm",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    userPatientId: Number,
    projectPatient: Array,
    projectRoles: Array,
  },
  data() {
    return {
      visible: this.value,
      prescription: null,
      defaultForm: {
        CreatorId: this.$loggedUser.UserId,
        CreatorOrganizationNickname: null,
        Doctor: null,
        Patient: null,
        NeakId: null,
        OutpatientLogNumber: null,
        PrescriptionTimestamp: moment().format(),
        PrescriptionTypeId: null,
        //Countersign: null,
        Countersign: {
          CountersignMandatory: false,
        },
        /* SubmitterId: this.$loggedUser.UserId,
                    SubmitterOrganizationNickname: null, */
        BnoId: null,
        ProductTypeId: null,
        Products: [null],
        SpecialistRecommendationObjectId: null,
        SpecialistRecommendation: null,
        Statement: null,
        DocumentId: null,
        DocumentDescription: null,
      },
      extraBarcode: null,
      productIds: [null],
      productsCount: 1,
      //listák
      filteredPatientList: null,
      eesztProductTypeList: null,
      eesztPrescriptionTypeList: null,
      eesztBNOList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
      //errorFlag
      errorDoctor: false,
      errorPatient: false,
      errorBaseData: false,
      errorProducts: false,
      errorProduct: [],
      errorSpecialistRecommendation: false,
      errorStatement: false,
      selectedPatientId: null,
      eesztMedicalDeviceList: null,
    };
  },
  watch: {
    async value(input) {
      this.visible = input;
      if (input) {
        this.setNewPrescriptionData();
        if (
          this.eesztMedicalDeviceList == null &&
          this.eesztProductTypeList == null &&
          this.eesztPrescriptionTypeList == null &&
          this.eesztBNOList == null
        ) {
          await this.getLists();
        }
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
    async selectedPatientId(input) {
      await this.selectPatient(input);
    },
    /* projectPatient(input) {
              if (input) {
                this.filteredPatientList = input.slice(0, 10);
              }
            }, */
  },
  validators: {
    "prescription.BnoId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "prescription.PrescriptionTypeId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "prescription.ProductTypeId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    setNewPrescriptionData() {
      this.extraBarcode = null;
      this.selectedPatientId = null;
      this.prescription = JSON.parse(JSON.stringify(this.defaultForm));
      this.prescription.PrescriptionTimestamp = moment().format();
      if (this.userPatientId) {
        this.selectPatient(this.userPatientId);
      } else {
        this.selectPatient(null);
      }
      this.resetValidation();
    },
    async selectPatient(patientId) {
      if (patientId) {
        const patientProfile = await this.getPatientProfileData(patientId);
        if (patientProfile) {
          this.prescription.Patient = {};
          //user azonosító
          this.prescription.Patient.PatientId = patientProfile.UserId;
          //user neve
          this.prescription.Patient.FullName =
            patientProfile.PersonalProfile.Name;
          //user igazolvány azonosító - SSN / EUSSN
          if (patientProfile.PersonalProfile.SocialSecurityNumber) {
            this.prescription.Patient.PatientIdentifierValue =
              patientProfile.PersonalProfile.SocialSecurityNumber;
            this.prescription.Patient.PatientIdentifierTypeId =
              this.$enums.Prescription.EesztPatientTransfer.Ssn.EesztPatientEntryId;
          } else if (patientProfile.PersonalProfile.EuSocialSecurityNumber) {
            this.prescription.Patient.PatientIdentifierValue =
              patientProfile.PersonalProfile.EuSocialSecurityNumber;
            this.prescription.Patient.PatientIdentifierTypeId =
              this.$enums.Prescription.EesztPatientTransfer.EuSsn.EesztPatientEntryId;
          }
          // user neme - Female / Male
          if (
            patientProfile.PersonalProfile.Sex ==
            this.$enums.Prescription.EesztPatientTransfer.GenderFemale.UserProfileId
          ) {
            this.prescription.Patient.GenderId =
              this.$enums.Prescription.EesztPatientTransfer.GenderFemale.EesztPatientEntryId;
          } else if (
            patientProfile.PersonalProfile.Sex ==
            this.$enums.Prescription.EesztPatientTransfer.GenderMale.UserProfileId
          ) {
            this.prescription.Patient.GenderId =
              this.$enums.Prescription.EesztPatientTransfer.GenderMale.EesztPatientEntryId;
          }
          //user telefon
          this.prescription.Patient.Phone =
            patientProfile.PersonalProfile.PersonalContact.Phone;
          //user születésnap
          this.prescription.Patient.BirthDate =
            patientProfile.PersonalProfile.Birthday;
          //user cím
          this.$set(this.prescription.Patient, "Address", null);
          this.prescription.Patient.Address = {
            PostalCode:
              patientProfile.PersonalProfile.PersonalContact.Address.ZipCode,
            City: patientProfile.PersonalProfile.PersonalContact.Address.City,
            StreetAddress: patientProfile.PersonalProfile.PersonalContact
              .Address.Street
              ? patientProfile.PersonalProfile.PersonalContact.Address.Street
              : null +
                " " +
                patientProfile.PersonalProfile.PersonalContact.Address.Building
              ? patientProfile.PersonalProfile.PersonalContact.Address.Building
              : null +
                " " +
                patientProfile.PersonalProfile.PersonalContact.Address
                  .SubBuilding
              ? patientProfile.PersonalProfile.PersonalContact.Address
                  .SubBuilding
              : null,
          };
          //user ph
          this.prescription.Patient.PublicHealthIdentifier =
            patientProfile.PersonalProfile.PublicHealthcareCardNumber;
          this.prescription.Patient.PublicHealthIdentifierValidBy =
            patientProfile.PersonalProfile.PublicHealthcareCardValidBy
              ? moment(
                  patientProfile.PersonalProfile.PublicHealthcareCardValidBy
                ).format("YYYY-MM-DD")
              : null;
          //euDocCode
          this.prescription.Patient.EuDocumentCode =
            patientProfile.PersonalProfile.EuDocumentCode;
        }
      } else {
        this.prescription.Patient = null;
      }
      if (this.$refs["createPrescriptionPatient"]) {
        this.$refs["createPrescriptionPatient"].resetValidation();
      }
    },
    async getPatientProfileData(userId) {
      const getProfileResponse = await UserProfileLogic.getOtherProfile(userId);
      if (!getProfileResponse.Code) {
        return getProfileResponse;
      } else {
        //TODO: hiba
      }
    },
    async searchUser(input) {
      /* this.filteredPatientList = this.$filterList(input, this.projectPatient, [
                  "Username",
                  "Name",
                  "Ssn",
                  "DateOfBirth",
                ]); */
      if (input.length > 2) {
        await this.getProjectPatientsData({
          multiValue: input,
        });
      } else if (input) {
        this.filteredPatientList = null;
      } else {
        await this.getProjectPatientsData({
          "-limit": 100,
        });
      }
    },
    searchPatientByTag(input) {
      this.filteredPatientList = this.patientList;
      if (this.filterInput) {
        this.searchPatient(this.filterInput, true);
      }
      if (input) {
        this.filteredPatientList = this.filteredPatientList.filter((patient) =>
          patient.Tags.some((tag) => tag.TagId == input)
        );
      }
    },
    async searchBNO(input) {
      if (input.length > 2) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            ["Name-contains"]: input,
          }
        );
        const codeContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            ["Code-contains"]: input,
          }
        );
        this.eesztBNOList = this.checkDuplicates([
          ...nameContainsList,
          ...codeContainsList,
        ]);
      } else if (input.length == 0) {
        this.eesztBNOList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            "-orderby": "Code",
            ["-limit"]: 10,
          }
        );
      } else {
        this.eesztBNOList = [];
      }
    },
    async searchMedicalDeviceByTttCode(tttId) {
      if (tttId) {
        this.eesztMedicalDeviceList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Ttt-contains"]: tttId,
          }
        );
      } else {
        this.eesztMedicalDeviceList = [];
      }
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    addNewProductToRecipe(index) {
      this.productsCount++;
      this.productIds.splice(index + 1, 0, this.productsCount);
      this.prescription.Products.splice(index + 1, 0, null);
    },
    deleteProductFromRecipe(index) {
      this.productIds.splice(index, 1);
      this.prescription.Products.splice(index, 1);
    },
    async addRecipe() {
      if (this.extraBarcode != null) {
        if (this.extraBarcode.length == 43) {
          const response = await this.getExtraBarcode({
            barcodeString: this.extraBarcode,
          });

          if (response) {
            await this.searchUser(response.Patientssn);
            if (
              this.filteredPatientList &&
              this.filteredPatientList.length &&
              this.filteredPatientList.length == 1
            ) {
              this.selectedPatientId = this.filteredPatientList[0].UserId;
            }
            if (response.Bno != null) {
              await this.searchBNO(response.Bno.Code);
              if (
                this.eesztBNOList &&
                this.eesztBNOList.length &&
                this.eesztBNOList.length == 1
              ) {
                this.prescription.BnoId = this.eesztBNOList[0].EntryId;
              }
            }
            this.prescription.PrescriptionTimestamp =
              response.PrescriptionTimestamp;
            this.prescription.Products[0].PrescriptionTitleId =
              response.PrescriptionTitle ? response.PrescriptionTitle.Id : null;
            this.prescription.Products[0].Amount = response.Amount;

            if (response.Ttt != null) {
              await this.searchMedicalDeviceByTttCode(response.Ttt.Code);
              if (
                this.eesztMedicalDeviceList &&
                this.eesztMedicalDeviceList.length &&
                this.eesztMedicalDeviceList.length == 1
              ) {
                this.eesztMedicalDeviceList.Ttt = response.Ttt.Code;
                this.prescription.Products[0].MedicalDeviceId =
                  this.eesztMedicalDeviceList[0].EntryId;
              }
            }
            if (
              response.RecommendationDate &&
              response.DoctorStampNumber &&
              response.DoctorStampNumber != "00000"
            )
              this.prescription.SpecialistRecommendation = {
                EnkkId: response.DoctorStampNumber,
                RecommendationDate: response.RecommendationDate,
              };

            this.$bvToast.toast("Az adatok sikeresen hozzáadva az űrlaphoz!", {
              title: "Recept hozzáadása",
              variant: "success",
              solid: true,
            });

            if (!response.PrescriptionIsValid) {
              this.$bvToast.toast(
                "Recept felírási dátuma három hónapnál régebbi!",
                {
                  title: "Figyelmeztetés",
                  variant: "warning",
                  solid: true,
                }
              );
            }
          }
        }
      } else {
        this.$bvToast.toast("Az extra vonalkód mező üres!", {
          title: "Hibás mező kitöltés",
          variant: "danger",
          solid: true,
        });
      }

      this.extraBarcode = null;
    },
    async getExtraBarcode(params) {
      const getgetExtraBarcodeResponse =
        await MedicalDeviceLogic.getExtraBarcode(params);
      if (!getgetExtraBarcodeResponse.Code) {
        return getgetExtraBarcodeResponse;
      } else {
        return null;
      }
    },
    async savePrescription() {
      const valid = await this.checkValidation();
      if (valid) {
        this.prescription.CreatorOrganizationNickname =
          this.$loggedUser.SelectedOrganization;
        /* this.prescription.SubmitterOrganizationNickname =
                      this.$loggedUser.SelectedOrganization; */
        this.$emit("saveRecipe", this.prescription);
        this.visible = false;
      } else {
        //TODO validációs hiba
        this.$bvToast.toast(
          "Nézze át az űrlapot, hogy minden adat helyesen lett kitöltve!",
          {
            title: "Hibás mező kitöltés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async checkValidation() {
      const valid = await this.$validate();
      this.errorBaseData = !valid;

      const doctorValid = await this.$refs[
        "createPrescriptionDoctor"
      ].checkValidation();
      this.errorDoctor = !doctorValid;

      const patientValid = await this.$refs[
        "createPrescriptionPatient"
      ].checkValidation();
      this.errorPatient = !patientValid;

      const productsValidArray = await Promise.all(
        this.$refs["createPrescriptionProduct"].map(function (product) {
          return product.checkValidation();
        })
      );
      this.errorProduct = [];
      productsValidArray.forEach((p, index) => {
        if (!p) {
          this.errorProduct.push(index);
        }
      });
      const productsValid = !productsValidArray.some((x) => !x);
      this.errorProducts = !productsValid;

      /* const countersignValid = await this.$refs[
                  "createPrescriptionCountersign"
                ].checkValidation(); */

      const specialistRecommentdationValid = await this.$refs[
        "createPrescriptionSpecialistRecommendation"
      ].checkValidation();
      this.errorSpecialistRecommendation = !specialistRecommentdationValid;

      const statementValid = await this.$refs[
        "createPrescriptionStatement"
      ].checkValidation();
      this.errorStatement = !statementValid;

      return (
        valid &&
        doctorValid &&
        patientValid &&
        productsValid &&
        /* countersignValid && */
        specialistRecommentdationValid &&
        statementValid
      );
    },
    resetValidation() {
      this.validation.reset();
      this.errorBaseData = false;

      if (this.$refs["createPrescriptionDoctor"]) {
        this.$refs["createPrescriptionDoctor"].resetValidation();
      }
      this.errorDoctor = false;

      if (this.$refs["createPrescriptionPatient"]) {
        this.$refs["createPrescriptionPatient"].resetValidation();
      }
      this.errorPatient = false;

      if (this.$refs["createPrescriptionProduct"]) {
        this.$refs["createPrescriptionProduct"].forEach((product) =>
          product.resetValidation()
        );
      }
      this.errorProduct = [];
      this.errorProducts = false;

      /* if (this.$refs["createPrescriptionCountersign"]) {
                  this.$refs["createPrescriptionCountersign"].resetValidation();
                } */

      if (this.$refs["createPrescriptionSpecialistRecommendation"]) {
        this.$refs[
          "createPrescriptionSpecialistRecommendation"
        ].resetValidation();
      }
      this.errorSpecialistRecommendation = false;

      if (this.$refs["createPrescriptionStatement"]) {
        this.$refs["createPrescriptionStatement"].resetValidation();
      }
      this.errorStatement = false;
    },
    async getLists() {
      this.eesztProductTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_product_type.Value,
        {
          ...this.validDateFilterParams,
        }
      );

      this.eesztPrescriptionTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_prescription_type.Value,
        this.validDateFilterParams
      );

      this.eesztBNOList = await this.getBase(
        this.$enums.BaseName.eeszt_bno10.Value,
        {
          "-orderby": "Code",
          ["-limit"]: 10,
        }
      );

      await this.getProjectPatientsData({ "-limit": 100 });
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    async getProjectPatientsData(params) {
      if (this.projectRoles) {
        const patientRoles = this.projectRoles.filter((r) =>
          r.BaseRoles.includes("Patient")
        );
        //kérés indítás
        var tempPatientList = await Promise.all(
          patientRoles.map(async (r) => {
            const patientListResponse = await UserLogic.getUserData({
              projectId: this.$loggedUser.SelectedProject.ProjectId,
              projectRole: r.RoleName,
              "-orderby": "Username",
              ...params,
            });
            //sikeres kérés?
            if (!patientListResponse.Code) {
              //van projekt adat?
              //this.patientList = this.filteredPatientList = patientListResponse;
              return patientListResponse;
            } else {
              this.$bvToast.toast(patientListResponse.Message, {
                title: this.$t(
                  "requestResponse.project.errorGetProjectSubordinates"
                ),
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              });
            }
          })
        );
        this.filteredPatientList = tempPatientList.flat();
      }
    },
    async changeValue(key, value) {
      if (key == "extraBarcode" && value != null) {
        if (value.length == 43) {
          await this.addRecipe();
        }
      }
    },
  },
  async mounted() {
    if (this.visible) {
      await this.getLists();
    }
  },
};
</script>
