<template>
  <fp-form-modal
    v-model="visible"
    :title="
      'Új vény jelentés készítő' +
      (stepComponents && stepComponents[currentStep - 1]
        ? ' - ' + stepComponents[currentStep - 1].Title
        : '')
    "
    size="xl"
    :closable="false"
  >
    <template #content>
      <div style="padding: 20px">
        <div v-for="step in stepComponents.length" :key="'bever-step-' + step">
          <div v-if="step == currentStep">
            <!-- Step icons -->
            <div style="display: flex; width: fit-content; margin: auto">
              <div
                v-for="stepicon in stepComponents.length"
                :key="'bever-step-icon' + stepicon"
              >
                <fp-svg-icon
                  :icon="
                    stepComponents[stepicon - 1]
                      ? stepComponents[stepicon - 1].IconPath
                      : null
                  "
                  :size="40"
                  style="border-radius: 50px; padding: 5px"
                  :style="
                    stepicon == currentStep
                      ? 'background: var(--eh-secondary-5);'
                      : ''
                  "
                />
              </div>
            </div>
            <!-- Step controller -->
            <div>
              <b-button v-if="showPreviousStepButton" @click="stepPrevious">
                Előző
              </b-button>
              <b-button
                style="margin-left: auto"
                :disabled="getSelectedPrescription && !getSelectedPrescription.length"
                v-if="showNextStepButton"
                @click="stepNext"
              >
                Következő
              </b-button>
            </div>
            <!-- Step page -->
            <component
              :is="stepComponents[step - 1].Component"
              :canSelectProducts="true"
              @stepNext="stepNext"
              @savedReport="savedReport"
            />
          </div>
        </div>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CreateBeverReport",
  model: {
    prop: "showForm",
    event: "change",
  },
  props: {
    showForm: Boolean,
  },
  data() {
    return {
      visible: null,
      currentStep: 1,
      maxStep: 3,
      stepComponents: [
        {
          Title: "Receptek kiválasztása",
          Component: "fp-bever-step-select-prescription",
          IconPath: "prescription-list",
        },
        {
          Title: "Jelentés adatok megadása",
          Component: "fp-bever-step-neak-report",
          IconPath: "bever-report",
        },
      ],
      showNextStepButton: true,
      showPreviousStepButton: false,
    };
  },
  watch: {
    showForm(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.setStoreData({
          key: "SelectedPrescriptions",
          value: [],
        });
      }
    },
  },
  computed: {
    getSelectedPrescription() {
      return this.$store.state.bever.SelectedPrescriptions;
    },
  },
  methods: {
    ...mapActions({
      setStoreData: "setData",
    }),
    savedReport() {
      this.$emit("refreshList");
      this.currentStep = 1;
      this.setStoreData({
        key: "SelectedPrescriptions",
        value: [],
      });
      this.showNextStepButton = true;
      this.showPreviousStepButton = false;
      this.visible = false;
    },
    stepNext() {
      if (this.currentStep < this.stepComponents.length) {
        this.currentStep++;
        this.showPreviousStepButton = true;
        if (this.currentStep == this.stepComponents.length) {
          this.showNextStepButton = false;
        }
      }
    },
    stepPrevious() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.showNextStepButton = true;
        if (this.currentStep == 1) {
          this.showPreviousStepButton = false;
        }
      }
    },
  },
  beforeDestroy() {
    this.currentStep = 1;
    this.setStoreData({
      key: "SelectedPrescriptions",
      value: [],
    });
  },
};
</script>
