<template>
  <div v-if="project">
    <fp-templates-manager :projectId="project.ProjectId" />
  </div>
</template>
<script>
export default {
  name: "SpecialistTemplates",
  props: {
    project: Object,
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.$t("healthProfessional.menu.templates"));
  },
};
</script>
