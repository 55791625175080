import i18n from "@/Locale";
import moment from "moment";

const HTTPErrorResponseCodes = {
  400: { Code: 400, Message: "A szerver nem tudja értelmezni a kérést." },
  401: { Code: 401, Message: "Jelentkezzen be a művelet végrehajtásához." },
  403: { Code: 403, Message: "Nincs jogosultsága a funkció használatára." },
  404: { Code: 404, Message: "Az URL nem ismerhető fel." },
  405: { Code: 405, Message: "A funkció nem használható." },
  415: { Code: 415, Message: "A küldött adatok formátuma nem megfelelő" },
};

export const RequestResponseLogic = {
  /* Válasz vizsgálatára szolgál. A válasz a kért értékkel tért vissza vagy hibával.
       Paraméterek:
       - result(object): kérés válasza
       - isJSON(boolean): a várt kérés válasza JSON formátumú?
         */
  async checkRequestResponse(result, isJSON = true) {
    //válasz body tároló
    var content = null;
    if (!result) { return { Code: 9999, Message: "Váratlan hiba történt." } }
    if (!result.Response) { return result; }
    //sikeres kérés?
    if (result.Response.status == 200) {
      //JSON formátumú választ várunk?
      if (isJSON) {
        //Ha igen, kibontjuk a JSON fromátumot
        return await result.Response.json();
      }
      //Ha nem, a kibontás a várt válasznak megfelelően történik
      return { Data: result.Response, Headers: result.Headers };
      //hiba
    } else {
      //ha van megfelelő body akkor tároljuk
      try {
        content = await result.Response.json();
      } catch {
        //nincs body
        if (
          result.Response.status &&
          HTTPErrorResponseCodes[result.Response.status]
        ) {
          //regisztrált HTTP error
          content = HTTPErrorResponseCodes[result.Response.status];
        } else if (result.Response.status && result.Response.statusText) {
          //nem regisztrált, de HTTP error
          content = {
            Code: result.Response.status,
            Message: result.Response.statusText,
          };
        } else if (result.Response.Code && result.Response.Message) {
          //ismertformátumú error
          content = result.Response;
        } else {
          //nem ismert formátumú error
          content = { Code: 999, Message: JSON.stringify(result.Response) };
        }
      }
      if (content.Code && content.Message) {
        //ismert formátumú a body
        return content;
      } else if (content.Code) {
        //Van code-ja, de a message nem megfelelő formátumban van
        return { Code: content.Code, Message: JSON.stringify(content) };
      } else {
        //nem ismert formátumú az error
        return { Code: 998, Message: JSON.stringify(content) };
      }
    }
  },
};

export const EesztRequestResponseLogic = {
  async checkEesztRequestResponse(result) {
    const response = await RequestResponseLogic.checkRequestResponse(result);
    if (!response.Code && response.Result == "Success") {
      if (response.Data) {
        return response.Data;
      } else {
        return response;
      }
    } else if (response.Code && response.Message) {
      return {
        Code: response.Code,
        Message: [response.Message],
        Response: response,
        Curl: result.Curl,
      };
    } else {
      var resultCode;
      var colorCode;
      var message = [];
      switch (response.Result) {
        case "Info":
          colorCode = "info";
          resultCode = 200;
          break;
        case "Warning":
          colorCode = "warning";
          resultCode = 200;
          break;
        case "SyntaxError":
          colorCode = "danger";
          resultCode = 1002;
          break;
        case "LogicError":
          colorCode = "danger";
          resultCode = 1002;
          break;
        case "ConnectionError":
          colorCode = "danger";
          resultCode = 1002;
          break;
        case "Timeout":
          colorCode = "danger";
          resultCode = 1002;
          break;
        case "Unknown":
          colorCode = "danger";
          resultCode = 1002;
          break;
        default:
          colorCode = "danger";
          resultCode = 1002;
          break;
      }
      if (
        !response.StatusMessages ||
        (response.StatusMessages && !response.StatusMessages.length)
      ) {
        message.push("Ismeretlen EESZT hiba történt! ");
      } else {
        response.StatusMessages.forEach((e) => {
          /* if (e.Code.includes("XMLKEZELES")) {
            const errorType = e.Message.split(":")[0];
            switch (errorType) {
              case "cvc-maxLength-valid":
                message += "Hibás adatbevitel! A megadott érték túl hosszú!";
                break;
              case "cvc-pattern-valid":
                message += "Hibás adatbevitel! (" + e.Code + "); ";
                break;
              default:
                message += e.Message + " (" + e.Code + ")";
                //message += "Hibás adatbevitel! (" + e.Code + "); ";
                break;
            }
          } else {
            message += e.Message + " (" + e.Code + ")";
          } */
          message.push(e.Message + " (" + e.Code + ")");
        });
      }
      return {
        Code: resultCode,
        Message: message,
        ColorCode: colorCode,
        Curl: result.Curl,
        Response: response,
      };
    }
  },
  async checkEesztValidateResponse(result) {
    const response = await RequestResponseLogic.checkRequestResponse(result);
    if (!response.Code && response.IsValid) {
      return response;
    } else if (response.Code && response.Message) {
      return {
        Code: response.Code,
        Message: [response.Message],
        Response: response,
        Curl: result.Curl,
      };
    } else {
      var message = [];
      response.Errors.forEach((e) => {
        if (e.ErrorCode == "NotEmptyValidator") {
          message.push(
            i18n.t("EesztValidateError.NotEmptyValidator", {
              field: i18n.t(
                "EesztValidateError." + e.PropertyName.replaceAll(".", "")
              ),
            })
          );
        } else if (e.ErrorCode == "InclusiveBetweenValidator") {
          message.push(
            i18n.t("EesztValidateError.InclusiveBetweenValidator", {
              field: i18n.t(
                "EesztValidateError." + e.PropertyName.replaceAll(".", "")
              ),
              to: moment(e.FormattedMessagePlaceholderValues.To).format(
                "YYYY-MM-DD HH:mm"
              ),
              from: moment(e.FormattedMessagePlaceholderValues.From).format(
                "YYYY-MM-DD HH:mm"
              ),
              current: moment(
                e.FormattedMessagePlaceholderValues.PropertyValue
              ).format("YYYY-MM-DD HH:mm"),
            })
          );
        } else {
          //message += e.ErrorMessage + " \n";
          //Hibás adarbevitel
          message.push(e.ErrorMessage);
        }
      });
      return {
        Code: 1001,
        Message: message,
        Curl: result.Curl,
        Response: response,
      };
    }
  },
};
