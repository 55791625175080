import Vue from 'vue';
import { AuthUiLogic } from '../Logic/Ui/auth';
import { UserLogic } from '../Logic/Backend/user';

export default (to, from, next) => {
    const toParentRoute = to.matched.find((m) => Array.isArray(m.meta));
    var authorize = null;
    if (Array.isArray(to.meta)) {
        authorize = to.meta;
    } else if (toParentRoute) {
        authorize = toParentRoute.meta;
    }
    const token = AuthUiLogic.canStartRequest();
    const loggedUserResponse = UserLogic.getSignedUser();
    //TODO: ha van token de valamiért nincs signedUser akkor az kell újra generálni
    if (!token || !loggedUserResponse) {
        const staticPage = to.matched.find((x) => x.name == "Static");
        //nincs érvényes token
        if (to.name != "Login" && !staticPage) {
            /* var url = to.path;
            const queriesArray = Object.keys(to.query);
            if (queriesArray.length > 0) {
              url = url + "?";
              queriesArray.forEach((key) => (url += "&" + key + "=" + to.query[key]));
            } */
            //ha nem a Login oldalon van, akkor érvénytelen token esetén a Login oldalra irányít
            AuthUiLogic.clearSessionStorage();
            return next({ name: "Login" /* , query: { returnUrl: url }  */ });
        }
    }
    if (Array.isArray(authorize)) {
        //ha van a megnyitni kívánt oldalnak a metá-ban szerepkörök
        if (
            authorize.length &&
            loggedUserResponse &&
            !authorize.some((auth) => loggedUserResponse.Roles.includes(auth))
        ) {
            //ha a szerepkörökben a bejelentkezett felhasználó szerepköre nem szerepel akkor vissza irányít a Menube
            const loggedUserHomePage =
                Vue.prototype.$enums.UserRoleWithHome[loggedUserResponse.SelectedRole];

            return next({ name: loggedUserHomePage.Home });
        }
    }
    if (!Vue.prototype.$loggedUser && loggedUserResponse) {
        Vue.prototype.$loggedUser = loggedUserResponse;
    }

    if (
        Vue.prototype.$loggedUser &&
        to.path.split("/")[1] != from.path.split("/")[1]
    ) {
        const newRole = Object.values(Vue.prototype.$enums.UserRoleWithHome).find(
            (role) => role.Home.includes(to.path.split("/")[1])
        );
        if (newRole) {
            Vue.prototype.$loggedUser.SelectedRole = newRole.Value;
            UserLogic.saveLoginUser(Vue.prototype.$loggedUser);
        }
    }
    //ha van token és nincs jogosultság korlátozás (metában nincs szerepkör)
    next();
}