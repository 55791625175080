<!-- Version: 1.0.1 -->
<template>
  <div>
    <div>
      <b-button
        v-if="profileOrganizations && profileOrganizations.length > 1"
        class="eh-action-button"
        @click="showEesztOrganizationSelectModal = true"
        >Intézmény váltás</b-button
      >
      <div style="margin: 10px 0px">
        <h4>EESZT recept/kiadás műveletek</h4>
      </div>
      <div>
        <!-- OrganizationNickname választó -->
        <fp-form-modal
          title="Intézmény kiválasztása"
          v-model="showEesztOrganizationSelectModal"
          :closable="false"
          @hidden="cancelSelectOrganization"
        >
          <template #content>
            <fp-select
              :items="profileOrganizations"
              v-model="changedOrganizationNickname"
              valueKey="Nickname"
              textKey="OrganizationName"
            >
              <template #element="e">
                <div>{{ e.OrganizationName }}</div>
                <div>{{ e.OrganizationUnitName }}</div>
              </template>
            </fp-select>
            <b-button @click="selectOrganization">Kiválaszt</b-button>
          </template>
        </fp-form-modal>
        <!-- EESZT receptek -->
        <fp-form-modal
          size="xl"
          title="EESZT receptlista"
          v-model="showEesztPrescriptionsModal"
          @hidden="clearSelectPatientModal"
        >
          <template #content>
            <div
              v-if="
                userProjectPermissions &&
                userProjectPermissions.includes(prescriptionPermission)
              "
            >
              <b-button
                :disabled="
                  (selectedEesztElementIds &&
                    !selectedEesztElementIds.length) ||
                  !selectedEesztElementIds
                "
                class="eh-action-button"
                @click="startEesztLogin('eesztImport')"
              >
                {{
                  selectedEesztElementIds ? selectedEesztElementIds.length : 0
                }}
                recept(ek) importálása
                <b-icon
                  icon="info-circle-fill"
                  v-b-tooltip.hover="
                    'A kiválasztott recept(ek) importálásra kerül(nek) a saját rendszerbe.'
                  "
                />
              </b-button>
              <b-button
                :disabled="
                  (selectedEesztElementIds &&
                    !selectedEesztElementIds.length) ||
                  !selectedEesztElementIds
                "
                class="eh-action-button"
                @click="startEesztImportWithDispense"
              >
                {{
                  selectedEesztElementIds ? selectedEesztElementIds.length : 0
                }}
                recept(ek) importálása és foglalás(ok) rögzítése
                <b-icon
                  icon="info-circle-fill"
                  v-b-tooltip.hover="
                    'A kiválasztott recept(ek) importálásra, lefoglalásra kerül(nek) a saját rendszerben és a lefoglalás(ok) rögzítésre kerül(nek) az EESZT-ben is.'
                  "
                />
              </b-button>
            </div>
            <div style="font-size: 13px; margin-top: 10px">
              <b>
                A táblázatban csak a zöld színnel kiemelt és "Nyitott" státuszú
                recept(ek) importálható(ak) az EESZT rendszerből a saját
                rendszerbe! </b
              ><br />
              <b>
                A táblázatban a szürke színnel jelölt és "Nyitott" státuszú
                recept(ek) már megtalálható(ak) a saját rendszerben!
              </b>
            </div>

            <b-row align-v="start" align-h="start">
              <b-col class="col-auto">
                <fp-input
                  style="width: 250px"
                  label="Szűrő"
                  placeholder="pl. EESZT azonosító"
                  v-model="filter"
                  @change="filterList"
                />
              </b-col>
              <b-col class="col-auto">
                <fp-select
                  style="width: fit-content; min-width: 250px"
                  :items="filterStatusList"
                  v-model="filterStatusValue"
                  textKey="Text"
                  valueKey="Value"
                  label="Státusz"
                  @change="filterList"
                />
              </b-col>
              <b-col class="col-auto">
                <fp-date-range-button
                  :hideClearButton="true"
                  label="Felírás dátuma"
                  v-model="filterEesztCreateDate"
                  @change="getEesztImportablePrescriptionList"
                />
              </b-col>
            </b-row>
            <fp-table-list
              class="eeszt-prescription-list"
              :items="filterEesztPrescriptionList"
              :fields="eesztPrescriptionFields"
              :selectable="
                userProjectPermissions &&
                userProjectPermissions.includes(prescriptionPermission)
                  ? true
                  : false
              "
              :hasPagination="true"
              @row-selected="selectEesztPrescriptionElements"
              :limitedDataLoad="true"
              @nextStackLoad="eesztPrescriptionNextStackLoad"
            >
              <template #Products="row">
                <div
                  v-for="(product, index) in row.item.Products"
                  :key="'eeszt-prescription-head-' + index"
                >
                  <div>{{ product.ProductName }}</div>
                  <div>
                    {{
                      product.Ttt
                        ? "(TTT kód: " + product.Ttt + ")"
                        : product.Iso
                        ? " (ISO10 kód: " + product.Iso + ")"
                        : null
                    }}
                  </div>
                </div>
              </template>
              <template #PrescriptionTimestamp="row">
                {{
                  $convertDateToString(
                    row.item.PrescriptionTimestamp,
                    "YYYY-MM-DD HH:mm"
                  )
                }}
              </template>
              <template #ValidTo="row">
                <div style="display: flex; align-items: center">
                  <div style="margin-right: 5px">
                    {{
                      $convertDateToString(row.item.ValidTo, "YYYY-MM-DD HH:mm")
                    }}
                  </div>
                  <b-icon
                    v-if="isExpired(row.item.ValidTo) && row.item.Importable"
                    class="h2"
                    icon="exclamation-circle-fill"
                    variant="warning"
                    v-b-tooltip.hover="'A recept érvényessége lejárt'"
                  />
                </div>
              </template>
              <template #Status="row">
                {{
                  $enums.PrescriptionEesztStatus[row.item.EesztStatus]
                    ? $enums.PrescriptionEesztStatus[row.item.EesztStatus].Text
                    : row.item.EesztStatus
                }}
              </template>
              <template #Operations="row">
                <b-icon-eye-fill
                  class="h4"
                  v-b-tooltip.hover="'Recept megtekintés'"
                  @click="getEesztPrescription(row.item)"
                />
                <eeszt-prescription-form
                  v-model="row.item.showDetails"
                  :recipe="selectedPrescriptionDetails"
                />
              </template>
            </fp-table-list>
          </template>
        </fp-form-modal>
        <!-- Betegválasztó -->
        <fp-form-modal
          title="EESZT beteg választó recept lekérdezéshez"
          v-model="showSelectPatientModal"
          :closable="false"
          @closed="clearSelectPatientModal"
        >
          <template #content>
            <fp-select
              label="Páciens lista"
              description="Keresés felhasználónév, taj, születési dátum szerint"
              :searchable="true"
              @search="filterParticipants"
              :items="filteredParticipants"
              valueKey="UserId"
              v-model="patientId"
            >
              <template #element="e">
                {{
                  e.Name +
                  " ( " +
                  (e.Username ? e.Username : "") +
                  (e.Username && e.Ssn ? ", " : "") +
                  (e.Ssn ? e.Ssn : "") +
                  " )"
                }}
              </template>
            </fp-select>
            <b-button @click="startEesztLogin('startEesztImport')"
              >Kiválaszt</b-button
            >
          </template>
        </fp-form-modal>
        <!-- Login választó -->
        <fp-form-modal
          v-model="showSelectLoginTypeModal"
          title="EESZT bejelentkezés típusa"
        >
          <template #content>
            <!-- <b-button
              class="eh-action-button"
              @click="showHardTokenModal = true"
              >Hardware Token</b-button
            > -->
            <b-button
              class="eh-action-button"
              @click="showMobilTokenModal = true"
              >Mobil Token</b-button
            >
            <b-button class="eh-action-button" @click="startESzigLogin"
              >eSzig Kliens</b-button
            >
            <b-button
              v-if="isDevelopmentMode"
              class="eh-action-button"
              @click="showPasswordModal = true"
              >Jelszavas bejelentkezés</b-button
            >
          </template>
        </fp-form-modal>
        <!-- Password -->
        <fp-form-modal
          title="Jelszavas bejelentkezés bejelentkezés"
          v-model="showPasswordModal"
        >
          <template #content>
            <form autocomplete="off">
              <fp-input label="Jelszó" v-model="password" type="password" />
              <b-button :disabled="!password" @click="startPasswordLogin"
                >Belépés</b-button
              >
            </form>
          </template>
        </fp-form-modal>
        <!-- HardToken -->
        <!-- <fp-form-modal
          title="gemaltoT Token bejelentkezés"
          v-model="showHardTokenModal"
        >
          <template #content>
            <fp-input
              label="Jelszó"
              v-model="hardToken.Password"
              type="password"
            />
            <fp-input label="Token" v-model="hardToken.OneTimePassword" />
            <b-button
              :disabled="!hardToken.Password || !hardToken.OneTimePassword"
              @click="startHardTokenLogin"
              >Belépés</b-button
            >
          </template>
        </fp-form-modal> -->
        <!-- MobilToken -->
        <fp-form-modal
          title="Mobil Token bejelentkezés"
          v-model="showMobilTokenModal"
        >
          <template #content>
            <fp-input label="Token" v-model="mobilToken.OneTimePassword" />
            <b-button
              :disabled="!mobilToken.OneTimePassword"
              @click="startMobilTokenLogin"
              >Belépés</b-button
            >
          </template>
        </fp-form-modal>
        <!-- Recept törlés megjegyzés -->
        <fp-form-modal
          title="Kiválasztott elemek törlése"
          v-model="showPrescriptionDeleteDescriptionModal"
        >
          <template #content>
            <fp-input
              label="Törlés oka"
              v-model="deleteDescription"
              :required="true"
            />
            <b-button
              :disabled="!deleteDescription"
              @click="startEesztLogin('prescriptionRemoveFromEeszt')"
              >Törlés</b-button
            >
          </template>
        </fp-form-modal>
        <!-- Kiadás törlés megjegyzés -->
        <fp-form-modal
          title="Kiválasztott elemek törlése"
          v-model="showDispenseDeleteDescriptionModal"
        >
          <template #content>
            <fp-input
              label="Törlés oka"
              v-model="deleteDescription"
              :required="true"
            />
            <b-button
              :disabled="!deleteDescription"
              @click="startEesztLogin('dispenseDeleteFromEeszt')"
              >Törlés</b-button
            >
          </template>
        </fp-form-modal>
        <!-- EESZT funkció gombok -->
        <!-- Importálás -->
        <b-button
          @click="startEesztLogin('startEesztImport')"
          class="eh-action-button"
        >
          <div
            v-if="
              userProjectPermissions &&
              userProjectPermissions.includes(prescriptionPermission)
            "
          >
            Recept(ek) importálása
            <b-icon-info-circle-fill
              v-b-tooltip.hover="'Receptek importálása az EESZT-ből'"
            />
          </div>
          <div
            v-else-if="
              userProjectPermissions &&
              !userProjectPermissions.includes(prescriptionPermission)
            "
          >
            Recept(ek) megtekintése
            <b-icon-info-circle-fill
              v-b-tooltip.hover="'Receptek megtekintése az EESZT-ben'"
            />
          </div>
        </b-button>
        <!-- Recept feltöltés -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('prescriptionAddToEeszt')"
          class="eh-action-button"
          :disabled="!seletedAddablePrescriptionIds.length"
        >
          {{ seletedAddablePrescriptionIds.length }} recept(ek) feltöltése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="'Recept(ek) feltöltése az EESZT-be'"
          />
        </b-button>
        <!-- Recept törlés -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('startPrescriptionRemoveFromEeszt')"
          class="eh-action-button"
          :disabled="!seletedDeletablePrescriptionIds.length"
        >
          {{ seletedDeletablePrescriptionIds.length }} recept(ek) törlése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="'Recept(ek) törlése az EESZT-ből'"
          />
        </b-button>
        <!-- Kiadás foglalás -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startDispenseListAddToEeszt"
          class="eh-action-button"
          :disabled="!seletedAddableDispenseIds.length"
        >
          {{ seletedAddableDispenseIds.length }} recept(ek) lefoglalása
          <b-icon-info-circle-fill
            v-b-tooltip.hover="'Lefoglalt recept(ek) rögzítése az EESZT-be'"
          />
        </b-button>
        <!-- Kiadás feltöltés -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('dispenseDetailsToEeszt')"
          class="eh-action-button"
          :disabled="!seletedDetailsAddableDispenseIds.length"
        >
          {{ seletedDetailsAddableDispenseIds.length }} kiadás(ok) feltöltése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="
              'Rögzített recept kiadás(ok) feltöltése az EESZT-be'
            "
          />
        </b-button>
        <!-- Kiadás frissítés -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('dispenseUpdateToEeszt')"
          class="eh-action-button"
          :disabled="!seletedUpdatableDispenseIds.length"
        >
          {{ seletedUpdatableDispenseIds.length }} kiadás(ok) frissítése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="
              'Rögzített recept kiadás(ok) frissítése az EESZT-ben'
            "
          />
        </b-button>
        <!-- Kiadás törlés -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('startDispenseDeleteFromEeszt')"
          class="eh-action-button"
          :disabled="!seletedDeletableDispenseIds.length"
        >
          {{ seletedDeletableDispenseIds.length }} kiadás(ok) törlése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="
              'Rögzített recept kiadás(ok) törlése az EESZT-ből'
            "
          />
        </b-button>
        <!-- Kiadás ellenőrzése -->
        <b-button
          v-if="
            userProjectPermissions &&
            userProjectPermissions.includes(prescriptionPermission)
          "
          @click="startEesztLogin('dispenseReviewToEeszt')"
          class="eh-action-button"
          :disabled="!selectedReviewableDispenseIds.length"
        >
          {{ selectedReviewableDispenseIds.length }} kiadás(ok) ellenőrzése
          <b-icon-info-circle-fill
            v-b-tooltip.hover="
              'Rögzített recept kiadás(ok) ellenőrzése az EESZT-ben'
            "
          />
        </b-button>
      </div>
    </div>
    <fp-form-modal
      v-if="open90daysConfirmationModal"
      v-model="open90daysConfirmationModal"
      title="90 napnál régebben felírt receptek"
    >
      <template #content>
        <div>
          A {{ over90daysIds.join(", ") }} azonosatójú receptek felírási dátumai
          90 napnál régebbiek.
        </div>
        <div style="text-align: center">
          <div style="margin: 5px">
            <b-button
              variant="warning"
              @click="
                startEesztLogin(activeOptionName, [
                  ...over90daysIds,
                  ...under90daysIds,
                ])
              "
            >
              Minden recept lefoglalása mindenképp
            </b-button>
          </div>
          <div style="margin: 5px">
            <b-button
              variant="info"
              v-if="under90daysIds && under90daysIds.length > 0"
              @click="startEesztLogin(activeOptionName, under90daysIds)"
            >
              Csak a 90 napos vagy annál újabb receptek lefoglalása
            </b-button>
            <b-button v-else @click="open90daysConfirmationModal = false">
              Lefoglalás elvetése
            </b-button>
          </div>
        </div>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
/* Components */
import EesztPrescriptionForm from "../Forms/EesztPrescriptionForm";
/* Logic */
import moment from "moment";
import { AuthLogic } from "../../../Logic/Backend/auth";
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import { UserLogic } from "../../../Logic/Backend/user";
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";
export default {
  components: { EesztPrescriptionForm },
  name: "EesztOptions",
  props: {
    seletedAddablePrescriptionIds: Array,
    seletedDeletablePrescriptionIds: Array,
    seletedAddableDispenseIds: Array,
    seletedDetailsAddableDispenseIds: Array,
    seletedUpdatableDispenseIds: Array,
    seletedDeletableDispenseIds: Array,
    selectedReviewableDispenseIds: Array,
    seletedAddableDispensePrescriptions: Array,
    projectPatient: Array,
    participantId: Number,
    projectRoles: Array,
    prescriptionIdToDispenseAddToEeszt: Number,
  },
  data() {
    return {
      eesztOrganizationNickname: null,
      changedOrganizationNickname: null,
      showEesztOrganizationSelectModal: null,
      showEesztPrescriptionsModal: null,
      showSelectPatientModal: null,
      filteredParticipants: this.projectPatient
        ? this.projectPatient.slice(0, 10)
        : null,
      patientId: this.participantId,
      //filter
      filterEesztPrescriptionList: null,
      filter: null,
      filterEesztCreateDate: {
        from: moment().subtract(1, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      filterStatusValue: "Open",
      /* filterStatusList: [
        { Value: null, Text: "Minden kategória" },
        { Value: "Open", Text: "Nyitott" },
        { Value: "WaitingForCountersign", Text: "Ellenjegyzésre vár" },
      ], */
      filterStatusList: [
        { Value: null, Text: "Minden kategória" },
        ...Object.values(this.$enums.PrescriptionEesztStatus),
      ],
      eesztPrescriptions: null,
      eesztPrescriptionFields: [
        { key: "EesztId", label: "EESZT azonosító" },
        { key: "NeakId", label: "NEAK azonosító" },
        { key: "Products", label: "Termékek" },
        {
          key: "PrescriptionTimestamp",
          label: "Felírás időpontja",
          sortable: true,
        },
        { key: "ValidTo", label: "Recept érvényesség vége", sortable: true },
        { key: "Status", label: "Státusz" },
        { key: "Operations", label: "Műveletek" },
      ],
      activeEesztOption: {
        startEesztImport: this.startEesztImport,
        eesztImport: this.eesztImport,
        eesztImportWithDispense: this.eesztImportWithDispense,
        prescriptionAddToEeszt: this.prescriptionAddToEeszt,
        prescriptionRemoveFromEeszt: this.prescriptionRemoveFromEeszt,
        startPrescriptionRemoveFromEeszt: this.startPrescriptionRemoveFromEeszt,
        dispenseListAddToEeszt: this.dispenseListAddToEeszt,
        dispenseDetailsToEeszt: this.dispenseDetailsToEeszt,
        dispenseUpdateToEeszt: this.dispenseUpdateToEeszt,
        dispenseDeleteFromEeszt: this.dispenseDeleteFromEeszt,
        startDispenseDeleteFromEeszt: this.startDispenseDeleteFromEeszt,
        dispenseReviewToEeszt: this.dispenseReviewToEeszt,
      },
      activeOptionName: null,
      profileOrganizations: null,
      selectedPrescriptionDetails: null,
      deleteDescription: null,
      showPrescriptionDeleteDescriptionModal: null,
      showDispenseDeleteDescriptionModal: null,
      showSelectLoginTypeModal: false,
      showHardTokenModal: false,
      showMobilTokenModal: false,
      hardToken: {
        Password: null,
        OneTimePassword: null,
      },
      mobilToken: {
        OneTimePassword: null,
      },
      errorIndex: 0,
      selectedEesztElementIds: null,
      selectedEesztElements: null,
      eesztPrescriptionStackNumber: 0,
      successPageLoad: true,
      prescriptionPermission: "WriteMedicalDevicePrescription",
      showPasswordModal: false,
      password: null,
      over90daysIds: null,
      under90daysIds: null,
      open90daysConfirmationModal: false,
    };
  },
  watch: {
    eesztOrganizationNickname(input) {
      if (input != this.changedOrganizationNickname) {
        this.changedOrganizationNickname = input;
      }
      this.setLoggedUser();
    },
    participantId(input) {
      this.patientId = input;
    },
    async prescriptionIdToDispenseAddToEeszt(prescriptionId) {
      if (prescriptionId) {
        await this.dispenseAddToEeszt(prescriptionId, true);
        this.$emit("update:prescriptionIdToDispenseAddToEeszt", null);
      }
    },
    showEesztPrescriptionsModal(show) {
      if (!show) {
        this.eesztPrescriptionStackNumber = 0;
      }
    },
    /* projectPatient(input) {
      this.filteredParticipants = input.slice(0, 10);
    }, */
  },
  methods: {
    isExpired(date) {
      return date ? moment(date).diff(moment.now(), "days") < 0 : false;
    },
    filterList() {
      this.filterEesztPrescriptionList = this.eesztPrescriptions;
      if (this.filter) {
        this.filterEesztPrescriptionList = this.$filterList(
          this.filter,
          this.eesztPrescriptions,
          [
            "Products[].ProductName",
            "Products[].Ttt",
            "Products[].Iso",
            "EesztId",
            "NeakId",
          ]
        );
      }

      /* if (this.filterStatusValue) {
        this.filterEesztPrescriptionList =
          this.filterEesztPrescriptionList.filter(
            (p) => p.EesztStatus == this.filterStatusValue
          );
      } */
      if (this.filterStatusValue) {
        this.filterEesztPrescriptionList =
          this.filterEesztPrescriptionList.filter(
            (p) => p.EesztStatus == this.filterStatusValue
          );
      }
    },
    filterStatus(input) {
      if (!input) {
        this.filterEesztPrescriptionList = this.eesztPrescriptions;
      } else {
        this.filterEesztPrescriptionList = this.eesztPrescriptions.filter(
          (p) => p.EesztStatus == input
        );
      }
    },
    //intézmény választás
    selectOrganization() {
      this.eesztOrganizationNickname = this.changedOrganizationNickname;
      this.showEesztOrganizationSelectModal = false;
    },
    cancelSelectOrganization() {
      this.changedOrganizationNickname = this.eesztOrganizationNickname;
    },
    async selectOrganizationNickname() {
      const eesztProfile = await this.getEesztProfile();
      if (eesztProfile && eesztProfile.OrganizationUnits) {
        this.profileOrganizations = eesztProfile.OrganizationUnits;
        if (this.$loggedUser.SelectedOrganization) {
          this.eesztOrganizationNickname =
            this.$loggedUser.SelectedOrganization;
        } else {
          if (
            this.profileOrganizations &&
            this.profileOrganizations.length == 1
          ) {
            if (this.profileOrganizations[0].Nickname) {
              this.eesztOrganizationNickname =
                this.profileOrganizations[0].Nickname;
            } else {
              //TODO: hiba nincs Nickname
              this.$bvToast.toast(
                "A profil beállításoknál adja meg az intézmény rövid nevét!",
                {
                  title: "Hiba történt a megadott intézmény betöltése során!",
                  variant: "danger",
                  solid: true,
                }
              );
            }
            ///await this.startEesztLogin(this.activeOptionName)
          } else {
            this.showEesztOrganizationSelectModal = true;
          }
        }
      } else {
        this.$bvToast.toast(
          "Hiányos a profil beállítása. Adjon meg a profilom menüpontban legalább egy intézményt!",
          {
            title: "Hiba történt az EESZT adatok betöltése során!",
            variant: "danger",
            solid: true,
          }
        );
        //TODO: hiba, üzenet: állítsa be a profilban az adatokat
      }
    },
    async getEesztProfile() {
      const getProfileResponse = await UserProfileLogic.getUserProfile();
      if (!getProfileResponse.Code) {
        return getProfileResponse.EesztProfile;
      } else {
        this.$bvToast.toast(getProfileResponse.Message, {
          title: "Hiba történt az EESZT adatok betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
    setLoggedUser() {
      const loggedUser = this.$loggedUser;
      loggedUser.SelectedOrganization = this.eesztOrganizationNickname;
      UserLogic.saveLoginUser(loggedUser);
    },

    //betegválasztás
    async filterParticipants(input) {
      /* this.filteredParticipants = this.$filterList(input, this.projectPatient, [
        "Username",
        "Name",
        "Ssn",
        "DateOfBirth",
      ]); */
      if (input.length > 2) {
        await this.getProjectPatientsData({
          multiValue: input,
        });
      } else if (input) {
        this.filteredParticipants = null;
      } else {
        await this.getProjectPatientsData({
          "-limit": 100,
        });
      }
    },
    clearSelectPatientModal() {
      this.patientId = this.participantId;
    },

    async changedStorage(event) {
      if (
        event.key == this.$EESZT_USER_TOKEN_STORAGE_KEY &&
        AuthLogic.canStartEesztRequest()
      ) {
        await this.actionByOption();
        this.$bvToast.toast("Sikeres EESZT bejelentkezés!", {
          title: "Sikeres bejelentkezés!",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(
          "Töltse újra az oldalt, és az EESZT funkció újbóli meghívására, újból elindul az EESZT bejelentkezés!",
          {
            title: "Hiba történt az EESZT-be való bejelentkezés során!",
            variant: "warning",
            solid: true,
          }
        );
      }
    },
    async actionByOption() {
      if (this.activeOptionName) {
        await this.activeEesztOption[this.activeOptionName]();
        this.activeOptionName = null;
      }
    },

    //EESZT műveletek
    async startEesztImport() {
      if (this.patientId) {
        this.showSelectPatientModal = false;
        await this.getEesztImportablePrescriptionList();
        if (this.eesztPrescriptions) {
          this.showEesztPrescriptionsModal = true;
        }
      } else {
        this.showSelectPatientModal = true;
        await this.getProjectPatientsData({ "-limit": 100 });
      }
    },
    //TODO: next stack betöltés
    async eesztPrescriptionNextStackLoad() {
      if (this.successPageLoad) {
        this.eesztPrescriptionStackNumber++;
      }
      const nextStackPrescriptions = await this.getEesztPrescriptionList({
        onlyOpen: false,
        onlyImportable: false,
        pageNumber: this.eesztPrescriptionStackNumber,
      });
      const convertedList = this.convertEesztPrescriptionToRowVariant(
        nextStackPrescriptions
      );
      //this.successPageLoad = convertedList && convertedList.length;

      this.eesztPrescriptions = this.filterEesztPrescriptionList =
        this.$checkDuplicates(
          this.eesztPrescriptions.concat(convertedList),
          "EesztId"
        );
      //this.filterStatus(this.filterStatusValue);
      this.filterList();
    },
    async getEesztPrescriptionList(params) {
      const getListResponse =
        await MedicalDeviceLogic.listPrescriptionFromEeszt(
          { OrganizationNickname: this.$loggedUser.SelectedOrganization },
          this.patientId,
          {
            startDate: this.filterEesztCreateDate.from,
            endDate: this.filterEesztCreateDate.to,
            ...params,
          }
        );
      if (!getListResponse.Code) {
        if (getListResponse && getListResponse.length) {
          this.successPageLoad = true;
        }
        return getListResponse;
      } else {
        if (getListResponse.Code == 200) {
          this.$bvToast.toast(getListResponse.Message, {
            title: "Sikeres lista betöltés!",
            variant: getListResponse.ColorCode
              ? getListResponse.ColorCode
              : "danger",
            solid: true,
          });
        } else {
          if (getListResponse.Code == 201) {
            await this.loginEeszt();
          }
          //hibás feltöltés, sikertelen művelet
          this.$bvToast.toast(getListResponse.Message, {
            title: "Hiba történt a recept lista lekérdezése során!",
            variant: getListResponse.ColorCode
              ? getListResponse.ColorCode
              : "danger",
            solid: true,
          });

          this.patientId = null;
        }
      }
      return null;
    },
    async getEesztImportablePrescriptionList() {
      /* const eesztOpenPrescriptions = await this.getEesztPrescriptionList({
        EesztStatus: "Open",
      });
      if (eesztOpenPrescriptions) { */
      const eesztOpenPrescriptions = await this.getEesztPrescriptionList({
        onlyOpen: false,
        onlyImportable: false,
      });

      this.eesztPrescriptions = this.filterEesztPrescriptionList =
        this.convertEesztPrescriptionToRowVariant(eesztOpenPrescriptions);
      //this.filterStatus(this.filterStatusValue);
      this.filterList();
    },
    convertEesztPrescriptionToRowVariant(prescriptions) {
      return prescriptions.map((i) => {
        return {
          ...i,
          _rowVariant: i.Importable ? "success" : null,
        };
      });
    },
    selectEesztPrescriptionElements(elements) {
      this.selectedEesztElements = elements /* .filter((e) => e.Importable) */;
      this.selectedEesztElementIds = this.selectedEesztElements.map(
        (e) => e.EesztId
      );
    },
    async getEesztPrescription(element) {
      this.$set(element, "showDetails", true);
      await this.getEesztPrescriptionByEesztId(element.EesztId);
    },
    async getEesztPrescriptionByEesztId(eesztId) {
      const getPrescriptionResponse =
        await MedicalDeviceLogic.getPrescriptionFromEeszt(
          { OrganizationNickname: this.$loggedUser.SelectedOrganization },
          eesztId
        );
      if (!getPrescriptionResponse.Code) {
        this.selectedPrescriptionDetails = getPrescriptionResponse;
      } else {
        if (getPrescriptionResponse.Code == 200) {
          this.$bvToast.toast(getPrescriptionResponse.Message, {
            title: "Sikeres betöltés!",
            variant: getPrescriptionResponse.ColorCode
              ? getPrescriptionResponse.ColorCode
              : "danger",
            solid: true,
          });
        } else {
          if (getPrescriptionResponse.Code == 201) {
            await this.loginEeszt();
          }
          //hibás feltöltés, sikertelen művelet
          this.$bvToast.toast(getPrescriptionResponse.Message, {
            title: "Hiba történt a recept lekérdezése során!",
            variant: getPrescriptionResponse.ColorCode
              ? getPrescriptionResponse.ColorCode
              : "danger",
            solid: true,
          });
        }
      }
    },
    async eesztImport() {
      var response = [];
      await Promise.all(
        this.selectedEesztElementIds.map(async (e) => {
          const result = await MedicalDeviceLogic.importPrescriptionFromEeszt(
            { OrganizationNickname: this.$loggedUser.SelectedOrganization },
            e
          );
          response.push({ PrescriptionId: e, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle: " azonosítújú recept importálása megtörtént!",
          ElementErrorTitle: " azonosítújú recept importálása sikertelen!",
          ElementsSuccessesMessage:
            " db recept EESZT-ből való importálása sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres importálás!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
        await this.startEesztImport();
      }
    },
    async eesztImportWithDispense(prescriptionIds) {
      this.open90daysConfirmationModal = false;
      var response = [];
      await Promise.all(
        prescriptionIds.map(async (e) => {
          const result =
            await MedicalDeviceLogic.importPrescriptionFromEesztWithDispense(
              { OrganizationNickname: this.$loggedUser.SelectedOrganization },
              e
            );
          response.push({ PrescriptionId: e, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept importálása és lefoglalása megtörtént!",
          ElementErrorTitle:
            " azonosítújú recept importálása és lefoglalása sikertelen!",
          ElementsSuccessesMessage:
            " db recept EESZT-ből való importálása és lefoglalása sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres importálás és foglalás!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
        await this.startEesztImport();
      }
    },
    startEesztImportWithDispense() {
      this.over90daysIds = this.checkPrescriptionsDispensedDateOver90days(
        this.selectedEesztElements,
        (p) => p.PrescriptionTimestamp
      ).map((p) => p.EesztId);
      this.under90daysIds = this.selectedEesztElementIds.filter(
        (x) => !this.over90daysIds.includes(x)
      );
      if (this.over90daysIds && this.over90daysIds.length > 0) {
        this.activeOptionName = "eesztImportWithDispense";
        this.open90daysConfirmationModal = true;
      } else {
        this.startEesztLogin("eesztImportWithDispense", this.under90daysIds);
      }
    },
    async prescriptionAddToEeszt() {
      var response = [];
      await Promise.all(
        this.seletedAddablePrescriptionIds.map(async (id) => {
          const result = await MedicalDeviceLogic.addPrescriptionToEeszt(
            { OrganizationNickname: this.$loggedUser.SelectedOrganization },
            id
          );
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle: " azonosítújú recept feltöltése megtörtént!",
          ElementErrorTitle: " azonosítújú recept feltöltése sikertelen!",
          ElementsSuccessesMessage:
            " db recept EESZT-be való feltöltése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres feltöltés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
    },
    async startPrescriptionRemoveFromEeszt() {
      this.showPrescriptionDeleteDescriptionModal = true;
    },
    async prescriptionRemoveFromEeszt() {
      var response = [];
      await Promise.all(
        this.seletedDeletablePrescriptionIds.map(async (id) => {
          const result = await MedicalDeviceLogic.deletePrescriptionFromEeszt(
            this.deleteDescription,
            id
          );
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle: " azonosítújú recept törlése megtörtént!",
          ElementErrorTitle: " azonosítújú recept törlése sikertelen!",
          ElementsSuccessesMessage:
            " db recept EESZT-ből való törlése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres törlés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
      this.showPrescriptionDeleteDescriptionModal = false;
    },
    async dispenseListAddToEeszt(prescriptionIds) {
      this.open90daysConfirmationModal = false;
      var response = [];
      await Promise.all(
        prescriptionIds.map(async (id) => {
          const result = await MedicalDeviceLogic.addDispenseToEeszt(id);
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept kiadás feltöltése megtörtént!",
          ElementErrorTitle:
            " azonosítújú recept kiadás feltöltése sikertelen!",
          ElementsSuccessesMessage:
            " db recept kiadás EESZT-be való feltöltése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres feltöltés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
    },
    startDispenseListAddToEeszt() {
      this.over90daysIds = this.checkPrescriptionsDispensedDateOver90days(
        this.seletedAddableDispensePrescriptions,
        (p) => p.PrescriptionData.PrescriptionTimestamp
      ).map((p) => p.PrescriptionId);
      this.under90daysIds = this.seletedAddableDispenseIds.filter(
        (x) => !this.over90daysIds.includes(x)
      );
      if (this.over90daysIds && this.over90daysIds.length > 0) {
        this.activeOptionName = "dispenseListAddToEeszt";
        this.open90daysConfirmationModal = true;
      } else {
        this.startEesztLogin("dispenseListAddToEeszt", this.under90daysIds);
      }
    },
    checkPrescriptionsDispensedDateOver90days(prescriptions, pFunc) {
      return prescriptions.filter((p) => {
        const disTime = moment(pFunc(p));
        const diff = moment().diff(disTime, "days");
        return diff > 90;
      });
    },
    //külső esemény kiváltására felkészítve
    async dispenseAddToEeszt(id, checkError = false) {
      const result = await MedicalDeviceLogic.addDispenseToEeszt(id);
      if (!checkError) {
        return result;
      } else {
        if (!result.Code) {
          this.$bvToast.toast(
            "Sikeresen rögzítésre került(ek) a recept(ek) foglalása az EESZT-be!",
            {
              title: "Sikeres rögzítés!",
              variant: "success",
              solid: true,
            }
          );
        } else {
          if (result.Code == 201) {
            await this.loginEeszt();
            this.$bvToast.toast(
              "Nem volt vagy lejárt az EESZT bejelentkezési tokene. EESZT-be való bejelentkezést követően a " +
                id +
                " azonosítójú recepte foglalását manuálisan tudja rögzíteni az EESZT-be. Ezt az EESZT recept/kiadás műveleteknél található Recept foglalás gomb segítségével teheti meg.",
              {
                title:
                  "Hiba történt a recept foglalásának az EESZT-be való feltöltése során!",
                variant: "warning",
                solid: true,
                noAutoHide: true,
              }
            );
          } else {
            this.$bvToast.toast(result.Message, {
              title:
                "Hiba történt a recept foglalásának az EESZT-be való feltöltése során!",
              variant: "danger",
              solid: true,
              noAutoHide: true,
            });
          }
        }
        this.$emit("refreshList");
      }
    },
    async dispenseDetailsToEeszt() {
      var response = [];
      await Promise.all(
        this.seletedDetailsAddableDispenseIds.map(async (id) => {
          const result = await MedicalDeviceLogic.addDispenseDetailsToEeszt(id);
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept kiadás részleteinek feltöltése megtörtént!",
          ElementErrorTitle:
            " azonosítújú recept kiadás részleteinek feltöltése sikertelen!",
          ElementsSuccessesMessage:
            " db recept kiadás részleteinek EESZT-be való feltöltése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres feltöltés!",
        };
        this.eesztStatusMessage(response, messages);

        const successIds = response
          .filter(
            (r) =>
              r.Response.StatusMessages && r.Response.StatusMessages.length == 0
          )
          .map((r) => r.PrescriptionId);
        this.$emit("printPDF", successIds);
      }
    },
    async dispenseUpdateToEeszt() {
      var response = [];
      await Promise.all(
        this.seletedUpdatableDispenseIds.map(async (id) => {
          const result = await MedicalDeviceLogic.updateDispenseToEeszt(id);
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept kiadás frissítése sikeresen megtörtént!",
          ElementErrorTitle:
            " azonosítújú recept kiadás frissítése sikertelen!",
          ElementsSuccessesMessage:
            " db recept kiadás EESZT-be való frissítése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres frissítés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
    },
    async startDispenseDeleteFromEeszt() {
      this.showDispenseDeleteDescriptionModal = true;
    },
    async dispenseDeleteFromEeszt() {
      var response = [];
      await Promise.all(
        this.seletedDeletableDispenseIds.map(async (id) => {
          const result = await MedicalDeviceLogic.deleteDispenseFromEeszt(
            this.deleteDescription,
            id
          );
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept kiadás törlése sikeresen megtörtént!",
          ElementErrorTitle: " azonosítújú recept kiadás törlése sikertelen!",
          ElementsSuccessesMessage:
            " db recept kiadás EESZT-ből való törlése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres törlés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
      this.showDispenseDeleteDescriptionModal = false;
    },
    async dispenseReviewToEeszt() {
      var response = [];
      await Promise.all(
        this.selectedReviewableDispenseIds.map(async (id) => {
          const result = await MedicalDeviceLogic.reviewDispenseToEeszt(id);
          response.push({ PrescriptionId: id, Response: result });
        })
      );
      if (response.map((r) => r.Response.Code).some((x) => x == 201)) {
        await this.loginEeszt();
      } else {
        const messages = {
          ElementSuccessTitle:
            " azonosítújú recept kiadás ellenőrzése sikeresen megtörtént!",
          ElementErrorTitle:
            " azonosítújú recept kiadás ellenőrzése sikertelen!",
          ElementsSuccessesMessage:
            " db recept kiadás EESZT-ben való ellenőrzése sikeresen megtörtént!",
          ElementsSuccessesTitle: "Sikeres ellenőrzés!",
        };
        this.eesztStatusMessage(response, messages);
        this.$emit("refreshList");
      }
    },
    //EESZT bejelentkezés
    async loginEeszt() {
      this.showSelectLoginTypeModal = true;
      /* const body = {
        OrganizationNickname: this.eesztOrganizationNickname,
        RedirectUrl: window.location.origin + "/eSzigRedirect",
      };
      const loginEesztResponse = await AuthLogic.initEeszt(body);
      if (!loginEesztResponse.Code) {
        window.open(
          "http://127.0.0.1:19750" + loginEesztResponse.RidRequestString,
          "_blank"
        );
      } else {
        this.$bvToast.toast(loginEesztResponse.Message, {
          title: "Hiba történt az EESZT funkció indítása során!",
          variant: "success",
          solid: true,
        });
      } */
    },
    async startEesztLogin(option, params) {
      this.activeOptionName = option;
      const token = AuthLogic.canStartEesztRequest();
      if (!token) {
        if (this.eesztOrganizationNickname) {
          await this.loginEeszt();
        } else {
          await this.selectOrganizationNickname();
          this.$bvToast.toast(
            "A rendszer elindította az EESZT bejelentkezést! Az EESZT bejelentkezéshez szükséges egy kiválasztott intézmény, ezt a felugró ablakba válassza ki, különben nem fog tudni EESZT funkciókat végrehajtani!",
            {
              title: "EESZT bejelentkezés",
              variant: "warning",
              solid: true,
            }
          );
        }
      } else {
        await this.activeEesztOption[this.activeOptionName](params);
      }
    },
    async startESzigLogin() {
      this.showSelectLoginTypeModal = false;

      const body = {
        OrganizationNickname: this.eesztOrganizationNickname,
        RedirectUrl: window.location.origin + "/eSzigRedirect",
      };
      const loginEesztResponse = await AuthLogic.initEeszt(body);
      if (!loginEesztResponse.Code) {
        window.open(
          "http://127.0.0.1:19750" + loginEesztResponse.RidRequestString,
          "_blank"
        );
      } else {
        this.$bvToast.toast(loginEesztResponse.Message, {
          title: "Hiba történt az EESZT funkció indítása során!",
          variant: "success",
          solid: true,
        });
      }
    },
    async startPasswordLogin() {
      const loginResponse = await AuthLogic.loginEesztByPassword({
        Password: this.password,
      });
      if (!loginResponse.Code) {
        this.showPasswordModal = false;
        this.showSelectLoginTypeModal = false;
        await this.activeEesztOption[this.activeOptionName]();
      } else {
        this.$bvToast.toast(
          "A bejelentkezés sikertelen, kérjük próbálja újra!",
          {
            title: "EESZT bejelentkezés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    /* async startHardTokenLogin() {
      const loginResponse = await AuthLogic.loginEesztByHardToken(
        this.hardToken
      );
      if (!loginResponse.Code) {
        this.showHardTokenModal = false;
        this.showSelectLoginTypeModal = false;
        await this.activeEesztOption[this.activeOptionName]();
      } else {
        this.$bvToast.toast(
          "A bejelentkezés sikertelen, kérjük próbálja újra!",
          {
            title: "EESZT bejelentkezés",
            variant: "danger",
            solid: true,
          }
        );
      }
    }, */
    async startMobilTokenLogin() {
      const loginResponse = await AuthLogic.loginEesztByMobilToken(
        this.mobilToken
      );
      if (!loginResponse.Code) {
        this.showMobilTokenModal = false;
        this.showSelectLoginTypeModal = false;
        await this.activeEesztOption[this.activeOptionName]();
        this.$bvToast.toast("Sikeres EESZT bejelentkezés!", {
          title: "Sikeres bejelentkezés!",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(
          "A bejelentkezés sikertelen, kérjük próbálja újra!",
          {
            title: "EESZT bejelentkezés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },

    eesztStatusMessage(response, messages) {
      //hibás feltöltések
      if (!response.map((r) => !r.Response.Code).some((x) => x)) {
        response.forEach((r) => {
          //hiba volt a feltöltésbe, sikeresen művelet
          if (r.Response.Code == 200) {
            this.$bvToast.toast(r.Response.Message, {
              title: r.PrescriptionId + messages.ElementSuccessTitle,
              variant: r.Response.ColorCode ? r.Response.ColorCode : "danger",
              solid: true,
              noAutoHide: true,
            });
          } else {
            //hibás feltöltés, sikertelen művelet
            /* this.$bvToast.toast(r.Response.Message, {
              title: r.PrescriptionId + messages.ElementErrorTitle,
              variant: r.Response.ColorCode ? r.Response.ColorCode : "danger",
              solid: true,
              noAutoHide: true,
            }); */
            this.$store.dispatch("setToastError", {
              Id: this.errorIndex++,
              Messages: r.Response.Message,
              CopyableResponse: {
                Curl: r.Response.Curl,
                Response: r.Response.Response,
              },
              Title: r.PrescriptionId + messages.ElementErrorTitle,
              Variant: r.Response.ColorCode ? r.Response.ColorCode : "danger",
              NoAutoHide: true,
            });
          }
        });
      }
      //sikeres feltöltések
      var successAddsLength = response.filter((r) => {
        return (
          (!r.Response.Code && !r.Response.StatusMessages) ||
          (r.Response.StatusMessages && r.Response.StatusMessages.length == 0)
        );
      }).length;
      if (successAddsLength > 0) {
        this.$bvToast.toast(
          successAddsLength + messages.ElementsSuccessesMessage,
          {
            title: messages.ElementsSuccessesTitle,
            variant: "success",
            solid: true,
          }
        );
      }
    },
    async getProjectPatientsData(params) {
      if (this.projectRoles) {
        const patientRoles = this.projectRoles.filter((r) =>
          r.BaseRoles.includes("Patient")
        );
        //kérés indítás
        var tempPatientList = await Promise.all(
          patientRoles.map(async (r) => {
            const patientListResponse = await UserLogic.getUserData({
              projectId: this.$loggedUser.SelectedProject.ProjectId,
              projectRole: r.RoleName,
              "-orderby": "Username",
              ...params,
            });
            //sikeres kérés?
            if (!patientListResponse.Code) {
              //van projekt adat?
              //this.patientList = this.filteredPatientList = patientListResponse;
              return patientListResponse;
            } else {
              this.$bvToast.toast(patientListResponse.Message, {
                title: this.$t(
                  "requestResponse.project.errorGetProjectSubordinates"
                ),
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              });
            }
          })
        );
        this.filteredParticipants = tempPatientList.flat();
      }
    },
  },
  computed: {
    userProjectPermissions() {
      if (this.$store.state.loggedUser) {
        return this.$store.state.loggedUser.ProjectPermissions;
      }
      return null;
    },
    isDevelopmentMode() {
      return process.env.NODE_ENV == "development";
    },
  },
  async mounted() {
    window.addEventListener("storage", this.changedStorage, false);
    await this.selectOrganizationNickname();
    await this.getProjectPatientsData({ "-limit": 100 });
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.changedStorage, false);
  },
};
</script>
<style>
.eeszt-prescription-list .table.b-table > tbody > .table-active.table-success {
  background: lightgreen;
}
</style>
