import Vue from "vue"
import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'
import { ModulesTechnicalData } from '../../Modules/HealthModule/Logic/modules-technical-data'
import { StoreManager } from '@/Store/store-manager'

const USER_PREFERENCES = '/user/preferences/pc'
const USER_PREFERENCES_MODULES = '/user/preferences/modules/pc'
const USER_MODULE = '/user/preferences/pc/module'

export const UserPreferencesLogic = {
    //preferences lekérés
    async getUserPreferences(params) {
        //TODO: session-be menteni?
        const result = await ClientService.sendRequest(RequestMethod.GET, USER_PREFERENCES, params)
        const response = await RequestResponseLogic.checkRequestResponse(result)
        if (!response.Code && response.Modules) {
            StoreManager.sessionStoragePut(Vue.prototype.$USER_PREFERENCES_STORAGE_KEY, response)
            UserPreferencesTechnical.setModulesTechnicalData(response.Modules)
        }
        return response
    },

    async getModule(params) {
        var userPreferences = StoreManager.sessionStorageGet(Vue.prototype.$USER_PREFERENCES_STORAGE_KEY)
        if (userPreferences && userPreferences.Modules) {
            UserPreferencesTechnical.setModulesTechnicalData(userPreferences.Modules)
        } else {
            userPreferences = await this.getUserPreferences(params)
        }
        return userPreferences
    },

    //elérhető modulok listája
    getEnabledModules(modules) {
        const temp = []
        Object.values(modules).forEach(x => {
            if (x.Enabled) {
                temp.push(x)
            }
        })
        temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
        return temp
    },

    //nem elérhető modulok listája
    getDisabledModules(modules) {
        const temp = []
        Object.values(modules).forEach(x => {
            if (!x.Enabled) {
                temp.push(x)
            }
        })
        temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
        return temp
    },

    async setModule(moduleName, body, params) {
        const result = await ClientService.sendRequestWithBody(
            RequestMethod.PATCH,
            USER_MODULE + '/' + moduleName,
            params,
            body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async setChart(moduleName, chartName, body, params) {
        const result = await ClientService.sendRequestWithBody(
            RequestMethod.PATCH,
            USER_MODULE + '/' + moduleName + '/chart/' + chartName,
            params,
            body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    //Modulok beállítása (módosítása)
    async setModulesByOrder(enabledModules, disabledModules, params) {
        // emptying the preference module list
        var userPreferences = StoreManager.sessionStorageGet(Vue.prototype.$USER_PREFERENCES_STORAGE_KEY)
        // setting the enabled modules
        for (var i = 0; i < enabledModules.length; i++) {
            const module = enabledModules[i]
            userPreferences.Modules[module.Name].Enabled = true
            userPreferences.Modules[module.Name].OrderNumber = i
        }
        // setting the disabled modules
        for (i = 0; i < disabledModules.length; i++) {
            const module = disabledModules[i]
            userPreferences.Modules[module.Name].Enabled = false
            userPreferences.Modules[module.Name].OrderNumber = i
        }
        return await this.saveUserPreferences(userPreferences, params)
    },
    //Modul módosítások elküldése
    async saveUserPreferences(userPreferences, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.PUT,
            USER_PREFERENCES_MODULES,
            params,
            userPreferences.Modules)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    //Modul beállítások törlése session storage-ból
    deleteUserPreferencesFromStorage() {
        StoreManager.sessionStorageDelete(Vue.prototype.$USER_PREFERENCES_STORAGE_KEY)
    },

    getEmptyModulesChartData(moduleName) {
        const modules = ModulesTechnicalData.emptyModules
        UserPreferencesTechnical.setChartsTechnicalData(modules[moduleName].Charts)
        return modules[moduleName].Charts
    },
    getActiveModules() {
        return ModulesTechnicalData.activeProjectModules
    }
}

const UserPreferencesTechnical = {
    //technicaki adatok beállítása a modulokhoz
    setModulesTechnicalData(modules) {
        Object.entries(modules).forEach(([key, value]) => {
            this.setChartsTechnicalData(value.Charts)
            Object.assign(value, ModulesTechnicalData.modulesTechnicalData[key])
        })
    },

    setChartsTechnicalData(charts) {
        Object.entries(charts).forEach(([key, value]) => {
            Object.assign(value, ModulesTechnicalData.chartsTechnicalData[key])
        })
    },
}