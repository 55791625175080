<template>
  <div v-if="managerType">
    <fp-new-participant-manager
      @selectOtherParticipant="addNewParticipantToList"
      :project="project"
      :managerType="managerType"
    />
    <fp-tag-manager
      v-if="$productSettings[managerType].ParticipantList.HasParticipantTag"
      :tagListType="$productSettings[managerType].Tag.ManagedTagType"
      :project="project"
      defaultNewTagType="PatientTag"
    />
    <fp-input
      v-model="multivalueFilter"
      @change="filterParticipantList"
      :label="$t('base.basic.filter')"
    />
    <fp-participant-list
      :title="listTitle"
      :refreshedList.sync="refreshedList"
      :participantList="participantList"
      @selectedParticipant="selectParticipant"
      :shownData="
        $productSettings[managerType].ParticipantList.ParticipantShownDataKeys
      "
      :hasTag="$productSettings[managerType].ParticipantList.HasParticipantTag"
      :forcedSelectUserId="forcedSelectUserId"
      :managerType="managerType"
      @getNextStack="getNextStack"
      :listIsLoading="listIsLoading"
      :noOtherParticipant="noOtherParticipant"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
export default {
  name: "ParticipantManager",
  props: {
    project: Object,
    managerType: String,
    refreshingList: Boolean,
    listTitle: String,
  },
  data() {
    return {
      participantList: null,
      multivalueFilter: null,
      tagFilter: null,
      currentParticipant: null,
      refreshedList: false,
      forcedSelectUserId: null,
      listIsLoading: false,
      noOtherParticipant: false,
    };
  },
  watch: {
    async project(input) {
      if (input) {
        await this.setDefaultLists();
      }
    },
    async refreshingList(input) {
      if (input) {
        await this.filterParticipantList();
        const refreshedParticipant = this.currentParticipant
          ? this.participantList.find(
              (p) => p.UserId == this.currentParticipant.UserId
            )
          : null;
        if (refreshedParticipant) {
          this.$emit("changedSelectedParticipant", refreshedParticipant);
        } else {
          this.$emit("changedSelectedParticipant", this.participantList[0]);
        }
      }
      this.refreshedList = true;
      this.$emit("update:refreshingList", false);
    },
  },
  methods: {
    async addNewParticipantToList(participant) {
      this.forcedSelectUserId = null;
      await this.filterParticipantList();
      this.forcedSelectUserId = participant ? participant.UserId : null;
      this.refreshedList = true;
      this.noOtherParticipant = false;
    },
    async filterParticipantList() {
      var params = {};
      if (this.multivalueFilter) {
        if (!params) {
          params = {};
        }
        params.MultiValue = this.multivalueFilter;
      }
      if (this.tagFilter && this.tagFilter.length) {
        if (!params) {
          params = {};
        }
        params.TagIds = this.tagFilter;
      }
      if (!this.multivalueFilter && !this.tagFilter) {
        await this.setDefaultLists();
      } else if (params) {
        this.participantList = await this.getParticipantList({
          ...params,
          "-orderby": "Name",
          "-limit": 10,
        });
      } else {
        this.participantList = [];
      }
    },
    async getParticipantList(params) {
      const baseRoles =
        this.$productSettings[this.managerType].ParticipantList
          .ParticipantsBaseRoles;
      const onlySubordinates =
        this.$productSettings[this.managerType].ParticipantList
          .ParticipantListLoadType ==
        this.$enums.SystemParameters.ParticipantListLoadType.JustRelationship
          .Value;
      const tempList = [];
      await Promise.all(
        baseRoles.map(async (role) => {
          const getResponse = await ProjectLogic.getParticipant(
            this.project.ProjectId,
            {
              BaseRole: role.Value,
              OnlySubordinates: onlySubordinates,
              ...params,
            }
          );
          if (!getResponse.Code) {
            tempList.push(getResponse);
          } else {
            this.$bvToast.toast(getResponse.Message, {
              title:
                "Hiba történt a lista betöltése során! Előfordulhat, hogy a résztvevő lista egy részét nem sikerült betölteni.",
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        })
      );
      const fixedList = this.$checkDuplicates(tempList.flat(), "UserId");
      return fixedList;
    },
    async setDefaultLists() {
      if (this.project) {
        this.participantList = await this.getParticipantList({
          "-orderby": "Name",
          "-limit": 10,
        });
      }
    },
    selectParticipant(participant) {
      this.currentParticipant = participant;
      this.$emit("changedSelectedParticipant", participant);
    },
    async getNextStack() {
      this.listIsLoading = true;
      var params = {};
      if (this.multivalueFilter) {
        params.MultiValue = this.multivalueFilter;
      }
      if (this.tagFilter && this.tagFilter.length) {
        params.TagIds = this.tagFilter;
      }

      const newListElements = await this.getParticipantList({
        ...params,
        "-orderby": "Name",
        "-offset": this.participantList.length,
        "-limit": 10,
      });
      if (newListElements.length == 0) {
        this.noOtherParticipant = true;
      }
      this.participantList = this.participantList.concat(newListElements);

      this.listIsLoading = false;
    },
  },
  async mounted() {
    await this.setDefaultLists();
  },
};
</script>
