var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.module)?_c('div',[_c('b-button',{staticClass:"eh-action-button",on:{"click":function($event){_vm.showCreateModal = true}}},[_vm._v("Új egészségcél")]),_c('create-health-goal-form-modal',{staticStyle:{"margin-bottom":"10px"},attrs:{"UserId":_vm.UserId,"Module":_vm.module},on:{"SetSelectedPlan":_vm.SetSelectedPlan},model:{value:(_vm.showCreateModal),callback:function ($$v) {_vm.showCreateModal=$$v},expression:"showCreateModal"}}),(Object.keys(_vm.module.HealthPlans).length > 1)?_c('fp-tabs',{key:"main-many-tabs-container",staticClass:"eh-main-tab",model:{value:(_vm.tabMainIndex),callback:function ($$v) {_vm.tabMainIndex=$$v},expression:"tabMainIndex"}},_vm._l((_vm.module.HealthPlans),function(value,key,index){return _c('fp-tab',{key:index,attrs:{"title":_vm.$t('module.plans.types.' + key)},on:{"click":function($event){return _vm.SelectPlanTab(value[0].Value, key)}}},[(
          _vm.module.HealthPlans[Object.keys(_vm.module.HealthPlans)[0]].length > 1
        )?_c('fp-tabs',{key:"many-and-many-tabs-container",staticClass:"eh-sub-tab",model:{value:(_vm.tabSubIndex),callback:function ($$v) {_vm.tabSubIndex=$$v},expression:"tabSubIndex"}},_vm._l((value),function(v,vIndex){return _c('fp-tab',{key:index + '-' + v.Value + '-' + vIndex,attrs:{"title":_vm.$t('module.plans.' + key + '.' + v.Name)},on:{"click":function($event){return _vm.SelectPlanTab(v.Value, key)}}},[_c('health-goal-table',{attrs:{"items":_vm.items,"planType":key,"goalType":v.Value},on:{"delete-item":_vm.DeleteHealthGoal}})],1)}),1):_c('fp-tabs',{key:"more-and-one-tabs-container",staticClass:"eh-sub-tab",model:{value:(_vm.tabMainIndex),callback:function ($$v) {_vm.tabMainIndex=$$v},expression:"tabMainIndex"}},[_c('fp-tab',{attrs:{"title":_vm.$t('module.plans.' + key + '.' + _vm.module.HealthPlans[key][0].Name)}},[_c('health-goal-table',{attrs:{"items":_vm.items,"planType":key,"goalType":_vm.module.HealthPlans[key][0].Value},on:{"delete-item":_vm.DeleteHealthGoal}})],1)],1)],1)}),1):_c('div',[(_vm.module.HealthPlans[Object.keys(_vm.module.HealthPlans)[0]].length > 1)?_c('fp-tabs',{key:"one-and-more-tabs-container",model:{value:(_vm.tabSubIndex),callback:function ($$v) {_vm.tabSubIndex=$$v},expression:"tabSubIndex"}},_vm._l((_vm.module.HealthPlans[
          Object.keys(_vm.module.HealthPlans)[0]
        ]),function(value,index){return _c('fp-tab',{key:index + '-' + value,attrs:{"title":_vm.$t(
            'module.plans.' +
              Object.keys(_vm.module.HealthPlans)[0] +
              '.' +
              value.Name
          )},on:{"click":function($event){_vm.SelectPlanTab(value.Value, Object.keys(_vm.module.HealthPlans)[0])}}},[_c('health-goal-table',{attrs:{"items":_vm.items,"planType":Object.keys(_vm.module.HealthPlans)[0],"goalType":value.Value},on:{"delete-item":_vm.DeleteHealthGoal}})],1)}),1):_c('fp-tabs',{key:"one-and-one-tabs-container"},[_c('fp-tab',{attrs:{"title":_vm.$t(
            'module.plans.' +
              Object.keys(_vm.module.HealthPlans)[0] +
              '.' +
              _vm.module.HealthPlans[Object.keys(_vm.module.HealthPlans)[0]][0].Name
          )}},[_c('health-goal-table',{attrs:{"items":_vm.items,"planType":Object.keys(_vm.module.HealthPlans)[0],"goalType":_vm.module.HealthPlans[Object.keys(_vm.module.HealthPlans)[0]][0].Value},on:{"delete-item":_vm.DeleteHealthGoal}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }