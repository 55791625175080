<template>
  <fp-form-modal
    v-if="editableRelationship"
    v-model="showEditParticipant"
    @hidden="clearEditRelationship"
    title="Résztvevő szerkesztése"
  >
    <template #content>
      <fp-input
        v-if="participant"
        :disabled="true"
        :value="participant.Name + ' (' + participant.Username + ')'"
      />
      <fp-select
        :label="$t('partner.participantList.newParticipantForm.projectRole')"
        :items="projectRoleList"
        valueKey="RoleName"
        textKey="RoleName"
        v-model="editableRelationship.RoleName"
        @change="changValue('RoleName', $event)"
      />
      <fp-input
        :label="$t('partner.participantList.newParticipantForm.note')"
        v-model="editableRelationship.Remark"
        @change="changValue('Remark', $event)"
      />
      <b-button @click="setParticipant()">{{ $t("base.basic.save") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";
import { ProjectLogic } from "@/Logic/Backend/project";

export default {
  name: "EditParticipant",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    projectRoleList: Array,
    participant: Object,
    projectId: Number,
  },
  data() {
    return {
      showEditParticipant: this.visible,
      defaultRelationship: {
        UserId: null,
        RoleName: null,
        TagIds: [],
        Parameters: {},
        Remark: "",
      },
      editableRelationship: null,
      selectedUser: null,
      userList: null,
      selectedRoleName: null,
      filteredParticipantList: null,
      changedValues: null,
    };
  },
  watch: {
    visible(value) {
      this.showEditParticipant = value;
      this.setEditableRelationship();
    },
    showEditParticipant(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    changValue(path, value) {
      if (!this.changedValues) {
        this.changedValues = {};
      }
      this.$set(this.changedValues, path, value);
    },
    //új résztvevő felvétele a projektbe
    async setParticipant() {
      console.log(this.changedValues);
      const setParticipantResponse = await ProjectLogic.setParticipant(
        this.projectId,
        this.participant.UserId,
        this.changedValues
      );
      if (!setParticipantResponse.Code) {
        //TODO: sikeres
        //új beteg kapcsolat modal megjelenítő változó false-ra állítása
        this.showEditParticipant = false;
        //új kapcsolat tároló ürítése
        this.editableRelationship = null;
        //felhasználó lista ürítése
        this.userList = [];
        this.$emit("refreshProject");
      } else {
        //TODO: hiba
      }
    },
    //felhasználó lista szűrése felhasználónév szerint
    async searchUserByName(e) {
      //a kereső input több mint 2 karakterből áll?
      if (e && e.length >= 3) {
        //igen, felhasználó lista kérés input alapján
        await this.getUserList({
          "Username-contains": e,
        });
      } else {
        //nem, első 10 felhasználó lista kérés
        await this.getUserList({
          "-limit": 10,
        });
      }
    },
    //felhasználó lista kérés
    async getUserList(params) {
      //kérés indítás megadott params alapján
      const userListResponse = await UserLogic.getUser(params);
      //sikeres kérés?
      if (!userListResponse.Code) {
        //igen, kapott lista tárolása
        this.userList = userListResponse;
      } else {
        this.$bvToast.toast(userListResponse.Message, {
          title: this.$t("base.error"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteg hozzáadása űrlap ürítése
    clearEditRelationship() {
      this.editableRelationship = JSON.parse(
        JSON.stringify(this.defaultRelationship)
      );
    },
    setEditableRelationship() {
      this.editableRelationship = JSON.parse(
        JSON.stringify(this.defaultRelationship)
      );
      if (this.participant) {
        this.editableRelationship.UserId = this.participant.UserId;
        this.editableRelationship.RoleName = this.participant.RoleName;
        this.editableRelationship.TagIds = this.participant.TagIds;
        this.editableRelationship.Parameters = this.participant.Parameters;
        this.editableRelationship.Remark = this.participant.Remark;
      }
      this.changedValues = null;
    },
  },
  async mounted() {
    this.setEditableRelationship();
    //felhasználó lista kérés
    await this.getUserList({ "-limit": 10 });
  },
};
</script>
