import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const MEDICINE_PLAN = '/medicine_plan'
const MEDICINE_TASK = '/task/medicine'
const MEDICINE_COMPLETE = '/task/medicine/complete'
const MEDICINE_UNCOMPLETE = '/task/medicine/uncomplete'

export const MedicinePlanLogic = {

    /* Gyógyszerszedési tervek listája */
    async getMedicinePlanList(params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            MEDICINE_PLAN,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async getMedicinePlanListByUser(userId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            MEDICINE_PLAN + '/' + userId,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    /* Beszedhető gyógyszerek listázása */
    async getMedicineTaskList(params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            MEDICINE_TASK,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    /* Gyógyszerszedési terv felvétele */
    async setMedicinePlan(data, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            MEDICINE_PLAN,
            params,
            data)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async setMedicinePlanToTarget(targetId, data, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            MEDICINE_PLAN + '/' + targetId,
            params,
            data)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    /* Beszedhető gyógyszer beszedése */
    async completeMedicineTask(data, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.PATCH,
            MEDICINE_COMPLETE,
            params,
            data)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    /* Beszedett gyógyszer törlése */
    async uncompleteMedicineTask(data, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.PATCH,
            MEDICINE_UNCOMPLETE,
            params,
            data)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    /* Gyógyszerszedési terv törlése */
    async deleteMedicinePlan(planId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.DELETE,
            MEDICINE_PLAN + '/' + planId,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async deleteMedicinePlanByTarget(planId, targetId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.DELETE,
            MEDICINE_PLAN + '/' + targetId + '/' + planId,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

}