<template>
  <fp-form-modal
    hide-footer
    id="nutrition-input-modal"
    v-model="showInputModal"
    :title="$t('module.diaries.nutrition.addNewPost')"
  >
    <template #content>
      <b-form :class="theme">
        <div>
          <fp-select
            v-model="newEntry.MealName"
            :items="Object.values(mealNames)"
            :label="$t('module.diaries.nutrition.mealName')"
            valueKey="Value"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
          <fp-panel-card
            style="margin-bottom: 5px"
            :title="$t('module.diaries.nutrition.items')"
          >
            <template #content>
              <div
                v-for="(foodItem, index) in newEntry.Items"
                :key="ItemIds[index]"
              >
                <fp-panel-card
                  style="margin: 10px 0px"
                  :isShowAddButton="newEntry.Items.length < 20"
                  :isShowDeleteButton="newEntry.Items.length > 1"
                  @addNewElement="addNewElement(index)"
                  @deleteElement="deleteElementByIndex(index)"
                >
                  <template #content>
                    <fp-input
                      :label="$t('module.diaries.nutrition.itemName')"
                      v-model="foodItem.Name"
                    />
                    <fp-input
                      :label="$t('module.diaries.nutrition.itemAmount')"
                      v-model="foodItem.Amount"
                    />
                    <fp-select
                      :label="$t('module.diaries.nutrition.itemUnit')"
                      v-model="foodItem.Unit"
                      valueKey="Value"
                      :items="Object.values(units)"
                    >
                      <template #element="element">
                        {{ $t(element.Text) }}
                      </template>
                    </fp-select>
                    <fp-input
                      :label="$t('module.diaries.nutrition.itemCalories')"
                      v-model="foodItem.Calories"
                    />
                  </template>
                </fp-panel-card>
              </div>
            </template>
          </fp-panel-card>
          <fp-date-time-picker
            :state="!validation.hasError('newEntry.MeasuredAt')"
            :error="validation.firstError('newEntry.MeasuredAt')"
            :requited="true"
            :label="$t('base.calendar.date')"
            v-model="newEntry.MeasuredAt"
          />
          <fp-input
            :label="$t('module.diaries.nutrition.remark')"
            v-model="newEntry.Remark"
          />
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>

<script>
import moment from "moment";
import { DiaryLogic } from '../../../../Logic/Backend/diary';

export default {
  name: "MoodDiaryInput",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    TargetId: Number,
  },
  data() {
    return {
      newEntry: {
        MealName: null,
        Items: [{ Name: null, Amount: null, Unit: null, Calories: null }],
        Remark: null,
        SourceName: "UserInput",
        MeasuredAt: moment().format(),
      },
      ItemIds: [0],
      KeyIndex: 0,
      mealNames: this.$enums.MealName, //Object.values(this.$t("enums.MealName")),
      units: this.$enums.Unit, //Object.values(this.$t("enums.Unit")),
      showInputModal: null,
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.MeasuredAt": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredDateTime"));
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const getDiaryResponse = await this.setDiaryByUser([this.newEntry]);
        if (!getDiaryResponse.Code || getDiaryResponse.Code == 0) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(getDiaryResponse.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async setDiaryByUser(data) {
      if (this.TargetId) {
        return await DiaryLogic.addDiaryEntryToTarget(
          "nutrition",
          this.TargetId,
          data
        );
      } else {
        return await DiaryLogic.addDiaryEntry("nutrition", data);
      }
    },
    addNewElement(index) {
      this.ItemIds.splice(index + 1, 0, ++this.KeyIndex);
      this.newEntry.Items.splice(index + 1, 0, {});
    },
    deleteElementByIndex(index) {
      this.ItemIds.splice(index, 1);
      this.newEntry.Items.splice(index, 1);
    },
  },
};
</script>
