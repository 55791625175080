<template>
  <div class="module-dashboard" v-if="settings">
    <!-- Funkció gombok, beállítások -->
    <b-row class="module-buttons" align-h="between" style="margin-top: 10px">
      <!-- Funkció gombok -->
      <div class="module-diary-buttons">
        <!-- Egészségcél -->
        <router-link
          :to="{
            name: 'HealthGoal',
            query: { module: settings.Name },
          }"
        >
          <button style="margin: 10px" class="eh-action-button">{{
            $t("client.healthGoals.title")
          }}</button>
        </router-link>
        <!-- Naplók -->
        <router-link
          v-for="diary in settings.Buttons"
          :key="diary.Name"
          :to="{ name: diary.RouteName }"
        >
          <button
            v-if="settings.Functions[diary.Name]"
            style="margin: 10px"
            class="eh-action-button"
            >{{ $t("module.moduleDiaries." + diary.Name) }}</button
          >
        </router-link>
      </div>
      <!-- Beállítások -->
      <div class="module-settings-button">
        <router-link
          style="margin: 10px"
          :to="{
            name: 'FunctionSettings',
            query: { module: settings.Name },
          }"
        >
          <b-icon class="h3" icon="gear-fill" />
          <!-- <img style="height: 50px" src="@/Assets/custom-icons/settings.png" /> -->
        </router-link>
      </div>
    </b-row>
    <!-- Chartok -->
    <!-- TODO: nem megjelenő chartok helyét kihagyja a formázások miatt -->
    <div
      v-for="(chart, index) in getModulesOrderedChartsArray(settings.Charts)"
      :key="chart.ChartName + '-chart-' + index"
      class="module-charts-table"
      :class="getChartSize(chart.Size)"
    >
      <div v-if="chart.Enabled">
        <component
          :is="chart.ComponentName"
          :Interval="chart.Interval"
          :ChartType="'chart'"
          :Chart="chart"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { UserPreferencesLogic } from "@/Logic/Backend/user-preferences";

export default {
  name: "ExerciseModule",
  data() {
    return {
      settings: null,
      chartSizeValues: this.$enums.ChartSize,
    };
  },
  methods: {
    //modulok lekérése
    async getModule() {
      //kérés indítás
      const getModuleResponse = await UserPreferencesLogic.getUserPreferences();
      //sikeres kérés? benne van a modules?
      if (!getModuleResponse.Code && getModuleResponse.Modules) {
        //igen, a megnyitott modul adatok kiemelése
        this.settings = getModuleResponse.Modules.Exercise;
      } else {
        //nem, hiba üzenet
        this.$bvToast.toast(getModuleResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t('module.modules.module'),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //modulhoz tartozó chartok tömbbe rendezése megadott sorrend alapján
    getModulesOrderedChartsArray(charts) {
      //kapott chartok objektum értékeinek kiemelése
      var temp = Object.values(charts);
      //chartok sorrendezése
      temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
      //visszaadjuk a tömböt
      return temp;
    },
    //megadott chart mérethez rendelt class név lekérése
    getChartSize(size) {
      //return ChartUiLogic.getChartCssClassBySize(size);
      return this.chartSizeValues[size].className;
    },
  },
  async mounted() {
    await this.getModule();
    this.$store.dispatch("setPageTitle", this.$t("module.modules." + this.settings.Name));
    this.$emit("color-change", this.settings.Color);
  },
};
</script>
<style scoped>
.module-charts-table {
  height: 100%;
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}
</style>