import Vue from 'vue'

export const UserManager = {
  saveUserToken(token) {
    sessionStorage.setItem(Vue.prototype.$USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getUserToken() {
    const token = sessionStorage.getItem(Vue.prototype.$USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteUserToken() {
    sessionStorage.removeItem(Vue.prototype.$USER_TOKEN_STORAGE_KEY);
  },
  saveEesztUserTokenToSession(token) {
    sessionStorage.setItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getEesztUserTokenFromSession() {
    const token = sessionStorage.getItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteEesztUserTokenFromSession() {
    sessionStorage.removeItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY);
  },
  saveEesztUserTokenToLocal(token) {
    localStorage.setItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY, JSON.stringify(token));
  },
  getEesztUserTokenFromLocal() {
    const token = localStorage.getItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY);
    return JSON.parse(token);
  },
  deleteEesztUserTokenFromLocal() {
    localStorage.removeItem(Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY);
  },
  saveLoggedUser(user) {
    sessionStorage.setItem(Vue.prototype.$LOGGED_USER_STORAGE_KEY, JSON.stringify(user));
  },
  getLoggedUser() {
    const user = JSON.parse(sessionStorage.getItem(Vue.prototype.$LOGGED_USER_STORAGE_KEY));
    //Vue.prototype.$loggedUser = user;
    return user;
  },
  deleteLoggedUser() {
    sessionStorage.removeItem(Vue.prototype.$LOGGED_USER_STORAGE_KEY);
    //Vue.prototype.$loggedUser = null;
  },
};
