export default{
    BmiCategory:{
        Unknown: 'Unknown', 
        ExtremelyUnderweight: 'Extremely underweight', 
        Underweight: 'Underweight', 
        Normal: 'Normal', 
        Overweight: 'Overweight', 
        Obese: 'Obese', 
        ExtremelyObese: 'Extremely obese'
    },
}