<template>
  <fp-form-modal size="lg" v-if="messages" v-model="visible">
    <template #content>
      <div
          v-for="(lang, index) in messages.SupportedLanguages"
          :key="'language' + index"
      >
        <!-- Egyik nyelv sablonai -->
        <fp-panel-card
            :closedCard="true"
            style="margin: 10px 0px"
            :title="$enums.Languages[lang].Text"
        >
          <!-- Sablonok egyik nyelv a panel címe -->
          <template #title>
            <img
                style="margin: 0px 5px 0px 10px; border: solid 1px #00000044"
                width="20px"
                :src="require('@/Assets' + $enums.Languages[lang].flag)"
            />
            {{ $enums.Languages[lang].Text }}
          </template>
          <!-- Sablonok egyik nyelv a panel törzse -->
          <template #content>
            <!-- Email sablon -->
            <fp-panel-card
                v-if="messages.EmailTemplate"
                :closedCard="true"
                style="margin: 10px 0px"
                :title="$t('components.templates.email')"
            >
              <!-- Sablon típusa -->
              <template #title>
                <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
                {{ $t("components.templates.email") }}
              </template>
              <!-- Sablon adatok -->
              <template #content>
                <!-- Tárgy -->
                <fp-input
                    :label="$t('components.templates.emailSubject')"
                    :disabled="true"
                    v-model="messages.EmailTemplate.Subject[lang]"
                />
                <!-- Email szöveg -->
                <div v-html="messages.EmailTemplate.HtmlContent[lang]"></div>
                <!-- <ejs-richtexteditor
                  ref="richTextEditor"
                  v-model="messages.EmailTemplate.HtmlContent[lang]"
                  :insertImageSettings="insertImageSettings"
                  :pasteCleanupSettings="pasteCleanupSettings"
                  :toolbarClick="toolbarClick"
                  :readonly="true"
                  :width="'100%'"
                  :height="500"
                >
                </ejs-richtexteditor> -->
              </template>
            </fp-panel-card>
            <!-- SMS sablon -->
            <fp-panel-card
                v-if="messages.SmsTemplate"
                :closedCard="true"
                style="margin: 10px 0px"
                :title="$t('components.templates.sms')"
            >
              <!-- Sablon típusa -->
              <template #title>
                <b-icon
                    style="margin: 0px 5px"
                    icon="chat-square-text-fill"
                ></b-icon>
                {{ $t("components.templates.sms") }}
              </template>
              <!-- Sablon adatok -->
              <template #content>
                <!-- SMS üzenet -->
                <fp-text-area
                    :label="$t('components.templates.smsMessage')"
                    :disabled="true"
                    v-model="messages.SmsTemplate.Text[lang]"
                />
              </template>
            </fp-panel-card>
            <!-- PUSH sablon -->
            <fp-panel-card
                v-if="messages.PushTemplate"
                :closedCard="true"
                style="margin: 10px 0px"
                :title="$t('components.templates.push')"
            >
              <!-- Sablon típusa -->
              <template #title>
                <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
                {{ $t("components.templates.push") }}
              </template>
              <!-- Sablon adatok -->
              <template #content>
                <!-- Push címe -->
                <fp-input
                    :label="$t('components.templates.pushTitle')"
                    :disabled="true"
                    v-model="messages.PushTemplate.Title[lang]"
                />
                <!-- Push üzenet -->
                <fp-text-area
                    :label="$t('components.templates.pushMessage')"
                    :disabled="true"
                    v-model="messages.PushTemplate.Body[lang]"
                />
                <!-- Push képe -->
                <fp-input
                    :disabled="true"
                    :label="$t('components.templates.pushImg')"
                    v-model="messages.PushTemplate.ImageUrl[lang]"
                />
                <div>
                  <img
                      width="200px"
                      v-if="messages.PushTemplate.ImageUrl"
                      :src="messages.PushTemplate.ImageUrl[lang]"
                  />
                </div>
              </template>
            </fp-panel-card>
          </template>
        </fp-panel-card>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
export default {
  name: "ViewTemplateModal",
  props: {
    messages: Object,
    value: Boolean,
  },
  data() {
    return {
      visible: this.value,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
};
</script>
