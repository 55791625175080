<template>
  <fp-form-modal
    v-if="legals && legals.length"
    size="xl"
    :closable="false"
    v-model="visible"
  >
    <template #header>
      <div>
        {{ "Adatkezelési tájékoztatók " + legalsCurrent + "/" + legalsCount }}
      </div>
      <div style="text-align: center; font-size: 25px; font-weight: bold">
        {{ $getLanguagedText(legals[legalsCurrent - 1].Title) }}
      </div>
      <div style="text-align: center; font-size: 20px">
        {{ $getLanguagedText(legals[legalsCurrent - 1].Description) }}
      </div>
    </template>
    <template #content>
      <b-progress
        style="
          border-radius: 0;
          margin-top: -17px;
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: 20px;
        "
        variant="info"
        :value="legalsCurrent"
        :max="legalsCount"
      />
      <legal-view
        :Document="legals[legalsCurrent - 1]"
        :Disabled="false"
        :Version="legals[legalsCurrent - 1].LastVersion"
        @AcceptedStatements="AcceptedStatements"
      />
    </template>
  </fp-form-modal>
</template>
<script>
import { LegalLogic } from "../../Logic/Backend/legal";
import LegalView from "./LegalView.vue";
export default {
  name: "GDPR_modal",
  components: { LegalView },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    legals: Array,
  },
  data() {
    return {
      visible: this.value,
      legalsCount: 0,
      legalsCurrent: 1,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
      this.legalsCount = this.legals.length;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    AcceptedStatements(indexes) {
      const acceptLegalResponse = LegalLogic.createStatementLegal({
        DocumentId: this.legals[this.legalsCurrent - 1].DocumentId,
        FromDevice: "Pc",
        AcceptedStatementIndexes: indexes,
      });

      if (!acceptLegalResponse.Code) {
        if (this.legalsCount != this.legalsCurrent) {
          this.legalsCurrent++;
          document.getElementById("fp-form-modal").scrollTo(0, 0);
        } else {
          this.$emit("change", false);
          this.$emit("acceptGDPR");
        }
      } else {
        //TODO: hiba
      }
    },
    rejectGDPR() {
      this.$emit("change", false);
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 25px;
}
h2 {
  font-size: 20px;
}
</style>
