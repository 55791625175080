import Form from "@/Views/Common/Form.vue";

/* Specialist */
import EszigRedirect from "@/Views/Specialist/EszigRedirect.vue";
import Specialist from "@/Views/Specialist/RouterView.vue";
import WebAppSpecialistHome from "@/Views/Specialist/Home.vue";
import RecipeReportManager from "@/Views/Specialist/Reports";
/* Specialist - Menu */
import WebAppSpecialistProfile from "@/Views/Specialist/Profile.vue";
import WebAppSpecialistLegal from "@/Views/Specialist/Legal.vue";
import WebAppSpecialistPatientList from "@/Views/Specialist/PatientList.vue";
import WebAppSpecialistTasks from "@/Views/Specialist/Tasks.vue";
import WebAppSpecialistDocuments from "@/Views/Specialist/Documents.vue";
import WebAppSpecialistQuestionnaires from "@/Views/Specialist/Questionnaires.vue";
import WebAppSpecialistProjects from "@/Views/Specialist/Project.vue";
//import WebAppSpecialistPatientManager from "@/Views/Specialist/PatientManager.vue";
import WebAppSpecialistWorkflow from "@/Views/Specialist/Workflows.vue";
import WebAppSpecialistAppointments from "@/Views/Specialist/Appointments.vue";
import WebAppSpecialistTemplates from "@/Views/Specialist/Templates.vue";
import WebAppSpecialistRecipeManager from "@/Views/Specialist/Prescriptions.vue";
//import WebAppSpecialistRecipeReportManager from "@/Views/Specialist/RecipeReportManager.vue";
/* Specialis - Questionnaire */
import WebAppSpecialistEvaluatingQuestionnaire from "@/Views/Specialist/questionnaire/EvaluatingQuestionnaire.vue";
//import WebAppSpecialistQuestionnaire from "@/Views/Specialist/questionnaire/Questionnaire.vue";
//import WebAppSpecialistForm from "@/Views/Specialist/questionnaire/Form.vue";

export default [
  {
    path: "/eSzigRedirect",
    name: "EszigRedirect",
    component: EszigRedirect,
  },
  {
    path: "/Specialist",
    name: "Specialist",
    component: Specialist,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistHome,
        },
      },
      {
        path: "Home",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistHome,
        },
      },
      {
        path: "PatientList",
        name: "WebAppSpecialistPatientList",
        components: {
          specialist: WebAppSpecialistPatientList,
        },
        props: {
          specialist: true,
        },
      },
      /* {
        path: "PatientManager",
        name: "WebAppSpecialistPatientManager",
        components: {
          specialist: WebAppSpecialistPatientManager,
        },
        props: {
          specialist: true,
        },
      }, */
      {
        path: "Questionnaires",
        name: "WebAppSpecialistQuestionnaires",
        components: {
          specialist: WebAppSpecialistQuestionnaires,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Form",
        name: "WebAppSpecialistQuestionnaire",
        components: {
          specialist: Form,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Form",
        name: "WebAppSpecialistForm",
        components: {
          specialist: Form,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "EvaluatingQuestionnaire",
        name: "WebAppSpecialistEvaluatingQuestionnaire",
        components: {
          specialist: WebAppSpecialistEvaluatingQuestionnaire,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Documents",
        name: "WebAppSpecialistDocuments",
        components: {
          specialist: WebAppSpecialistDocuments,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Tasks",
        name: "WebAppSpecialistTasks",
        components: {
          specialist: WebAppSpecialistTasks,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Project",
        name: "WebAppSpecialistProjects",
        components: {
          specialist: WebAppSpecialistProjects,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppSpecialistProfile",
        components: {
          specialist: WebAppSpecialistProfile,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Workflow",
        name: "WebAppSpecialistWorkflow",
        components: {
          specialist: WebAppSpecialistWorkflow,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Appointments",
        name: "WebAppSpecialistAppointments",
        components: {
          specialist: WebAppSpecialistAppointments,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Templates",
        name: "WebAppSpecialistTemplates",
        components: {
          specialist: WebAppSpecialistTemplates,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "RecipeReportManager",
        name: "WebAppSpecialistRecipeReportManager",
        components: {
          specialist: RecipeReportManager,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "RecipeManager",
        name: "WebAppSpecialistRecipeManager",
        components: {
          specialist: WebAppSpecialistRecipeManager,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Legal",
        name: "WebAppSpecialistLegal",
        components: {
          specialist: WebAppSpecialistLegal,
        },
        props: {
          specialist: true,
        },
      },
    ],
    meta: ["Supervisor", "HealthProfessional", "Administrator", "Technical"],
  },
];
