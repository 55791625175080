<template>
  <div>
    <button
      class="eh-action-button"
      style="margin: 20px 0px"
      @click="$router.go(-1)"
    >
      {{ $t("base.basic.back") }}
    </button>
    <fp-health-goal-table
      v-if="moduleName"
      :UserId="$loggedUser.UserId"
      :ModuleName="moduleName"
    />
  </div>
</template>

<script>
export default {
  name: "HealthGoalPage",
  data() {
    return {
      moduleName: null,
    };
  },
  methods: {
    checkQuery() {
      this.moduleName = this.$route.query.module;
      if (!this.moduleName) {
        this.$router.push({ path: "Home" });
      }
    },
    setHeaderbar() {
      if (this.module) {
        this.$store.dispatch(
          "setPageTitle",
          this.$t("module.modules." + this.moduleName) +
            " - " +
            this.$t("client.healthGoals.title")
        );
        this.$emit("color-change", this.module.Color);
      } else {
        //TODO: elirányítsuk valahova? v-else ágba informáljuk a felhasználót, hogy rossz az url?
        this.$store.dispatch(
          "setPageTitle",
          this.$t("module.modules.noModule")
        );
      }
    },
  },
  mounted() {
    this.checkQuery();
    this.setHeaderbar();
    this.$store.dispatch("setPageTitle", this.$t("client.healthGoals.title"));
  },
};
</script>