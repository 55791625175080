var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fp-form-modal',{attrs:{"title":'Új vény jelentés készítő' +
    (_vm.stepComponents && _vm.stepComponents[_vm.currentStep - 1]
      ? ' - ' + _vm.stepComponents[_vm.currentStep - 1].Title
      : ''),"size":"xl","closable":false},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"padding":"20px"}},_vm._l((_vm.stepComponents.length),function(step){return _c('div',{key:'bever-step-' + step},[(step == _vm.currentStep)?_c('div',[_c('div',{staticStyle:{"display":"flex","width":"fit-content","margin":"auto"}},_vm._l((_vm.stepComponents.length),function(stepicon){return _c('div',{key:'bever-step-icon' + stepicon},[_c('fp-svg-icon',{staticStyle:{"border-radius":"50px","padding":"5px"},style:(stepicon == _vm.currentStep
                    ? 'background: var(--eh-secondary-5);'
                    : ''),attrs:{"icon":_vm.stepComponents[stepicon - 1]
                    ? _vm.stepComponents[stepicon - 1].IconPath
                    : null,"size":40}})],1)}),0),_c('div',[(_vm.showPreviousStepButton)?_c('b-button',{on:{"click":_vm.stepPrevious}},[_vm._v(" Előző ")]):_vm._e(),(_vm.showNextStepButton)?_c('b-button',{staticStyle:{"margin-left":"auto"},attrs:{"disabled":_vm.getSelectedPrescription && !_vm.getSelectedPrescription.length},on:{"click":_vm.stepNext}},[_vm._v(" Következő ")]):_vm._e()],1),_c(_vm.stepComponents[step - 1].Component,{tag:"component",attrs:{"canSelectProducts":true},on:{"stepNext":_vm.stepNext,"savedReport":_vm.savedReport}})],1):_vm._e()])}),0)]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}})}
var staticRenderFns = []

export { render, staticRenderFns }