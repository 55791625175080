<template>
  <div>
    <div v-if="taskList">
      <div
        v-if="!filterDisabled"
        class="eh-flex-container eh-task-table-filter-container"
      >
        <div class="eh-task-table-text-filter">
          <fp-input
            :label="$t('base.basic.filter')"
            @change="filterTaskList"
            v-model="filter"
          />
        </div>
        <div class="eh-flex-container">
          <div>
            <fp-select
              :label="$t('components.taskTable.priority')"
              :items="Object.values($enums.TaskPriority)"
              valueKey="Value"
              @change="filterTaskList"
              v-model="priorFilter"
              class="eh-task-table-selector-filter"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
          <div>
            <fp-select
              class="eh-task-table-selector-filter"
              :label="$t('components.taskTable.status')"
              :items="Object.values($enums.TaskStatus)"
              valueKey="Value"
              @change="filterTaskList"
              v-model="statFilter"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
          <div>
            <fp-select
              class="eh-task-table-selector-filter"
              :label="$t('components.taskTable.type')"
              :items="Object.values($enums.TaskType)"
              valueKey="Value"
              @change="filterTaskList"
              v-model="typeFilter"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
        </div>
      </div>

      <div class="eh-flex-container" v-if="!filterDisabled">
        <div>
          <fp-date-range-button
            :label="$t('components.taskTable.startDate')"
            v-model="startTimeFilter"
            @change="filterTaskList"
          />
        </div>
        <div>
          <fp-date-range-button
            :label="$t('components.taskTable.deadline')"
            v-model="deadlineFilter"
            @change="filterTaskList"
          />
        </div>
      </div>
      <fp-table-list
        refId="task-module-task-table"
        :items="filteredTaskList"
        :bordered="true"
        :hasPagination="true"
        :listSorting="sorting"
        :fields="taskListFields"
        :selectable="true"
        selectMode="single"
        sortBy="Status"
        :isSelectClear.sync="isSelectClear"
        @row-selected="singleSelectTask"
      >
        <template #Deadline="row">
          {{ $convertDateToString(row.item.Deadline, "YYYY-MM-DD HH:mm") }}
        </template>
        <template #StartTime="row">
          {{ $convertDateToString(row.item.StartTime, "YYYY-MM-DD HH:mm") }}
        </template>
        <template #Status="row">
          <div style="text-align: center">
            <b-icon
              v-b-tooltip.hover="$t($enums.TaskStatus[row.item.Status].Text)"
              v-if="row.item.Status == $enums.TaskStatus.Overdue.Value"
              icon="clock-history"
              font-scale="2"
              style="
                background: red;
                border-radius: 50px;
                color: white;
                padding: 2px;
              "
            ></b-icon>
            <b-icon
              v-b-tooltip.hover="$t($enums.TaskStatus[row.item.Status].Text)"
              v-if="row.item.Status == $enums.TaskStatus.Completed.Value"
              icon="check-circle-fill"
              font-scale="2"
              variant="success"
            ></b-icon>
            <b-icon
              v-b-tooltip.hover="$t($enums.TaskStatus[row.item.Status].Text)"
              v-if="row.item.Status == $enums.TaskStatus.Failed.Value"
              icon="x-circle-fill"
              font-scale="2"
              variant="danger"
            ></b-icon>
            <b-iconstack
              v-b-tooltip.hover="$t($enums.TaskStatus[row.item.Status].Text)"
              v-if="row.item.Status == $enums.TaskStatus.InProgress.Value"
              font-scale="2"
              variant="warning"
            >
              <b-icon stacked icon="circle-half" rotate="180"></b-icon>
              <b-icon stacked icon="circle-half" rotate="-90"></b-icon>
            </b-iconstack>
            <b-iconstack
              v-b-tooltip.hover="$t($enums.TaskStatus[row.item.Status].Text)"
              v-if="row.item.Status == $enums.TaskStatus.Added.Value"
              font-scale="2"
              variant="info"
            >
              <b-icon stacked icon="circle" scale="0.9"></b-icon>
              <b-icon stacked icon="circle"></b-icon>
            </b-iconstack>
          </div>
        </template>
        <template #Priority="row">
          <div style="text-align: center">
            <b-iconstack
              v-b-tooltip.hover="
                $t($enums.TaskPriority[row.item.Priority].Text)
              "
              v-if="row.item.Priority == $enums.TaskPriority.Urgent.Value"
              font-scale="2"
              variant="danger"
            >
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="-6"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="2"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="-2"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="6"
              ></b-icon>
            </b-iconstack>

            <b-iconstack
              v-b-tooltip.hover="
                $t($enums.TaskPriority[row.item.Priority].Text)
              "
              v-else-if="row.item.Priority == $enums.TaskPriority.High.Value"
              font-scale="2"
              variant="warning"
            >
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="-4"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="0"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="4"
              ></b-icon>
            </b-iconstack>

            <b-iconstack
              v-b-tooltip.hover="
                $t($enums.TaskPriority[row.item.Priority].Text)
              "
              v-else-if="row.item.Priority == $enums.TaskPriority.Medium.Value"
              font-scale="2"
              variant="info"
            >
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="-2"
              ></b-icon>
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="2"
              ></b-icon>
            </b-iconstack>

            <b-iconstack
              v-b-tooltip.hover="
                $t($enums.TaskPriority[row.item.Priority].Text)
              "
              v-else-if="row.item.Priority == $enums.TaskPriority.Low.Value"
              font-scale="2"
              variant="success"
            >
              <b-icon
                stacked
                icon="chevron-up"
                scale="1.5"
                shift-v="0"
              ></b-icon>
            </b-iconstack>
          </div>
        </template>
        <template #Type="row">
          {{ $t($enums.TaskType[row.item.Type].Text) }}
        </template>
      </fp-table-list>
    </div>
    <fp-form-modal
      v-if="selectedManualTask"
      :title="selectedManualTask.Title"
      v-model="showManualTaskManager"
    >
      <template #content>
        <div>
          {{
            $t("components.taskTable.task", {
              description: selectedManualTask.Description,
            })
          }}
        </div>
        <button
          class="eh-action-button"
          v-if="
            [
              $enums.TaskStatus.InProgress.Value,
              $enums.TaskStatus.Overdue.Value,
            ].includes(selectedManualTask.Status)
          "
          @click="taskComplete(selectedManualTask.TaskId)"
        >
          {{ $t("components.taskTable.taskCompleted") }}
        </button>
        <button
          class="eh-action-button"
          v-else-if="
            [$enums.TaskStatus.Completed.Value].includes(
              selectedManualTask.Status
            )
          "
          @click="uncompleteTask(selectedManualTask.TaskId)"
        >
          {{ $t("components.taskTable.taskUncompleted") }}
        </button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import { UserTaskLogic } from "../../Logic/Backend/user-task";
import moment from "moment";
export default {
  name: "TaskTable",
  data() {
    return {
      showManualTaskManager: false,
      selectedManualTask: null,
      isSelectClear: false,
      filter: null,
      priorFilter: null,
      statFilter: null,
      typeFilter: null,
      startTimeFilter: null,
      deadlineFilter: null,
      filteredTaskList: this.taskList,
    };
  },
  props: {
    taskList: Array,
    taskListFields: Array,
    filterDisabled: Boolean,
  },
  watch: {
    taskList(input) {
      this.filteredTaskList = input;
    },
  },
  methods: {
    filterTaskList() {
      this.filteredTaskList = this.taskList;
      if (this.filter) {
        this.filteredTaskList = this.$filterList(this.filter, this.taskList, [
          "Title",
          "Description",
        ]);
      }
      if (this.priorFilter) {
        this.filteredTaskList = this.filteredTaskList.filter(
          (task) => task.Priority == this.priorFilter
        );
      }
      if (this.statFilter) {
        this.filteredTaskList = this.filteredTaskList.filter(
          (task) => task.Status == this.statFilter
        );
      }
      if (this.typeFilter) {
        this.filteredTaskList = this.filteredTaskList.filter(
          (task) => task.Type == this.typeFilter
        );
      }
      if (this.startTimeFilter) {
        this.filteredTaskList = this.filteredTaskList.filter((task) => {
          if (this.startTimeFilter.from && this.startTimeFilter.to) {
            return moment(task.StartTime).isBetween(
              this.startTimeFilter.from,
              this.startTimeFilter.to,
              undefined,
              "[]"
            );
          } else if (this.startTimeFilter.from) {
            return moment(task.StartTime).isSameOrAfter(
              moment(this.startTimeFilter.from)
            );
          } else if (this.startTimeFilter.to) {
            return moment(task.StartTime).isSameOrBefore(
              moment(this.startTimeFilter.to)
            );
          }
          return true;
        });
      }
      if (this.deadlineFilter) {
        this.filteredTaskList = this.filteredTaskList.filter((task) => {
          if (this.deadlineFilter.from && this.deadlineFilter.to) {
            return moment(task.Deadline).isBetween(
              this.deadlineFilter.from,
              this.deadlineFilter.to,
              undefined,
              "[]"
            );
          } else if (this.deadlineFilter.from) {
            return moment(task.Deadline).isSameOrAfter(
              moment(this.deadlineFilter.from)
            );
          } else if (this.deadlineFilter.to) {
            return moment(task.Deadline).isSameOrBefore(
              moment(this.deadlineFilter.to)
            );
          }
          return true;
        });
      }
    },
    sorting(a, b, key) {
      switch (key) {
        case "Priority": {
          return this.$enums.TaskPriority[a.Priority].Weight <
            this.$enums.TaskPriority[b.Priority].Weight
            ? -1
            : 1;
        }
        case "Status": {
          return this.$enums.TaskStatus[a.Status].Weight <
            this.$enums.TaskStatus[b.Status].Weight
            ? -1
            : 1;
        }
      }
    },
    singleSelectTask(selectedElements) {
      const item = selectedElements[0];
      if (item) {
        const taskEnum = this.$enums.TaskType[item.Type];

        //task típusa manuál?
        if (item.Type == this.$enums.TaskType.DoManual.Value) {
          this.showManualTaskManager = true;
          this.selectedManualTask = item;
        }
        //task folyamatban/lejárt (de pótolható)
        else if (
          taskEnum &&
          ([
            this.$enums.TaskStatus.InProgress.Value,
            this.$enums.TaskStatus.Overdue.Value,
          ].includes(item.Status) ||
            (item.Type == this.$enums.TaskType.FillForm.Value &&
              item.Status != this.$enums.TaskStatus.Added.Value))
        ) {
          //task típusa addtodiary?
          if (item.Type == this.$enums.TaskType.AddToDiary.Value) {
            //a lehetséges route-ok között ki keressük ami kell
            const route = taskEnum.Routes.find((path) =>
              path
                .toLowerCase()
                .includes(item.Parameters.DiaryName.toLowerCase())
            );
            //létezik a keresett route?
            if (route) {
              //átirányítás
              this.$router.push({
                path: route,
              });
            }
          } else {
            //task típusa más, átirányítás paraméterekkel
            this.$router.push({
              path: taskEnum.Route,
              query: { taskParams: JSON.stringify(item.Parameters) },
            });
          }
        } else if (
          [
            this.$enums.TaskStatus.Added.Value,
            this.$enums.TaskStatus.Completed.Value,
            this.$enums.TaskStatus.Failed.Value,
          ].includes(item.Status)
        ) {
          //TODO: szótár
          this.$bvToast.toast(
            "A feladat vagy már el lett végezve, " +
              "vagy határideje még nem kezdődött el, " +
              "vagy lejárt és a feladat nem pótolható. " +
              "Csak Folyamatban vagy Lejárt státuszú feladatot lehet végrehajtani!",
            {
              title: "Nem szerkezthető",
              variant: "warning",
              solid: true,
            }
          );
        }
      }
      this.isSelectClear = true;
    },
    rowClass(item, type) {
      //console.log(type);
      if (!item || type !== "row") {
        return;
      }
      if (
        item.Status === this.$enums.TaskStatus.Overdue.Value ||
        item.Priority === this.$enums.TaskPriority.Urgent.Value
      ) {
        return "table-danger";
      } else if (item.Status === this.$enums.TaskStatus.InProgress.Value) {
        return "table-info";
      } else if (item.Status === this.$enums.TaskStatus.Failed.Value) {
        return "table-warning";
      } else if (item.Status === this.$enums.TaskStatus.Completed.Value) {
        return "table-success";
      }
    },
    async taskComplete(taskId) {
      const taskCompleteResponse = await UserTaskLogic.completeTask(taskId);
      if (!taskCompleteResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.task.successTaskComplete"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
        this.showManualTaskManager = false;
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(taskCompleteResponse.Message, {
          title: this.$t("requestResponse.task.errorTaskComplete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async uncompleteTask(taskId) {
      const taskUncompleteResponse = await UserTaskLogic.uncompleteTask(taskId);
      if (!taskUncompleteResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.task.successTaskUncomplete"),
          {
            title: this.$t("base.basic.cancellation"),
            variant: "info",
            solid: true,
          }
        );
        this.showManualTaskManager = false;
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(taskUncompleteResponse.Message, {
          title: this.$t("requestResponse.task.errorTaskUncomplete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
