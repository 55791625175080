import AppointmentList from "./AppointmentList.vue";
import AddVisitorModal from "./AddVisitorModal.vue";
import AppliedAppointments from "./AppliedAppointments.vue";
import FreeAppointments from "./FreeAppointments.vue";

export default {
  install(Vue) {
    Vue.component("fp-appointment-list", AppointmentList);
    Vue.component("fp-appointment-add-visitor", AddVisitorModal);
    Vue.component("fp-appointment-applied-list", AppliedAppointments);
    Vue.component("fp-appointment-free-list", FreeAppointments);
  },
};
