export default {
  Prescription: {
    EesztPatientTransfer: {
      Ssn: {
        UserProfileKey: "SocialSecurityNumber",
        EesztPatientKey: "PatientIdentifierTypeId",
        EesztPatientEntryId: 8, // Med: 10, N2: 8, N3: 8
      },
      EuSsn: {
        UserProfileKey: "EuSocialSecurityNumber",
        EesztPatientKey: "PatientIdentifierTypeId",
        EesztPatientEntryId: 14, // Med: 16, N2: 14, N3: 14
      },
      GenderFemale: {
        UserProfileKey: "Sex",
        UserProfileId: "Female",
        EesztPatientKey: "GenderId",
        EesztPatientEntryId: 5, //  Med: 6, N2: 5, N3: 5
      },
      GenderMale: {
        UserProfileKey: "Sex",
        UserProfileId: "Male",
        EesztPatientKey: "GenderId",
        EesztPatientEntryId: 4, // Med: 5, N2: 4, N3: 4
      },
    },
    EesztDosageCondition: {
      ByPartOfDay: 1, // Med: 6, N2: 1, N3: 1
      ByFrequence: 2, // Med: 7, N2: 2, N3: 2
      ByText: 3, // Med: 8, N2: 3, N3: 3
    },
    LocalStatus: {
      Recorded: { Value: "Recorded", Text: "Rögzitett" },
      Booked: { Value: "Booked", Text: "Lefoglalt" },
      Dispensed: { Value: "Dispensed", Text: "Kiadott" },
      Reviewed: { Value: "Reviewed", Text: "Ellenőrzött" },
    },
    EesztStatus: {
      NotUploaded: { Value: "NotUploaded", Text: "Nem feltöltött" },
      Open: { Value: "Open", Text: "Nyitott" },
      Booked: { Value: "Booked", Text: "Lefoglalt" },
      Dispensed: { Value: "Dispensed", Text: "Kiadott" },
      Reviewed: { Value: "Reviewed", Text: "Ellenőrzött" },
      Withdrawn: { Value: "Withdrawn", Text: "Visszavont" },
      Expired: { Value: "Expired", Text: "Lejárt" },
      WaitingForCountersign: {
        Value: "WaitingForCountersign",
        Text: "Ellenjegyzésre vár",
      },
    },
    NeakReportStatus: {
      New: { Value: "New", Text: "Új" },
      WaitingForValidation: { Value: "WaitingForValidation", Text: "Érvényesítésre vár" },
      Submitted: { Value: "Submitted", Text: "Beküldve" },
      Processing: { Value: "Processing", Text: "Feldolgozása megkezdve" },
      Accepted: { Value: "Accepted", Text: "Elfogadva" },
      WrongAndMustBeChanged: { Value: "WrongAndMustBeChanged", Text: "Hibás (javítás szükséges)" },
      WrongAndCanBeChanged: { Value: "WrongAndCanBeChanged", Text: "Hibás (tartalmaz kizárt tételeket)" },
      Cancelled: { Value: "Cancelled", Text: "Visszavont (sztornózott)" },
      OutDated: { Value: "OutDated", Text: "Elavult" },
      Recalled: { Value: "Recalled", Text: "Visszahívott" },
      AcknowledgedWrong: { Value: "AcknowledgedWrong", Text: "Nyugtázott (hibás)" },
      AcknowledgedGood: { Value: "AcknowledgedGood", Text: "Nyugtázott (elfogadva)" },
    },
  },
};
