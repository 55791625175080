import Form from "@/Views/Common/Form.vue";

/* Client */
import Client from "@/Views/Client/RouterView.vue";
import WebAppClientHome from "@/Views/Client/Home.vue";
/* Client - Main menu */
import WebAppClientProfile from "@/Views/Client/MainMenu/Profile.vue";
import WebAppClientLegal from "@/Views/Client/MainMenu/Legal.vue";
import WebAppClientNotifications from "@/Views/Client/MainMenu/Notifications.vue";
import WebAppClientDocuments from "@/Views/Client/MainMenu/Documents.vue";
import WebAppClientPrograms from "@/Views/Client/MainMenu/Programs.vue";
import WebAppClientQuestionnaireList from "@/Views/Client/MainMenu/QuestionnaireList.vue";
import WebAppClientTasks from "@/Views/Client/MainMenu/Tasks.vue";
import WebAppClientAppointments from "@/Views/Client/MainMenu/Appointments.vue";
/* Client - Sub menu */
import WebAppClientBadges from "@/Views/Client/SubMenu/Badges.vue";
import WebAppClientDiseaseGuide from "@/Views/Client/SubMenu/DiseaseGuide.vue";
import WebAppClientServiceFinder from "@/Views/Client/SubMenu/ServiceFinder.vue";
/* Client - Module */
import ClientModule from "@/Views/Client/HealthModules/Module.vue";
import ClientMedicinePlan from "@/Views/Client/HealthModules/Module/MedicinePlan.vue";
import ClientHealthGoal from "@/Views/Client/HealthModules/Module/HealthGoal.vue";
/* Client - Diary */
import ClientExerciseDiary from "@/Views/Client/HealthModules/Diary/ExerciseDiary.vue";
import ClientBloodPressureDiary from "@/Views/Client/HealthModules/Diary/BloodPressureDiary.vue";
import ClientEcgDiary from "@/Views/Client/HealthModules/Diary/EcgDiary.vue";
import ClientPulseDiary from "@/Views/Client/HealthModules/Diary/PulseDiary.vue";
import ClientBodyWeightDiary from "@/Views/Client/HealthModules/Diary/BodyWeightDiary.vue";
import ClientMedicineDiary from "@/Views/Client/HealthModules/Diary/MedicineDiary.vue";
import ClientOxygenSaturationDiary from "@/Views/Client/HealthModules/Diary/OxygenSaturationDiary.vue";
import ClientMoodDiary from "@/Views/Client/HealthModules/Diary/MoodDiary.vue";
import ClientSleepDiary from "@/Views/Client/HealthModules/Diary/SleepDiary.vue";
import ClientBloodGlucoseDiary from "@/Views/Client/HealthModules/Diary/BloodGlucoseDiary.vue";
import ClientInsulinDiary from "@/Views/Client/HealthModules/Diary/InsulinDiary.vue";
import ClientNutritionDiary from "@/Views/Client/HealthModules/Diary/NutritionDiary.vue";
/* Client - Settings */
import ModuleSettings from "@/Views/Client/HealthModules/Setting/ModuleSettings.vue";
import FunctionSettings from "@/Views/Client/HealthModules/Setting/FunctionSettings.vue";

const HealthModulesModuleRoutes = [
  {
    path: "HealthGoal",
    name: "HealthGoal",
    components: {
      client: ClientHealthGoal,
    },
    props: {
      client: true,
    },
  },
  {
    path: "MedicinePlan",
    name: "ClientMedicinePlan",
    components: {
      client: ClientMedicinePlan,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Module/:openedModuleName",
    name: "ClientModule",
    components: {
      client: ClientModule,
    },
    props: {
      client: true,
    },
  },
];

const HealthModulesDiaryRoutes = [
  {
    path: "ExerciseDiary",
    name: "ClientExerciseDiary",
    components: {
      client: ClientExerciseDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "EcgDiary",
    name: "ClientEcgDiary",
    components: {
      client: ClientEcgDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "PulseDiary",
    name: "ClientPulseDiary",
    components: {
      client: ClientPulseDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "BloodPressureDiary",
    name: "ClientBloodPressureDiary",
    components: {
      client: ClientBloodPressureDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "MedicineDiary",
    name: "ClientMedicineDiary",
    components: {
      client: ClientMedicineDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "BodyWeightDiary",
    name: "ClientBodyWeightDiary",
    components: {
      client: ClientBodyWeightDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "OxygenSaturationDiary",
    name: "ClientOxygenSaturationDiary",
    components: {
      client: ClientOxygenSaturationDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "MoodDiary",
    name: "ClientMoodDiary",
    components: {
      client: ClientMoodDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "SleepDiary",
    name: "ClientSleepDiary",
    components: {
      client: ClientSleepDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "InsulinDiary",
    name: "ClientInsulinDiary",
    components: {
      client: ClientInsulinDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "BloodGlucoseDiary",
    name: "ClientBloodGlucoseDiary",
    components: {
      client: ClientBloodGlucoseDiary,
    },
    props: {
      client: true,
    },
  },
  {
    path: "NutritionDiary",
    name: "ClientNutritionDiary",
    components: {
      client: ClientNutritionDiary,
    },
    props: {
      client: true,
    },
  },
];

const HealthModulesSettingRoutes = [
  {
    path: "ModuleSettings",
    name: "ModuleSettings",
    components: {
      client: ModuleSettings,
    },
    props: {
      client: true,
    },
  },
  {
    path: "FunctionSettings",
    name: "FunctionSettings",
    components: {
      client: FunctionSettings,
    },
    props: {
      client: true,
    },
  },
];

const MainMenuRoutes = [
  {
    path: "Home",
    name: "WebAppClientHome",
    components: {
      client: WebAppClientHome,
    },
  },
  {
    path: "",
    name: "WebAppClientHome",
    components: {
      client: WebAppClientHome,
    },
  },
  {
    path: "Profile",
    name: "WebAppClientProfile",
    components: {
      client: WebAppClientProfile,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Legal",
    name: "WebAppClientLegal",
    components: {
      client: WebAppClientLegal,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Notifications",
    name: "WebAppClientNotifications",
    components: {
      client: WebAppClientNotifications,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Appointments",
    name: "WebAppClientAppointments",
    components: {
      client: WebAppClientAppointments,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Programs",
    name: "WebAppClientPrograms",
    components: {
      client: WebAppClientPrograms,
    },
    props: {
      client: true,
    },
  },
  {
    path: "Documents",
    name: "WebAppClientDocuments",
    components: {
      client: WebAppClientDocuments,
    },
    props: {
      client: true,
    },
  },
  {
    path: "QuestionnaireList",
    name: "WebAppClientQuestionnaireList",
    components: {
      client: WebAppClientQuestionnaireList,
    },
    props: {
      client: true,
    },
  },

  {
    path: "Tasks",
    name: "WebAppClientTasks",
    components: {
      client: WebAppClientTasks,
    },
    props: {
      client: true,
    },
  },
];

const SubMenuRoutes = [
  {
    path: "Badges",
    name: "WebAppClientBadges",
    components: {
      client: WebAppClientBadges,
    },
    props: {
      client: true,
    },
  },

  {
    path: "DiseaseGuide",
    name: "WebAppClientDiseaseGuide",
    components: {
      client: WebAppClientDiseaseGuide,
    },
    props: {
      client: true,
    },
  },

  {
    path: "ServiceFinder",
    name: "WebAppClientServiceFinder",
    components: {
      client: WebAppClientServiceFinder,
    },
    props: {
      client: true,
    },
  },
]

const CommonRoutes = [
  {
    path: "Form",
    name: "WebAppClientQuestionnaire",
    components: {
      client: Form,
    },
    props: {
      client: true,
    },
  },
]

export default [
  {
    path: "/Client",
    name: "Client",
    component: Client,
    props: true,
    children: [
      ...HealthModulesModuleRoutes,
      ...HealthModulesDiaryRoutes,
      ...HealthModulesSettingRoutes,
      ...MainMenuRoutes,
      ...SubMenuRoutes,
      ...CommonRoutes
    ],
    meta: ["Client", "Administrator", "Technical"],
  },
];
