<template>
  <fp-form-modal
    size="xl"
    title="Finanszírozás rögzítése"
    v-model="visible"
    v-if="finance"
    :closable="false"
  >
    <template #content>
      <fp-select
        label="Sűrgősségi recept felírás (CITO)?"
        :required="true"
        v-model="finance.ExpressPrescription"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
        :state="!validation.hasError('finance.ExpressPrescription')"
        :error="validation.firstError('finance.ExpressPrescription')"
      />
      <fp-panel-card
        style="margin-top: 10px"
        title="Felíró orvos főbb adatai"
        :required="true"
      >
        <template #content>
          <b-row cols="1" cols-md="2">
            <b-col>
              <div>
                <fp-input
                  label="Felíró orvos pecsétszáma"
                  :required="true"
                  v-model="finance.DoctorStampNumber"
                  :state="!validation.hasError('finance.DoctorStampNumber')"
                  :error="validation.firstError('finance.DoctorStampNumber')"
                />
              </div>
            </b-col>
            <b-col>
              <div>
                <fp-input
                  :required="true"
                  label="Felíró orvos szervezeti egység NNK azonosítója"
                  v-model="finance.DoctorPraxisOrganizationCode"
                  :state="
                    !validation.hasError('finance.DoctorPraxisOrganizationCode')
                  "
                  :error="
                    validation.firstError(
                      'finance.DoctorPraxisOrganizationCode'
                    )
                  "
                />
              </div>
            </b-col>
          </b-row>
        </template>
      </fp-panel-card>
      <fp-panel-card
        style="margin-top: 10px"
        title="Páciens főbb adatai"
        :required="true"
      >
        <template #content>
          <b-row cols="1" cols-md="2">
            <b-col>
              <fp-input
                label="Páciens azonosító"
                :required="true"
                v-model="finance.PatientSsn"
                :state="!validation.hasError('finance.PatientSsn')"
                :error="validation.firstError('finance.PatientSsn')"
              />
            </b-col>
            <b-col>
              <fp-select
                label="Páciens azonosító típusa"
                :required="true"
                v-model="finance.PatientDocumentCode"
                :state="!validation.hasError('finance.PatientDocumentCode')"
                :error="validation.firstError('finance.PatientDocumentCode')"
                :items="neakDocumentCodeList"
                valueKey="Code"
                textKey="Name"
              />
            </b-col>
            <b-col>
              <fp-select
                :required="true"
                label="Páciens állampolgársága"
                v-model="finance.PatientCitizenshipCode"
                :state="!validation.hasError('finance.PatientCitizenshipCode')"
                :error="validation.firstError('finance.PatientCitizenshipCode')"
                :items="eesztCountryList"
                valueKey="Code"
                textKey="CitizenshipName"
                :searchable="true"
                @search="searchCountry"
              />
            </b-col>
            <b-col>
              <fp-date-picker
                :disabled="!!dispensedPatient.BirthDate"
                :state="!validation.hasError('finance.BirthDate')"
                :error="validation.firstError('finance.BirthDate')"
                label="Páciens születési dátuma"
                :required="true"
                v-model="finance.PatientBirthDate"
              />
            </b-col>
          </b-row>
        </template>
      </fp-panel-card>
      <fp-panel-card
        style="margin-top: 10px"
        title="Kiváltott termékekhez tartozó finanszírozási adatok"
        :required="true"
      >
        <template #content>
          <fp-panel-card
            style="margin: 20px 0px"
            v-for="(product, index) in finance.Products"
            :key="'new-finance-product-' + index"
            :title="
              dispenseProductsForFinance[index].Ttt
                ? dispenseProductsForFinance[index].Ttt.Name +
                  ' (TTT: ' +
                  dispenseProductsForFinance[index].Ttt.Code +
                  ')'
                : index + 1 + '. termékhez tartozó adatok'
            "
            :closedCard="index != 0"
          >
            <template #content>
              <finance-product
                ref="financeProduct"
                v-model="finance.Products[index]"
                :dispenseProduct="dispenseProductsForFinance[index]"
                :prescriptionProduct="
                  getPrescriptionData(dispenseProductsForFinance[index])
                "
              />
            </template>
          </fp-panel-card>
        </template>
      </fp-panel-card>
      <b-button @click="saveFinanceData">Mentés</b-button>
      <b-button @click="visible = false">Mégsem</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { BaseDataLogic } from "../../../Logic/Backend/base-data";
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";
import FinanceProduct from "../Components/create/FinanceProduct.vue";
export default {
  components: { FinanceProduct },
  name: "NewFinanceDataForm",
  model: {
    prop: "modalVisibile",
    event: "change",
  },
  props: {
    modalVisibile: Boolean,
    dispenseProducts: Array,
    prescriptionProducts: Array,
    prescribingDoctor: Object,
    dispensedPatient: Object,
    prescriptionId: Number,
  },
  data() {
    return {
      visible: this.modalVisibile,
      productsCount: 0,
      finance: null,
      dispenseProductsForFinance: null,
      defaultFinance: {
        ExpressPrescription: false,
        Products: [null],
        DoctorPraxisOrganizationCode: null,
        DoctorStampNumber: null,
        PatientCitizenshipCode: null,
        PatientSsn: null,
        PatientDocumentCode: null,
        PatientBirthDate: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      neakDocumentCodeList: null,
      eesztCountryList: null,
    };
  },
  watch: {
    async modalVisibile(input) {
      this.visible = input;
      if (input) {
        await this.getLists();
        this.setNewFinanceDataData();
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "finance.DoctorPraxisOrganizationCode": function (value) {
      return this.$validator
        .value(value)
        .required("Kötelező mező!")
        .length(
          9,
          "Az érték 9 karakterből kell állnia! Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        );
    },
    "finance.DoctorStampNumber": function (value) {
      return this.$validator
        .value(value)
        .required("Kötelező mező!")
        .maxLength(
          9,
          "Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        )
        .minLength(
          5,
          "Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        );
    },
    "finance.PatientCitizenshipCode": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.ExpressPrescription": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientSsn": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientDocumentCode": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientBirthDate": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    getPrescriptionData(dispense) {
      const perscriptionProd = this.prescriptionProducts.find(
        (p) => p.UniqueId == dispense.PrescriptionProductUniqueId
      );
      if (perscriptionProd) {
        return perscriptionProd;
      } else {
        return null;
      }
    },
    async saveFinanceData() {
      const valid = await this.$validate();
      const validProds = await Promise.all(
        this.$refs["financeProduct"].map((p) => {
          return p.checkValidator();
        })
      );
      if (!validProds.some((x) => !x) && valid) {
        const saveResponse = await MedicalDeviceLogic.createFinance(
          this.finance,
          this.prescriptionId
        );
        if (!saveResponse.Code) {
          this.visible = false;
          this.$emit("refreshList");
          this.$bvToast.toast(
            "Sikeresen elmentette a vény jelentéshz szükséges adataokat.",
            {
              title: "Sikeres mentés!",
              variant: "success",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(saveResponse.Message, {
            title: "Hiba történt a mentés során!",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(
          "Nézze át az űrlapot, hogy minden adat helyesen lett kitöltve! A hiányzó vagy hibás mezők piros kerettel jelennek meg.",
          {
            title: "Hibás mező kitöltés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async searchCountry(input) {
      var list;
      if (input.length > 3) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { ["CitizenshipName-contains"]: input },
          "CitizenshipName",
          "string"
        );
      } else if (input.length == 0) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { "-orderby": "CitizenshipName", ["-limit"]: 10 },
          "CitizenshipName",
          "string"
        );
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.dispensedPatient.Nationality) {
        selectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.dispensedPatient.Nationality.Id,
          }
        );
      }

      this.eesztCountryList = this.$checkDuplicates(
        [...list, ...selectedValue],
        "EntryId"
      );
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        Code: null,
      });
    },
    async getLists() {
      const eesztCountryDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_citizenship.Value,
        { "-orderby": "CitizenshipName", ["-limit"]: 10 },
        "CitizenshipName",
        "string"
      );
      var eesztCountryListSelectedValue = [];
      if (this.dispensedPatient.Nationality) {
        eesztCountryListSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.dispensedPatient.Nationality.Id,
          }
        );
      }

      const hun = await this.getBase(
        this.$enums.BaseName.eeszt_citizenship.Value,
        {
          Code: 348,
        }
      );

      this.eesztCountryList = this.$checkDuplicates(
        [...eesztCountryDefaultList, ...eesztCountryListSelectedValue, ...hun],
        "EntryId"
      );
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        Code: null,
      });

      this.neakDocumentCodeList = await this.getBase(
        this.$enums.BaseName.neak_document_code.Value,
        this.validDateFilterParams,
        "Code",
        "number"
      );
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            Code: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    setNewFinanceDataData() {
      this.finance = JSON.parse(JSON.stringify(this.defaultFinance));
      const dispenseProductsWithSubCat = this.dispenseProducts.filter(
        (dp) => dp.SubsidizationCategory
      );
      this.dispenseProductsForFinance = dispenseProductsWithSubCat;
      this.finance.Products.length = dispenseProductsWithSubCat.length;
      if (this.prescribingDoctor) {
        this.finance.DoctorPraxisOrganizationCode = this.prescribingDoctor.OrganizationUnitNnkId;
        const docStamp = this.prescribingDoctor.StampNumber;
        const docEnkk = this.prescribingDoctor.EnkkId;
        if (
          docStamp &&
          (docStamp.length == 5 || docStamp.length == 7 || docStamp.length == 8)
        ) {
          this.finance.DoctorStampNumber = docStamp;
        } else if (
          docEnkk &&
          (docEnkk.length == 5 || docEnkk.length == 7 || docEnkk.length == 8)
        ) {
          this.finance.DoctorStampNumber = docEnkk;
        }
      }
      if (this.dispensedPatient) {
        this.finance.PatientCitizenshipCode = this.dispensedPatient.Nationality
          ? this.dispensedPatient.Nationality.Code
          : 348;
        this.finance.PatientSsn = this.dispensedPatient.PatientIdentifierValue;
        this.finance.PatientBirthDate = this.dispensedPatient.BirthDate;
        const patientIdType = this.dispensedPatient.PatientIdentifierType
          ? this.dispensedPatient.PatientIdentifierType.Code
          : null;
        if (patientIdType) {
          if (patientIdType == "1") {
            //TAJ
            this.finance.PatientDocumentCode = "01";
          } else if (patientIdType == "8") {
            //EU
            this.finance.PatientDocumentCode = "04";
          } else if (patientIdType == "5") {
            //menekült ig. szám
            this.finance.PatientDocumentCode = "99";
          }
        }
      }
    },
  },
  async mounted() {
    if (this.modalVisibile) {
      await this.getLists();
      this.setNewFinanceDataData();
    }
  },
};
</script>
