<template>
  <div class="page-background">
    <div>
      <div style="text-align: center" class="diary-title">
        {{ $t("module.diaries.oxygenSaturation.post") }}
      </div>
      <div style="margin: 20px 0px">
        <button class="eh-action-button" @click="goBack">
          {{ $t("base.basic.back") }}
        </button>
        <button
          class="eh-action-button"
          @click="showOxygenSaturationDiaryInputModal = true"
        >
          {{ $t("module.diaries.newPost") }}
        </button>
      </div>

      <fp-client-module-diary baseName="oxygen_saturation" v-model="refreshList" />
      <fp-oxygensaturation-diary-input
        @refreshList="refreshList = true"
        v-model="showOxygenSaturationDiaryInputModal"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "OxygenSaturationDiary",
  data() {
    return {
      refreshList: false,
      showOxygenSaturationDiaryInputModal: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.$emit(
      "title-changed",
      this.$t("module.diaries.oxygenSaturation.title")
    );
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
