<template>
  <div class="eh-menu-content">
    <div class="eh-flex-container">
      <fp-input
        class="eh-specialist-appointments-filter"
        :label="$t('base.basic.filter')"
        :description="$t('healthProfessional.appointment.filterDescription')"
        @change="filterList"
        v-model="filter"
      />
      <fp-date-range-button
        :label="$t('healthProfessional.appointment.date')"
        v-model="dateFilter"
        @change="dateFilterList"
      />
    </div>
    <fp-appointment-list
      :items="filteredAppointments"
      itemsOrderBy="StartTime"
      @refreshList="getAppointmentList"
      @nextStackLoad="getNextStackLoad"
      @addVisitor="startAddVisitor"
    />
    <!-- Időpontra feliratkoztatás -->
    <fp-appointment-add-visitor
      v-model="showAddNewVisitorModal"
      :selectedAppointmentId="
        selectedAppointment ? selectedAppointment.AppointmentId : null
      "
      @refreshList="getAppointmentList"
    />
  </div>
</template>
<script>
import { AppointmentLogic } from "@/Logic/Backend/appointment";
import moment from "moment";

export default {
  name: "SpecialistAppointments",
  data() {
    return {
      appointments: null,
      selectedAppointment: null,
      filteredAppointments: null,
      now: moment(),
      dateFilter: { from: moment().format("YYYY-MM-DD"), to: null },
      filter: null,
      showAddNewVisitorModal: false,
    };
  },
  methods: {
    filterList() {
      this.filteredAppointments = this.appointments;
      if (this.filter) {
        this.filteredAppointments = this.$filterList(
          this.filter,
          this.appointments,
          ["Title", "Description", "MaxVisitorCount"]
        );
      }
    },
    async dateFilterList() {
      this.appointments = this.filteredAppointments =
        await this.getAppointments({
          "StartTime-from":
            this.dateFilter && this.dateFilter.from
              ? this.dateFilter.from
              : null,
          "StartTime-to":
            this.dateFilter && this.dateFilter.to ? this.dateFilter.to : null,
          "-orderby": "StartTime",
          "-limit": 101,
        });
      this.filterList();
    },
    isInProgress(start, end) {
      return moment(start).isBefore(this.now) && moment(end).isAfter(this.now);
    },
    async getAppointmentList() {
      this.appointments = this.filteredAppointments =
        await this.getAppointments({
          "StartTime-from": this.dateFilter ? this.dateFilter.from : null,
          "-orderby": "StartTime",
          "-limit": 101,
        });
      this.filterList();
    },
    async getAppointments(params) {
      const getAppointmentsResponse = await AppointmentLogic.getAppointments({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
        ...params,
      });
      if (!getAppointmentsResponse.Code) {
        return getAppointmentsResponse.filter(
          (appoint) =>
            appoint.PersonnelIds.includes(this.$loggedUser.UserId) ||
            appoint.OwnerId == this.$loggedUser.UserId
        );
      } else {
        this.$bvToast.toast(getAppointmentsResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.appointment.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getNextStackLoad() {
      //ismétlődés nélküli léptetés, időpont kihagyás nélkül
      const temp = await this.getAppointments({
        "StartTime-from":
          this.appointments[this.appointments.length - 1].StartTime,
        "-orderby": "StartTime",
        "-limit": 101,
      });
      const duplicatableE = this.appointments.filter(
        (a) =>
          a.StartTime ==
          this.appointments[this.appointments.length - 1].StartTime
      );
      const deduplicateTemp = temp.filter(
        (t) => !duplicatableE.some((e) => e.AppointmentId == t.AppointmentId)
      );
      this.appointments = this.filteredAppointments =
        this.appointments.concat(deduplicateTemp);
      this.filterList();
    },
    startAddVisitor(appointment) {
      this.showAddNewVisitorModal = true;
      this.selectedAppointment = appointment;
    },
  },
  async mounted() {
    await this.getAppointmentList();
    this.$emit(
      "title-changed",
      this.$t("healthProfessional.menu.appointments")
    );
  },
};
</script>
