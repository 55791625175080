/* Admin */
import Admin from "@/Views/Admin/RouterView.vue";
import WebAppAdminHome from "@/Views/Admin/Home.vue";
/* Admin - Main menu */
import WebAppAdminProfile from "@/Views/Admin/main-menu/Profile.vue";
import WebAppAdminProjectRole from "@/Views/Admin/main-menu/ProjectRole.vue";
import WebAppAdminNewProject from "@/Views/Admin/main-menu/NewProject";
import WebAppAdminProject from "@/Views/Admin/main-menu/Project";
/* Admin - Sub menu */
import WebAppAdminNewUser from "@/Views/Admin/sub-menu/NewUser.vue";
import WebAppAdminUserManager from "@/Views/Admin/sub-menu/UserManager.vue";

export default [
  {
    path: "/Admin",
    name: "Admin",
    component: Admin,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Home",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Profile",
        name: "WebAppAdminProfile",
        components: {
          admin: WebAppAdminProfile,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "ProjectRole",
        name: "WebAppAdminProjectRole",
        components: {
          admin: WebAppAdminProjectRole,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewUser",
        name: "WebAppAdminNewUser",
        components: {
          admin: WebAppAdminNewUser,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "UserManager",
        name: "WebAppAdminUserManager",
        components: {
          admin: WebAppAdminUserManager,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewProject",
        name: "WebAppAdminNewProject",
        components: {
          admin: WebAppAdminNewProject,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "Project",
        name: "WebAppAdminProject",
        components: {
          admin: WebAppAdminProject,
        },
        props: {
          admin: true,
        },
      },
    ],
    meta: ["Administrator"],
  },
];
