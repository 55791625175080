<template>
  <div>
    <component
      :is="pageModuleName"
      :enabledProjectFunctions="enabledProjectFunctions"
    />
  </div>
</template>
<script>
import BodyWeight from "./Module/BodyWeightModule.vue";
import Cardiology from "./Module/CardiologyModule.vue";
import Pulmonology from "./Module/PulmonologyModule.vue";
import Depression from "./Module/DepressionModule.vue";
import Diabetology from "./Module/DiabetologyModule.vue";
import Exercise from "./Module/ExerciseModule.vue";
import Medicine from "./Module/MedicineModule.vue";
import Nutrition from "./Module/NutritionModule.vue";
import Other from "./Module/OtherModule.vue";

export default {
  name: "Module",
  props: {
    enabledProjectModules: Array,
    enabledProjectFunctions: Array,
  },
  data() {
    return {
      pageModuleName: null,
      modules: {
        BodyWeight: BodyWeight,
        Cardiology: Cardiology,
        Pulmonology: Pulmonology,
        Depression: Depression,
        Diabetology: Diabetology,
        Exercise: Exercise,
        Medicine: Medicine,
        Nutrition: Nutrition,
        Other: Other,
      },
    };
  },
  watch: {
    enabledProjectModules() {
      this.modulePageLoad();
    },
  },
  methods: {
    modulePageLoad() {
      const openModuleName = this.$route.params.openedModuleName;

      if (
        openModuleName &&
        Object.keys(this.modules).includes(openModuleName) &&
        (!this.enabledProjectModules ||
          this.enabledProjectModules.includes(openModuleName))
      ) {
        this.pageModuleName = this.modules[openModuleName];
      } else {
        this.$router.replace({ name: "WebAppClientHome" });
      }
    },
  },
  mounted() {
    this.modulePageLoad();
  },
};
</script>