<template>
  <div>
    <div
      @click="isOpen = !isOpen"
      class="eh-tag-manager-header"
      :class="isOpen ? 'eh-tag-manager-open' : ''"
    >
      {{ $t("partner.participantList.tag.tagManager")
      }}<b-icon
        class="eh-tag-manager-header-icon"
        :icon="isOpen ? 'chevron-down' : 'chevron-up'"
      ></b-icon>
    </div>
    <div v-if="isOpen" class="eh-tag-manager-content">
      <b-icon
        @click="showNewTagForm = true"
        scale="2"
        icon="plus"
        class="eh-tag-manager-content-plus"
      />
      <!-- Saját címkék -->
      <div>
        <div>
          {{ $t("Tag.PersonalTagType") }}:<b-icon
            v-b-tooltip.hover="$t('partner.participantList.tag.tagInfo')"
            scale="0.8"
            style="vertical-align: top"
            icon="info-circle"
          />
        </div>
        <div
          style="width: 100%"
          v-if="
            personalTagList &&
            personalTagList.length &&
            personalTagList.length > 0
          "
        >
          <div style="display: flex; flex-wrap: wrap">
            <div v-for="tag in personalTagList" :key="tag.TagId">
              <tag
                :tag="tag"
                @getList="getTagListByType"
                :editable="tag.OwnerId == $loggedUser.UserId"
              />
            </div>
          </div>
        </div>
        <div v-else>{{ $t("partner.participantList.tag.noTags") }}</div>
      </div>
      <!-- Projekt címkék -->
      <div v-if="hasProjectTagList">
        <div>
          {{ $t("Tag.ProjectTagType") }}:<b-icon
            v-b-tooltip.hover="$t('partner.participantList.tag.tagInfo')"
            scale="0.8"
            style="vertical-align: top"
            icon="info-circle"
          />
        </div>
        <div
          style="width: 100%"
          v-if="
            projectTagList && projectTagList.length && projectTagList.length > 0
          "
        >
          <div style="display: flex; flex-wrap: wrap">
            <div v-for="tag in projectTagList" :key="tag.TagId">
              <tag
                :tag="tag"
                @getList="getTagListByType"
                :editable="tag.OwnerId == $loggedUser.UserId"
              />
            </div>
          </div>
        </div>
        <div v-else>{{ $t("partner.participantList.tag.noTags") }}</div>
      </div>
    </div>
    <new-tag-form
      @getList="getTagListByType"
      v-model="showNewTagForm"
      :projectId="project ? project.ProjectId : null"
      :defaultNewTagType="defaultNewTagType"
    />
  </div>
</template>
<script>
import { TagLogic } from "../../Logic/Backend/tag";
import NewTagForm from "./NewTagForm.vue";
import Tag from "./Tag.vue";
export default {
  components: { Tag, NewTagForm },
  name: "TagManager",
  data() {
    return {
      isOpen: false,
      showNewTagForm: false,
      personalTagList: null,
      projectTagList: null,
      ownTagList: null,
    };
  },
  props: {
    project: Object,
    tagListType: String,
    defaultNewTagType: String,
  },
  computed: {
    hasProjectTagList() {
      return (
        this.tagListType == this.$enums.Tag.TagListType.Both.Value ||
        this.tagListType == this.$enums.Tag.TagListType.Project.Value
      );
    },
    hasOwnTagList() {
      return (
        this.tagListType == this.$enums.Tag.TagListType.Both.Value ||
        this.tagListType == this.$enums.Tag.TagListType.Own.Value
      );
    },
  },
  methods: {
    async getTagByParams(params) {
      const getResponse = await TagLogic.getTags(params);
      if (!getResponse.Code) {
        return getResponse
      } else {
        //TODO: hiba
        return null;
      }
    },
    async getTagListByType() {
      var ownTags = [];
      var projectTags = [];
      if (this.hasProjectTagList && this.project) {
        const project = await this.getTagByParams({ Type: "ProjectTag" });
        if (project) {
          projectTags = project.filter((t) =>
          this.project.Items.find(
            (i) => i.EntityTypeName == "FpTag" && i.EntityId == t.TagId
          ));
        }
      }
      if (this.hasOwnTagList) {
        const own = await this.getTagByParams({
          Type: "PatientTag",
          OwnerId: this.$loggedUser.UserId,
        });
        if (own) {
          ownTags = own;
        }
      }
      this.projectTagList = projectTags;
      this.personalTagList = ownTags;
    },
  },
  async mounted() {
    this.getTagListByType();
  },
};
</script>
