export const DeModule = {
  module: {
    charts: {
      BloodGlucose: "Blutzuckerspiegel",
      bloodGlucoseMax: "Höchster Blutzucker",
      bloodGlucoseMin: "Niedrigster Blutzucker",
      BloodPressure: "Blutdruck",
      BMI: "Täglicher BMI",
      BodyWeight: "Körpergewicht",
      CalorieIntake: "Kalorienaufnahme",
      collectedMedicines: "Erworbene Medikamente",
      default: "Unbestimmt",
      diastolicMax: "Höchster diastolischer Blutdruck",
      diastolicMin: "Niedrigster diastolischer Blutdruck",
      distance: "Entfernung [m]",
      ExerciseCalories: "Während der Übungen verbrannte Kalorien ",
      ExerciseDistance: "Zurückgelegte Entfernung",
      ExerciseDuration: "Für die Übungen in Anspruch genommene Zeit ",
      ExerciseFloors: "Anzahl der bewältigten Stockwerke",
      ExerciseSteps: "Anzahl der zurückgelegten Schritte",
      floor: " Stockwerk",
      floorsCount: "Anzahl der Stockwerke",
      givenInsulin: "Injizierte Insulineinheit",
      Insulin: "Insulin verabreicht",
      kcal: "Kalorien [kcal]",
      Medicine: "Medikationstagebuch",
      MedicineDaily: "Tägliche Medikamentenzusammenstellung",
      MedicineMonthly: "Monatliche Medikamentenzusammenstellung",
      MedicineWeekly: "Wöchentliche  Medikamentenzusammenstellung",
      missedMedicines: "Nicht eingenommene Medikamente",
      Mood: "Stimmung",
      moodValue: "Stimmungswert",
      OxygenSaturation: "Blutsauerstoffsättigung",
      Pulse: "Puls",
      Respiration : "Atmung",
      Sleep: "Schlafen",
      sleepQuality: "Schlafvolumen",
      step: " Schritt",
      steptsCount: "Anzahl der Schritte",
      systolicMax: "Höchster systolischer Blutdruck",
      systolicMin: "Niedrigster systolischer Blutdruck",
      unit: "Einheit",
      weight: "Körpergewicht [kg]",
      weightFat: "Dickleibig",
      weightNormal: "Normal",
      weightOverweight: "Übergewicht",
      weightThin: "Schlank",
      weightVeryFat: "Sehr dickleibig",
    },
    diaries: {
      addTimestamp: "Datum hinzugefügt",
      bloodGlucose: {
        addNewPost: "Neuen Blutzuckereintrag hinzufügen",
        bloodGlucoseValue: "Blutzuckerspiegel",
        mealQuantity: "Essensmenge",
        mealRelation: "Essensverhältnis",
        post: "Blutzuckereinträge",
        title: "Blutzuckertagebuch - Diabetologie",
        value: "Menge",
        valueDescription: "Geben Sie den Wert in [mmol / l] ein.",
      },
      bloodPressure: {
        addNewPost: "Neuer Eintrag Blutdruck",
        category: "Kategorie",
        diastole: "Diastole",
        diastoleMeasure: "Gemessene Diastole",
        diastolePlaceholder: "Diastole [hgmm]",
        post: "Einträge zum Blutdruck",
        pulse: "Gemessene Herzfrequenz",
        pulsePlaceholder: "Herzfrequenz [Schläge pro Minute]",
        systole: "Systole",
        systoleMeasure: "Gemessene Systole",
        systolePlaceholder: "Systole [hgmm]",
        title: "Blutdruck-Tagebuch - Kardiologie",
      },
      bodyWeight: {
        addNewPost: "Neuer Gewichtseintrag hizugefügt",
        bmiCategory: "BMI-Kategorie",
        bmiScroe: "BMI-Wert",
        fat: "Fettanteil",
        fatPercentage: "Prozent Körperfett",
        height: "Größe",
        heightPlaceholder: "Meter [m]",
        muscle: "Muskelanteil",
        musclePercentage: "Prozent Muskelmasse",
        percentage: "Prozent [%]",
        post: "Gewichtseinträge",
        title: "Gewichtstagebuch - Körpergewicht",
        water: "Wasseranteil",
        waterPercentage: "Prozent Wasser im Körper",
        weight: "Körpergewicht",
        weightPlaceholder: "Kilogramm [kg]",
      },
      ecg: {
        title: "EKG-Tagebuch - Kardiologie",
      },
      exercise: {
        addNewPost: "Neuen Bewegungseintrag hinzufügen",
        burnedCalories: "Verbrauchte Kalorien",
        burnedCaloriesDescription:
          "Wenn nicht angegeben, wird es automatisch berechnet.",
        burnedCaloriesPlaceholder: "Kalorienwert [kcal]",
        distance: "Entfernung",
        distanceDescription: "Entfernung (falls relevant)",
        distancePlaceholder: "Entfernung [m]",
        duration: "Dauer der Bewegung (Stunden: Minuten)",
        exerciseType: "Bewegungsart",
        floorCount: "bewältigte Etagen",
        floors: "Anzahl der bewältigten Stockwerke",
        floorsDescription:
          "Anzahl der bewältigten Stockwerke während der Bewegung (falls relevant). Mit Etage ist eine vertikale Strecke von ca. 3 m oder 16 Stufen gemeint.",
        floorsPlaceholder: "Anzahl der Stockwerke",
        intensity: "Bewegungsintensität",
        post: "Eintrag in Bewegungstagebuch",
        startDate: "Zeitlicher Beginn der Bewegung",
        stepCount: "Getätigte Schritte",
        steps: "Anzahl der getätigten Schritte",
        stepsDescription:
          "Anzahl der getätigten Schritte während der Bewegung (falls relevant)",
        stepsPlaceholder: "Anzahl der Schritte",
        title: "Tagebuch körperlicher Übungen - Übung",
        typeDefault: "Bitte eine Bewegungsart wählen.",
      },
      insulin: {
        addNewPost: "Neuen Insulineintrag hinzufügen",
        date: "Datum der Einreichung",
        insulinValue: "Insulin verabreicht",
        mealRelation: "Essensverhältnis",
        post: "Insulineinträge",
        title: "Insulintagebuch - Diabetologie",
      },
      measureDate: "Messdatum",
      medicine: {
        addNewPost: "Neues Medikament hinzufügen",
        logging: "Sammelprotokoll",
        medicine: "Medizin",
        medicineName: "Bezeichnung des Medikaments",
        post: "Medikamenteneinträge",
        takenCount: "Erfasste Menge",
        takenDate: "Abholtermin",
        title: "Medikamententagebuch - Medikament",
      },
      mood: {
        addNewPost: "Fügen Sie einen neuen Stimmungseintrag hinzu.",
        balanceLevel: "Balance",
        balanceLevelInput: "Wie ausgeglichen fühlen Sie sich?",
        balanceLevelInputDescription:
          "Bewerten Sie auf einer Skala von 1 (überhaupt nicht) bis 5 (voll und ganz).",
        energyLevel: "Energieniveau",
        energyLevelInput: "Wie hoch ist Ihr Energieniveau?",
        energyLevelInputDescription:
          "Bewerten Sie auf einer Skala von 1 (Niedrig) bis 5 (Hoch).",
        moodValue: "Stimmungswert",
        moodValueInput: "Wie fühlen Sie sich?",
        moodValueInputDescription:
          "Bewerten Sie es auf einer Skala von 1 (schlecht) bis 5 (ausgezeichnet).",
        post: "Stimmungseinträge",
        stressLevel: "Stress",
        stressLevelInput: "Wie gut können Sie mit Stress umgehen?",
        stressLevelInputDescription:
          "Bewerten Sie auf einer Skala von 1 (überhaupt nicht) bis 5 (voll und ganz).",
        title: "Stimmungstagebuch - Depression",
      },
      newPost: "Neuer Eintrag",
      nutrition: {
        addNewPost: "Füge einen neuen Ernährungseintrag hinzu.",
        itemAmount: "Menge",
        itemCalories: "Kalorien (optional)",
        itemName: "Name des Lebensmittels",
        items: "Lebensmittel",
        itemUnit: "Maßeinheit",
        mealName: "Tagesabschnitt",
        post: "Nährwerteinträge",
        remark: "Kommentar",
        title: "Ernährungstagebuch - Ernährung",
      },
      oxygenSaturation: {
        addNewPost: "Fügen Sie einen neuen Blutdruckeintrag hinzu",
        circumstances: "Messbedingungen",
        oxygenSaturationType: "Messbedingung",
        post: "Einträge Blutsauerstoff",
        pulseValue: "Gemessene Herzfrequenz",
        pulseValuePlaceholder: "Herzfrequenz [Schläge pro Minute]",
        title: "Tagebuch über den Blutsauerstoffgehalt - Pulmonology",
        value: "Gemessener Blutsauerstoffgehalt",
        valuePlaceholder: "Blutsauerstoffgehalt [%]",
      },
      pulse: {
        addNewPost: "Fügen Sie einen neuen Herzfrequenzeintrag hinzu",
        circumstances: "Bewegungsintensität",
        post: "Herzfrequenzeinträge",
        pulseType: "Messbedingung",
        pulseValue: "Puls",
        title: "Pulstagebuch - Kardiologie",
        value: "Gemessene Herzfrequenz",
        valuePlaceholder: "Herzfrequenz  [Schläge pro Minute]",
      },
      "respiration" : {
        "name" : "Name",
        "duration" : "Dauer",
        "minimum" : "Minimum",
        "maximum" : "Maximum",
        "total" : "Total",
        "average" : "Durchschnitt",
        "details" : "Einzelheiten",
        "data" : "Messwert",
        "seconds" : "Sekunde",
        "export": "Export",
        "delete" : "Löschen",
        "cancel" : "Abbrechen",
        "confirm" : "Möchten Sie die ausgewählte Messung wirklich löschen?",
        "measurement" : "Messung",
        "unit" : "st",
        "chest" : "Brust",
        "abdomen" : "Abdomen",
      },
      sleep: {
        addNewPost: "Fügen Sie einen neuen Schlafeintrag hinzu.",
        post: "Schlafeinträge",
        sleepDuration: "Schlafmenge",
        sleepDurationInput: "Wie viele Stunden haben Sie geschlafen?",
        sleepQuality: "Schlafqualität",
        sleepQualityInput: "Wie fühlen Sie sich?",
        sleepQualityInputDescription:
          "Bewerten Sie auf einer Skala von 1 (überhaupt nicht) bis 5 (voll und ganz).",
        title: "Schlaftagebuch - Depression",
      },
    },
    moduleDiaries: {
      BloodGlucose: "Blutzuckertagebuch",
      BloodPressure: "Blutdrucktagebuch",
      BodyWeight: "Gewichtstagebuch",
      Ecg: "EKG-Tagebuch",
      Exercise: "Übungstagebuch",
      Insulin: "Insulintagebuch",
      Medicine: "Medikationstagebuch",
      Mood: "Stimmungstagebuch",
      Nutrition: "Ernährungstagebuch",
      OxygenSaturation: "Tagebuch über den Blutsauerstoffgehalt",
      Pulse: "Pulstagebuch",
      Sleep: "Schlaftagebuch",
      //TODO: wikiről
      RibcageMeter:'Brustkorb Tagebuch'
    },
    modules: {
      BodyWeight: "Körpergewicht",
      bodyWeightModule: "Körpergewichtsmodul",
      Cardiology: "Kardiologie",
      cardiologyModule: "Kardiologiemodul",
      Depression: "Depression",
      depressionModule: "Depressionsmodul",
      Diabetology: "Diabetologie",
      diabetologyModule: "Diabetesmodul",
      Exercise: "Übung",
      exerciseModule: "Übungsmodul",
      Medicine: "Medizin",
      medicineModule: "Medizinmodul",
      module: "Modul",
      noModule: "Modul kann nicht angezeigt werden",
      Nutrition: "Ernährung",
      nutritionModule: "Ernährungsmodul",
      Oncology: "Onkologie",
      oncologyModule: "Omkologiemodul",
      Pulmonology: "Pulmonologie",
      pulmonologyModule: "Pulmonologiemodul",
      BodyTemperature: "TODO: Body temperature",
      bodyTemperatureModule: "TODO: Body temperature module",
    },
    plans: {
      daily: {
        DailyMood: "Täglicher minimaler Stimmungswert",
        DailySleepHours: "Tägliche Mindestschlafzeit [Stunden]",
        DailyStress: "Täglicher maximaler schlimmster Stresswert",
        ExerciseCalories:
          "Kalorienverbrauch bei minimalem täglichen Training [kcal]",
        ExerciseDistance: "Tägliche Mindeststrecke [m]",
        ExerciseFloors:
          "Anzahl der bewältigten Stockwerke bei der täglichen Mindestübung",
        ExerciseHours:
          "Anzahl der für die tägliche Mindestübung absolvierten Stunden",
        ExerciseSteps: "Minimale Anzahl von täglichen Schritten",
        MaxNutrition: "Maximale tägliche Kalorienaufnahme",
        MinNutrition: "Minimale tägliche Kalorienaufnahme",
        PulmonologyDaily:
          "Normaler Blutsauerstoffgehalt - Noch keine verbundene Funktion",
      },
      interval: {
        BloodPressure: "Blutdruck im Normalbereich",
        DailyExerciseCalories:
          "Kalorienverbrauch bei minimalem täglichen Training [kcal]",
        DailyExerciseDistance: "Tägliche Mindeststrecke [m]",
        DailyExerciseFloors:
          "Anzahl der bewältigten Stockwerke bei der täglichen Mindestübung ",
        DailyExerciseHours:
          "Anzahl der für die tägliche Mindestübung absolvierten Stunden ",
        DailyExerciseSteps: "Tägliche Mindestzahl an Schritten",
        DailyMaxNutrition: "Maximale tägliche Kalorienaufnahme",
        DailyMinNutrition: "Minimale tägliche Kalorienaufnahme",
        IntervalDailyMood: "Täglicher minimaler Stimmungswert",
        IntervalDailySleepHours: "Tägliche Mindestschlafzeit [Stunden]",
        IntervalDailyStress: "Täglicher maximaler schlimmster Stresswert",
        PulmonologyInterval:
          "Normaler Blutsauerstoffspiegel, noch keine verbundene Funktion",
      },
      medicine: {
        addNewPlan: "Neuer Plan der Medikation",
        daysOfWeek: "Tage der Woche",
        endDate: "Einnahme beendet",
        evening: "Abends",
        medicineName: "Bezeichnung des Medikaments",
        morning: "Morgens",
        namePlaceholder:
          "Geben Sie die Bezeichnung des Medikamentes ein oder suchen sie es in der Liste.",
        newPlan: "Neuer Medikationsplan",
        night: "Nachts",
        noon: "Mittags",
        notification: "Warnung",
        plans: "Medikationsplan",
        quantity: "Einnahmemengen",
        questityNotification:
          "Mindestens eine Medikamenteneinnahme muss eingetragen werden.",
        startDate: "Einnahme begonnen",
        timing: "Einnahmehäufigkeit",
        timingType: "Regelmäßigkeit",
        title: "Medikamente - Einnahmeplan",
      },
      property: {
        Bmi: "BMI [kg / m2]",
        BodyFatPercent: "Prozent Körperfett",
        BodyMusclePercent: "Prozent Muskelmasse",
        BodyWaterPercent: "Prozent Wassergehalt im Körper",
        BodyWeight: "Körpergewicht [kg]",
        ExerciseCalories: "Durch Übungen verbrannte Gesamtkalorien [kcal]",
        ExerciseDistance: "Gefahrene Gesamtstrecke [m]",
        ExerciseFloors:
          "Anzahl der während der gesamten Bewegung bewältigten Stockwerke",
        ExerciseHours:
          "Anzahl der für die gesamten Übungen aufgewandten Stunden",
        ExerciseSteps: "Gesamtzahl der Schritte",
      },
      types: {
        daily: "Tagesziel",
        interval: "Zwischenziel",
        property: "Erreichen des Tageswertes",
      },
    },
  },
};
