<template>
  <div>
    <div v-if="tempValue" style="margin-bottom: 10px">
      <b-row style="margin: 0px" cols="1" align-h="center">
        <!-- Profilkép kezelése -->
        <div v-if="tempClipperImg" style="text-align: center">
          <input style="display: none" type="file" id="profile-picture" />
          <img
            :class="disabled ? 'disbaled-img-profile' : 'img-profile'"
            style="width: 200px; height: 200px; border-radius: 50%"
            id="profile-img"
            :src="tempClipperImg"
            @click="disabled ? '' : choosePhoto()"
          />
          <b-modal
            v-model="showClipperModal"
            ref="clipper-modal"
            hide-header
            hide-footer
          >
            <clipper-fixed
              id="profile-clipper-img"
              :round="true"
              ref="clipper"
              :area="90"
              :src="tempClipperImg"
            ></clipper-fixed>
            <b-row style="margin: 0px" align-h="between">
              <b-button style="margin-top: 10px" @click="closeDialog">{{
                $t("base.basic.cancel")
              }}</b-button>
              <b-button
                variant="warning"
                style="margin-top: 10px"
                @click="getUserPictureResult"
                >{{ $t("base.basic.select") }}</b-button
              >
            </b-row>
          </b-modal>
        </div>
        <!-- Kommunikációs csatorna -->
        <b-row style="margin-top: 20px" align-h="center" align-v="center">
          <label style="margin: 10px 10px"
            >{{ $t("client.profile.personalData.communication") }}:</label
          >
          <b-row align-h="center">
            <!-- Még hiányzik -->
            <b-form-checkbox
              :disabled="disabled"
              v-model="tempValue.NotificationSettings.Email"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.email") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="tempValue.NotificationSettings.Push"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.push") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="tempValue.NotificationSettings.Sms"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.sms") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="tempValue.NotificationSettings.Chat"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.chat") }}</b-form-checkbox
            >
          </b-row>
        </b-row>
        <b-row cols="1" cols-lg="2">
          <!-- Felhasználónév -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="true"
              :label="$t('client.profile.personalData.username')"
              :value="username"
            />
          </div>
          <!-- Név -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('tempValue.Name')"
              :error="validation.firstError('tempValue.Name')"
              :disabled="disabled"
              :label="$t('client.profile.personalData.name')"
              v-model="tempValue.Name"
            />
          </div>
          <!-- Anyja neve -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('tempValue.MotherName')"
              :error="validation.firstError('tempValue.MotherName')"
              :disabled="disabled"
              :label="$t('client.profile.personalData.mother')"
              v-model="tempValue.MotherName"
            />
          </div>
          <!-- Email -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('tempValue.PersonalContact.Email')"
              :error="validation.firstError('tempValue.PersonalContact.Email')"
              :disabled="disabled"
              type="email"
              :label="$t('client.profile.personalData.emailAddress')"
              v-model="tempValue.PersonalContact.Email"
            />
          </div>
          <!-- Nem -->
          <div class="client-profile-inputs">
            <fp-select
              :label="$t('client.profile.personalData.sex')"
              :disabled="disabled"
              :items="Object.values(sexList)"
              valueKey="Value"
              v-model="tempValue.Sex"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
          <!-- Családi állapot -->
          <div class="client-profile-inputs">
            <fp-select
              :label="$t('client.profile.personalData.maritalStatus')"
              :disabled="disabled"
              :items="Object.values(maritalStatuses)"
              valueKey="Value"
              v-model="tempValue.MaritalStatus"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
          <!-- Születési hely és dátum -->
          <b-row
            class="client-profile-inputs"
            cols="1"
            cols-md="2"
            style="margin: 0px"
            align-h="center"
          >
            <!-- Születési hely -->
            <fp-input
              :disabled="disabled"
              style="padding-right: 5px"
              :label="$t('client.profile.personalData.birthplace')"
              v-model="tempValue.Birthplace"
            />
            <!-- Születési dátum -->
            <fp-date-picker
              :disabled="disabled"
              :label="$t('client.profile.personalData.birthday')"
              v-model="tempValue.Birthday"
            />
          </b-row>
          <!-- Nemzetiség -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.nationality')"
              v-model="tempValue.Nationality"
            />
          </div>
          <!-- Személyigazolvány szám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.idCard')"
              v-model="tempValue.IdentityCardNumber"
            />
          </div>
          <!-- Telefonszám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.phone')"
              v-model="tempValue.PersonalContact.Phone"
            />
          </div>
          <!-- TAJ szám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.ssn')"
              v-model="tempValue.SocialSecurityNumber"
            />
          </div>
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('Profile.euSocialSecurityNumber')"
              v-model="tempValue.EuSocialSecurityNumber"
            />
          </div>
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('Profile.publicHealthcareCardNumber')"
              v-model="tempValue.PublicHealthcareCardNumber"
            />
          </div>
          <div class="client-profile-inputs">
            <fp-date-picker
              :disabled="disabled"
              :label="$t('Profile.publicHealthcareCardValidBy')"
              v-model="tempValue.PublicHealthcareCardValidBy"
            />
          </div>
        </b-row>
        <b-row cols="1" cols-lg="2">
          <!-- Cím -->
          <div class="client-profile-inputs" style="margin-top: 10px">
            <fp-profile-address
              :disabled="disabled"
              v-model="tempValue.PersonalContact.Address"
            />
          </div>
          <!-- Értesítendő személy adatai -->
          <div
            class="client-profile-inputs"
            v-if="tempValue.EmergencyContact"
            style="margin-top: 10px"
          >
            <label>{{
              $t("client.profile.personalData.emergencyPerson")
            }}</label>
            <div style="margin-top: -10px">
              <!-- Értesítendő személy neve -->
              <fp-input
                :disabled="disabled"
                :label="$t('client.profile.personalData.name')"
                v-model="tempValue.EmergencyContact.Name"
              />
              <!-- Értesítendő személy rokonsági foka -->
              <!-- <fp-select
                :placeholder="
                  $t('client.profile.personalData.relationshipType')
                "
                :disabled="disabled"
                :items="getEnums('RelationshipType')"
                valueKey="Value"
                textKey="Text"
                v-model="tempValue.EmergencyContact.RelationshipType"
              /> -->
              <!-- Értesítendő személy email címe -->
              <fp-input
                :disabled="disabled"
                :label="$t('client.profile.personalData.emailAddress')"
                v-model="tempValue.EmergencyContact.Email"
              />
              <!-- Értesítendő személy telefonszáma -->
              <fp-input
                :disabled="disabled"
                :label="$t('client.profile.personalData.phone')"
                v-model="tempValue.EmergencyContact.Phone"
              />
              <!-- Cím -->
              <div style="margin-top: 10px">
                <fp-profile-address
                  :disabled="disabled"
                  v-model="tempValue.EmergencyContact.Address"
                />
              </div>
            </div>
          </div>
        </b-row>
      </b-row>
      <fp-text-area
        v-if="showRemark"
        :disabled="disabled"
        :label="$t('Base.remark')"
        v-model="tempValue.Remark"
      />
    </div>
    <div v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import ProfileAddress from "./Components/ProfileAddress.vue";
export default {
  components: { "fp-profile-address": ProfileAddress },
  name: "ClientProfile",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
    clipperImg: String,
    username: String,
    showRemark: Boolean,
  },
  data() {
    return {
      tempValue: this.value,
      tempClipperImg: this.clipperImg,
      showClipperModal: false,
      sexList: this.$enums.Sex,
      maritalStatuses: this.$enums.MaritalStatus,
    };
  },
  validators: {
    //TODO: adat modelben nullára kell állítani a validálandó mezőket (különben hiba lesz)
    "tempValue.Name": function (value) {
      return this.$validator
        .value(value)
        .minLength(
          5,
          "A 'Név' mezőbe legalább 15 karakter hosszú szöveget kell megadni"
        );
    },
    "tempValue.MotherName": function (value) {
      return this.$validator
        .value(value)
        .minLength(
          5,
          "Az 'Anyja neve' mezőbe legalább 15 karakter hosszú szöveget kell megadni"
        );
    },
    "tempValue.PersonalContact.Email": function (value) {
      return this.$validator
        .value(value)
        .email("Az 'Email cím' mező nem valid email címet tartalmaz");
    },
  },
  watch: {
    clipperImg(input) {
      this.tempClipperImg = input;
    },
    value(input) {
      if (!input.EmergencyContact) {
        input.EmergencyContact = {};
      }
      this.tempValue = input;
    },
  },
  methods: {
    choosePhoto() {
      this.showClipperModal = true;
      const inputElement = document.getElementById("profile-picture");
      inputElement.click();
      inputElement.addEventListener("change", this.handleFiles, false);
    },
    handleFiles(args) {
      const fileList = args.target.files;
      if (fileList) {
        this.tempClipperImg = window.URL.createObjectURL(fileList[0]);
      }
    },
    async getUserPictureResult() {
      this.profileImgCanvas = this.$refs.clipper.clip();
      const img = document.getElementById("profile-img");
      img.src = this.profileImgCanvas.toDataURL("image/jpeg", 1);
      this.$refs["clipper-modal"].hide();
      this.$emit("setProfilePicture", this.profileImgCanvas);
    },
    closeDialog() {
      const imgComp = document.getElementById("client-profil-icon");
      if (imgComp) {
        this.tempClipperImg = imgComp.src;
      }
      this.$refs["clipper-modal"].hide();
    },
  },
  mounted() {
    if (this.value) {
      if (!this.value.EmergencyContact) {
        this.value.EmergencyContact = {};
      }
      this.tempValue = this.value;
    }
  },
};
</script>
<style scoped>
.client-profile-inputs {
  padding: 0px 10px;
}
.disbaled-img-profile {
  cursor: default;
}
.img-profile {
  cursor: pointer;
}
</style>
