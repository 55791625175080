export default {
    state: {
        UserId: null,
        Name: null,
        Roles: null,
        Projects: null,
        SelectedProject: null,
        SelectedRole: null,
        Token: null,
        ProjectPermissions: null
    },
    mutations: {
        setProjectPermissions(state, payload) {
            state.ProjectPermissions = payload
        }
    },
    actions: {
        setProjectPermissions: ({ commit }, payload) => {
            commit("setProjectPermissions", payload);
        },
    },
};
