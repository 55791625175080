<template>
  <div v-if="research">
    <table style="width: 100%">
      <!-- <tr>
        <td>Projektnév:</td>
        <td>
          <b><div v-html="research.Name"></div></b>
        </td>
      </tr>
      <tr>
        <td>Projektcím:</td>
        <td>
          <div v-html="research.Description"></div>
        </td>
      </tr> -->
      <tr>
        <td>{{ $t("client.programs.period") }}:</td>
        <td>
          {{ $convertDateToString(research.StartDate, "YYYY-MM-DD") }} –
          {{ $convertDateToString(research.EndDate, "YYYY-MM-DD") }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("client.programs.state") }}:</td>
        <td>{{ $t($enums.ProjectStatus[state].Text) }}</td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.projectGoal") }}:</td>
        <td>
          <v-md-preview
            :text="getStringByLanguage(research.ResearchGoal)"
            height="400px"
            class="no-margin-markdown no-padding-markdown no-background-markdown"
          />
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.projectCriteria") }}:</td>
        <td>
          <v-md-preview
            :text="getStringByLanguage(research.SelectionCriteria)"
            height="400px"
            class="no-margin-markdown no-padding-markdown no-background-markdown"
          />
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.description") }}:</td>
        <td>
          <v-md-preview
            :text="getStringByLanguage(research.ResearchPlan)"
            height="400px"
            class="no-margin-markdown no-padding-markdown no-background-markdown"
          />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "DataSheet",
  props: {
    research: Object,
    state: String,
  },
  methods: {
    getStringByLanguage(textObject) {
      const text = this.$getLanguagedText(textObject);
      return text ? text : "";
    },
  },
};
</script>
<style scoped>
table tr td {
  padding: 10px 50px 10px 10px;
}
</style>
