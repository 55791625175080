<template>
  <fp-form-modal size="xl" title="EESZT recept lefoglalása" v-model="visible">
    <template #content>
      <div v-if="newDispenseData">
        <div>
          <fp-date-time-picker
            label="Kiadás időpontja"
            v-model="newDispenseData.DispenseTimestamp"
          />
        </div>
        <div style="margin-top: 10px">
          <fp-panel-card title="Ellátott személy adatai" :required="true">
            <template #content>
              <patient
                ref="dispense-patient"
                v-model="newDispenseData.Patient"
              />
            </template>
          </fp-panel-card>
        </div>
      </div>
      <div style="margin-top: 20px">
        <b-button @click="saveDispense">Mentés</b-button>
        <b-button @click="cancelDispense">Mégsem</b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import moment from "moment";
import Patient from "../Components/create/Patient";
export default {
  name: "NewDispenseForm",
  components: { Patient },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    prescriptionPatient: Object,
    value: Boolean,
  },
  data() {
    return {
      visible: this.value,
      defaultForm: {
        Patient: null,
        DispenseTimestamp: null,
      },
      newDispenseData: null,
    };
  },
  watch: {
    visible(input) {
      this.$emit("change", input);
    },
    async value(input) {
      this.visible = input;
      await this.setDispenseData();
    },
  },
  methods: {
    async saveDispense() {
      const valid = await this.$refs["dispense-patient"].checkValidation();
      if (valid) {
        this.$emit("saveDispense", this.newDispenseData);
      }
    },
    cancelDispense() {
      this.visible = false;
    },
    async setDispensePatient() {
      this.$set(this.newDispenseData, "DispenseTimestamp", moment().format());
      console.log(this.newDispenseData.DispenseTimestamp);
      //this.newDispenseData.DispenseTimestamp = moment().format();
      if (this.prescriptionPatient) {
        this.newDispenseData.Patient = {};
        //user azonosító
        this.newDispenseData.Patient.PatientId =
          this.prescriptionPatient.PatientId;
        //user címe
        if (this.prescriptionPatient.Address) {
          this.newDispenseData.Patient.Address = {
            CountryId: this.prescriptionPatient.Address.Country
              ? this.prescriptionPatient.Address.Country.Id
              : null,
            City: this.prescriptionPatient.Address.City,
            PostalCode: this.prescriptionPatient.Address.PostalCode,
            StreetAddress: this.prescriptionPatient.Address.StreetAddress,
          };
        }
        //user születésnap
        this.newDispenseData.Patient.BirthDate =
          this.prescriptionPatient.BirthDate;
        //user euDocCode
        this.newDispenseData.Patient.EuDocumentCode =
          this.prescriptionPatient.EuDocumentCode;
        //user neve
        this.newDispenseData.Patient.FullName =
          this.prescriptionPatient.FullName;
        //user neme
        this.newDispenseData.Patient.GenderId = this.prescriptionPatient.Gender
          ? this.prescriptionPatient.Gender.Id
          : null;
        //user nemzetiseg
        this.newDispenseData.Patient.NationalityId = this.prescriptionPatient
          .Nationality
          ? this.prescriptionPatient.Nationality.Id
          : null;
        //user igazolvány azonosító típusa
        this.newDispenseData.Patient.PatientIdentifierTypeId = this
          .prescriptionPatient.PatientIdentifierType
          ? this.prescriptionPatient.PatientIdentifierType.Id
          : null;
        //user igazolvány azonosító
        this.newDispenseData.Patient.PatientIdentifierValue =
          this.prescriptionPatient.PatientIdentifierValue;
        //user igazolvány telefon
        this.newDispenseData.Patient.Phone = this.prescriptionPatient.Phone;
        //user igazolvány közgyógy
        this.newDispenseData.Patient.PublicHealthIdentifier =
          this.prescriptionPatient.PublicHealthIdentifier;
        //user igazolvány közgyógy érv
        this.newDispenseData.Patient.PublicHealthIdentifierValidBy =
          this.prescriptionPatient.PublicHealthIdentifierValidBy;
      }
    },
    async setDispenseData() {
      this.newDispenseData = await JSON.parse(JSON.stringify(this.defaultForm));
      if (this.visible) {
        await this.setDispensePatient();
      }
      this.resetValidation();
    },
    resetValidation() {
      if (this.$refs["dispense-patient"]) {
        this.$refs["dispense-patient"].resetValidation();
      }
    },
  },
  async mounted() {
    if (this.visible) {
      await this.setDispenseData();
    }
  },
};
</script>
