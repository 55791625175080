<template>
  <div>
    <b-button @click="$router.go(-1)">Vissza</b-button>
    <fp-legal-manager :OwnerId="$loggedUser.UserId" />
  </div>
</template>
<script>
export default {
  name: "Legal",
  //betöltéskor egyszer lefut
  mounted() {
    //oldal címnek beállítása
    this.$store.dispatch("setPageTitle", "Nyilatkozatok");
  },
};
</script>