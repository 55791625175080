<template>
  <div style="width: 90%; margin: auto" class="profile">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <router-link to="Legal"><b-button>Nyilatkozatok</b-button></router-link>
      </div>
      <div>
        <b-button @click="showChangePasswordForm = true">{{
          $t("client.profile.changePassword")
        }}</b-button>
        <fp-change-password
          :username="$loggedUser.Username"
          v-model="showChangePasswordForm"
        />
      </div>
    </div>
    <div v-if="profileData" style="margin-bottom: 10px">
      <fp-health-professional-profile
        :clipperImg="clipperImg"
        :username="$loggedUser.Username"
        @setProfilePicture="changeProfilePicture"
        v-model="profileData"
      />
      <div style="text-align: center; margin-top: 20px">
        <b-button @click="saveUserPersonalProfile">{{
          $t("base.basic.save")
        }}</b-button>
      </div>
      <div>
        <b-button variant="outline-danger" @click="deleteProfile">{{
          $t("healthProfessional.profile.profileDelete")
        }}</b-button>
      </div>
    </div>
    <div v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import { UserProfileLogic } from "@/Logic/Backend/user-profile";
import { UserLogic } from "@/Logic/Backend/user";
import { AttachmentLogic } from "@/Logic/Backend/attachment";

export default {
  name: "SpecialistProfile",
  data() {
    return {
      profileData: null,
      profileId: null,
      showClipperModal: false,
      clipperImg: null,
      profileImgCanvas: null,
      showChangePasswordForm: false,
    };
  },
  methods: {
    //profilkép választás
    choosePhoto() {
      //clipper modal megjelenítés
      this.showClipperModal = true;
      //rejtett input ideiglenes tárolás
      const inputElement = document.getElementById("profile-picture");
      //click eseményének kiváltása
      inputElement.click();
      //change eseményre feliratkoztatása megadott metódus hívással
      inputElement.addEventListener("change", this.handleFiles, false);
      //TODO: ha nincs kiválasztott elem, nem váltódik ki a change akkor modal bezárás?
    },
    //profil kép választása fileból
    handleFiles(args) {
      //kiválasztott file-ok ideiglenes tárolás
      const fileList = args.target.files;
      //van kiválasztott file?
      if (fileList) {
        //igen
        //urlt generálunk a kiválasztott filehoz
        this.clipperImg = window.URL.createObjectURL(fileList[0]);
      }
    },
    //profil kép kiválasztása a modalban
    async getUserPictureResult() {
      //a megjelenő kép levágása és canvasra mentés
      this.profileImgCanvas = this.$refs.clipper.clip();
      //kép elem ideiglenes tárolása
      const img = document.getElementById("profile-img");
      //profilkép elérésének beállítása az elemnek
      img.src = this.profileImgCanvas.toDataURL("image/jpeg", 1);
      //modal elrejtése
      this.closeDialog();
    },
    //modal bezáró metódus
    closeDialog() {
      //this.clipperImg = document.getElementById("client-profil-icon").src;

      //modal bezárás
      this.$refs["clipper-modal"].hide();
    },
    //profil adatok kérés
    async getAllProfile() {
      //kérés indítás
      const userProfileResponse = await UserProfileLogic.getUserProfile();
      //sikeres kérés?
      if (!userProfileResponse.Code) {
        //profil adatok frissítése
        this.profileData = userProfileResponse;
        this.profileId = userProfileResponse.UserId;
        //default profilkép tárolás
        this.clipperImg = require("@/Assets/Default/annonym-profil-icon.png");
        //profilkép letöltése, megjelnítése
        this.getProfilePicture();
      } else {
        this.$bvToast.toast(userProfileResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.profile.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    changeProfilePicture(canvas) {
      this.profileImgCanvas = canvas;
    },
    //profil frissítése mentése (kép feltöltés, ha van új kép beállítva, és adatok mentése)
    async saveUserPersonalProfile() {
      //feltöltés kérés eredménye
      var uploadResult = false;
      //van új profilkép canvas tárolva?
      if (this.profileImgCanvas) {
        //igen, előkészítés a blob konvertálásra
        const blobImg = await new Promise((resolve) =>
          this.profileImgCanvas.toBlob(resolve)
        );
        //igen, blob objektumot készítünk
        var blob = new Blob([blobImg]);
        //érvényes blob?
        if (blob) {
          //igen, kép feltöltése blobként az előzőleg létrehozott dokumentum adatok alapján
          uploadResult = await AttachmentLogic.uploadAttachment(
            blob,
            this.$enums.AttachmentTypeName.UserProfile.TypeName,
            this.profileId
          );
        }
        //sikeres feltöltési kérés?
        if (!uploadResult.Code) {
          //igen, előző profilkép file id-ját eltároljuk stringként
          const previousProfilePictureId =
            this.profileData.PersonalProfile.ProfilePictureId;
          //a profil megkapja az új profilkép fileid-ját
          this.profileData.PersonalProfile.ProfilePictureId =
            uploadResult.AttachmentId;
          //személyes adatok mentés kérése
          await this.savePersonalProfileData();
          //van előző profilkép?
          if (previousProfilePictureId && previousProfilePictureId != "") {
            //előző profil kép file törlése a dokumentumlistából
            await AttachmentLogic.deleteAttachment(previousProfilePictureId);
          }
        } else {
          this.$bvToast.toast(uploadResult.Message, {
            title: this.$t(
              "requestResponse.attachment.errorUploadProfilePhoto"
            ),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      } else {
        //TODO: ez ne fusson le mindeképp?
        //nincs, csak a személyes adatok mentés kérése
        await this.savePersonalProfileData();
      }
    },
    //személyes adatok mentése
    async savePersonalProfileData() {
      //kérés indítása
      const savePersonalProfileDataResponse =
        await UserProfileLogic.setUserProfile(
          {
            PersonalProfile: this.profileData.PersonalProfile,
            HealthProfessionalProfile:
              this.profileData.HealthProfessionalProfile,
            EesztProfile: this.profileData.EesztProfile,
          },
          this.$loggedUser.UserId
        );
      //sikeres kérés?
      if (
        !savePersonalProfileDataResponse.Code ||
        savePersonalProfileDataResponse.Code == 0
      ) {
        //sikeres üzenet
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("signed-user-name", this.profileData.PersonalProfile.Name);
      } else {
        this.$bvToast.toast(savePersonalProfileDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      //profil adatok frissítése
      this.getAllProfile();
    },
    //profil kép letöltése megjelenítése
    async getProfilePicture() {
      //default kép beállítása
      this.clipperImg = require(`@/Assets/Default/annonym-profil-icon.png`);
      //van profilhoz tartozó profilkép?
      if (this.profileData.PersonalProfile.ProfilePictureId) {
        //igen, kép letöltés kérés
        this.clipperImg = await this.getUserProfilePicture(
          this.profileData.PersonalProfile.ProfilePictureId
        );
        //van letöltött kép?
        if (this.clipperImg) {
          //van, fejléc profilkép cseréje
          this.$emit("picture-changed", this.clipperImg);
          //kép urljnek tárolása a storeban
          /* this.$store.dispatch("changeUser", {
            Url: this.clipperImg,
          }); */
        } else {
          //nincs, default kép beállítása
          this.clipperImg = require(`@/Assets/Default/annonym-profil-icon.png`);
        }
      } else {
        //nincs, default kép beállítása
        this.clipperImg = require(`@/Assets/Default/annonym-profil-icon.png`);
      }
    },
    //profilkép letöltése
    async getUserProfilePicture(pictureId) {
      //kérés indítása
      const pictureDocuResponse = await AttachmentLogic.downloadAttachment(
        pictureId
      );
      //sikeres kérés?
      if (!pictureDocuResponse.Code) {
        //kapott kép blobba mentés
        const imgBlob = await pictureDocuResponse.blob();
        //kép url generáslásával tér vissza
        return URL.createObjectURL(imgBlob);
      } else {
        this.$bvToast.toast(pictureDocuResponse.Message, {
          title: this.$t(
            "requestResponse.attachment.errorDownloadProfilePhoto"
          ),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async deleteProfile() {
      //TODO: tesztelni
      if (
        confirm(this.$t("requestResponse.basic.deleteProfileConfirmMessage"))
      ) {
        const deleteProfileResponse = await UserLogic.deleteUser(
          this.$loggedUser.UserId
        );
        if (!deleteProfileResponse.Code) {
          this.$bvToast.toast(this.$t("requestResponse.basic.successDelete"), {
            title: this.$t("base.basic.delete"),
            variant: "info",
            solid: true,
          });
          this.$router.push({ name: "Home" });
        } else {
          this.$bvToast.toast(deleteProfileResponse.Message, {
            title: this.$t("requestResponse.basic.errorDelete"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", this.$t("healthProfessional.menu.profile"));
    //profil adatok begtöltése
    await this.getAllProfile();
  },
};
</script>
<style scoped>
.client-profile-inputs {
  padding: 0px 10px;
}
.disbaled-img-profile {
  cursor: default;
}
.img-profile {
  cursor: pointer;
}
.column-padding {
  padding: 5px;
}
.profile {
  padding: 50px 0px 50px 0px;
}
.profile .tab-content {
  background: white;
  padding-top: 20px;
  border-radius: 0px 0px 10px 10px;
  border-color: rgb(163, 163, 163) !important;
  border: 1px solid;
}
.profile .custom-control-input:checked ~ .custom-control-label::before {
  color: var(--eh-white);
  border-color: orange !important;
  background-color: orange !important;
}
.profile .custom-control-input:focus {
  color: orange !important;
  outline: none;
}
.profile .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border: #adb5bd solid 1px !important;
}
.profile #profile-img {
  border-radius: 50%;
}
.profile #profile-img:focus {
  outline: none;
}
.profile .profile-save-button-box {
  text-align: center;
}
.profile .profile-tab-content {
  width: 90%;
  margin: auto;
}
.profile #active-address-creator-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
.profile #active-address-creator-modal .modal-content header {
  padding: 0px;
}
.profile
  .profile-tabs
  .e-float-input:not(.e-error)
  input:valid
  ~ label.e-float-text,
.profile
  .profile-tabs
  .e-float-input:not(.e-error)
  input:focus
  ~ label.e-float-text {
  color: black;
  opacity: 0.6;
}
@media (max-width: 700px) {
  .profile .profile-tab-content {
    width: 90%;
  }
}
</style>
