import Vue from "vue";
import moment from "moment";
import { DocumentsLogic } from "../Backend/documents";

export const TableUiLogic = {
  /* Saját dokumentumok token alapján való lekérdezése, 
     más dokumentumának letöltéséhez egyéb változók bevezetése szükséges */
  async downloadSelectedDocuments(selectedItems) {
    var responseList = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const element = selectedItems[i];
      const response = await DocumentsLogic.downloadProfileDocument(
        element.DocumentId
      );
      if (!response.Code) {
        const blob = await response.blob();
        /* TODO: nem működik a letöltés: iPhone, Ipad Chrome böngészőben a letöltés (meg se nyikkan) */
        var file = new File([blob], element.FileName);
        var fileURL = URL.createObjectURL(file);
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.download = element.FileName;
        fileLink.target = "_blank";
        fileLink.click();
        responseList.push({ Code: 0, Message: "Sikeres letöltés" });
      } else {
        responseList.push({
          Code: response.Code,
          Message:
            " Hiba a " +
            element.FileName +
            "nevű dokumentum letöltése közben: " +
            response.Message,
        });
      }
    }
    return responseList;
  },

  async deleteSelectedDocuments(selectedItems) {
    var responseList = [];
    if (confirm("Biztosan törölni szeretné a kijelölt elemeket")) {
      for (let i = 0; i < selectedItems.length; i++) {
        const element = selectedItems[i];
        const response = await DocumentsLogic.deleteDocument(
          element.DocumentId
        );
        if (!response.Code) {
          responseList.push({ Code: 0, Message: "Sikeres törlés" });
        } else {
          responseList.push({
            Code: response.Code,
            Message:
              " Hiba a " +
              element.FileName +
              "nevű dokumentum törlése közben: " +
              response.Message,
          });
        }
      }
    }
    return responseList;
  },
  async deleteSelectedPatientDocuments(targetId, selectedItems) {
    var responseList = [];
    if (confirm("Biztosan törölni szeretné a kijelölt elemeket")) {
      for (let i = 0; i < selectedItems.length; i++) {
        const element = selectedItems[i];
        const response = await DocumentsLogic.deletePatientDocument(
          targetId, element.DocumentId
        );
        if (!response.Code) {
          responseList.push({ Code: 0, Message: "Sikeres törlés" });
        } else {
          responseList.push({
            Code: response.Code,
            Message:
              " Hiba a " +
              element.FileName +
              "nevű dokumentum törlése közben: " +
              response.Message,
          });
        }
      }
    }
    return responseList;
  },
  /* Visszatérési érték formátuma:
     {Result: Enums.RequestResponse[], Value: Array | String | null } */
  async uploadDocuments(type, list) {
    var responseList = [];
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      const fileNameTags = element.name.split(".");
      fileNameTags.pop();
      const createdDocumentResult = await DocumentsLogic.createDocument({
        DocumentName: fileNameTags.toString().replaceAll(",", "."),
        FileName: element.name,
        Category: type,
        SourceName: "FocusProgram",
        FileCreatedAt: moment().format(),
      });
      if (!createdDocumentResult.Code) {
        var blob = new Blob([element]);
        if (blob) {
          const uploadResult = await DocumentsLogic.uploadDocument(
            blob,
            createdDocumentResult.DocumentId
          );
          if (!uploadResult.Code) {
            responseList.push({ Code: 0, Message: "Sikeres feltöltés" });
          } else {
            responseList.push(uploadResult);
          }
        }
      } else {
        responseList.push(createdDocumentResult);
      }
    }
    return responseList;
  },
  async uploadPatientDocuments(targetId, type, list) {
    var responseList = [];
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      const fileNameTags = element.name.split(".");
      fileNameTags.pop();
      const createdDocumentResult = await DocumentsLogic.createPatientDocument(targetId,
        {
          DocumentName: fileNameTags.toString().replaceAll(",", "."),
          FileName: element.name,
          Category: type,
          SourceName: "FocusProgram",
          FileCreatedAt: moment().format(),
        });
      if (!createdDocumentResult.Code) {
        var blob = new Blob([element]);
        if (blob) {
          const uploadResult = await DocumentsLogic.uploadPatientDocument(
            targetId, blob, createdDocumentResult.DocumentId
          );
          if (!uploadResult.Code) {
            responseList.push({ Code: 0, Message: "Sikeres feltöltés" });
          } else {
            responseList.push(uploadResult);
          }
        }
      } else {
        responseList.push(createdDocumentResult);
      }
    }
    return responseList;
  },
  async getDocuments() {
    const getDocumentsListResponse = await DocumentsLogic.getProfileDocuments({
      OwnerId: Vue.prototype.$loggedUser.UserId,
    });
    return getDocumentsListResponse;
  },
  async eesztSync() {
    const eeszt = await DocumentsLogic.eesztSync();
    return eeszt;
  },
};
