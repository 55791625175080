export const HuModule = {
  "module": {
    "charts": {
      default: 'Nincs kiválasztva',
      "BloodGlucose": "Vércukorszint",
      "BloodPressure": "Vérnyomás",
      "BMI": "Napi BMI",
      "BodyWeight": "Testtömeg",
      "CalorieIntake": "Bevitt kalória",
      "collectedMedicines": "Beszedett gyógyszerek",
      "diastolicMax": "Legmagasabb diastolic",
      "diastolicMin": "Legalacsonyabb diastolic",
      "distance": "Távolság [m]",
      "ExerciseCalories": "Mozgással elégetett kalória",
      "ExerciseDistance": "Mozgással megtett távolság",
      "ExerciseDuration": "Mozgással töltött idő",
      "ExerciseFloors": "Megmászott emeletek száma",
      "ExerciseSteps": "Megtett lépések",
      "floor": " emelet",
      "floorsCount": "Emeletek száma",
      "Insulin": "Beadott inzulin",
      "kcal": "Kalória [kcal]",
      "Medicine": "Gyógyszerszedési napló",
      "MedicineDaily": "Napi gyógyszerbeszedés",
      "MedicineMonthly": "Havi gyógyszerbeszedés",
      "MedicineWeekly": "Heti gyógyszerbeszedés",
      "missedMedicines": "Kimaradt gyógyszerek",
      "Mood": "Kedély",
      moodValue: 'Kedély érték',
      "OxygenSaturation": "Véroxigénszint",
      "Pulse": "Pulzus",
      "Respiration": "Légzés",
      "Sleep": "Alvás",
      sleepQuality: 'Alvás mennyiség',
      "step": " lépés",
      "steptsCount": "Lépések száma",
      "systolicMax": "Legmagasabb systolic",
      "systolicMin": "Legalacsonyebb systolic",
      "weight": "Testtömeg [kg]",
      "weightFat": "Kövér",
      "weightNormal": "Normál",
      "weightOverweight": "Túlsúlyos",
      "weightThin": "Sovány",
      "weightVeryFat": "Nagyon kövér",
      bloodGlucoseMax: 'Legmagasabb vércukorszint',
      bloodGlucoseMin: 'Legalacsonabb vércukorszint',
      givenInsulin: 'Beadott inzulin egység',
      unit: "egység"
    },
    diaries: {
      addTimestamp: "Mérés dátuma",
      bloodGlucose: {
        addNewPost: "Új vércukorszint bejegyzés hozzáadása",
        bloodGlucoseValue: "Vércukorszint",
        mealQuantity: "Táplálkozás mennyisége",
        mealRelation: "Mérés módja",
        post: "Vércukorszint bejegyzések",
        title: "Vércukorszint napló - Diabetológia",
        value: "Mennyiség",
        valueDescription: "Az értéket [mmol/l] mértékegységben adja meg",
      },
      bloodPressure: {
        addNewPost: "Új vérnyomás bejegyzés hozzáadása",
        category: "Kategória",
        diastole: "Diastole",
        diastoleMeasure: "Mért diastole",
        diastolePlaceholder: "Diastole [hgmm]",
        post: "Vérnyomás bejegyzések",
        pulse: "Mért pulzus",
        pulsePlaceholder: "Pulzus [ütés/perc]",
        systole: "Systole",
        systoleMeasure: "Mért systole",
        systolePlaceholder: "Systole [hgmm]",
        title: "Vérnyomás napló - Kardiológia",
      },
      bodyWeight: {
        addNewPost: "Új testtömeg bejegyzés hozzáadása",
        bmiCategory: "BMI kategória",
        bmiScroe: "BMI érték",
        fat: "Zsírszázalék",
        fatPercentage: "Testzsír százalék",
        height: "Testmagasság",
        heightPlaceholder: "Méter [m]",
        muscle: "Izomszázalék",
        musclePercentage: "Testizom százalék",
        percentage: "Százalék [%]",
        post: "Testtömeg bejegyzések",
        title: "Testtömeg napló - Testtömeg",
        water: "Vízszázalék",
        waterPercentage: "Testvíz százalék",
        weight: "Testtömeg",
        weightPlaceholder: "Kilogramm [kg]",
      },
      ecg: {
        title: "EKG napló - Kardiológia",
      },
      exercise: {
        addNewPost: "Új mozgásnapló bejegyzés hozzáadása",
        burnedCalories: "Elégetett kalória",
        burnedCaloriesDescription:
          "Amennyiben nem kerül megadásra, automatikusan kerül kiszámításra",
        burnedCaloriesPlaceholder: "Kalória értéke [kcal]",
        distance: "Megtett távolság",
        distanceDescription:
          "A mozgás során megtett távolság (amennyiben releváns)",
        distancePlaceholder: "Távolság [m]",
        duration: "Mozgás időtartama (óra:perc)",
        exerciseType: "Mozgás típus",
        floorCount: "Megtett emeletek",
        floors: "Megmászott emeletek száma",
        floorsDescription:
          "A mozgás során megmászott emeletek száma (amennyiben releváns). Egy emelet hozzávetőleg három méter függőleges távolságot vagy 16 lépcsőfokot jelent",
        floorsPlaceholder: "Emeletek száma",
        intensity: "Mozgás intenzitása",
        post: "Mozgásnapló bejegyzések",
        startDate: "A mozgás kezdetének időpontja",
        stepCount: "Megtett lépések",
        steps: "Megtett lépések száma",
        stepsDescription:
          "A mozgás során megtett lépések száma (amennyiben releváns)",
        stepsPlaceholder: "Lépések száma",
        title: "Mozgás napló - Testmozgás",
        typeDefault: "Kérem válasszon mozgás típust!",
      },
      insulin: {
        addNewPost: "Új inzulin bejegyzés hozzáadása",
        date: "Beadás dátuma",
        insulinValue: "Beadott inzulin",
        mealRelation: "Beadás módja",
        post: "Inzulin bejegyzések",
        title: "Inzulin napló - Diabetológia",
      },
      measureDate: "A mérés időpontja",
      medicine: {
        addNewPost: "Új gyógyszer felvitele",
        logging: "Beszedés naplózás",
        medicine: "Gyógyszer",
        medicineName: "Gyógyszer neve",
        post: "Gyógyszer bejegyzések",
        takenCount: "Beszedett mennyiség",
        takenDate: "Beszedés dátuma",
        title: "Gyógyszerszedési napló - Gyógyszer",
      },
      mood: {
        addNewPost: "Új kedély bejegyzés hozzáadása",
        balanceLevel: "Kiengyensúlyozottság",
        balanceLevelInput: "Mennyire érzi magát kiegyensúlyozottnak?",
        balanceLevelInputDescription:
          "Értékelje egy 1(Egyáltalán nem) - 5(Teljes mértékben) skálán",
        energyLevel: "Energiaszint",
        energyLevelInput: "Milyen az energiaszintje?",
        energyLevelInputDescription:
          "Értékelje egy 1(Alacsony) - 5(Magas) skálán",
        moodValue: "Közérzet",
        moodValueInput: "Milyen a közérzete?",
        moodValueInputDescription: "Értékelje egy 1(Rossz) - 5(Kitűnő) skálán",
        post: "Kedély bejegyzések",
        stressLevel: "Stressz",
        stressLevelInput: "Mennyire tud megbírkózni a stresszel?",
        stressLevelInputDescription:
          "Értékelje egy 1(Egyáltalán nem) - 5(Teljes mértékben) skálán",
        title: "Kedély napló - Depresszió",
      },
      newPost: "Új bejegyzés",
      nutrition: {
        addNewPost: "Új Táplálkozás bejegyzés hozzáadása",
        itemAmount: "Mennyiség",
        itemCalories: "Kalória (opcionális)",
        itemName: "Étel neve",
        items: "Ételek",
        itemUnit: "Mértékegység",
        mealName: "Napszak",
        post: "Táplálkozás bejegyzések",
        remark: "Megjegyzés",
        title: "Táplálkozás napló - Táplálkozás",
      },
      oxygenSaturation: {
        addNewPost: "Új véroxigénszint bejegyzés hozzáadása",
        circumstances: "Mérési körülmények",
        oxygenSaturationType: "Mérés körülménye",
        post: "Véroxigénszint bejegyzések",
        pulseValue: "Mért pulzus",
        pulseValuePlaceholder: "Pulzus [ütés/perc]",
        title: "Véroxigénszint napló - Pulmonológia",
        value: "Mért véroxigénszint",
        valuePlaceholder: "Véroxigénszint [%]",
      },
      pulse: {
        addNewPost: "Új pulzus bejegyzés hozzáadása",
        circumstances: "Mozgás intenzitás",
        post: "Pulzus bejegyzések",
        pulseType: "Mérés körülménye",
        pulseValue: "Pulzus",
        title: "Pulzus napló - Kardiológia",
        value: "Mért pulzus",
        valuePlaceholder: "Pulzus [ütés/perc]",
      },
      sleep: {
        addNewPost: "Új alvás bejegyzés hozzáadása",
        post: "Alvás bejegyzések",
        sleepDuration: "Alvás mennyisége",
        sleepDurationInput: "Hány órát aludt?",
        sleepQuality: "Alvás minősége",
        sleepQualityInput: "Milyen a közérzete?",
        sleepQualityInputDescription:
          "Értékelje egy 1(Egyáltalán nem) - 5(Teljes mértékben) skálán",
        title: "Alvás napló - Depresszió",
      },
      "respiration" : {
        "name" : "Név",
        "duration" : "Időtartam",
        "minimum" : "Minimum",
        "maximum" : "Maximum",
        "total" : "Összes",
        "average" : "Átlag",
        "details" : "Részletek",
        "data" : "Mért érték",
        "seconds" : "Másodperc",
        "export": "Exportálás",
        "delete" : "Törlés",
        "cancel" : "Mégsem",
        "confirm" : "Biztosan törölni akarja a kiválasztott mérést?",
        "measurement" : "Mérés",
        "unit" : "db",
        "perMin": "{m}/perc",
        "chest": "Mellkasi",
        "abdomen": "Hasi",
      },
    },
    moduleDiaries: {
      BloodGlucose: "Vércukorszint napló",
      BloodPressure: "Vérnyomás napló",
      BodyWeight: "Testtömeg napló",
      Ecg: "EKG napló",
      Exercise: "Mozgásnapló",
      Insulin: "Inzulin napló",
      Medicine: "Gyógyszernapló",
      Mood: "Kedély napló",
      Nutrition: "Táplálkozás napló",
      OxygenSaturation: "Véroxigénszint napló",
      Pulse: "Pulzus napló",
      Sleep: "Alvás napló",
      RibcageMeter:'Bordakosármérő napló'
    },
    modules: {
      BodyWeight: "Testtömeg",
      bodyWeightModule: "Testtömeg modul",
      Cardiology: "Kardiológia",
      cardiologyModule: "Kardiológiai modul",
      Depression: "Depresszió",
      depressionModule: "Depresszió modul",
      Diabetology: "Diabetológia",
      diabetologyModule: "Diabetológiai modul",
      Exercise: "Testmozgás",
      exerciseModule: "Mozgás modul",
      Medicine: "Gyógyszer",
      medicineModule: "Gyógyszer modul",
      module: "modul",
      noModule: "Nem megjeleníthető modul",
      Nutrition: "Táplálkozás",
      nutritionModule: "Táplálkozás modul",
      Oncology: "Onkológia",
      oncologyModule: "Onkológiai modul",
      Pulmonology: "Pulmonológia",
      pulmonologyModule: "Pulmonológiai modul",
      BodyTemperature: "Testhőmérséklet",
      bodyTemperatureModule: "Testhőmérséklet modul",
    },
    plans: {
      daily: {
        DailyMood: "Napi minimum kedély érték",
        DailySleepHours: "Napi minimum alvással töltött idő [óra]",
        DailyStress: "Napi maximum legrosszabb stressz érték",
        ExerciseCalories: "Napi minimum mozgással elégetett kalória [kcal]",
        ExerciseDistance: "Napi minimum mozgással megtett távolság [m]",
        ExerciseFloors: "Napi minimum mozgással megmászott emeletek száma",
        ExerciseHours: "Napi minimum mozgással töltött órák száma",
        ExerciseSteps: "Napi minimum lépésszám",
        MaxNutrition: "Napi maximum bevitt kalória mennyiség",
        MinNutrition: "Napi minimum bevitt kalória mennyiség",
        PulmonologyDaily:
          "Normál tartományba eső véroxigénszint - Még nem bekötött funkció",
      },
      interval: {
        BloodPressure: "Normál tartományba eső vérnyomás",
        DailyExerciseCalories:
          "Napi minimum mozgással elégetett kalória [kcal]",
        DailyExerciseDistance: "Napi minimum mozgással megtett távolság [m]",
        DailyExerciseFloors: "Napi minimum mozgással megmászott emeletek száma",
        DailyExerciseHours: "Napi minimum mozgással töltött órák száma",
        DailyExerciseSteps: "Napi minimum lépésszám",
        DailyMaxNutrition: "Napi maximum bevitt kalória mennyiség",
        DailyMinNutrition: "Napi minimum bevitt kalória mennyiség",
        IntervalDailyMood: "Napi minimum kedély érték",
        IntervalDailySleepHours: "Napi minimum alvással töltött idő [óra]",
        IntervalDailyStress: "Napi maximum legrosszabb stressz érték",
        PulmonologyInterval:
          "Normál tartományba eső véroxigénszint - Még nem bekötött funkció",
      },
      medicine: {
        addNewPlan: "Új gyógyszerbeszedési terv",
        daysOfWeek: "A hét napjai",
        endDate: "Szedés vége",
        evening: "Este",
        medicineName: "Gyógyszer neve",
        morning: "Reggel",
        namePlaceholder: "Adja meg a gyógyszer nevét és keresse ki a listából",
        newPlan: "Új gyógyszerszedési terv",
        night: "Éjszaka",
        noon: "Délben",
        notification: "Riasztás",
        plans: "Gyógyszerszedési tervek",
        quantity: "Szedési mennyiségek",
        questityNotification:
          "Legalább egy szedési mennyiséget be kell állítani!",
        startDate: "Szedés kezdete",
        timing: "Szedési gyakoriság",
        timingType: "Rendszeresség",
        title: "Gyógyszer - Szedési terv",
      },
      property: {
        Bmi: "BMI [kg/m2]",
        BodyFatPercent: "Testzsírszázalék",
        BodyMusclePercent: "Testizomszázalék",
        BodyWaterPercent: "Testvízszázalék",
        BodyWeight: "Testtömeg [kg]",
        ExerciseCalories: "Összesített mozgással elégetett kalória [kcal]",
        ExerciseDistance: "Összesített mozgással megtett távolság [m]",
        ExerciseFloors: "Összesített mozgással megmászott emeletek száma",
        ExerciseHours: "Összesített mozgással töltött órák száma",
        ExerciseSteps: "Összesített lépésszám",
      },
      types: {
        daily: "Napi cél",
        interval: "Időszakos cél",
        property: "Célérték elérés",
      },
    },
  },
};
