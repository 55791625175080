<template>
  <div class="eh-menu-content">
    <div v-if="project">
      <fp-tabs :lazy="true" :tabs="questionnaireTabs">
        <template #title="Component">{{ Component.Title }}</template>
        <template #content="Component">
          <component
            :is="Component.ComponentName"
            :projectItems="project.Items"
            :project="project"
            :managerType="
              $enums.SystemParameters.ParticipantManagerType.Partner
            "
            :participantListFields="participantListFields"
          />
        </template>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: "PartnerQuestionnaires",
  props: {
    project: Object,
  },
  data() {
    return {
      questionnaireTabs: [
        {
          ComponentName: "fp-questionnaire-wait-evaluate-list",
          Title: this.$t(
            "healthProfessional.questionnaires.waitingForEvaluting"
          ),
        },
        {
          ComponentName: "fp-questionnaire-assignable-list",
          Title: this.$t("healthProfessional.questionnaires.assignable"),
        },
      ],
      participantListFields: [
        { key: "Name", label: "Név" },
        { key: "StampNumber", label: "Pecsétszám" },
        { key: "InstitutionName", label: "Intézmény neve" },
      ],
    };
  },
  mounted() {
    this.$emit(
      "title-changed",
      this.$t("healthProfessional.menu.questionnaires")
    );
  },
};
</script>
