<template>
  <b-container fluid class="eh-client-notifications">
    <b-row>
      <b-col cols="12" lg="6" class="eh-client-notification-container">
        {{ $t("client.notification.noti") }}
        <fp-table-list :items="[]"></fp-table-list>
      </b-col>
      <b-col cols="12" lg="6" class="eh-client-notification-container">
        {{ $t("client.notification.invitation") }}
        <fp-table-list :items="invitations" :fields="invitationsField">
          <template #Buttons="row">
            <b-icon
              v-if="row.item.Status == 'Pending'"
              class="h2 text-success"
              @click="accept(row.item)"
              icon="check-circle-fill"
              style="cursor: pointer; padding: 5px"
            ></b-icon>
            <b-icon
              v-if="row.item.Status == 'Pending'"
              class="h2 text-danger"
              @click="reject(row.item)"
              icon="x-circle-fill"
              style="cursor: pointer; padding: 5px"
            ></b-icon>
          </template>
          <template #Status="row">
            {{ $t($enums.InvitationStatus[row.item.Status].Text) }}
          </template>
          <template #Parameters="row">
            {{
              $t(
                $enums.InvitationRelationshipType[
                  row.item.Parameters.RelationshipType
                ].Text
              )
            }}
          </template>
        </fp-table-list>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { InvitationLogic } from "@/Logic/Backend/invitation.js";

export default {
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.menu.notifications"),
      invitations: null,
      invitationsField: [
        {
          key: "RequestedByName",
          label: this.$t("client.notification.requestedBy"),
        },
        { key: "Parameters", label: this.$t("client.notification.parameters") },
        { key: "Status", label: this.$t("client.notification.status") },
        { key: "Buttons", label: "" },
      ],
    };
  },
  methods: {
    async getInvitations() {
      const getInvitationsResponse = await InvitationLogic.getInvitations({
        TargetId: this.$loggedUser.UserId,
      });
      if (!getInvitationsResponse.Code) {
        this.invitations = getInvitationsResponse;
      } else {
        this.$bvToast.toast(getInvitationsResponse.Message, {
          title: this.$("requestResponse.basic.errorGetNamedList", {
            name: this.$t("client.notification.errorInvitationName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async accept(row) {
      const acceptResponse = await InvitationLogic.acceptInvitation(
        row.InvitationId
      );
      if (!acceptResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.notification.successAccept"),
          {
            title: this.$t("requestResponse.notification.successAcceptMessage"),
            variant: "success",
            solid: true,
          }
        );
        await this.getInvitations();
        this.$emit("forceUpdate");
      } else {
        this.$bvToast.toast(acceptResponse.Message, {
          title: this.$t("requestResponse.notification.errorAccept"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async reject(row) {
      const rejectResponse = await InvitationLogic.rejectInvitation(
        row.InvitationId
      );
      if (!rejectResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.notification.successReject"),
          {
            title: this.$t("requestResponse.notification.successRejectMessage"),
            variant: "info",
            solid: true,
          }
        );
        await this.getInvitations();
        this.$emit("forceUpdate");
      } else {
        this.$bvToast.toast(rejectResponse.Message, {
          title: this.$t("requestResponse.notification.errorReject"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getInvitations();
    this.$store.dispatch("setPageTitle", this.siteTitle);
  },
};
</script>
