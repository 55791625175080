<template>
  <div class="eh-participant-component">
    <fp-prescription-list
      :showPatientData="true"
      :projectPatient="projectPatient"
      :projectRoles="
        project && project.Participants.length ? project.Roles : null
      "
      @selectRow="selectedRow"
    />
  </div>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";

export default {
  name: "RecipeManager",
  props: {
    project: Object,
  },
  data() {
    return {
      selectedPrescriptions: null,
      projectPatient: null,
    };
  },
  methods: {
    selectedRow(elements) {
      this.selectedPrescriptions = elements;
    },
    exportElements() {
      console.log("this.selectedPrescriptions", this.selectedPrescriptions);
    },
    async getPatientsBaseData(params) {
      const getUserDataResponse = await UserLogic.getUserData(params);
      if (!getUserDataResponse.Code) {
        return getUserDataResponse;
      } else {
        //TODO: hiba
      }
    },
  },
  async mounted() {
    this.$store.dispatch("setPageTitle", "Recept adminisztráció");
  },
};
</script>
