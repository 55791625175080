export default {
  SystemParameters: {
    ParticipantListLoadType: {
      AllUser: { Value: "AllUser" },
      AllProjectUser: { Value: "AllProjectUser" },
      JustRelationship: { Value: "JustRelationship" },
    },
    TagManagedType: {
      Both: { Value: "Both" },
      ProjectTag: { Value: "ProjectTag" },
      PatientTag: { Value: "PatientTag" },
    },
    ParticipantManagerType: {
      Specialist: "Specialist",
      Partner: "Partner",
    },
    RoleView: {
      Client: { Value: "Client" },
      HealthProfessional: { Value: "HealthProfessional" },
      Partner: { Value: "Partner" },
      Admin: { Value: "Administrator" },
    },
    ProjectParticipantsBaseRole: {
      Doctor: { Value: "Doctor", ViewType: "HealthProfessional" },
      Manager: { Value: "Manager", ViewType: "Partner" },
      Patient: { Value: "Patient", ViewType: "Client" },
    }
  },
};
