<template>
  <div class="eh-menu-content">
    <b-container fluid="true">
      <b-row>
        <!-- Sablonok listája -->
        <b-col cols="12" md="3">
          <fp-template-list
            :items="filteredTemplates"
            :selectedTemplate="selectedTemplate"
            :isNewTemplate.sync="isNewTemplate"
            @createTemplate="isNewTemplate = true"
            @selectingTemplate="selectTemplate"
          />
        </b-col>
        <!-- Kiválasztott sablon részletei -->
        <b-col cols="12" md="9">
          <fp-template-content
            :selectedTemplate="editableTemplate"
            :isNewTemplate.sync="isNewTemplate"
            :isEditTemplate.sync="isEditTemplate"
            :hasPermissionAddToProject="permissionAddTemplateToProject"
            :hasPermissionRemoveFromProject="
              permissionRemoveTemplateFromProject
            "
            :addedToProject="addedTemplateToProject"
            @createTemplate="createTemplate"
            @setTemplate="setTemplate"
            @removeTemplate="removeTemplate"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
import { TemplateLogic } from "../../Logic/Backend/template";

export default {
  name: "Templates",
  props: {
    projectId: Number,
  },
  data() {
    return {
      project: null,
      filteredTemplates: null,
      templates: null,
      selectedTemplate: null,
      editableTemplate: null,
      isNewTemplate: false,
      isEditTemplate: false,
      addedTemplateToProject: false,
      permissionAddTemplateToProject: false,
      permissionRemoveTemplateFromProject: false,
    };
  },
  watch: {
    async projectId() {
      await this.getTemplates();
      this.checkPermission();
    },
  },
  computed: {
    isOwnTemplate() {
      return this.selectedTemplate.OwnerId == this.$loggedUser.UserId;
    },
  },
  methods: {
    //sablonlista betöltése
    async getTemplates() {
      const getTemplatesResponse = await TemplateLogic.getTemplates({
        "-orderbydesc": "TemplateId",
      });
      if (!getTemplatesResponse.Code) {
        this.templates = this.filteredTemplates = getTemplatesResponse;
        await this.getProjectDetailsById();
      } else {
        this.$bvToast.toast(getTemplatesResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("components.templates.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getProjectDetailsById() {
      const getProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.projectId,
      });
      if (!getProjectResponse.Code) {
        this.project = getProjectResponse[0];
      } else {
        //TODO: hiba
      }
    },
    //sablon kiválasztása
    //TODO: projekt lekérdezés változás miatt
    selectTemplate(template) {
      this.editableTemplate = JSON.parse(JSON.stringify(template));
      delete this.editableTemplate.IsActive;
      this.selectedTemplate = template;
      this.isNewTemplate = false;
      this.isEditTemplate = false;
      this.isPanelDefaultClose = true;
      this.addedTemplateToProject = this.project.Items.find(
        (i) =>
          i.EntityTypeName == "NotificationTemplate" &&
          i.EntityId == template.TemplateId
      )
        ? true
        : false;
    },
    //sablon létrehozása
    async createTemplate(templateObject) {
      console.log("CREATE_TEMPLATE");
      const createTemplateResponse = await TemplateLogic.createTemplate(
        templateObject.Template
      );
      if (!createTemplateResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
          title: this.$t("base.basic.save"),
          variant: "success",
          solid: true,
        });
        if (templateObject.AddToProject) {
          await this.addToProject(createTemplateResponse.TemplateId);
        }
        await this.getTemplates();
        this.isNewTemplate = false;
        const newTemplateObject = this.templates.find(
          (t) => t.TemplateId == createTemplateResponse.TemplateId
        );
        if (newTemplateObject) {
          this.selectTemplate(newTemplateObject);
        }
      } else {
        this.$bvToast.toast(createTemplateResponse.Message, {
          title: this.$t("requestResponse.basic.errorSave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //sablon módosítás mentés
    async setTemplate(templateObject) {
      console.log(templateObject.Changes);
      if (templateObject.Changes) {
        const setTemplateResponse = await TemplateLogic.setTemplate(
          this.editableTemplate.TemplateId,
          templateObject.Changes
        );
        if (!setTemplateResponse.Code) {
          this.changeTemplateElements = null;
          this.$bvToast.toast(
            this.$t("requestResponse.basic.successModifySave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(setTemplateResponse.Message, {
            title: this.$t("requestResponse.basic.errorModifySave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
      if (templateObject.AddToProject) {
        await this.addToProject(this.editableTemplate.TemplateId);
      } else {
        await this.removeFromProject(this.editableTemplate.TemplateId);
      }
      await this.getTemplates();
      this.selectTemplate(
        this.templates.find(
          (t) => t.TemplateId == this.editableTemplate.TemplateId
        )
      );
    },
    //sablon hozzáadása a projekthez
    async addToProject(templateId) {
      const body = [
        {
          EntityTypeName: "NotificationTemplate",
          EntityId: templateId,
        },
      ];
      const itemId = this.project.Items.find(
        (item) =>
          item.EntityId == templateId &&
          item.EntityTypeName == "NotificationTemplate"
      );
      if (!itemId && this.permissionAddTemplateToProject) {
        const addToProjectResponse = await ProjectLogic.setItem(
          body,
          this.$loggedUser.SelectedProject.ProjectId
        );
        if (!addToProjectResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.template.successAddToProject"),
            {
              title: this.$t("requestResponse.basic.successAssign"),
              variant: "success",
              solid: true,
            }
          );
           this.$emit('refreshProject');
        } else {
          this.$bvToast.toast(addToProjectResponse.Message, {
            title: this.$t("requestResponse.template.errorAddToProject"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    //sablon eltávolítása a projektből
    async removeFromProject(templateId) {
      const itemId = this.project.Items.find(
        (item) => item.EntityId == templateId
      );
      if (itemId && this.permissionRemoveTemplateFromProject) {
        const removeFormProjectResponse = await ProjectLogic.removeItem(
          this.$loggedUser.SelectedProject.ProjectId,
          itemId.ItemId
        );
        if (!removeFormProjectResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.template.successRemoveFromProject"),
            {
              title: this.$t("requestResponse.basic.successRemove"),
              variant: "info",
              solid: true,
            }
          );
          this.$emit('refreshProject');
        } else {
          this.$bvToast.toast(removeFormProjectResponse.Message, {
            title: this.$t("requestResponse.template.errorRemoveFromProject"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    //sablon törlése
    async removeTemplate() {
      const removeTemplateResponse = await TemplateLogic.deleteTemplate(
        this.editableTemplate.TemplateId
      );
      if (!removeTemplateResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.basic.successDelete"), {
          title: this.$t("base.basic.save"),
          variant: "info",
          solid: true,
        });
        this.removeFromProject(this.editableTemplate.TemplateId);
        await this.getTemplates();
        this.selectedTemplate = this.editableTemplate = null;
      } else {
        this.$bvToast.toast(removeTemplateResponse.Message, {
          title: this.$t("requestResponse.basic.errorDelete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //projekt szerepkörhöz tartozó engedélyek csekkolása
    checkPermission() {
      if (this.project) {
        const rolesByPermissionAddToItem = this.project.Roles.filter((role) =>
          role.Permissions.includes("AddItem")
        );
        const rolesByPermissionRemoveToItem = this.project.Roles.filter(
          (role) => role.Permissions.includes("RemoveItem")
        );
        const ownParticipant = this.project.Participants.find(
          (part) => part.UserId == this.$loggedUser.UserId
        );
        this.permissionAddTemplateToProject = rolesByPermissionAddToItem.find(
          (role) => role.RoleName === ownParticipant.RoleName
        )
          ? true
          : false;
        this.permissionRemoveTemplateFromProject =
          rolesByPermissionRemoveToItem.find(
            (role) => role.RoleName === ownParticipant.RoleName
          )
            ? true
            : false;
      }
    },
  },
  async mounted() {
    if (this.projectId) {
      await this.getTemplates();
      this.checkPermission();
    }
  },
};
</script>
