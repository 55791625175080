<template>
  <b-row cols="1" cols-md="2" v-if="submitter">
    <b-col>
      <fp-input
        label="EESZT azonosító"
        v-model="submitter.EesztId"
        @change="changeValue('EesztId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="ENKK azonosító"
        v-model="submitter.EnkkId"
        @change="changeValue('EnkkId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Vényt rögzítő felhasználó neve"
        v-model="submitter.EesztUsername"
        @change="changeValue('EesztUsername', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Vényt rögzítő teljes neve"
        v-model="submitter.FullName"
        @change="changeValue('FullName', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Egészségügyi szolgáltató EESZT  azonosítója"
        v-model="submitter.OrganizationEesztId"
        @change="changeValue('OrganizationEesztId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Egészségügyi szolgáltató NNK  azonosítója"
        description="Vényt rögzítő felhasználó egészségügyi szolgáltatójának 6 jegyű NNK  azonosítója"
        v-model="submitter.OrganizationNnkId"
        @change="changeValue('OrganizationNnkId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Egészségügyi szolgáltató neve"
        v-model="submitter.OrganizationName"
        @change="changeValue('OrganizationName', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szervezeti egység EESZT azonosítója"
        v-model="submitter.OrganizationUnitEesztId"
        @change="changeValue('OrganizationUnitEesztId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szervezeti egység NNK azonosítója"
        description="Vényt rögzítő felhasználó 9-jegyű NNK szervezeti egység azonosítója"
        v-model="submitter.OrganizationUnitNnkId"
        @change="changeValue('OrganizationUnitNnkId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szervezeti egység neve"
        v-model="submitter.OrganizationUnitName"
        @change="changeValue('OrganizationUnitName', $event)"
        :disabled="disabled"
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "FormSubmitter",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      submitter: null,
      changedSubmitterValue: {},
      defaultSubmitter: {
        EesztId: null,
        EnkkId: null,
        EesztUsername: null,
        FullName: null,
        OrganizationEesztId: null,
        OrganizationNnkId: null,
        OrganizationName: null,
        OrganizationUnitEesztId: null,
        OrganizationUnitNnkId: null,
        OrganizationUnitName: null,
      },
    };
  },
  watch: {
    value() {
      this.setSubmitter();
    },
  },
  computed: {
    hasSubmitterData() {
      return Object.values(this.submitter).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    changeValue(key, value) {
      if (this.hasSubmitterData) {
        this.changedSubmitterValue[key] = value;
        this.$emit("changeValue", this.changedSubmitterValue);
      } else {
        this.$emit("changeValue", null);
      }
    },
    setSubmitter() {
      if (this.value) {
        this.submitter = this.value;
      } else {
        this.submitter = JSON.parse(JSON.stringify(this.defaultSubmitter));
      }
      this.changedSubmitterValue = null;
    },
  },
  mounted() {
    this.setSubmitter();
  },
};
</script>
