<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :projectEnabledUiElements="project ? project.EnabledUiElements : null"
      :menuElements="partnerMenuElements"
      :enabledMenuElements="project ? project.EnabledUiElements.Partner : null"
      @forceUpdate="forceUpdate"
    />
    <router-view
      :key="'views-partner-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="partner"
      :project="project"
      @title-changed="changeTitle"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
      @forceUpdate="forceUpdate"
    />
    <Footer
      v-if="
        currentRouteName === 'WebAppPartnerHome' ||
        currentRouteName === 'WebAppPartnerLogs' ||
        currentRouteName === 'WebAppPartnerResearch'
      "
    />
  </div>
</template>
<script>
import Footer from "@/Views/Partner/footer-menu/WebAppPartnerFooter.vue";
import { ProjectLogic } from "@/Logic/Backend/project";
import { mapActions } from "vuex";

export default {
  name: "PartnerRouterView",
  components: {
    Footer,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      profileFillingRate: null,
      forceUpdateKey: 0,
      project: null,
      partnerMenuElements: [
        /* {
          url: "RegistrationParticipant",
          langKey: "partner.menu.registrationParticipant",
          key: "registration-participant",
        }, */
        {
          url: "ParticipantList",
          urlName: "WebAppPartnerParticipantList",
          langKey: "partner.participantList.title",
          key: "participant-list",
        },
        {
          url: "Projects",
          urlName: "WebAppPartnerProjects",
          langKey: "partner.projects.title",
          key: "project",
        },
        {
          url: "Documents",
          urlName: "WebAppPartnerDocuments",
          langKey: "partner.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "Tasks",
          urlName: "WebAppPartnerTasks",
          langKey: "partner.tasks.title",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Workflows",
          urlName: "WebAppPartnerWorkflows",
          langKey: "partner.workflows.title",
          uiElementName: "Workflow",
          key: "workflow",
        },
        {
          url: "Templates",
          urlName: "WebAppPartnerTemplates",
          langKey: "partner.menu.templates",
          uiElementName: "NotificationTemplate",
          key: "template",
        },
        {
          url: "Questionnaires",
          urlName: "WebAppPartnerQuestionnaires",
          langKey: "healthProfessional.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(["setProjectPermissions"]),
    async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async getProject() {
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
          this.setStore();
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: "Hiba",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          this.project = null;
        }
      } else {
        this.project = null;
      }
    },
    setStore() {
      const ownProjectParticipant = this.project.Participants.find(
        (participant) => participant.UserId == this.$loggedUser.UserId
      );
      if (ownProjectParticipant) {
        const ownProjectRole = this.project.Roles.find(
          (role) => role.RoleName == ownProjectParticipant.RoleName
        );
        if (ownProjectParticipant) {
          this.setProjectPermissions(ownProjectRole.Permissions);
        }
      }
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.siteTitle = newTitle;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
  },
  async mounted() {
    await this.getProject();
  },
};
</script>
