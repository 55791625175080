<template>
  <div class="eh-client-home">
    <b-container fluid>
      <!-- Engedélyezett modulok -->
      <!-- Engedélyezett modulok fejléc (cím és gomb elhelyezés) -->
      <b-row align-h="between" align-v="center">
        <b-col cols="12" lg="8" class="eh-flex-center-container">
          <label class="citizen-home-modules-title">{{
            $t("client.home.enabledModules")
          }}</label>
        </b-col>
        <b-col cols="12" lg="4" class="eh-flex-center-container">
          <router-link :to="{ name: 'ModuleSettings' }">
            <button style="margin: 10px" class="eh-action-button">
              {{ $t("client.home.settingModules") }}
            </button>
          </router-link>
        </b-col>
      </b-row>
      <!-- Engedélyezett modulok elemeinek megjelenítése -->
      <table class="eh-client-home-table">
        <td
          v-for="module in enabledModules"
          :key="module.Name"
          class="eh-client-home-module-box"
        >
          <!-- Engedélyezett modul felépítése -->
          <router-link
            style="text-decoration-line: none"
            :to="{
              name: 'ClientModule',
              params: { openedModuleName: module.Name },
            }"
          >
            <tr>
              <div class="module-header">
                <div style="display: flex;">
                  <!-- Engedélyezett modul ikonja -->
                  <img
                    :style="
                      'background:' + module.Color + '; border-radius: 50px;'
                    "
                    v-if="module.IconURL"
                    class="program-img"
                    :src="require('@/Assets/' + module.IconURL)"
                    basic
                  />
                  <div
                    v-else
                    :style="
                      'background:' + module.Color + '; border-radius: 50px;'
                    "
                    style="width: 70px; height: 70px; margin: 10px"
                  ></div>
                  <!-- Engedélyezett modul neve -->
                  <label class="module-title">{{
                    $t("module.modules." + module.Name)
                  }}</label>
                </div>
                <!-- Engedélyezett modul aktuális havi (esetenként heti) elsőként beállított chart megjelenése -->
                <div>
                  <component
                    style="margin-top: -25px"
                    v-if="
                      module.Charts &&
                      Object.keys(module.Charts).length &&
                      getFirstChart(module)
                    "
                    :is="getFirstChart(module).ComponentName"
                    :IsDashboard="true"
                    :Interval="'Month'"
                    :ChartType="'chart'"
                    :Chart="getFirstChart(module)"
                  ></component>
                  <div v-else style="magin: auto; text-align: center">
                    Fejlesztés alatt
                  </div>
                </div>
              </div>
            </tr>
          </router-link>
        </td>
      </table>
      <!-- További almenü gombok -->
      <div class="eh-flex-center-container">
        <table style="margin: 20px auto">
          <!-- Kommunikációs almenüpont -->
          <!--<router-link to="Communication">
                    <td class="program-box">
                        <button class="eh-action-button">
                            {{
                      $t("client.menu.communication")
                            }}
                        </button>
                    </td>
                </router-link>-->
          <!-- Jelvények almenüpont -->
          <router-link to="Badges">
            <td class="program-box">
              <button class="eh-action-button">
                {{ $t("client.menu.badges") }}
              </button>
            </td>
          </router-link>
          <!-- Gondozási programok almenüpont -->
          <!-- <router-link to="CarePrograms">
                  <td class="program-box">
                    <ejs-button class="webapp-homepage-button">{{
                      $t("client.home.carePrograms")
                    }}</ejs-button>
                  </td>
                </router-link> -->
          <!-- Egészség programok almenüpont -->
          <!-- <router-link to="HealthPrograms">
                  <td class="program-box">
                    <ejs-button class="webapp-homepage-button">{{
                      $t("client.home.healthPrograms")
                    }}</ejs-button>
                  </td>
                </router-link> -->
          <!-- Betegség kalauz almenüpont -->
          <router-link to="DiseaseGuide">
            <td class="program-box">
              <button class="eh-action-button">
                {{ $t("client.menu.diseaseGuide") }}
              </button>
            </td>
          </router-link>
          <!-- Dokumentum tár almenüpont -->
          <!-- <router-link to="Documents">
                  <td class="program-box">
                    <ejs-button class="webapp-homepage-button">{{
                      $t("client.home.documents")
                    }}</ejs-button>
                  </td>
                </router-link> -->
          <!-- Szolgáltatás kereső almenüpont -->
          <router-link to="ServiceFinder">
            <td class="program-box">
              <button class="eh-action-button">
                {{ $t("client.menu.serviceFinder") }}
              </button>
            </td>
          </router-link>
          <!-- Kérdőívek almenüpont -->
          <!-- <router-link to="QuestionnaireList">
                  <td class="program-box">
                    <ejs-button class="webapp-homepage-button">{{
                      $t("client.home.questionnaires")
                    }}</ejs-button>
                  </td>
                </router-link> -->
          <!-- Időpontok almenüpont -->
          <!-- <router-link to="Appointments">
                  <td class="program-box">
                    <ejs-button class="webapp-homepage-button"
                      >Időpontok</ejs-button
                    >
                  </td>
                </router-link> -->
        </table>
      </div>
    </b-container>
  </div>
</template>
<script>
import { UserPreferencesLogic } from "@/Logic/Backend/user-preferences";

export default {
  name: "Home",
  data() {
    return {
      enabledModules: [],
      userModules: null,
      siteTitle: this.$t("client.menu.homeButton"),
    };
  },
  props: {
    enabledProjectModules: Array,
  },
  watch: {
    enabledProjectModules() {
      this.loadEnabledModules();
    },
  },
  methods: {
    async getEnabledModules() {
      const getUserPreferencesResponse =
        await UserPreferencesLogic.getUserPreferences();
      if (
        !getUserPreferencesResponse.Code &&
        getUserPreferencesResponse.Modules
      ) {
        this.userModules = getUserPreferencesResponse.Modules;
        this.loadEnabledModules();
      } else {
        this.$bvToast.toast(getUserPreferencesResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.modules.module"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    loadEnabledModules() {
      var temp = UserPreferencesLogic.getEnabledModules(this.userModules);

      if (this.enabledProjectModules) {
        temp = temp.filter((x) => this.enabledProjectModules.includes(x.Name));
      }

      this.enabledModules = temp;
    },
    getFirstChart(module) {
      //segédváltozó definiálás
      var temp = [];
      //modul chart objektumokon végig futunk
      Object.values(module.Charts).forEach((value) => {
        //elérhető a chart?
        if (value.Enabled == true) {
          //igen, ideiglenes változóban tároljuk az elérhető chartokat
          temp.push(value);
        }
      });
      //a modulban elérhető chartokat sorrendezzük az OrderNumber alapján
      temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
      //első chartot adjuk vissza
      return temp[0];
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", this.siteTitle);
    //oldal szín beállítás
    this.$emit("color-change", "transparent");
    await this.getEnabledModules();
  },
};
</script>
<style scoped>
table > td {
  vertical-align: top;
}

.module-header {
  background: #cddbe5;
  height: 60px;
  width: 350px;
  text-align: left;
  color: #698393;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
}
.program-img {
  width: 70px;
  height: 70px;
  margin: 10px;
}
.module-title {
  margin-top: 20px;
  height: 60px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
