<template>
  <fp-form-modal
    v-if="editableRelationship"
    v-model="showEditParticipant"
    @hidden="clearEditRelationship"
    title="Résztvevő kapcsolatok szerkesztése"
  >
    <template #content>
      <fp-select
        v-if="participantList"
        type="multi"
        label="Felettesek"
        :searchable="true"
        :items="superiorList"
        valueKey="UserId"
        textKey="Username"
        v-model="editableRelationship.SuperiorIds"
        @search="filterParticipants"
        @change="changValue('SuperiorIds', $event)"
      />
      <fp-select
        v-if="participantList"
        type="multi"
        label="Alárendeltek"
        :searchable="true"
        :items="subordinateList"
        valueKey="UserId"
        textKey="Username"
        v-model="editableRelationship.SubordinateIds"
        @search="filterParticipants"
        @change="changValue('SuperiorIds', $event)"
      />
      <b-button @click="setParticipant()">{{ $t("base.basic.save") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";
import { ProjectLogic } from "@/Logic/Backend/project";

export default {
  name: "EditParticipantRelationship",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    participantList: Array,
    participant: Object,
    projectId: Number,
  },
  data() {
    return {
      showEditParticipant: this.visible,
      defaultRelationship: {
        SuperiorIds: [],
        SubordinateIds: [],
      },
      editableRelationship: null,
      selectedUser: null,
      userList: null,
      selectedRoleName: null,
      filteredParticipantList: null,
      changedValues: null,
    };
  },
  watch: {
    visible(value) {
      this.showEditParticipant = value;
      this.setEditableRelationship();
    },
    showEditParticipant(input) {
      this.$emit("change", input);
    },
    participantList(input) {
      this.filteredParticipantList = input;
    },
  },
  computed: {
    subordinateList() {
      if (this.filteredParticipantList) {
        return this.filteredParticipantList.filter(
          (p) => !this.editableRelationship.SuperiorIds.includes(p.UserId)
        );
      }
      return null;
    },
    superiorList() {
      if (this.filteredParticipantList) {
        return this.filteredParticipantList.filter(
          (p) => !this.editableRelationship.SubordinateIds.includes(p.UserId)
        );
      }
      return null;
    },
  },
  methods: {
    changValue(path, value) {
      if (!this.changedValues) {
        this.changedValues = {};
      }
      this.$set(this.changedValues, path, value);
    },
    filterParticipants(input) {
      this.filteredParticipantList = this.$filterList(
        input,
        this.participantList,
        ["Username"]
      );
    },
    //új résztvevő felvétele a projektbe
    async setParticipant() {
      const addSuperiorIds = this.getPlusIds(
        this.participant.SuperiorIds,
        this.editableRelationship.SuperiorIds
      );
      const deleteSuperiorIds = this.getPlusIds(
        this.editableRelationship.SuperiorIds,
        this.participant.SuperiorIds
      );
      const addSubordinateIds = this.getPlusIds(
        this.participant.SubordinateIds,
        this.editableRelationship.SubordinateIds
      );
      const deleteSubordinateIds = this.getPlusIds(
        this.editableRelationship.SubordinateIds,
        this.participant.SubordinateIds
      );
      console.log(addSuperiorIds);
      console.log(deleteSuperiorIds);
      console.log(addSubordinateIds);
      console.log(deleteSubordinateIds);
      if (addSuperiorIds && addSuperiorIds.length) {
        await this.addSuperiorIds(addSuperiorIds);
      }
      if (deleteSuperiorIds && deleteSuperiorIds.length) {
        await this.deleteSuperiorIds(deleteSuperiorIds);
      }
      if (addSubordinateIds && addSubordinateIds.length) {
        await this.addSubordinateIds(addSubordinateIds);
      }
      if (deleteSubordinateIds && deleteSubordinateIds.length) {
        await this.deleteSubordinateIds(deleteSubordinateIds);
      }
      this.$emit("refreshProject");
      this.showEditParticipant = false;
    },
    getPlusIds(origin, changed) {
      var diff = [];
      changed.forEach((ch) => {
        if (!origin.includes(ch)) {
          diff.push(ch);
        }
      });
      return diff;
    },
    async addSuperiorIds(superiorIds) {
      const body = [];
      superiorIds.forEach((id) => {
        body.push({
          SubordinateId: this.participant.UserId,
          SuperiorId: id,
        });
      });
      const addResponse = await ProjectLogic.createRelationship(
        body,
        this.projectId
      );
      if (!addResponse.Code) {
        this.$bvToast.toast(
          "Sikeresen hozzáadott a felhasználóhoz " +
            body.length +
            " db projekten belüli alárendeltet.",
          {
            title: "Sikeres mentés",
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(addResponse.Message, {
          title: "Hiba történt a projekt kapcsolat szerkesztése során.",
          variant: "danger",
          solid: true,
        });
      }
    },
    async deleteSuperiorIds(superiorIds) {
      const body = [];
      superiorIds.forEach((id) => {
        body.push({
          SubordinateId: this.participant.UserId,
          SuperiorId: id,
        });
      });
      const addResponse = await ProjectLogic.deleteRelationship(
        body,
        this.projectId
      );
      if (!addResponse.Code) {
        this.$bvToast.toast(
          "Sikeresen törölve a felhasználóhoz " +
            body.length +
            " db projekten belüli felettest.",
          {
            title: "Sikeres törlés",
            variant: "info",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(addResponse.Message, {
          title: "Hiba történt a projekt kapcsolat szerkesztése során.",
          variant: "danger",
          solid: true,
        });
      }
    },
    async addSubordinateIds(superiorIds) {
      const body = [];
      superiorIds.forEach((id) => {
        body.push({
          SubordinateId: id,
          SuperiorId: this.participant.UserId,
        });
      });
      const addResponse = await ProjectLogic.createRelationship(
        body,
        this.projectId
      );
      if (!addResponse.Code) {
        this.$bvToast.toast(
          "Sikeresen hozzáadott a felhasználóhoz " +
            body.length +
            " db projekten belüli felettest.",
          {
            title: "Sikeres mentés",
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(addResponse.Message, {
          title: "Hiba történt a projekt kapcsolat szerkesztése során.",
          variant: "danger",
          solid: true,
        });
      }
    },
    async deleteSubordinateIds(superiorIds) {
      const body = [];
      superiorIds.forEach((id) => {
        body.push({
          SubordinateId: id,
          SuperiorId: this.participant.UserId,
        });
      });
      const addResponse = await ProjectLogic.deleteRelationship(
        body,
        this.projectId
      );
      if (!addResponse.Code) {
        this.$bvToast.toast(
          "Sikeresen törölve a felhasználóhoz " +
            body.length +
            " db projekten belüli alárendeltet.",
          {
            title: "Sikeres törlés",
            variant: "info",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(addResponse.Message, {
          title: "Hiba történt a projekt kapcsolat szerkesztése során.",
          variant: "danger",
          solid: true,
        });
      }
    },
    //felhasználó lista szűrése felhasználónév szerint
    async searchUserByName(e) {
      //a kereső input több mint 2 karakterből áll?
      if (e && e.length >= 3) {
        //igen, felhasználó lista kérés input alapján
        await this.getUserList({
          "Username-contains": e,
        });
      } else {
        //nem, első 10 felhasználó lista kérés
        await this.getUserList({
          "-limit": 10,
        });
      }
    },
    //felhasználó lista kérés
    async getUserList(params) {
      //kérés indítás megadott params alapján
      const userListResponse = await UserLogic.getUser(params);
      //sikeres kérés?
      if (!userListResponse.Code) {
        //igen, kapott lista tárolása
        this.userList = userListResponse;
      } else {
        this.$bvToast.toast(userListResponse.Message, {
          title: this.$t("base.error"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteg hozzáadása űrlap ürítése
    clearEditRelationship() {
      this.editableRelationship = JSON.parse(
        JSON.stringify(this.defaultRelationship)
      );
    },
    setEditableRelationship() {
      this.editableRelationship = JSON.parse(
        JSON.stringify(this.defaultRelationship)
      );
      if (this.participant) {
        this.editableRelationship.SuperiorIds = this.participant.SuperiorIds;
        this.editableRelationship.SubordinateIds =
          this.participant.SubordinateIds;
      }
      this.changedValues = null;
    },
  },
  async mounted() {
    this.setEditableRelationship();
    //felhasználó lista kérés
    await this.getUserList({ "-limit": 10 });
  },
};
</script>
