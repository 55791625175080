<template>
  <div>
    <!-- TODO: bekötés! -->
    <b-button style="margin-top: 10px" @click="$router.go(-1)">{{
      $t("base.basic.back")
    }}</b-button>
    <fp-form-schema-builder
      v-if="selectedQuestionnaire"
      :instance="selectedQuestionnaire"
      :disabled="true"
      :scoreable="formEvaluable"
      @closeForm="closeForm"
    />
  </div>
</template>
<script>
import { FormLogic } from "@/Logic/Backend/form";
export default {
  data() {
    return {
      selectedQuestionnaire: null,
      questionnaireId: null,
      formEvaluable: false,
    };
  },
  methods: {
    //kiválasztott kérdőív példány kérés
    async getSelectesQuestionannaire() {
      //kérés indítás id alapján
      const questionnaireListResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.questionnaireId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kapott lista első eleme tárolása
        this.selectedQuestionnaire = questionnaireListResponse[0];
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      if (this.selectedQuestionnaire) {
        this.formEvaluable = [
          this.$enums.QuestionnaireStatus.Filled.Value,
          this.$enums.QuestionnaireStatus.UnderEvaluation.Value,
        ].includes(this.selectedQuestionnaire.Status);
      }
    },
    //értékelés mentés nélkül vissza lépés a listába
    closeForm() {
      this.$router.push({ name: "WebPartnerQuestionnaires" });
    },
  },
  //első betöltéskor fut le
  async mounted() {
    console.log("questionnaire");
    //urlben küldött id tárolása
    const taskParams = this.$route.query.taskParams;
    if (taskParams) {
      this.questionnaireId = JSON.parse(taskParams).FormInstanceId;
    } else {
      this.questionnaireId = this.$route.query.Id;
    }
    //nics tárolt id?
    if (!this.questionnaireId) {
      //vissza lépés a listába
      this.$router.push({ name: "WebAppPartnerQuestionnaires" });
    }
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", "Kérdőív kiértékelés");
    //kiválasztott kérdőív példány kérés
    await this.getSelectesQuestionannaire();
  },
};
</script>
