var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.product)?_c('b-row',{attrs:{"cols":"1","cols-md":"2"}},[_c('b-col',[_c('fp-panel-card',{staticStyle:{"margin":"20px 0px"},attrs:{"title":"Kiváltott termék mennyisége"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('product-amount',{attrs:{"disabled":true,"value":_vm.dispenseProduct.Amount}})]},proxy:true}],null,false,568860422)})],1),_c('b-col',[_c('fp-panel-card',{staticStyle:{"margin":"20px 0px"},attrs:{"title":"Kiváltott termék finanszírozási árai","description":_vm.dispenseProduct.Amount
            ? 'A fizetés információ egy ' +
              _vm.dispenseProduct.Amount.Unit +
              ' mennyiségre értendő'
            : 'A fizetés információ egy egységnyi mennyiségre értendő'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('financial-info',{attrs:{"disabled":!_vm.hasUniqueLicensedProduct},model:{value:(_vm.product.FinancialInfo),callback:function ($$v) {_vm.$set(_vm.product, "FinancialInfo", $$v)},expression:"product.FinancialInfo"}})]},proxy:true}],null,false,978274545)})],1),_c('b-col',[_c('fp-select',{attrs:{"label":"NEAK jogcím kód","required":true,"items":_vm.neakLegalType,"valueKey":"EntryId","textKey":"Name","state":!_vm.validation.hasError('product.NeakLegalTypeId'),"error":_vm.validation.firstError('product.NeakLegalTypeId')},model:{value:(_vm.product.NeakLegalTypeId),callback:function ($$v) {_vm.$set(_vm.product, "NeakLegalTypeId", $$v)},expression:"product.NeakLegalTypeId"}})],1),_c('b-col',[_c('fp-input',{attrs:{"label":"Egyedi engedélyszám","description":"Kötelező mező, ha a termék NEAK külön engedélyes!","state":!(
            _vm.hasUniqueLicensedProduct &&
            _vm.validation.hasError('product.ProductLicenseNumber')
          ),"error":_vm.validation.firstError('product.ProductLicenseNumber')},model:{value:(_vm.product.ProductLicenseNumber),callback:function ($$v) {_vm.$set(_vm.product, "ProductLicenseNumber", $$v)},expression:"product.ProductLicenseNumber"}})],1),_c('b-col',[_c('fp-select',{attrs:{"label":"NEAK forgalmi kód","required":true,"items":_vm.neakFinancialCode,"valueKey":"EntryId","textKey":"Name","state":!_vm.validation.hasError('product.NeakFinancialCodeId'),"error":_vm.validation.firstError('product.NeakFinancialCodeId')},model:{value:(_vm.product.NeakFinancialCodeId),callback:function ($$v) {_vm.$set(_vm.product, "NeakFinancialCodeId", $$v)},expression:"product.NeakFinancialCodeId"}})],1),_c('b-col',[_c('fp-select',{attrs:{"label":"NEAK magisztrális díjosztály","required":true,"items":_vm.neakMagistralfeeClass,"valueKey":"EntryId","textKey":"Name","state":!_vm.validation.hasError('product.NeakMagistralFeeClassId'),"error":_vm.validation.firstError('product.NeakMagistralFeeClassId')},model:{value:(_vm.product.NeakMagistralFeeClassId),callback:function ($$v) {_vm.$set(_vm.product, "NeakMagistralFeeClassId", $$v)},expression:"product.NeakMagistralFeeClassId"}})],1),_c('b-col',[_c('fp-select',{attrs:{"disabled":_vm.prescriptionProduct && _vm.prescriptionProduct.Substitutable != null,"label":"Helyettesíthető a termék?","required":true,"items":_vm.threeStateList,"valueKey":"Value","textKey":"Text","state":!_vm.validation.hasError('product.Substituitable'),"error":_vm.validation.firstError('product.Substituitable')},model:{value:(_vm.product.Substituitable),callback:function ($$v) {_vm.$set(_vm.product, "Substituitable", $$v)},expression:"product.Substituitable"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }