<template>
  <div class="disease-guide">
    <div>
      <b-row cols="1" align-h="center">
        <div class="half-page-width">
          <fp-input
            :label="$t('client.diseaseGuide.diseaseName')"
            v-model="filterInputs.Name"
          />
        </div>
        <div>{{ $t("client.diseaseGuide.filteredFields") }}</div>
        <div class="half-page-width" style="margin-top: 20px; text-align: left">
          <b-row cols="1" cols-md="2">
            <div style="padding: 0px 5px">
              <fp-select
                :label="$t('client.diseaseGuide.diseaseSexList')"
                v-model="filterInputs.Sex"
                :items="Object.values(sexListItems)"
                valueKey="Value"
              >
                <template #element="element">
                  {{ $t(element.Text) }}
                </template>
              </fp-select>
            </div>
            <div style="padding: 0px 5px">
              <fp-select
                :label="$t('client.diseaseGuide.diseaseBodyPartList')"
                v-model="filterInputs.BodyPart"
                :items="bodyPartListItems"
                valueKey="Code"
                textKey="Name"
              />
            </div>
          </b-row>
          <b-row cols="1" cols-md="2">
            <div style="padding: 0px 5px">
              <fp-input
                v-model="filterInputs.IcdCode"
                :label="$t('client.diseaseGuide.diseaseBNOCode')"
              />
            </div>
            <div style="text-align: center">
              <b-button
                v-on:click="cleanFilter"
                style="margin-bottom: 20px; margin-top: 5px"
                class="sub-button eh-action-button"
                >{{ $t("base.basic.clearFilter") }}</b-button
              >
            </div>
            <div style="text-align: center">
              <b-button
                v-on:click="search"
                style="margin-bottom: 20px"
                class="main-button eh-action-button"
                >{{ $t("base.basic.search") }}</b-button
              >
            </div>
          </b-row>
        </div>
      </b-row>
      <b-list-group style="margin: 10px 0px">
        <b-list-group-item style="text-align: center; color: #000">{{
          $t("client.diseaseGuide.diseaseList")
        }}</b-list-group-item>
        <b-list-group-item
          style="color: #000"
          v-for="data in diseaseList"
          :key="data.DiseaseId"
          href="#"
          @click="selectDisease(data)"
        >
          <b-row
            style="margin: 0px"
            cols="1"
            align-h="between"
            align-v="center"
          >
            <label>{{ $getLanguagedText(data.Name) }}</label>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
    <fp-form-modal
      v-if="selectedDisease"
      size="xl"
      style="background: transparent"
      id="diseaseDetails-modal"
      v-model="selectedDisease.IsActive"
      :title="$getLanguagedText(selectedDisease.Name)"
      hide-footer
    >
      <!-- <template #header>
        <div style="width: 100%; background: #f7b944" class="module-header">
          <b-row style="margin: 0px" align-h="between">
            <label
              class="module-title"
              style="color: var(--eh-white); margin-left: 10px"
              >{{ selectedDisease.Name }}</label
            >
            <img
              @click="selectedDisease.IsActive = false"
              style="width: 20px; height: 20px; margin: 20px; cursor: pointer"
              src="@/Assets/settings-button/muvelet9.png"
            />
          </b-row>
        </div>
      </template> -->
      <template #content>
        <div>
          <b>{{ $t("client.diseaseGuide.diseaseBNOCode") }}:</b>
          {{ selectedDisease.IcdCode }}
          <br />
          <b>{{ $t("client.diseaseGuide.diseaseBodyPart") }}</b>
          {{ getDescriptionName(bodyPartListItems, selectedDisease.BodyPart) }}
          <br />
          <b>{{ $t("client.diseaseGuide.diseaseSex") }}</b>
          {{ $t($enums.DiseaseGuideSex[selectedDisease.Sex].Text) }}
          <br /><b>{{ $t("client.diseaseGuide.diseaseDescription") }}</b>
          <label
            v-html="$getLanguagedText(selectedDisease.Description)"
          ></label>
        </div>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import { BaseDataLogic } from "@/Logic/Backend/base-data.js";
export default {
  name: "DiseaseGuide",
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.menu.diseaseGuide"),
      selectedDisease: {},
      diseaseList: [],
      checkFields: { text: "Name", value: "Code" },
      //TODO: enomsítani
      bodyPartListItems: [
        { Name: "Testrészhez nem köthető", Code: "Any", id: 0 },
        { Name: "Fej", Code: "Head", id: 1 },
        { Name: "Mellkas", Code: "Chest", id: 2 },
        { Name: "Has és ágyék", Code: "Abdomen", id: 3 },
        { Name: "Hát", Code: "Back", id: 4 },
        { Name: "Felső végtag", Code: "UpperLimbs", id: 5 },
        { Name: "Alsó végtag", Code: "LowerLimbs", id: 6 },
      ],
      /* Enum */
      sexListItems: this.$enums.DiseaseGuideSex,
      filterInputs: {
        Name: null,
        Sex: null,
        BodyPart: null,
        IcdCode: null,
      },
      alert: {
        Message: "",
        IsShow: false,
      },
    };
  },
  methods: {
    //
    getDescriptionName(list, data) {
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.Code == data) {
          return element.Name;
        }
      }
    },
    async search() {
      const params = {
        ["Name." + this.$i18n.locale]: this.filterInputs.Name,
        Sex: this.filterInputs.Sex,
        BodyPart: this.filterInputs.BodyPart,
        ["IcdCode-contains"]: this.filterInputs.IcdCode,
      };
      await this.getDiseaseList(params);
    },
    async getDiseaseList(params) {
      const result = await BaseDataLogic.getBaseData("disease_guide", params);
      if (!result.Code) {
        this.diseaseList = result;
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.basic.errorGetDiseaseList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    selectDisease(disease) {
      this.selectedDisease = {
        IsActive: true,
        Name: disease.Name,
        IcdCode: disease.IcdCode,
        BodyPart: disease.BodyPart,
        Sex: disease.Sex,
        Description: disease.Description,
      };
    },
    async cleanFilter() {
      this.filterInputs = {
        Name: null,
        Sex: null,
        BodyPart: null,
        IcdCode: null,
      };
      await this.getDiseaseList();
    },
  },
  async mounted() {
    this.$store.dispatch("setPageTitle", this.siteTitle);
    await this.getDiseaseList();
  },
};
</script>
<style>
.disease-guide {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
#diseaseDetails-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
#diseaseDetails-modal .modal-content header {
  padding: 0px;
}
div.half-page-width {
  flex: 0 0 50%;
}
@media (max-width: 600px) {
  div.half-page-width {
    flex: 0 0 100%;
  }
}
</style>
