<template>
  <fp-form-modal
    :title="$t('healthProfessional.patientList.tag.editTag')"
    v-model="visible"
  >
    <template #content>
      <fp-select
        v-if="showPersonalTags"
        v-model="selectedPersonalTags"
        :label="$t('Tag.PersonalTagType')"
        :items="personalTagList"
        valueKey="TagId"
        :textKeyConverter="
          (e) => {
            return $getLanguagedText(e.Name);
          }
        "
        type="multi"
        :searchable="false"
        @search="searchTag($event, $enums.Tag.TagListType.Own.Value)"
      >
        <template #element="tag">
          <span style="margin-left: 5px">
            {{ $getLanguagedText(tag.Name) }}
          </span>
          <b-icon
            icon="bookmark-fill"
            scale="1.5"
            :style="'color:' + tag.ColorCode"
            style="margin-left: 5px"
          />
        </template>
      </fp-select>
      <fp-select
        v-if="showProjectTags"
        v-model="selectedProjectTags"
        :label="$t('Tag.ProjectTagType')"
        :items="projectTagList"
        valueKey="TagId"
        :textKeyConverter="
          (e) => {
            return $getLanguagedText(e.Name);
          }
        "
        type="multi"
        :searchable="false"
        @search="searchTag($event, $enums.Tag.TagListType.Project.Value)"
      >
        <template #element="tag">
          <span style="margin-left: 5px">{{
            $getLanguagedText(tag.Name)
          }}</span>
          <b-icon
            icon="bookmark-fill"
            scale="1.5"
            :style="'color:' + tag.ColorCode"
            style="margin-left: 5px"
          />
        </template>
      </fp-select>
      <b-button @click="setParticipantTags">{{
        $t("base.basic.save")
      }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { TagLogic } from "../../Logic/Backend/tag";
export default {
  name: "TagSelector",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    participant: Object,
    tagListType: String,
    project: Object,
  },
  data() {
    return {
      visible: this.value,
      personalTagList: null,
      projectTagList: null,
      selectedPersonalTags: null,
      selectedProjectTags: null,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
    participant() {
      this.selectedPersonalTags = this.getSelectedTags(this.personalTagList);
      this.selectedProjectTags = this.getSelectedTags(this.projectTagList);
    },
    personalTagList() {
      this.selectedPersonalTags = this.getSelectedTags(this.personalTagList);
    },
    projectTagList() {
      this.selectedPersonalTags = this.getSelectedTags(this.projectTagList);
    },
  },
  computed: {
    /* selectedPersonalTags() {
      return this.getSelectedTags(this.personalTagList);
    },
    selectedProjectTags() {
      return this.getSelectedTags(this.projectTagList);
    }, */
    showPersonalTags() {
      return (
        this.tagListType == this.$enums.Tag.TagListType.Both.Value ||
        this.tagListType == this.$enums.Tag.TagListType.Own.Value
      );
    },
    showProjectTags() {
      return (
        this.tagListType == this.$enums.Tag.TagListType.Both.Value ||
        this.tagListType == this.$enums.Tag.TagListType.Project.Value
      );
    },
  },
  methods: {
    getSelectedTags(tagList) {
      if (tagList && this.participant && this.participant.Tags) {
        return tagList
          .filter((t) =>
            this.participant.Tags.map((t) => t.TagId).includes(t.TagId)
          )
          .map((t) => t.TagId);
      } else {
        return null;
      }
    },
    async searchTag(input, tagType) {
      if (input) {
        if (input.length > 2) {
          const params = {
            ["TagName." + [this.$i18n.locale]]: input,
            ["-orderby"]: "TagId",
            ["-limit"]: 10,
          };
          await this.getTagByType(tagType, params);
        } else {
          this.personalTagList = [];
          this.projectTagList = [];
        }
      } else {
        const params = { ["-orderby"]: "TagId", ["-limit"]: 10 };
        await this.getTagByType(tagType, params);
      }
    },
    async getTagByType(tagType, params) {
      if (
        tagType == this.$enums.Tag.TagListType.Project.Value &&
        this.project
      ) {
        const paramsFull = {
          Type: "ProjectTag",
          ...params,
        };
        const pTags = await this.getTag(paramsFull);
        this.projectTagList = pTags.filter((t) =>
          this.project.Items.find(
            (i) => i.EntityTypeName == "FpTag" && i.EntityId == t.TagId
          )
        );
      } else if (tagType == this.$enums.Tag.TagListType.Own.Value) {
        const paramsFull = {
          Type: "PatientTag",
          OwnerId: this.$loggedUser.UserId,
          ...params,
        };
        this.personalTagList = await this.getTag(paramsFull);
      }
    },
    async getTag(params) {
      const getResposne = await TagLogic.getTags(params);
      if (!getResposne.Code) {
        return getResposne;
      } else {
        return null;
        //TODO: hiba
      }
    },
    async setParticipantTags() {
      const participantTags = {
        TagIds: [...this.selectedPersonalTags, ...this.selectedProjectTags],
      };
      const setTagsResponse = await ProjectLogic.setParticipant(
        this.$loggedUser.SelectedProject.ProjectId,
        this.participant.UserId,
        participantTags
      );
      if (!setTagsResponse.Code) {
        this.visible = false;
        this.$emit("changePatientList", this.participant);
      } else {
        this.$bvToast.toast(setTagsResponse.Message, {
          title: this.$t("requestResponse.base.erroModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async setList() {
      const getTagParams = { ["-orderby"]: "TagId", ["-limit"]: 10 };

      await this.getTagByType(
        this.$enums.Tag.TagListType.Own.Value,
        getTagParams
      );

      await this.getTagByType(
        this.$enums.Tag.TagListType.Project.Value,
        getTagParams
      );
    },
  },
  async mounted() {
    await this.setList();
    this.selectedPersonalTags = this.getSelectedTags(this.personalTagList);
    this.selectedProjectTags = this.getSelectedTags(this.projectTagList);
  },
};
</script>
