export default{
    //TODO: wiki
    BmiCategory:{
        Unknown: 'Unbekannt', 
        ExtremelyUnderweight: 'Extrem untergewichtig', 
        Underweight: 'Untergewicht', 
        Normal: 'Normal', 
        Overweight: 'Übergewicht', 
        Obese: 'Übergewichtig', 
        ExtremelyObese: 'Extrem fettleibig'
    }
}