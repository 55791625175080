<template>
  <div class="impress page-background">
    <div>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        A honlap tulajdonosa és fenntartója az eHealth Software Solutions Kft.
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        <b>Székhely</b>: 1037 Budapest, Szépvölgyi út 39. fsz. 2.
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        <b>Cégjegyzékszám</b>: 01 09 905190
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        <b>Nyilvántartásba bejegyző bíróság</b>: Fővárosi Törvényszék Cégbírósága
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        <b>Adószám</b>: 12022739-2-41
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        Véleménye fontos számunkra, tehát amennyiben a honlap tartalmával és/vagy működésével kapcsolatban problémát észlel, esetleg észrevétele, kérdése van, kérjük írja meg nekünk.
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        A honlap teljes tartalma szerzői jogi védelem alatt áll, bármiféle felhasználásához, utánközléséhez az e-Health Software Solutions Kft. előzetes írásos hozzájárulása szükséges.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.impress {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>