<template>
  <div>
    <div>
      <fp-input
        label="Jelentés éve"
        :required="true"
        v-model="selectedYear"
        type="number"
        :min="minYear"
        :max="maxYear"
        :state="!validation.hasError('selectedYear')"
        :error="validation.firstError('selectedYear')"
      />
      <fp-select
        label="Jelentés hónapja"
        :required="true"
        v-model="selectedMonth"
        :items="months"
        textKey="Text"
        valueKey="Key"
        :state="!validation.hasError('selectedMonth')"
        :error="validation.firstError('selectedMonth')"
      />
      <fp-select
        label="Dekád választás"
        :required="true"
        :items="neakDecades"
        valueKey="Value"
        textKey="Text"
        v-model="selectedDecade"
        :state="!validation.hasError('selectedDecade')"
        :error="validation.firstError('selectedDecade')"
      />
    </div>
    <fp-text-area label="Jelentés leírása" v-model="description" />
    <b-button @click="createReport">Mentés</b-button>
  </div>
</template>
<script>
import moment from "moment";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
export default {
  name: "CreateNeakReportForm",
  data() {
    return {
      description: null,
      endDate: null,
      startDate: null,
      neakDecades: [
        { Text: "1. dekád", Value: 1 },
        { Text: "2. dekád", Value: 2 },
        { Text: "3. dekád", Value: 3 },
        { Text: "4. dekád", Value: 4 },
      ],
      months: [
        { Key: 0, Text: "Január" },
        { Key: 1, Text: "Február" },
        { Key: 2, Text: "Március" },
        { Key: 3, Text: "Április" },
        { Key: 4, Text: "Május" },
        { Key: 5, Text: "Június" },
        { Key: 6, Text: "Július" },
        { Key: 7, Text: "Augusztus" },
        { Key: 8, Text: "Szeptember" },
        { Key: 9, Text: "Október" },
        { Key: 10, Text: "November" },
        { Key: 11, Text: "December" },
      ],
      selectedDecade: null,
      minYear: moment().subtract(4, "years").year(),
      maxYear: moment().year(),
      selectedYear: moment().year(),
      selectedMonth: moment().month(),
    };
  },
  validators: {
    selectedYear: function (value) {
      return this.$validator
        .value(value)
        .between(this.minYear, this.maxYear)
        .required(this.$t("validate.requiredField"));
    },
    selectedMonth: function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    selectedDecade: function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
  },
  methods: {
    convertDecadeToStartEndDate() {
      const baseDate = moment([this.selectedYear, this.selectedMonth, 1]);
      switch (this.selectedDecade) {
        case 1:
          return {
            start: baseDate.format(),
            end: baseDate.add(6, "days").format(),
          };
        case 2:
          return {
            start: baseDate.add(7, "days").format(),
            end: baseDate.add(7, "days").format(),
          };
        case 3:
          return {
            start: baseDate.add(15, "days").format(),
            end: baseDate.add(7, "days").format(),
          };
        case 4:
          return {
            start: baseDate.add(23, "days").format(),
            end: baseDate.endOf("months").format(),
          };
        default:
          return null;
      }
    },
    async createReport() {
      const valid = await this.$validate();
      const decadeDate = this.convertDecadeToStartEndDate();
      if (valid && decadeDate) {
        const presList = this.$store.state.bever.SelectedPrescriptions;
        if (presList && presList.length) {
          const body = {
            StartDate: decadeDate.start,
            EndDate: decadeDate.end,
            IncludedProducts: presList,
            Description: this.description,
          };
          const createResponse = await MedicalDeviceLogic.createReport(body);
          if (!createResponse.Code || createResponse.Code == 0) {
            this.$bvToast.toast("Sikeresen jelentés generálás!", {
              title: "Sikeres mentés!",
              variant: "success",
              solid: true,
            });
            this.$emit("savedReport");
          } else {
            this.$bvToast.toast(createResponse.Message, {
              title: "Hiba történt a jelentés generálás során!",
              variant: "danger",
              solid: true,
            });
          }
        } else {
          this.$bvToast.toast(
            "Nem került recept kiválasztásra, lépjen vissza és vegyen fel legalább egy receptet a jelentés előállításához!",
            {
              title: "Hiba történt a jelentés generálás során!",
              variant: "danger",
              solid: true,
            }
          );
        }
      }
    },
  },
};
</script>
