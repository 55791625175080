<template>
  <div>
    <b-row style="max-width: 500px; margin: auto; padding: 20px" cols="1">
      <div>
        <img id="registration-logo-img" src="@/Assets/ImgIcons/Logo/Fp/fp-logo-v.png" />
      </div>
      <div>
        <fp-input
          :required="true"
          :label="$t('Profile.name')"
          v-model="registration.PersonName"
        />
      </div>
      <div>
        <fp-input
          :label="$t('Profile.ssn')"
          v-model="registration.SocialSecurityNumber"
        />
      </div>
      <div>
        <fp-input
          :required="true"
          :label="$t('Profile.registrationCode')"
          v-model="registration.RegistrationCode"
        />
      </div>
      <div>
        <fp-input
          :required="true"
          :label="$t('Profile.emailAddress')"
          type="email"
          v-model="registration.Email"
        />
      </div>
      <div>
        <fp-input
          :required="true"
          :label="$t('Profile.password')"
          type="password"
          v-model="registration.Password"
        />
      </div>
      <div>
        <fp-input
          :required="true"
          :label="$t('Profile.passwordAgain')"
          type="password"
          v-model="registration.PasswordAgain"
        />
      </div>
      <b-button
        @click="registrate"
        class="main-button"
        id="registration-registration-button"
      >
        {{ $t("Base.registration") }}
      </b-button>
    </b-row>
  </div>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";
export default {
  name: "Registration",
  components: {},
  data() {
    return {
      registration: {
        PersonName: null,
        Email: null,
        RegistrationCode: null,
        SocialSecurityNumber: null,
        Password: null,
        PasswordAgain: null,
      },
    };
  },
  methods: {
    async registrate() {
      const regResponse = await UserLogic.registrationUser(this.registration);
      if (!regResponse.Code) {
        this.$bvToast.toast(
          "Sikeresen regisztrált a megadott regisztrációs kóddal és fiók adatokkal! A bejelentkező oldalon beléphet a felhasználó fiókjába!",
          {
            title: "Sikeres regisztráció",
            variant: "success",
            solid: true,
            AutoHideDelay: 10000,
          }
        );
      } else {
        this.$bvToast.toast(regResponse.Message, {
          title: "Hiba történt a regisztráció során!",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
<style>
#registration-box {
  width: 50%;
  margin: auto;
}
@media screen and (max-width: 400px) {
  #registration-box {
    width: 90%;
  }
}
#registration-logo-img {
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  margin: 50px 0px;
  height: 150px;
}
</style>
