import BaseClinSys from "./BaseClinSys";
//import Modul
import HealthModule from "../Modules/HealthModule";

export default {
  install(Vue) {
    //Modul use
    Vue.use(HealthModule);
     //Base use
     Vue.use(BaseClinSys);
  },
};
