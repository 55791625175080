<template>
  <div v-if="!params.Hidden">
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span
      v-if="params.Required && !params.Label"
      style="color: red; font-size: 14px"
    >
      * {{ $t("Base.required") }}
    </span>
    <fp-input
      v-if="!disabled && !tempValue"
      :label="params.Label ? params.Label[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
      :placeholder="params.Placeholder ? params.Placeholder[language] : null"
      type="file"
      @change="selectFile"
    />
    <b-row v-if="tempValue" align-v="center">
      <b-iconstack @click="changeFile" v-if="tempValue && !disabled" class="h3">
        <b-icon stacked icon="file"></b-icon>
        <b-icon stacked icon="arrow-down-up" scale="0.5"></b-icon>
      </b-iconstack>
      <b-icon
        class="h3"
        v-b-tooltip.hover="$t('base.basic.download')"
        icon="file-arrow-down-fill"
        style="cursor: pointer"
        @click="downloadFile"
      ></b-icon>
      <fp-input
        style="width: calc(100% - 60px)"
        :disabled="true"
        :placeholder="$t('components.form.oneDocumentUploaded', { count: 1 })"
        :description="params.Description ? params.Description[language] : null"
      />
    </b-row>
    <div v-if="disabled && !tempValue">
      <fp-input
        :disabled="true"
        :label="$t('components.form.noSelectedDocument')"
        :description="params.Description ? params.Description[language] : null"
      />
    </div>
  </div>
</template>
<script>
import { AttachmentLogic } from "../../../../Logic/Backend/attachment";
export default {
  name: "FileUploadComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Number, String, Array],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value,
      tempFile: null,
      formId: null,
      saveForm: false,
    };
  },
  watch: {
    tempValue(input) {
      //TODO: oldalfrissítéskor a feltöltött doksi nem törlődik!!
      /* if (input) {
      //console.log("AddEvent - beforeunload");
        window.addEventListener(
          "beforeunload",
          async (e) => await this.reloadBeforeSave(e),
          { capture: true }
        );
      } else {
      //console.log("RemoveEvent - beforeunload");
        window.removeEventListener(
          "beforeunload",
          async (e) => await this.reloadBeforeSave(e),
          { capture: true }
        );
      } */
      this.$emit("change", input);
    },
    value(input) {
      this.tempValue = input;
    },
  },
  methods: {
    async selectFile(input) {
      const fileList = input.target.files;
      if (this.tempValue) {
        await this.removeFile(this.tempValue);
      }
      if (fileList) {
        this.tempFile = fileList[0];
        var blob = new Blob([this.tempFile]);
        console.log(this.tempFile.type)
        if (blob) {
          const uploadResult = await AttachmentLogic.uploadAttachment(
            blob,
            this.$enums.AttachmentTypeName.FormInstance.TypeName,
            this.formId,
            null,
            this.tempFile.type
          );
          if (!uploadResult.Code) {
            this.tempValue = uploadResult.AttachmentId;
          } else {
            this.$bvToast.toast(uploadResult.Message, {
              title: this.$t("requestResponse.attachment.errorUploadDocument"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        }
      }
    },
    async getParentSchema() {
      var temp = true;
      var parent = this.$parent;
      while (temp && parent) {
        if (parent.$vnode.componentOptions.tag == "fp-form-schema-builder") {
          temp = false;
          const cancelListener = parent.$listeners.Cancel;
          const sendListener = parent.$listeners.Send;
          const saveListener = parent.$listeners.Save;
          if (parent.form) {
            this.formId =
              parent.form[
                this.$enums.AttachmentTypeName.FormInstance.EntityIdValueKey
              ];
            if (cancelListener && sendListener && saveListener) {
              await parent.$on("Save", async () => (this.saveForm = true));
              await parent.$on("Send", async () => (this.saveForm = true));
              await parent.$on("Cancel", async () => this.cancelFile());
            }
          }
        } else {
          parent = parent.$parent;
        }
      }
    },
    async cancelFile() {
      await this.removeFile(this.tempValue);
    },
    async removeFile(fileId) {
      if (fileId) {
        await AttachmentLogic.deleteAttachment(fileId);
      }
    },
    async downloadFile() {
      const downloadFileResponse = await AttachmentLogic.downloadAttachment(
        this.tempValue
      );
      if (!downloadFileResponse.Code) {
        //kapott választ blobként tároljuk ideiglenesen
        const blob = await downloadFileResponse.blob();
        //TODO: nem működik a letöltés: iPhone, Ipad Chrome böngészőben a letöltés (meg se nyikkan)
        //file-á alakítjuk a blobot és adunk neki filenevet
        //TODO: filenév?
        var file = new File([blob], "FP-quiestionnaire-document");
        //letölthető urlt készítünk neki
        var fileURL = URL.createObjectURL(file);
        //létrehozunk egy linket az oldalon
        var fileLink = document.createElement("a");
        //a linknek odaadjuk az urlt
        fileLink.href = fileURL;
        //a linknek odaadjuk a file nevet
        //TODO: (patient + kérdőív cím?)
        var mime = require('mime-types')
        fileLink.download = "FP-quiestionnaire-document." + mime.extension(blob.type);
        //megadjuk a link megnyitásának módját
        fileLink.target = "_blank";
        //és click eseményét meghívjuk
        fileLink.click();
      } else {
        this.$bvToast.toast(downloadFileResponse.Message, {
          title: this.$t("requestResponse.attachment.errorDownloadDocument"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async changeFile() {
      const inputElement = document.createElement("input");
      inputElement.type = "file";
      inputElement.addEventListener("change", (args) => this.selectFile(args));
      inputElement.click();
    },
    async checkSaveForm() {
      //console.log("save form check");
      if (!this.saveForm && this.tempValue) {
        await this.removeFile(this.tempValue);
      }
    },
    async reloadBeforeSave(e) {
      await this.checkSaveForm();
      //console.log("Saved?");
      //console.log(this.saveForm);
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "";
    },
  },
  async beforeDestroy() {
    await this.checkSaveForm();
  },
  async mounted() {
    this.disabled ? "" : await this.getParentSchema();
    //await this.checkUploadedFile(this.value);
  },
};
</script>
