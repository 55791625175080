/* VUE import */
import Vuex from 'vuex'
import Vue from "vue";
import VueRouter from "vue-router";

/* Local beállítások */
import App from "@/App.vue";
import ProjectStore from "@/Store";
import RouterAuthentication from "@/Router/auth";

import { version } from '../../package.json'

//Project
import ClinComSys from "../License/ClinComSys";
import ClinProSys from "../License/ClinProSys";
import ClinRecSys from "../License/ClinRecSys";
import ClinRegSys from "../License/ClinRegSys";
import ClinResSys from "../License/ClinResSys";
import ClinSurvSys from "../License/ClinSurvSys";
import ClinTriSys from "../License/ClinTriSys";
import ClinWorkFlowSys from "../License/ClinWorkFlowSys";

//Module
import Research from "../Modules/Research";
import Document from "../Modules/Document";
import Appointment from "../Modules/Appointment";
import Task from "../Modules/Task";
import Form from "../Modules/Form";
import Workflow from "../Modules/Workflow";
import Tag from "../Modules/Tag";
import Registration from "../Modules/Registration";
import Legal from "../Modules/Legal";
import BeverReport from "../Modules/BeverReport";

import Enums from '../Enums'

//Routes
import staticRoutes from "../Router/Default/staticRoutes";
import specialistRoutes from "../Router/Default/specialist";
import adminRoutes from "../Router/Default/admin";
import partnerRoutes from "../Router/Default/partner";
import clientRoutes from "../Router/Default/client";

//Specific Components
import FPFooter from '../Modules/Footer/Specific/FocusProgram/Footer.vue'

//Set version
Vue.prototype.$ProjectVersion = "V" + version
//Modul use
AddPlusModules()
//Project use
AddActiveProjects()
//Base URL
SetBaseUrl();
//Storage keys
SetStorageKeys();
//Product settings
SetProductSettings()
//Specific elements
LoadSpecificComponents()
//korábban volt: Vue.prototype.$patientListLoadType, törölve lett
// productSettings.specialist.participantList.ParticipantListLoadType használata

//Router beállítása
Vue.use(VueRouter);

const routes = [
  ...staticRoutes,
  ...clientRoutes,
  ...specialistRoutes,
  ...partnerRoutes,
  ...adminRoutes
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(RouterAuthentication);

/* Globális beállítások */
import i18n from "@/Locale";

/* Vue beállítások */
Vue.config.productionTip = false;
// DictateTextInput használja
Vue.prototype.Window = window;

//TODO: lecserélése store-ra
Vue.prototype.$loggedUser;

/* Store beállítása */
Vue.use(Vuex)
const store = new Vuex.Store(ProjectStore)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

function AddPlusModules() {
  Vue.use(Task);
  Vue.use(Form);
  Vue.use(Document);
  Vue.use(Appointment);
  Vue.use(Research);
  Vue.use(Workflow);
  Vue.use(Tag);
  Vue.use(Registration);
  Vue.use(Legal);
  Vue.use(BeverReport);
}
function AddActiveProjects() {
  Vue.use(ClinComSys);
  Vue.use(ClinProSys);
  Vue.use(ClinRecSys);
  Vue.use(ClinRegSys);
  Vue.use(ClinResSys);
  Vue.use(ClinSurvSys);
  Vue.use(ClinTriSys);
  Vue.use(ClinWorkFlowSys);
}
function SetBaseUrl() {
  if (process.env.VUE_APP_EHSS_API) {
    if (process.env.VUE_APP_EHSS_API == 'prod') {
      Vue.prototype.$baseUrl = "https://focusprogram.net/api"
    } else {
      Vue.prototype.$baseUrl = process.env.VUE_APP_EHSS_API
    }
  } else {
    throw "Nincs node megadva (npm run serve lett csak indítva)";
  }
}
function SetStorageKeys() {
  Vue.prototype.$USER_TOKEN_STORAGE_KEY = "FocusProgram_UserToken"
  Vue.prototype.$EESZT_USER_TOKEN_STORAGE_KEY = "FocusProgram_EesztUserToken"
  Vue.prototype.$LOGGED_USER_STORAGE_KEY = "FocusProgram_SignedUser"
  Vue.prototype.$USER_PREFERENCES_STORAGE_KEY = "FocusProgram_UserPreferences"
}
function SetProductSettings() {
  // korábban: kis betűsök voltak
  Vue.prototype.$productSettings = {
    Style: require("@/Style/ProjectDefaultTheme/eh-fp.bootstrap.css"),
    DefaultLang: "hu",
    HasLangSwitch: true,
    HasRegistration: true,
    AvailableRoleViews: [
      Enums.SystemParameters.RoleView.Admin.Value,
      Enums.SystemParameters.RoleView.Client.Value,
      Enums.SystemParameters.RoleView.HealthProfessional.Value,
      Enums.SystemParameters.RoleView.Partner.Value,
    ],
    Static: {
      HeaderElements: [
        { Title: 'Víziónk', Path: '/Visions' },
        { Title: 'Fókusz programról', Path: '/AboutFP' },
        { Title: 'Szolgáltatásaink', Path: '/Service' },
        { Title: 'Referenciáink', Path: '/Reference' },
      ]
    },
    Specialist: {
      //korábban egy objektumban volt minden
      ManageUser: {
        CanCreateProjectRelation: true,
        CanRemoveProjectRelation: true,
        CanAddParticipantToProject: true,
        CanRemoveParticipantFromProject: true,
        CanCreateParticipant: true,
        CanDeleteParticipant: true,
      },
      ParticipantList: {
        ParticipantListLoadType: Enums.SystemParameters.ParticipantListLoadType.JustRelationship.Value,
        HasParticipantTag: true,
        UserRoles: [
          Enums.UserRole.Client
        ],
        ParticipantsBaseRoles: [
          Enums.SystemParameters.ProjectParticipantsBaseRole.Patient
        ],
        ParticipantShownDataKeys: ["Username", "Name", "Ssn"],
      },
      Tag: {
        ManagedTagType: Enums.SystemParameters.TagManagedType.Both.Value,
        CanCreateTag: true,
      }
    },
    Partner: {
      ManageUser: {
        CanCreateProjectRelation: true,
        CanRemoveProjectRelation: true,
        CanAddParticipantToProject: true,
        CanRemoveParticipantFromProject: true,
        CanCreateParticipant: true,
        CanDeleteParticipant: true,
      },
      ParticipantList: {
        ParticipantListLoadType: Enums.SystemParameters.ParticipantListLoadType.JustRelationship.Value,
        HasParticipantTag: true,
        UserRoles: [
          Enums.UserRole.HealthProfessional,
          Enums.UserRole.Partner
        ],
        ParticipantsBaseRoles: [
          Enums.SystemParameters.ProjectParticipantsBaseRole.Doctor,
          Enums.SystemParameters.ProjectParticipantsBaseRole.Manager
        ],
        ParticipantShownDataKeys: ["Name", "RoleName", "StampNumber", "InstitutionName"],
      },
      Tag: {
        ManagedTagType: Enums.SystemParameters.TagManagedType.Both.Value,
        CanCreateTag: true,
      },
    },
  };
}
function LoadSpecificComponents() {
  Vue.component('fp-footer', FPFooter)
}

