<template>
  <div class="eh-client-appointments">
    <fp-tabs :tabs="appointmentTabs" :lazy="true">
      <template #title="tab">
        {{ tab.title }}
      </template>
      <template #content="tab">
        <component :is="tab.component" />
      </template>
    </fp-tabs>
  </div>
</template>
<script>
export default {
  name: "Appointments",
  data() {
    return {
      appointmentTabs: [
        {
          title: this.$t("client.appointment.own"),
          component: 'fp-appointment-applied-list',
        },
        {
          title: this.$t("client.appointment.free"),
          component: 'fp-appointment-free-list',
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.$t("client.menu.appointments"));
  },
};
</script>