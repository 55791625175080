<template>
  <!-- TODO: megfelelő mező típus használata, plusz amount és usage beillesztése -->
  <b-row v-if="product" cols="1" cols-md="2">
    <b-col>
      <fp-select
        label="Felírás jogcíme"
        :required="true"
        v-model="product.PrescriptionTitleId"
        :items="eesztLegalTypeList"
        valueKey="EntryId"
        textKey="Name"
        :state="!validation.hasError('product.PrescriptionTitleId')"
        :error="validation.firstError('product.PrescriptionTitleId')"
      />
    </b-col>
    <b-col>
      <fp-select
        label="Felírt termék TTT kód és név"
        description="A TTT kód vagy az ISO kód megadása kötelező!"
        v-model="product.MedicalDeviceId"
        :items="eesztMedicalDeviceList"
        valueKey="EntryId"
        :searchable="true"
        @change="changeProductTtt"
        @search="searchMedicalDevice"
        :state="!validation.hasError('product.MedicalDeviceId')"
        :error="validation.firstError('product.MedicalDeviceId')"
      >
        <template #element="e">
          <div>{{ e.Name }}</div>
          <div>
            {{
              (e.Ttt ? "TTT kód: " + e.Ttt : "") +
              (e.IsoCode ? ", ISO kód: " + e.IsoCode : "")
            }}
          </div>
        </template>
      </fp-select>
    </b-col>
    <b-col>
      <fp-select
        label="Felírt termék ISO 10 kód és név"
        description="A TTT kód vagy az ISO kód megadása kötelező!"
        v-model="product.IsoId"
        :items="eesztISOList"
        valueKey="EntryId"
        :searchable="true"
        @search="searchIso"
        :state="!validation.hasError('product.IsoId')"
        :error="validation.firstError('product.IsoId')"
      >
        <template #element="e">
          <div>{{ e.Name }}</div>
          <div>{{ e.Code ? "(" + e.Code + ")" : null }}</div>
        </template>
      </fp-select>
    </b-col>
    <b-col>
      <fp-panel-card
        title="Felírt termék mennyiség"
        :error="errorAmount"
        :required="true"
      >
        <template #content>
          <product-amount
            ref="prescriptionProductAmount"
            v-model="product.Amount"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-input
        label="Felírt termék kihordási idő"
        :required="true"
        v-model="product.Lifespan"
        :state="!validation.hasError('product.Lifespan')"
        :error="validation.firstError('product.Lifespan')"
      />
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Felírt termék felhasználás"
        :error="errorUsage"
        :closedCard="true"
      >
        <template #content>
          <product-usage
            ref="prescriptionProductUsage"
            v-model="product.Usage"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-input label="Felírt termék méret" v-model="product.Size" />
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Felírt termék helyettesíthetőség"
        description="Vény jelentéshez kötelező mezők!"
        :closedCard="true"
      >
        <template #content>
          <fp-select
            label="Helyettesíthető-e a termék?"
            description="Vény jelentéshez kötelező mezők!"
            v-model="product.Substitutable"
            :items="threeStateList"
            valueKey="Value"
            textKey="Text"
          />
          <fp-text-area
            label="Felírt termék nem helyettesíthető indoklás"
            v-model="product.ReasonOfNonSubstitutability"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Termék felhasználási területe"
        :closedCard="true"
      >
        <template #content>
          <fp-input label="Oldaliság" v-model="product.ApplicationSide" />
          <fp-input label="Testtáj" v-model="product.ApplicationBodyArea" />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Orvosi utasítások"
        :closedCard="true"
      >
        <template #content>
          <fp-text-area
            label="Felíró orvos utasítása a GYSE kiadónak"
            v-model="product.DoctorsOrdersForDispenser"
          />
          <fp-text-area
            label="Felíró orvos utasítása a páciensnek"
            v-model="product.DoctorsOrdersForPatient"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-select
        label="Egyedi méretvétel alapján készülő termék?"
        v-model="product.CustomMade"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Javítandó termék gyári száma"
        v-model="product.SerialNumber"
      />
    </b-col>
    <b-col>
      <fp-text-area
        label="Hiba jellegének szöveges meghatározása"
        v-model="product.FaultDescription"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Kölcsönzés időtartama napokban"
        v-model="product.RentalDuration"
      />
    </b-col>
  </b-row>
</template>
<script>
import ProductAmount from "../common/ProductAmount.vue";
import ProductUsage from "./ProductUsage.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  name: "PrescriptionProduct",
  components: { ProductAmount, ProductUsage },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
  },
  data() {
    return {
      product: null,
      defaultProduct: {
        MedicalDeviceId: null,
        PrescriptionTitleId: null,
        IsoId: null,
        Amount: null,
        Lifespan: null,
        Usage: null,
        Size: null,
        Substitutable: null,
        ReasonOfNonSubstitutability: null,
        ApplicationSide: null,
        ApplicationBodyArea: null,
        DoctorsOrdersForDispenser: null,
        DoctorsOrdersForPatient: null,
        CustomMade: null,
        SerialNumber: null,
        FaultDescription: null,
        RentalDuration: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      eesztMedicalDeviceList: null,
      eesztLegalTypeList: null,
      eesztISOList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
      //error
      errorAmount: false,
      errorUsage: false,
    };
  },
  watch: {
    async value() {
      this.setProductData();
      if (
        this.eesztMedicalDeviceList == null &&
        this.eesztLegalTypeList == null &&
        this.eesztISOList
      ) {
        await this.getLists();
      }
    },
    product(input) {
        this.$emit("change", input);
      },
      'product.MedicalDeviceId': async function (input) {
          if (this.eesztMedicalDeviceList && input) {
              const tttList = await this.getBase(
                  this.$enums.BaseName.eeszt_medical_device.Value,
                  {
                      ...this.validDateFilterParams,
                      EntryId: input,
                  }
              );
              this.eesztMedicalDeviceList.push(tttList[0])
          }
      }
  },
  validators: {
    "product.Lifespan": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.MedicalDeviceId,product.IsoId": function (tttValue, isoValue) {
      const validator = this.$validator;
      return validator.custom(function () {
        if (!validator.isEmpty(tttValue) && !validator.isEmpty(isoValue)) {
          return "Az ISO vagy a TTT alapján kell megadni a terméket! Egyszerre a kettőt nem lehet!";
        }
      });
    },
    "product.IsoId,product.MedicalDeviceId": function (isoValue, tttValue) {
      const validator = this.$validator;
      return validator.custom(function () {
        if (!validator.isEmpty(tttValue) && !validator.isEmpty(isoValue)) {
          return "Az ISO vagy a TTT alapján kell megadni a terméket! Egyszerre a kettőt nem lehet!";
        }
      });
    },
    "product.MedicalDeviceId": function (value) {
      if (!this.product.IsoId) {
        return this.$validator
          .value(value)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      }
    },
    "product.IsoId": function (value) {
      if (!this.product.MedicalDeviceId) {
        return this.$validator
          .value(value)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      }
    },
    "product.PrescriptionTitleId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    changeProductTtt(entryId) {
      const productTtt = this.eesztMedicalDeviceList.find(
        (e) => e.EntryId == entryId
      );
      if (productTtt) {
        console.log(productTtt);
        if (!this.product.Amount) {
          this.product.Amount = {
            Amount: null,
            Unit: null,
            Justification: null,
          };
        }
        this.product.Amount.Unit = productTtt.PackagingName;
      }
    },
    setProductData() {
      if (this.value) {
        this.product = this.value;
      } else {
        this.product = JSON.parse(JSON.stringify(this.defaultProduct));
      }
      this.resetValidation();
    },
    async checkValidation() {
      const valid = await this.$validate();

      const amountValid = await this.$refs[
        "prescriptionProductAmount"
      ].checkValidation();
      this.errorAmount = !amountValid;

      const usageValid = await this.$refs[
        "prescriptionProductUsage"
      ].checkValidation();
      this.errorUsage = !usageValid;

      return valid && amountValid && usageValid;
    },
    resetValidation() {
      this.validation.reset();

      if (this.$refs["prescriptionProductAmount"]) {
        this.$refs["prescriptionProductAmount"].resetValidation();
      }
      this.errorAmount = false;

      if (this.$refs["prescriptionProductUsage"]) {
        this.$refs["prescriptionProductUsage"].resetValidation();
      }
      this.errorUsage = false;
    },
    async searchIso(input) {
      if (input.length > 3) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_iso.Value,
          {
            ["Name-contains"]: input,
          }
        );
        const codeContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_iso.Value,
          {
            ["Code-contains"]: input,
          }
        );
        this.eesztISOList = this.checkDuplicates([
          ...nameContainsList,
          ...codeContainsList,
        ]);
      } else if (input.length == 0) {
        this.eesztISOList = await this.getBase(this.$enums.BaseName.eeszt_iso.Value, {
          "-orderby": "Code",
          ["-limit"]: 10,
        });
      } else {
        this.eesztISOList = [];
      }
      this.eesztISOList.splice(0, 0, { Name: "Nincs megadva", EntryId: null });
    },
    async searchMedicalDevice(input) {
      if (input.length > 3) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Name-contains"]: input,
          }
        );
        const tttContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Ttt-contains"]: input,
          }
        );
        const isoContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["IsoCode-contains"]: input,
          }
        );
        this.eesztMedicalDeviceList = this.checkDuplicates([
          ...nameContainsList,
          ...tttContainsList,
          ...isoContainsList,
        ]);
      } else if (input.length == 0) {
        this.eesztMedicalDeviceList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            "-orderby": "Code",
            ["-limit"]: 10,
          }
        );
      } else {
        this.eesztMedicalDeviceList = [];
      }
      this.eesztMedicalDeviceList.splice(0, 0, {
        Name: "Nincs megadva",
        EntryId: null,
      });
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    async getLists() {
      this.eesztMedicalDeviceList = await this.getBase(
        this.$enums.BaseName.eeszt_medical_device.Value,
        {
          ...this.validDateFilterParams,
          "-orderby": "Code",
          ["-limit"]: 10,
        }
      );
      this.eesztMedicalDeviceList.splice(0, 0, {
        Name: "Nincs megadva",
        EntryId: null,
      });

      this.eesztLegalTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_legal_type.Value,
        this.validDateFilterParams
      );

      this.eesztISOList = await this.getBase(this.$enums.BaseName.eeszt_iso.Value, {
        "-orderby": "Code",
        ["-limit"]: 10,
      });
      this.eesztISOList.splice(0, 0, { Name: "Nincs megadva", EntryId: null });
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    await this.getLists();
    this.setProductData();
  },
};
</script>
