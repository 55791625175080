<template>
  <b-row v-if="specialistRecommendation" cols="1" cols-md="2">
    <b-col>
      <fp-date-picker
        label="Szakorvosi javaslat kiállítási dátuma"
        :required="true"
        v-model="specialistRecommendation.RecommendationDate"
        @change="changeValue('RecommendationDate', $event)"
        :disabled="disabled"
        :state="
          !validation.hasError('specialistRecommendation.RecommendationDate')
        "
        :error="
          validation.firstError('specialistRecommendation.RecommendationDate')
        "
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szakorvosi javaslat naplósorszáma"
        :required="true"
        v-model="specialistRecommendation.LogNumber"
        @change="changeValue('LogNumber', $event)"
        :disabled="disabled"
        :state="!validation.hasError('specialistRecommendation.LogNumber')"
        :error="validation.firstError('specialistRecommendation.LogNumber')"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szakorvos pecsétszáma"
        :required="true"
        v-model="specialistRecommendation.EnkkId"
        @change="changeValue('EnkkId', $event)"
        :disabled="disabled"
        :state="!validation.hasError('specialistRecommendation.EnkkId')"
        :error="validation.firstError('specialistRecommendation.EnkkId')"
      />
    </b-col>
    <b-col>
      <fp-input
        label='Szakorvos pecsétszám "/" utáni része'
        v-model="specialistRecommendation.StampNumber"
        @change="changeValue('StampNumber', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Szakorvosi javaslatot kiállító orvos neve"
        :required="true"
        v-model="specialistRecommendation.SpecialistName"
        @change="changeValue('SpecialistName', $event)"
        :disabled="disabled"
        :state="!validation.hasError('specialistRecommendation.SpecialistName')"
        :error="
          validation.firstError('specialistRecommendation.SpecialistName')
        "
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "SpecialistRecommendation",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      specialistRecommendation: null,
      changeSpecialistRecommendationValue: {},
      defaultSpecialistRecommendation: {
        RecommendationDate: null,
        LogNumber: null,
        EnkkId: null,
        StampNumber: null,
        SpecialistName: null,
      },
    };
  },
  watch: {
    value() {
      this.setSpecialistRecommendation();
    },
  },
  validators: {
    "specialistRecommendation.RecommendationDate": function(value) {
      if (this.hasSpecialistRecommendationData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "specialistRecommendation.LogNumber": function(value) {
      if (this.hasSpecialistRecommendationData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "specialistRecommendation.EnkkId": function(value) {
      if (this.hasSpecialistRecommendationData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "specialistRecommendation.SpecialistName": function(value) {
      if (this.hasSpecialistRecommendationData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasSpecialistRecommendationData() {
      return Object.values(this.specialistRecommendation).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
      setSpecialistRecommendation() {
       this.specialistRecommendation = JSON.parse(
        JSON.stringify(this.defaultSpecialistRecommendation)
       );
      if (this.value) {
          Object.assign(this.specialistRecommendation, this.value);
      }
      this.resetValidation();
    },
    changeValue(key, value) {
      if (this.hasSpecialistRecommendationData) {
        this.changeSpecialistRecommendationValue[key] = value;
        this.$emit("change", this.specialistRecommendation);
        this.$emit("changeValue", this.changeSpecialistRecommendationValue);
      } else {
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
  },
  mounted() {
    this.setSpecialistRecommendation();
  },
};
</script>
