<template>
  <div>
    <div style="max-width: 400px">
      <div v-if="showPatientData">
        <div
          v-if="prescriptionData && prescriptionData.Patient"
          style="display: flex"
        >
          <b-iconstack
            v-b-tooltip.hover="'Páciens'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="person-lines-fill" />
          </b-iconstack>
          <div class="m-1">
            {{
              prescriptionData.Patient.FullName
                ? prescriptionData.Patient.FullName
                : prescriptionData.Patient.PatientId
            }}<br />
            {{
              "(" +
              prescriptionData.Patient.PatientIdentifierValue +
              ", " +
              getPatientAge(
                prescriptionData.PrescriptionTimestamp,
                prescriptionData.Patient.BirthDate
              ) +
              " év)"
            }}
          </div>
        </div>
      </div>
      <div>
        <!-- Felírás dátuma -->
        <div v-if="prescriptionData">
          <b-iconstack
            v-if="
              over90days(prescriptionData.PrescriptionTimestamp) &&
              (eesztStatus == null || eesztStatus == 'Open')
            "
            v-b-tooltip.hover="'Recept felírás dátuma 90 napnál régebbi!'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked variant="warning" icon="calendar-fill" />
            <b-icon
              stacked
              variant="white"
              icon="exclamation"
              scale="0.6"
              shift-v="-2"
            />
          </b-iconstack>
          <b-iconstack
            v-else
            v-b-tooltip.hover="'Recept felírás dátuma'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar" />
            <b-icon stacked icon="pencil-fill" scale="0.6" shift-v="-1.5" />
          </b-iconstack>

          {{
            $convertDateToString(
              prescriptionData.PrescriptionTimestamp,
              "YYYY-MM-DD"
            )
          }}
        </div>
        <!-- Érvényesség -->
        <div v-if="prescriptionData">
          <b-iconstack
            v-b-tooltip.hover="'Recept érvényesség'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar3-range" />
          </b-iconstack>
          {{
            $convertDateToString(
              prescriptionData.PrescriptionValidFrom,
              "YYYY-MM-DD"
            )
          }}
          -
          {{
            $convertDateToString(
              prescriptionData.PrescriptionValidTo,
              "YYYY-MM-DD"
            )
          }}
        </div>
        <!-- Státusz -->
        <div>
          <b-iconstack
            v-b-tooltip.hover="'Recept EESZT státusza'"
            font-scale="2.5"
          >
            <b-icon stacked icon="arrow-clockwise"></b-icon>
            <b-icon
              stacked
              icon="x"
              scale="0.5"
              shift-v="1.5"
              shift-h="-1"
            ></b-icon>
            <b-icon
              stacked
              icon="check"
              scale="0.6"
              shift-v="-2"
              shift-h="1.2"
            ></b-icon>
          </b-iconstack>
          {{
            $enums.Prescription.EesztStatus[eesztStatus]
              ? $enums.Prescription.EesztStatus[eesztStatus].Text
              : eesztStatus
          }}
        </div>
        <!-- Felíró -->
        <div v-if="prescriptionData.Submitter">
          <b-iconstack v-b-tooltip.hover="'Recept felíró'" font-scale="2.5">
            <b-icon
              stacked
              icon="person-fill"
              scale="0.5"
              shift-h="-4"
              shift-v="-4.5"
            ></b-icon>
            <b-icon
              stacked
              icon="file-text"
              scale="0.35"
              shift-h="4"
              shift-v="-4.5"
            ></b-icon>
            <b-icon
              stacked
              icon="cloud-upload"
              scale="0.78"
              shift-v="1.5"
            ></b-icon>
          </b-iconstack>
          {{
            prescriptionData.Submitter.FullName +
            " (" +
            prescriptionData.Submitter.EnkkId +
            ")"
          }}
        </div>
        <!-- Megjegyzés -->
        <div>
          <b-iconstack
            v-b-tooltip.hover="'Megjegyzés'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="chat-right-text" />
          </b-iconstack>
          {{ prescriptionRemark }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "PrescriptionData",
  props: {
    prescriptionData: Object,
    eesztStatus: String,
    prescriptionRemark: String,
    showPatientData: Boolean,
  },
  methods: {
    getPatientAge(prescriptionTimestamp, patientBirthDate) {
      if (prescriptionTimestamp && patientBirthDate) {
        return moment(prescriptionTimestamp).diff(patientBirthDate, "years");
      } else {
        return "";
      }
    },
    over90days(timestamp) {
      return moment(moment.now()).diff(moment(timestamp), "days") > 90;
    },
  },
};
</script>
