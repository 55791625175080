<template>
  <b-row v-if="product" cols="1" cols-md="2">
    <b-col v-if="isNewProduct">
      <fp-select :items="prescriptionProducts" @change="selectProduct">
        <template #element="e">
          {{
            e.ProductName
              ? e.ProductName
              : e.Ttt && e.Ttt.Name
              ? e.Ttt.Name + " (TTT kód:" + e.Ttt.Code + ")"
              : e.Iso && e.Iso.Name
              ? e.Iso.Name + " (ISO10 kód: " + e.Iso.Code + ")"
              : null
          }}
        </template>
      </fp-select>
    </b-col>
    <b-col>
      <fp-date-time-picker
        label="Termék kiadásának időpontja"
        :required="true"
        v-model="product.DispensedAt"
        :state="!validation.hasError('product.DispensedAt')"
        :error="validation.firstError('product.DispensedAt')"
      />
    </b-col>
    <b-col>
      <fp-select
        label="Felírás jogcíme"
        :required="true"
        v-model="product.DispenseTitleId"
        :items="eesztLegalTypeList"
        valueKey="EntryId"
        textKey="Name"
      />
    </b-col>
    <b-col>
      <div v-if="prescriptionProductIso">
        Felírt ISO10 kód:
        {{
          prescriptionProductIso.Name + "(" + prescriptionProductIso.Code + ")"
        }}
      </div>
      <fp-select
        label="Felírt termék TTT kód és név"
        :required="true"
        v-model="product.MedicalDeviceId"
        @change="changeProduct"
        :items="eesztMedicalDeviceList"
        textKey="Name"
        valueKey="EntryId"
        :searchable="true"
        @search="searchMedicalDevice"
        :state="!validation.hasError('product.MedicalDeviceId')"
        :error="validation.firstError('product.MedicalDeviceId')"
      >
        <template #element="e">
          <div>{{ e.Name }}</div>
          <div>
            {{
              (e.Ttt ? "TTT kód: " + e.Ttt : "") +
              (e.IsoCode ? ", ISO kód: " + e.IsoCode : "")
            }}
          </div>
        </template>
      </fp-select>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Kiváltott termék mennyiség"
        :required="true"
      >
        <template #content>
          <product-amount
            ref="dispenseProductAmount"
            v-model="product.Amount"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-input
        label="Kiváltott termék kihordási idő"
        :required="true"
        v-model="product.Lifespan"
        :state="!validation.hasError('product.Lifespan')"
        :error="validation.firstError('product.Lifespan')"
      />
    </b-col>
    <b-col>
      <fp-input label="Felírt termék méret" v-model="product.Size" />
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 15px"
        title="Termék felhasználási területe"
        :closedCard="true"
      >
        <template #content>
          <fp-input label="Oldaliság" v-model="product.ApplicationSide" />
          <fp-input label="Testtáj" v-model="product.ApplicationBodyArea" />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-select
        label="Egyedi méretvétel alapján készülő termék?"
        v-model="product.CustomMade"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
      />
    </b-col>
    <b-col>
      <!-- <fp-input
        label="Kiváltott termék gyártási száma"
        description="Béver jelentéshez kötelező mező!"
        v-model="product.SerialNumber"
        :state="!product.SubsidizationCategoryId"
        :error="requiredToNeakReport"
      /> -->
      <fp-input
        label="Kiváltott termék gyártási száma"
        v-model="product.SerialNumber"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Kiváltott termék munkalap sorszám"
        description="Amennyiben a termék egyedi méretvétel alapján készült, abban az esetben a munklap sorszám megadása kötelező!"
        v-model="product.WorksheetNumber"
        :state="
          !(
            product.CustomMade && validation.hasError('product.WorksheetNumber')
          )
        "
        :error="validation.firstError('product.WorksheetNumber')"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Kiváltott termék többletgarancia"
        v-model="product.ExtendedGuarantee"
      />
    </b-col>
    <b-col>
      <fp-select
        label="Beteg nyilatkozat a termékre: helyettesítés történt?"
        :required="true"
        v-model="product.SubstitutionAgreement"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
        :state="!validation.hasError('product.SubstitutionAgreement')"
        :error="validation.firstError('product.SubstitutionAgreement')"
      />
    </b-col>
    <b-col>
      <fp-text-area label="Kiadói megjegyzések" v-model="product.Comment" />
    </b-col>
    <b-col>
      <fp-select
        label="Finanszírozási kategória"
        description="A vény jelentésbe azok a termékek kerülnek be, amelyeknél meg van adva fiannszírózási kategória!"
        :items="neakSubsidizationList"
        @change="selectSubsidization"
      >
        <template #element="e">
          <div
            :style="
              patientAge &&
              e.MinimumAge != 0 &&
              e.MaximumAge != 0 &&
              ((e.MinimumAge && patientAge < e.MinimumAge) ||
                (e.MaximumAge && patientAge > e.MaximumAge))
                ? 'color: red'
                : ''
            "
          >
            {{
              e.SubsidizationCategory.Name +
              (e.SubsidizationPercentage
                ? " (" + e.SubsidizationPercentage + "%)"
                : "")
            }}
          </div>
          <div
            v-if="
              patientAge &&
              e.MinimumAge != 0 &&
              e.MaximumAge != 0 &&
              ((e.MinimumAge && patientAge < e.MinimumAge) ||
                (e.MaximumAge && patientAge > e.MaximumAge))
            "
            style="color: red; font-size: 12px"
          >
            A támogatás életkorhoz kötött: <br />Minimum életkor
            {{ e.MinimumAge }}, maximum életkor {{ e.MaximumAge }}, a páciens
            életkora {{ patientAge }}
          </div>
        </template>
      </fp-select>
      <fp-panel-card
        style="margin-top: 10px"
        title="Finanszírozási árak"
        :closedCard="true"
        :description="
          product.Amount
            ? 'A fizetés információ egy ' +
              product.Amount.Unit +
              ' mennyiségre értendő'
            : 'A fizetés információ egy egységnyi mennyiségre értendő'
        "
        :error="errorFinancialInfo"
      >
        <template #content>
          <financial-info
            ref="dispenseProductFinancialInfo"
            v-model="product.FinancialInfo"
            :subsidizationFinancialInfo="productSubsidizationFinancialInfo"
          />
        </template>
      </fp-panel-card>
    </b-col>
  </b-row>
</template>
<script>
import ProductAmount from "../common/ProductAmount.vue";
import FinancialInfo from "../common/FinancialInfo.vue";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  components: { ProductAmount, FinancialInfo },
  name: "CreateDispenseProduct",
  model: {
    prop: "dispenseProduct",
    event: "change",
  },
  props: {
    dispenseProduct: Object,
    prescriptionProduct: Object,
    prescriptionProducts: Array,
    patientAge: Number,
  },
  data() {
    return {
      product: null,
      eesztLegalTypeList: null,
      eesztMedicalDeviceList: null,
      neakSubsidizationList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      defaultProduct: {
        DispensedAt: null,
        MedicalDeviceId: null,
        DispenseTitleId: null,
        Amount: null,
        Lifespan: null,
        Size: null,
        ApplicationSide: null,
        ApplicationBodyArea: null,
        CustomMade: null,
        SerialNumber: null,
        WorksheetNumber: null,
        ExtendedGuarantee: null,
        SubstitutionAgreement: false,
        Comment: null,
        FinancialInfo: null,
      },
      prescriptionProductIso: null,
      errorFinancialInfo: null,
      isNewProduct: false,
      productSubsidizationFinancialInfo: null,
    };
  },
  watch: {
    async dispenseProduct() {
      this.setProductData();
      await this.getLists();
    },
    product(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "product.DispensedAt": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.MedicalDeviceId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.DispenseTitleId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.Amount": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.Lifespan": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.SubstitutionAgreement": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.WorksheetNumber": function (value) {
      if (this.product.CustomMade) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    requiredToNeakReport() {
      if (this.product.SubsidizationCategoryId) {
        return "A termék akkor kerül be a vény jelentésbe, ha a Fizetési információk mellett a Kiváltott termék gyártási számát is megadja.";
      }
      return null;
    },
  },
  methods: {
    async changeProduct(entryId) {
      const productTtt = this.eesztMedicalDeviceList.find(
        (e) => e.EntryId == entryId
      );
      if (productTtt) {
        await this.getSubsidizationByProductId();
        if (!this.product.Amount) {
          this.product.Amount = {
            Amount: null,
            Unit: null,
            Justification: null,
          };
        }
        this.product.Amount.Unit = productTtt.PackagingName;
        this.$set(this.product, "CustomMade", productTtt.CustomMade);
      }
    },
    selectSubsidization(cat) {
      this.$set(
        this.product,
        "SubsidizationCategoryId",
        cat.SubsidizationCategory.Id
      );
      this.productSubsidizationFinancialInfo = cat.FinancialInfo;
    },
    async selectProduct(product) {
      this.product = JSON.parse(JSON.stringify(this.defaultProduct));
      this.prescriptionProductIso = product.Iso;
      Object.assign(this.product, {
        PrescriptionProductUniqueId: product.UniqueId,
        MedicalDeviceId: product.Ttt ? product.Ttt.Id : null,
        DispenseTitleId: product.PrescriptionTitle
          ? product.PrescriptionTitle.Id
          : null,
        Amount: product.Amount,
        Lifespan: product.Lifespan,
        Size: product.Size,
        ApplicationSide: product.ApplicationSide,
        ApplicationBodyArea: product.ApplicationBodyArea,
        CustomMade: product.CustomMade,
      });
      this.product.DispensedAt = moment().format();
      await this.getNewProductList();
    },
    async getNewProductList() {
      var eesztMedicalDeviceParams;
      if (this.prescriptionProductIso && this.prescriptionProductIso.Code) {
        eesztMedicalDeviceParams = {
          ...this.validDateFilterParams,
          ["IsoCode-contains"]: this.prescriptionProductIso.Code,
        };
      } else {
        eesztMedicalDeviceParams = {
          ...this.validDateFilterParams,
          "-orderby": "Code",
          ["-limit"]: 10,
        };
      }

      const eesztMedicalDeviceDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_medical_device.Value,
        eesztMedicalDeviceParams
      );
      var eesztMedicalDeviceSelectedValue = [];
      if (this.product.Ttt && this.product.Ttt.Id) {
        eesztMedicalDeviceSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            EntryId: this.product.Ttt.Id,
          }
        );
      }
      this.eesztMedicalDeviceList = this.checkDuplicates([
        ...eesztMedicalDeviceDefaultList,
        ...eesztMedicalDeviceSelectedValue,
      ]);
    },
    async checkValidator() {
      const valid = await this.$validate();

      const financialInfoValid = await this.$refs[
        "dispenseProductFinancialInfo"
      ].checkValidation();
      this.errorFinancialInfo = !financialInfoValid;

      const amountValid = await this.$refs[
        "dispenseProductAmount"
      ].checkValidation();

      return valid && amountValid && financialInfoValid;
    },
    resetValidator() {
      this.validation.reset();
      if (this.$refs["dispenseProductAmount"]) {
        this.$refs["dispenseProductAmount"].resetValidation();
      }
      if (this.$refs["dispenseProductFinancialInfo"]) {
        this.$refs["dispenseProductFinancialInfo"].resetValidation();
      }
    },
    async searchMedicalDevice(input) {
      if (input.length > 3) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Name-contains"]: input,
          }
        );
        const tttContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Ttt-contains"]: input,
          }
        );
        const isoContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["IsoCode-contains"]: input,
          }
        );
        this.eesztMedicalDeviceList = this.checkDuplicates([
          ...nameContainsList,
          ...tttContainsList,
          ...isoContainsList,
        ]);
      } else if (input.length == 0) {
        var eesztMedicalDeviceParams;
        if (this.prescriptionProductIso && this.prescriptionProductIso.Code) {
          eesztMedicalDeviceParams = {
            ...this.validDateFilterParams,
            ["IsoCode-contains"]: this.prescriptionProductIso.Code,
          };
        } else {
          eesztMedicalDeviceParams = {
            ...this.validDateFilterParams,
            "-orderby": "Code",
            ["-limit"]: 10,
          };
        }
        this.eesztMedicalDeviceList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          eesztMedicalDeviceParams
        );
      } else {
        this.eesztMedicalDeviceList = [];
      }
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    async getLists() {
      var eesztMedicalDeviceParams;
      if (this.prescriptionProductIso && this.prescriptionProductIso.Code) {
        eesztMedicalDeviceParams = {
          ...this.validDateFilterParams,
          ["IsoCode-contains"]: this.prescriptionProductIso.Code,
        };
      } else {
        eesztMedicalDeviceParams = {
          ...this.validDateFilterParams,
          "-orderby": "Code",
          ["-limit"]: 10,
        };
      }

      const eesztMedicalDeviceDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_medical_device.Value,
        eesztMedicalDeviceParams
      );
      var eesztMedicalDeviceSelectedValue = [];
      if (this.product.MedicalDeviceId) {
        eesztMedicalDeviceSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            EntryId: this.product.MedicalDeviceId,
          }
        );
      }
      this.eesztMedicalDeviceList = this.checkDuplicates([
        ...eesztMedicalDeviceDefaultList,
        ...eesztMedicalDeviceSelectedValue,
      ]);

      this.eesztLegalTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_legal_type.Value,
        this.validDateFilterParams
      );

      await this.getSubsidizationByProductId();
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    async getSubsidizationByProductId() {
      if (this.product.MedicalDeviceId) {
        const getResponse = await MedicalDeviceLogic.getDispenseSubsidization(
          this.product.MedicalDeviceId
        );
        if (!getResponse.Code) {
          this.neakSubsidizationList = [
            {
              SubsidizationCategory: {
                Id: null,
                Name: "Nincs",
              },
              /* MinimumAge: 72,
              MaximumAge: 70, */
            },
            ...getResponse,
          ];
        } else {
          //TODO: hiba
        }
      }
    },
    setProductData() {
      if (this.dispenseProduct) {
        this.product = this.dispenseProduct;
      } else {
        this.product = JSON.parse(JSON.stringify(this.defaultProduct));
        this.product.DispensedAt = moment().format();
        if (this.prescriptionProduct) {
          this.prescriptionProductIso = this.prescriptionProduct.Iso;
          Object.assign(this.product, {
            MedicalDeviceId: this.prescriptionProduct.Ttt
              ? this.prescriptionProduct.Ttt.Id
              : null,
            DispenseTitleId: this.prescriptionProduct.PrescriptionTitle
              ? this.prescriptionProduct.PrescriptionTitle.Id
              : null,
            Amount: this.prescriptionProduct.Amount,
            Lifespan: this.prescriptionProduct.Lifespan,
            Size: this.prescriptionProduct.Size,
            ApplicationSide: this.prescriptionProduct.ApplicationSide,
            ApplicationBodyArea: this.prescriptionProduct.ApplicationBodyArea,
            CustomMade: this.prescriptionProduct.CustomMade,
            PrescriptionProductUniqueId: this.prescriptionProduct.UniqueId,
          });
        } else {
          this.isNewProduct = true;
        }
      }
      this.resetValidator();
    },
  },
  async mounted() {
    this.setProductData();
    await this.getLists();
  },
};
</script>
