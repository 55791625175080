var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.product)?_c('b-row',{attrs:{"cols":"1","cols-md":"2"}},[(_vm.dispenseProduct)?_c('b-col',[_c('fp-panel-card',{staticStyle:{"margin":"20px 0px"},attrs:{"title":"Kiváltott termék mennyisége"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('product-amount',{attrs:{"disabled":true,"value":_vm.dispenseProduct.Amount}})]},proxy:true}],null,false,568860422)})],1):_vm._e(),(_vm.dispenseProduct)?_c('b-col',[_c('fp-panel-card',{staticStyle:{"margin":"20px 0px"},attrs:{"title":"Kiváltott termék finanszírozási árai","description":_vm.dispenseProduct.Amount
            ? 'A fizetés információ egy ' +
              _vm.dispenseProduct.Amount.Unit +
              ' mennyiségre értendő'
            : 'A fizetés információ egy egységnyi mennyiségre értendő'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('financial-info',{attrs:{"disabled":!(_vm.editable && _vm.hasUniqueLicensedProduct)},on:{"change":function($event){return _vm.changeValue('FinancialInfo', $event)}},model:{value:(_vm.product.FinancialInfo),callback:function ($$v) {_vm.$set(_vm.product, "FinancialInfo", $$v)},expression:"product.FinancialInfo"}})]},proxy:true}],null,false,2442127651)})],1):_vm._e(),_c('b-col',[_c('code-name-pair',{attrs:{"label":"NEAK jogcím kód","required":true,"items":_vm.neakLegalType,"listTextKey":"Name","listValueKey":"EntryId","disabled":!_vm.editable,"state":!_vm.validation.hasError('product.NeakLegalType'),"error":_vm.validation.firstError('product.NeakLegalType')},on:{"changeValue":function($event){return _vm.changeValue('NeakLegalType', $event)}},model:{value:(_vm.product.NeakLegalType),callback:function ($$v) {_vm.$set(_vm.product, "NeakLegalType", $$v)},expression:"product.NeakLegalType"}})],1),_c('b-col',[_c('fp-input',{attrs:{"label":"Egyedi engedélyszám","disabled":!_vm.editable,"description":"Kötelező mező, ha a termék NEAK külön engedélyes!","state":!(
            _vm.hasUniqueLicensedProduct &&
            _vm.validation.hasError('product.ProductLicenseNumber')
          ),"error":_vm.validation.firstError('product.ProductLicenseNumber')},on:{"change":function($event){return _vm.changeValue('ProductLicenseNumber', $event)}},model:{value:(_vm.product.ProductLicenseNumber),callback:function ($$v) {_vm.$set(_vm.product, "ProductLicenseNumber", $$v)},expression:"product.ProductLicenseNumber"}})],1),_c('b-col',[_c('code-name-pair',{attrs:{"label":"NEAK forgalmi kód","required":true,"items":_vm.neakFinancialCode,"listTextKey":"Name","listValueKey":"EntryId","disabled":!_vm.editable,"state":!_vm.validation.hasError('product.NeakFinancialCode'),"error":_vm.validation.firstError('product.NeakFinancialCode')},on:{"changeValue":function($event){return _vm.changeValue('NeakFinancialCode', $event)}},model:{value:(_vm.product.NeakFinancialCode),callback:function ($$v) {_vm.$set(_vm.product, "NeakFinancialCode", $$v)},expression:"product.NeakFinancialCode"}})],1),_c('b-col',[_c('code-name-pair',{attrs:{"label":"NEAK magisztrális díjosztály","required":true,"items":_vm.neakMagistralfeeClass,"listTextKey":"Name","listValueKey":"EntryId","disabled":!_vm.editable,"state":!_vm.validation.hasError('product.NeakMagistralFeeClass'),"error":_vm.validation.firstError('product.NeakMagistralFeeClass')},on:{"changeValue":function($event){return _vm.changeValue('NeakMagistralFeeClass', $event)}},model:{value:(_vm.product.NeakMagistralFeeClass),callback:function ($$v) {_vm.$set(_vm.product, "NeakMagistralFeeClass", $$v)},expression:"product.NeakMagistralFeeClass"}})],1),_c('b-col',[_c('fp-select',{attrs:{"disabled":!_vm.editable ||
          (_vm.prescriptionProduct && !!_vm.prescriptionProduct.Substitutable),"label":"Helyettesíthető a termék?","required":true,"items":_vm.threeStateList,"valueKey":"Value","textKey":"Text","state":!_vm.validation.hasError('product.Substituitable'),"error":_vm.validation.firstError('product.Substituitable')},model:{value:(_vm.product.Substituitable),callback:function ($$v) {_vm.$set(_vm.product, "Substituitable", $$v)},expression:"product.Substituitable"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }