<template>
  <div class="improve-your-mood page-background">
    <h1>Fejlesztés alatt</h1>
  </div>
</template>
<script>
export default {
  components: {
  }
}
</script>
<style>
.improve-your-mood{
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>