<template>
  <div>
    <div v-for="(lang, index) in supportedLangs" :key="'language' + index">
      <!-- Egyik nyelv sablonai -->
      <fp-panel-card
        :closedCard="true"
        style="margin: 10px 0px"
        :title="$enums.Languages[lang] ? $enums.Languages[lang].Text : lang"
      >
        <!-- Sablonok egyik nyelv a panel címe -->
        <template #title>
          <img
            v-if="$enums.Languages[lang]"
            style="margin: 0px 5px 0px 10px; border: solid 1px #00000044"
            width="20px"
            :src="require('@/Assets' + $enums.Languages[lang].flag)"
          />
        </template>
        <!-- Sablonok egyik nyelv a panel törzse -->
        <template #content>
          <!-- Email sablon -->
          <!-- EmailTemplate version: 1.0 -->
          <fp-email-template
            v-if="email"
            v-model="email"
            @changedValues="$emit('changedTemplate', $event)"
            :editable="editable"
            :lang="lang"
            :supportedLangs="supportedLangs"
          />
          <!-- SMS sablon -->
          <!-- SmsTemplate version: 1.0 -->
          <fp-sms-template
            v-if="sms"
            v-model="sms"
            @changedValues="$emit('changedTemplate', $event)"
            :editable="editable"
            :lang="lang"
            :supportedLangs="supportedLangs"
          />
          <!-- PUSH sablon -->
          <!-- PushTemplate version: 1.0 -->
          <fp-push-template
            v-if="push"
            v-model="push"
            @changedValues="$emit('changedTemplate', $event)"
            :editable="editable"
            :lang="lang"
            :supportedLangs="supportedLangs"
          />
        </template>
      </fp-panel-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "TemplateByLangs",
  props: {
    supportedLangs: Array,
    emailTemplate: Object,
    smsTemplate: Object,
    pushTemplate: Object,
    editable: Boolean,
  },
  data() {
    return {
      email: this.emailTemplate,
      sms: this.smsTemplate,
      push: this.pushTemplate,
    };
  },
  watch: {
    emailTemplate(input) {
      this.email = input;
    },
    email(input) {
      this.$emit("update:emailTemplate", input);
    },
    smsTemplate(input) {
      //console.log("SMS_TEMPLATE");
      this.sms = input;
    },
    sms(input) {
      //console.log("SMS");
      this.$emit("update:smsTemplate", input);
    },
    pushTemplate(input) {
      this.push = input;
    },
    push(input) {
      this.$emit("update:pushTemplate", input);
    },
  },
};
</script>
