import MedicinePlan from '../HealthModule/MedicinePlan.vue'
import HealthGoal from '../HealthModule/HealthGoal.vue'
import CreateHealthGoalFormModal from '../HealthModule/Components/Inputs/CreateHealthGoalFormModal.vue'
import HealthModulesInputs from '../HealthModule/Components/Inputs'
import HealthModulesCharts from '../HealthModule/Components/Charts'

const ClientModuleDiary = () => import("./ClientModuleDiary.vue");
const Setting = () => import("./Setting.vue");

export default {
    install(Vue) {
        Vue.use(HealthModulesCharts)
        Vue.use(HealthModulesInputs)
        Vue.component("fp-health-medicine-plan", MedicinePlan);
        Vue.component("fp-health-goal-table", HealthGoal);
        Vue.component("fp-create-healt-goal-form-modal", CreateHealthGoalFormModal);
        Vue.component("fp-client-module-diary", ClientModuleDiary);
        Vue.component("fp-client-function-setting", Setting);
    },
};
