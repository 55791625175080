<template>
  <div class="oncology page-background">
    <div>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        <b>
          <span style="color: rgb(237, 125, 49);">Onkológiai</span>
        </b> gondozási modulunk komplex segítséget nyújt onkológiai betegséged kezelésében.
        
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        Az onkológiai gondozási programunkban a szakembereink összeállítják a kezelési-gondozási terved, melynek lépései elektronikusan is megjelennek a felületen, így láthatod, hogy a gondozási programban mi fog Veled történni, mikor és mit kell tenned gyógyulásod érdekében. A programot lépésről-lépésre végrehajthatod, időpontot foglalhatsz az elérhető ellátásra, vizitekre, testsúly-, mozgási aktivitás méréseket végezhetsz, táplálkozási naplót vezethetsz és még gyógyszerszedésed is beállíthatod. A csatlakoztatott mérőeszközök közül válaszd ki a Neked tetsző mérleget, mozgási aktivitást mérő készüléket és mérj rendszeresen, az értékek megjelennek egészségnaplódban. Ezeket az eseményeket, méréseket a Fókusz Programon keresztül szakorvosod és gondozód is látja, követi és ha szükséges beavatkozik. A
        <span style="color: rgb(237, 125, 49);">Fókusz Programon</span>keresztül bejelentkezhetsz szakorvosi vizitekre is, melynek naptárbejegyzései naptáradban jelennek meg és e-mail értesítést kapsz minden vizit előtt, hogy az időpontokat ne feledd el.
        
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        Az onkológiai állapotfelmérő kérdőív kitöltése pedig segíti gondozóink munkáját, akik a regisztráció után telefonon jelentkeznek és több hónap alatt „végigvisznek” a kezelési terven, mellyel onkológusod munkáját segítik. Személyes gondozód folyamatosan azon dolgozik, hogy segítséget nyújtson terápiádban, kérdéseidre válaszoljon és támogasson a gyógyulásod felé vezető úton.
        
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        A fontosabb terápiás feladatokról értesítéseket kapsz e-mail, sms, vagy a Fókusz Program applikációban push üzenet formájában. Mindemellett saját egészségtervet állíthatsz össze és ha teljesíted az egyes feladatokat és eléred saját magad által kitűzött célokat, akkor megjutalmazunk.
        
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif;"
      >
        Ebből is láthatod, hogy minden eszközzel a Te gyógyulásodat segítjük!
        <span style="color: red;">
          
        </span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.oncology {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>