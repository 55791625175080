var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","margin-bottom":"10px"}},[(
      _vm.$productSettings[_vm.managerType].ManageUser.CanCreateParticipant &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.addPatientPermission)
    )?_c('div',[_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Új felhasználó regisztrációja és beléptetése a projektbe'),expression:"'Új felhasználó regisztrációja és beléptetése a projektbe'",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"register-user"},on:{"click":function($event){_vm.showRegistration = true}}}),_c('fp-create-user-to-project',{attrs:{"project":_vm.project,"projectBaseRoles":_vm.$productSettings[_vm.managerType].ParticipantList.ParticipantsBaseRoles,"userRoles":_vm.$productSettings[_vm.managerType].ParticipantList.UserRoles},on:{"selectParticipant":function($event){return _vm.$emit('selectOtherParticipant', $event)}},model:{value:(_vm.showRegistration),callback:function ($$v) {_vm.showRegistration=$$v},expression:"showRegistration"}})],1):_vm._e(),(
      _vm.$productSettings[_vm.managerType].ManageUser.CanAddParticipantToProject &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.addPatientRelationPermission)
    )?_c('div',[_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Regisztrált felhasználó beléptetése a projektbe'),expression:"'Regisztrált felhasználó beléptetése a projektbe'",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"add-user-from-list"},on:{"click":function($event){_vm.showAddToProjectModal = true}}}),_c('fp-create-project-participant',{attrs:{"project":_vm.project,"baseRoles":_vm.$productSettings[_vm.managerType].ParticipantList.ParticipantsBaseRoles},on:{"selectParticipant":function($event){return _vm.$emit('selectOtherParticipant', $event)}},model:{value:(_vm.showAddToProjectModal),callback:function ($$v) {_vm.showAddToProjectModal=$$v},expression:"showAddToProjectModal"}})],1):_vm._e(),(
      _vm.$productSettings[_vm.managerType].ManageUser.CanCreateProjectRelation &&
      _vm.userProjectPermissions &&
      _vm.userProjectPermissions.includes(_vm.addSubordinatePatientPermission)
    )?_c('div',[_c('fp-svg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
        'Regisztrált, projekten szereplő felhasználó kapcsolat felvétele'
      ),expression:"\n        'Regisztrált, projekten szereplő felhasználó kapcsolat felvétele'\n      ",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"add-user-relation"},on:{"click":function($event){_vm.showCreateRelationModal = true}}}),_c('fp-create-project-relation',{attrs:{"baseRoles":_vm.$productSettings[_vm.managerType].ParticipantList.ParticipantsBaseRoles,"project":_vm.project},on:{"selectParticipant":function($event){return _vm.$emit('selectOtherParticipant', $event)}},model:{value:(_vm.showCreateRelationModal),callback:function ($$v) {_vm.showCreateRelationModal=$$v},expression:"showCreateRelationModal"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }