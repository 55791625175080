<template>
  <div v-if="participantsData && subordinatesProgress">
    <fp-table-list
      :striped="false"
      :items="participantsData"
      :fields="participantListFields"
    >
      <template #Progress="row">
        <div
          v-if="
            subordinatesProgress[row.item.UserId] != null ||
            subordinatesProgress[row.item.UserId] != undefined
          "
        >
          <b-progress
            :max="1"
            :value="subordinatesProgress[row.item.UserId]"
          ></b-progress>
          <div style="text-align: center">
            <b>{{ subordinatesProgress[row.item.UserId] * 100 }}%</b>
          </div>
        </div>
      </template>
      <template #Role="row">
        {{ row.item.ProjectRoles[0] }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { UserLogic } from "@/Logic/Backend/user";
export default {
  name: "ParticipanProgress",
  props: {
    participantList: Array,
    createdProjectId: Number,
  },
  data() {
    return {
      subordinatesProgress: null,
      ownProgress: null,
      projectRoleList: null,
      participantListFields: [
        {
          key: "Name",
          label: "Résztvevő neve",
        },
        {
          key: "Role",
          label: "Résztvevő szerepköre",
        },
        {
          key: "Progress",
          label: this.$t("healthProfessional.projects.tasksProgress"),
        },
      ],
      participantsData: null,
    };
  },
  watch: {
    async createdProjectId() {
      /* await this.getProjectRolesById(); */
      await this.getProgress();
      await this.getParticipantsData();
    },
  },
  methods: {
    /* getParticipantPosition(participant) {
      console.log(participant);
    }, */
    async getParticipantsData() {
      const getDataResponse = await UserLogic.getUserData({
        projectId: this.createdProjectId,
      });
      if (!getDataResponse.Code) {
        this.participantDataConcat(getDataResponse);
      } else {
        //TODO: hiba
      }
    },
    participantDataConcat(participantsData) {
      this.participantsData = this.participantList.filter((p) =>
        p.SuperiorIds.includes(this.$loggedUser.UserId)
      );
      this.participantsData.forEach((p) => {
        const participantData = participantsData.find(
          (pd) => pd.UserId == p.UserId
        );
        if (participantData) {
          Object.assign(p, participantData);
        }
      });
    },
    /* async getProjectRolesById() {
      const getProjectResponse = await ProjectLogic.getProjects({
        projectId: this.createdProjectId,
      });
      if (!getProjectResponse.Code) {
        this.projectRoleList = getProjectResponse[0].Roles;
      } else {
        //TODO: hiba
      }
    }, */
    async getProgress() {
      if (this.$loggedUser.SelectedRole == "HealthProfessional") {
        await this.getProjectDoctorProgress();
      } else if (this.$loggedUser.SelectedRole == "Partner") {
        await this.getProjectManagerProgress();
      }
    },
    async getProjectManagerProgress() {
      const getProjectProgressResponse =
        await ProjectLogic.getProjectManagerProgress(
          this.createdProjectId,
          this.$loggedUser.UserId
        );
      if (!getProjectProgressResponse.Code) {
        this.subordinatesProgress =
          getProjectProgressResponse.SubordinateDoctorProgress;
        this.ownProgress = getProjectProgressResponse;
      } else {
        this.$bvToast.toast(getProjectProgressResponse.Message, {
          title: this.$t("requestResponse.projectDetails.errorGetProgress"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getProjectDoctorProgress() {
      const getProjectProgressResponse =
        await ProjectLogic.getProjectDoctorProgress(
          this.createdProjectId,
          this.$loggedUser.UserId
        );
      if (!getProjectProgressResponse.Code) {
        this.subordinatesProgress =
          getProjectProgressResponse.SubordinatePatientProgress;
        this.ownProgress = getProjectProgressResponse;
      } else {
        this.$bvToast.toast(getProjectProgressResponse.Message, {
          title: this.$t("requestResponse.projectDetails.errorGetProgress"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    /* await this.getProjectRolesById(); */
    await this.getProgress();
    await this.getParticipantsData();
  },
};
</script>
