<template>
  <b-row cols="1" cols-md="2">
    <div
      v-if="visibleDashboardElement($enums.UserInterfaceElement.UserTask.Value)"
      class="eh-panel"
    >
      <fp-daily-task />
    </div>
    <div
      v-if="
        hasSelectedProject() &&
        visibleDashboardElement($enums.UserInterfaceElement.UserTask.Value)
      "
      class="eh-panel"
    >
      <fp-project-progress :project="project" />
    </div>
    <div v-if="visibleDashboardElement($enums.UserInterfaceElement.MedicalDevicePrescription.Value)" class="eh-panel">
      <fp-eeszt-rss-reader />
    </div>
  </b-row>
</template>
<script>
export default {
  name: "SpecialistHome",
  props: {
    project: Object,
  },
  methods: {
    visibleDashboardElement(element) {
      if (!this.project || !this.project.EnabledUiElements) {
        return false;
      } else {
        return (
          this.project.EnabledUiElements.HealthProfessional &&
          this.project.EnabledUiElements.HealthProfessional.includes(element)
        );
      }
    },
    hasSelectedProject() {
      return (
        this.$loggedUser.SelectedProject &&
        this.$loggedUser.SelectedProject.ProjectId
      );
    },
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.home")
    );
  },
};
</script>
