<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :menuElements="healthMenuElements"
      :projectEnabledUiElements="project ? project.EnabledUiElements : null"
      :enabledMenuElements="
        project && project.EnabledUiElements
          ? project.EnabledUiElements.HealthProfessional
          : null
      "
      @forceUpdate="forceUpdate"
    />
    <router-view
      :key="'views-specialist-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="specialist"
      :project="project"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
      @refreshProject="forceUpdate"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { mapActions } from "vuex";

export default {
  name: "SpecialistRouterView",
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      forceUpdateKey: 0,
      project: null,
      healthMenuElements: [
        {
          url: "PatientList",
          urlName: "WebAppSpecialistPatientList",
          langKey: "healthProfessional.menu.patientList",
          key: "patient-list",
        },
        {
          url: "Project",
          urlName: "WebAppSpecialistProjects",
          langKey: "healthProfessional.menu.project",
          key: "project",
        },
        {
          url: "Questionnaires",
          urlName: "WebAppSpecialistQuestionnaires",
          langKey: "healthProfessional.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
        {
          url: "Documents",
          urlName: "WebAppSpecialistDocuments",
          langKey: "healthProfessional.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "Workflow",
          urlName: "WebAppSpecialistWorkflow",
          langKey: "healthProfessional.menu.workflow",
          uiElementName: "Workflow",
          key: "workflow",
        },
        {
          url: "Tasks",
          urlName: "WebAppSpecialistTasks",
          langKey: "healthProfessional.menu.tasks",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Appointments",
          urlName: "WebAppSpecialistAppointments",
          langKey: "healthProfessional.menu.appointments",
          uiElementName: "Appointment",
          key: "appointment",
        },
        {
          url: "Templates",
          urlName: "WebAppSpecialistTemplates",
          langKey: "healthProfessional.menu.templates",
          uiElementName: "NotificationTemplate",
          key: "template",
        },
        {
          url: "RecipeManager",
          urlName: "WebAppSpecialistRecipeManager",
          langKey: "healthProfessional.menu.prescriptions",
          uiElementName: "MedicalDevicePrescription",
          key: "prescription",
        },
        {
          url: "RecipeReportManager",
          urlName: "WebAppSpecialistRecipeReportManager",
          langKey: "healthProfessional.menu.prescriptionReports",
          uiElementName: "MedicalDevicePrescription",
          key: "bever",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["setProjectPermissions"]),
    async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async getProject() {
      this.project = null;
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
          this.setStore();
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedData", {
              name: this.$t("healthProfessional.projects.currentProject"),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    setStore() {
      const ownProjectParticipant = this.project.Participants.find(
        (participant) => participant.UserId == this.$loggedUser.UserId
      );
      if (ownProjectParticipant) {
        const ownProjectRole = this.project.Roles.find(
          (role) => role.RoleName == ownProjectParticipant.RoleName
        );
        if (ownProjectParticipant) {
          this.setProjectPermissions(ownProjectRole.Permissions);
        }
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
  },
  async mounted() {
    await this.getProject();
  },
};
</script>
