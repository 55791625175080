<template>
  <!-- Receptlista -->
  <div>
    <div style="display: flex; align-items: baseline">
      <fp-date-range-button
        style="padding-left: 0px"
        v-model="dispensedDateRange"
        :hideClearButton="true"
        @change="filterList"
      />
      <fp-input
        style="width: 200px"
        label="Recept azonosító"
        v-model="filterPrescriptionId"
        @change="filterList"
      />
      <fp-input
        label="Recept EESZT azonosító"
        style="margin: 0px 10px; width: 200px"
        v-model="filterEesztId"
        @change="filterList"
      />
      <fp-input
        style="width: 250px"
        label="Recept NEAK vényazonosító"
        v-model="filterNeakId"
        @change="filterList"
      />
    </div>
    <div class="selected-pres-count-text">
      {{ selectedAllProductCount }} kiválasztott recept
    </div>
    <fp-tabs style="margin: 10px 0px">
      <fp-tab title="Lejelenthető receptek">
        <prescription-list
          :canSelectProducts="canSelectProducts"
          :isDisabledReportedProds="isDisabledReportedProds"
          :neakReported="false"
          :reportId="reportId"
          :filterParams="filterParams"
        />
      </fp-tab>
      <fp-tab title="Korábbi jelentésen szereplő receptek">
        <prescription-list
          :canSelectProducts="canSelectProducts"
          :isDisabledReportedProds="isDisabledReportedProds"
          :neakReported="true"
          :reportId="reportId"
          :filterParams="filterParams"
        />
      </fp-tab>
      <fp-tab v-if="fullPrescriptionInvalidList">
        <template #title>
          Hiányos receptek
          <b-badge pill variant="danger">
            {{ fullPrescriptionInvalidList.length }}
          </b-badge>
        </template>
        <fp-table-list
          :items="fullPrescriptionInvalidList"
          :fields="fullPrescriptionInvalidListFields"
        >
          <template #Messages="row">
            <li
              v-for="(msg, index) in row.item.Messages"
              :key="'presc-error-' + index"
            >
              {{ msg }}
            </li>
          </template>
          <template #InvalidProducts="row">
            <div
              v-if="row.item.InvalidProducts && row.item.InvalidProducts.length"
            >
              <li
                v-for="(prod, prodIndex) in row.item.InvalidProducts"
                :key="'presc-invalid-prod-' + prodIndex"
              >
                {{
                  prod.Ttt
                    ? prod.Ttt.Name +
                      " (TTT: " +
                      prod.Ttt.Code +
                      "), " +
                      (prod.Amount
                        ? prod.Amount.Amount + " " + prod.Amount.Unit
                        : "") +
                      ", " +
                      (prod.DispenseTitle ? prod.DispenseTitle.Name : "") +
                      ":"
                    : ""
                }}
                <ul>
                  <li
                    v-for="(prodError, msgIndex) in prod.Messages"
                    :key="'prescription-error-prod-error-' + msgIndex"
                  >
                    {{ prodError }}
                  </li>
                </ul>
              </li>
            </div>
            <div v-else></div>
          </template>
        </fp-table-list>
      </fp-tab>
    </fp-tabs>
  </div>
</template>
<script>
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import { mapActions } from "vuex";
import moment from "moment";
import PrescriptionErrorMessagesModal from "./PrescriptionErrorMessagesModal.vue";
import PrescriptionList from "./PrescriptionList.vue";

export default {
  components: { PrescriptionErrorMessagesModal, PrescriptionList },
  name: "SelectParticipantList",
  props: {
    isDisabledReportedProds: Boolean,
    reportId: Number,
    canSelectProducts: Boolean,
  },
  data() {
    return {
      filterParams: null,
      prescriptionIds: null,
      fullPrescriptionInvalidListFields: [
        { key: "PrescriptionId", label: "Recept azonosító" },
        { key: "Messages", label: "Recept hibák, hiányosságok" },
        {
          key: "InvalidProducts",
          label: "Recept termékek hibái, hiányosságai",
        },
      ],
      dispensedDateRange: {
        to: moment().format(),
        from: moment().subtract(7, "days").startOf("days").format(),
      },
      filterPrescriptionId: null,
      filterEesztId: null,
      filterNeakId: null,
      invalidPrescriptionList: null,
      invalidPresProductsFields: [
        { key: "Error", label: "" },
        { key: "Data", label: "Termék" },
        { key: "Messages", label: "Hiba üzenetek" },
      ],
    };
  },
  computed: {
    selectedAllProductCount() {
      return this.$store.state.bever.SelectedPrescriptions.length;
    },
    fullPrescriptionInvalidList() {
      if (this.invalidPrescriptionList) {
        const filteredList = this.invalidPrescriptionList.filter(
          (ip) =>
            !this.prescriptionIds
              .map((p) => p.PrescriptionId)
              .includes(ip.PrescriptionId)
        );
        return filteredList;
      }
      return null;
    },
  },
  methods: {
    async filterList() {
      this.filterParams = {
        dispenseStartDate: this.dispensedDateRange.from,
        dispenseEndDate: this.dispensedDateRange.to,
        PrescriptionId: this.filterPrescriptionId
          ? this.filterPrescriptionId
          : null,
        "PrescriptionData.EesztId-contains": this.filterEesztId
          ? this.filterEesztId
          : null,
        "PrescriptionData.NeakId": this.filterNeakId ? this.filterNeakId : null,
      };
      await this.getPrescriptionList(this.filterParams);
    },
    async getPrescriptionList(params) {
      await this.getPrescriptionIds(params);
      await this.getInvalidPrescriptions(params);
    },
    async getPrescriptionIds(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceProductIds(params);
      if (!getResponse.Code) {
        this.prescriptionIds = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a recept lista betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getInvalidPrescriptions(params) {
      const getResponse = await MedicalDeviceLogic.getInvalidFinanceProducts({
        ...params,
        "-orderbydesc": "PrescriptionId",
      });
      if (!getResponse.Code) {
        this.invalidPrescriptionList = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a hibás recept lista betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  async mounted() {
    this.filterParams = {
      dispenseStartDate: this.dispensedDateRange.from,
      dispenseEndDate: this.dispensedDateRange.to,
    };
    await this.getPrescriptionList(this.filterParams);
  },
};
</script>
<style scoped>
.selected-pres-count-text {
  background: var(--eh-primary-5);
  padding: 5px 10px;
  width: fit-content;
  border-radius: 10px;
  color: white;
  margin: auto;
}
</style>
