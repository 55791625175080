<template>
  <div v-if="usage">
    <fp-select
      label="Felírt termék felhasználás típusa"
      :required="true"
      v-model="usage.TypeId"
      @change="changeValue"
      :items="eesztDosageTypeList"
      valueKey="EntryId"
      textKey="Name"
      :state="!validation.hasError('usage.TypeId')"
      :error="validation.firstError('usage.TypeId')"
    />
    <fp-input
      v-if="
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByPartOfDay ||
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByFrequence
      "
      label="Felírt termék felhasználási gyakoriság"
      description="pl.: 3x1 esetén 3, vagy Reggel (R), Délben (D), Este (E), Lefekvés előtt (LE)"
      v-model="usage.Frequency"
      @change="changeValue"
    />
    <fp-input
      v-if="
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByPartOfDay ||
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByFrequence
      "
      label="Felírt termék felhasználási mennyiség, gyakoriság szerint"
      v-model="usage.Amount"
      @change="changeValue"
    />
    <fp-input
      v-if="
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByPartOfDay ||
        usage.TypeId == $enums.Prescription.EesztDosageCondition.ByFrequence
      "
      label="Felírt termék felhasználási gyakoriság egység"
      v-model="usage.Unit"
      @change="changeValue"
    />
    <fp-text-area
      v-if="usage.TypeId == $enums.Prescription.EesztDosageCondition.ByText"
      label="Felírt termék felhasználása szövegesen"
      v-model="usage.UsageDescription"
      @change="changeValue"
    />
  </div>
</template>
<script>
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  name: "ProductUsage",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
  },
  data() {
    return {
      usage: null,
      defaultProductUsage: {
        TypeId: null,
        Frequency: null,
        Amount: null,
        Unit: null,
        UsageDescription: null,
      },
      eesztDosageTypeList: null,
    };
  },
  watch: {
    value() {
      this.setUsage();
    },
  },
  validators: {
    "usage.TypeId": function (value) {
      if (this.hasUsageData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasUsageData() {
      return Object.values(this.usage).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    setUsage() {
      if (this.value) {
        this.usage = this.value;
      } else {
        this.usage = JSON.parse(JSON.stringify(this.defaultProductUsage));
      }
    },
    changeValue() {
      if (this.hasUsageData) {
        this.$emit("change", this.usage);
      } else {
        this.$emit("change", null);
      }
      this.resetValidation();
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    async getBase() {
      const getBaseResponse = await BaseDataLogic.getBaseData(
        this.$enums.BaseName.eeszt_dosage_type.Value,
        {
          ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
          ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
        }
      );
      if (!getBaseResponse.Code) {
        this.eesztDosageTypeList = getBaseResponse;
        this.eesztDosageTypeList.splice(0, 0, {
          Name: "Nincs megadva",
          EntryId: null,
        });
      } else {
        this.eesztDosageTypeList = [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    await this.getBase();
    this.setUsage();
  },
};
</script>
