<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :changedProfileFillingRate="profileFillingRate"
      :menuElements="clientMenuElements"
      :enabledMenuElements="enabledMenuElements"
    />
    <router-view
      :key="'views-client-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="client"
      :enabledProjectModules="enabledModules"
      :enabledProjectFunctions="enabledFunctions"
      @forceUpdate="forceUpdate"
      @title-changed="changeTitle"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
      @profile-filling-rate-changed="changeProfileFillingRate"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { UserPreferencesLogic } from "@/Logic/Backend/user-preferences";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      profileFillingRate: null,
      forceUpdateKey: 0,
      enabledMenuElements: null,
      enabledModules: null,
      enabledFunctions: null,
      clientMenuElements: [
        //TODO: notification jelző beállítása (notificationCount)
        {
          url: "Notifications",
          urlName: "WebAppClientNotifications",
          langKey: "client.menu.notifications",
          uiElementName: "NotificationTemplate",
          key: "notification",
          //badge:notificationCount
        },
        {
          url: "Programs",
          urlName: "WebAppClientPrograms",
          langKey: "client.menu.programs",
          key: "program",
        },
        {
          url: "Documents",
          urlName: "WebAppClientDocuments",
          langKey: "client.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "QuestionnaireList",
          urlName: "WebAppClientQuestionnaireList",
          langKey: "client.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
        {
          url: "Tasks",
          urlName: "WebAppClientTasks",
          langKey: "client.menu.tasks",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Appointments",
          urlName: "WebAppClientAppointments",
          langKey: "client.menu.appointments",
          uiElementName: "Appointment",
          key: "appointment",
        },
      ],
    };
  },
  methods: {
    forceUpdate() {
      ++this.forceUpdateKey;
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.siteTitle = newTitle;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
    changeProfileFillingRate(fillingRate) {
      //console.log("RouterView.vue")
      //console.log(fillingRate)
      if (fillingRate) {
        this.profileFillingRate = fillingRate;
      }
    },
    async getProjects() {
      const getProjectsResponse = await ProjectLogic.getProjectDetails({
        UserId: this.$loggedUser.UserId,
      });
      if (!getProjectsResponse.Code) {
        this.setEnabledUiElements(getProjectsResponse);
        this.setEnabledModules(getProjectsResponse);
        this.setEnabledFunctions(getProjectsResponse);
      }
    },
    setEnabledUiElements(projects) {
      this.enabledMenuElements = [];
      projects.forEach((p) => {
        this.enabledMenuElements = this.enabledMenuElements.concat(
          p.EnabledUiElements.Client
        );
      });
    },
    setEnabledModules(projects) {
      this.enabledModules = [];
      projects.forEach((p) => {
        this.enabledModules = this.enabledModules.concat(
          p.EnabledModules.Client
        );
      });
    },
    setEnabledFunctions(projects) {
      this.enabledFunctions = [];
      projects.forEach((p) => {
        this.enabledFunctions = this.enabledFunctions.concat(
          p.EnabledFunctions.Client
        );
      });
    },
  },
  async mounted() {
    await this.getProjects();
  },
  beforeDestroy() {
    UserPreferencesLogic.deleteUserPreferencesFromStorage();
  },
};
</script>
