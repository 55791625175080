/* Static */
import Static from "@/Views/Static/Default/RouterView.vue";
import Home from "@/Views/Static/Default/Home.vue";
import Login from "@/Views/Static/Default/Login.vue";
import ForgottenPassword from "@/Views/Static/Default/ForgottenPassword.vue";
import Search from "@/Views/Static/Default/Search.vue";
import Registration from "@/Views/Static/Default/Registration.vue";
/* Static - Moduls */
import Cardiology from "@/Views/Static/Default/modules/Cardiology.vue";
import Depressio from "@/Views/Static/Default/modules/Depressio.vue";
import Diabetology from "@/Views/Static/Default/modules/Diabetology.vue";
import Oncology from "@/Views/Static/Default/modules/Oncology.vue";
/* Static - Programs */
import ThrowTheCigarette from "@/Views/Static/Default/programs/ThrowTheCigarette.vue";
import EatHealthy from "@/Views/Static/Default/programs/EatHealthy.vue";
import ImproveYourMood from "@/Views/Static/Default/programs/ImproveYourMood.vue";
import CalorieConsumption from "@/Views/Static/Default/programs/CalorieConsumption.vue";
/* Static - Menu */
import AboutFP from "@/Views/Static/Default/menu/AboutFP.vue";
import WriteUs from "@/Views/Static/Default/menu/WriteUs.vue";
import Reference from "@/Views/Static/Default/menu/Reference.vue";
import Visions from "@/Views/Static/Default/menu/Visions.vue";
import Service from "@/Views/Static/Default/menu/Service.vue";
/* Footer */
import Legal from "@/Views/Static/Default/footer/Legal.vue";
import GYIK from "@/Views/Static/Default/footer/GYIK.vue";
import DataProtection from "@/Views/Static/Default/footer/DataProtection.vue";
import Impress from "@/Views/Static/Default/footer/Impress.vue";

export default [
  {
    path: "/",
    name: "Static",
    component: Static,
    children: [
      {
        path: "/",
        name: "Home",
        components: {
          customStatic: Home,
        },
      },
      {
        path: "/Login",
        name: "Login",
        components: {
          customStatic: Login,
        },
      },
      {
        path: "/Registration",
        name: "Registration",
        components: {
          customStatic: Registration,
        },
      },
      {
        path: "/ForgottenPassword",
        name: "ForgottenPassword",
        components: {
          static: ForgottenPassword,
        },
      },
      {
        path: "/Search",
        name: "Search",
        components: {
          static: Search,
        },
      },
      {
        path: "/AboutFP",
        name: "AboutFP",
        components: {
          static: AboutFP,
        },
      },
      {
        path: "/WriteUs",
        name: "WriteUs",
        components: {
          customStatic: WriteUs,
        },
      },
      {
        path: "/Service",
        name: "Service",
        components: {
          static: Service,
        },
      },
      {
        path: "/Visions",
        name: "Visions",
        components: {
          static: Visions,
        },
      },
      {
        path: "/Reference",
        name: "Reference",
        components: {
          static: Reference,
        },
      },
      {
        path: "/Legal",
        name: "Legal",
        components: {
          static: Legal,
        },
      },
      {
        path: "/GYIK",
        name: "GYIK",
        components: {
          static: GYIK,
        },
      },
      {
        path: "/DataProtection",
        name: "DataProtection",
        components: {
          static: DataProtection,
        },
      },
      {
        path: "/Impress",
        name: "Impress",
        components: {
          static: Impress,
        },
      },
      {
        path: "/Cardiology",
        name: "Cardiology",
        components: {
          static: Cardiology,
        },
      },
      {
        path: "/Diabetology",
        name: "Diabetology",
        components: {
          static: Diabetology,
        },
      },
      {
        path: "/Depressio",
        name: "Depressio",
        components: {
          static: Depressio,
        },
      },
      {
        path: "/Oncology",
        name: "Oncology",
        components: {
          static: Oncology,
        },
      },
      {
        path: "/CalorieConsumption",
        name: "CalorieConsumption",
        components: {
          static: CalorieConsumption,
        },
      },
      {
        path: "/EatHealthy",
        name: "EatHealthy",
        components: {
          static: EatHealthy,
        },
      },
      {
        path: "/ImproveYourMood",
        name: "ImproveYourMood",
        components: {
          static: ImproveYourMood,
        },
      },
      {
        path: "/ThrowTheCigarette",
        name: "ThrowTheCigarette",
        components: {
          static: ThrowTheCigarette,
        },
      },
    ],
  },
];
