<template>
  <div>
    <fp-form-modal
      @closed="visible = false"
      :closable="false"
      v-model="showNeakLoginForm"
      title="NEAK kassza választás"
    >
      <template #content>
        <div v-if="loginData">
          <fp-select
            label="Kassza"
            :items="loginData.ReportPurses"
            v-model="selectedPurse"
            textKey="PurseName"
          />
          <fp-select
            label="Jelentés típusa"
            v-if="selectedPurse"
            :items="selectedPurse.PurseReportTypes"
            v-model="selectedPurseReportType"
            textKey="ReportTypeName"
          />
        </div>
        <b-button @click="reportSubmit">Kiválaszt</b-button>
      </template>
    </fp-form-modal>
    <fp-form-modal
      @closed="visible = false"
      :closable="false"
      v-model="showValidationForm"
      title="Érvényesítés/Érvénytelenítés"
    >
      <template #content>
        <fp-input
          v-if="transaction"
          label="A feltöltött fájlok SHA-2 kódjának ellenőrzése"
          :state="
            transaction &&
            sentValidationHashCode == transaction.ValidationHashCode
          "
          error="Az SHA-2 kód nem ehhez a tranzakcióhoz tartozik, ellenőrizze az emailt, hogy jól másolta-e be az SHA-2 kódot."
          v-model="sentValidationHashCode"
        />
        <fp-select
          label="Érvényesítés típusa"
          :items="validationTypeList"
          textKey="Text"
          valueKey="Value"
          v-model="validationType"
        />
        <fp-input label="Érvényesítő kód" v-model="sendValidationCode" />
        <b-button
          @click="reportValidate"
          :disabled="
            !(
              !transaction ||
              sentValidationHashCode == transaction.ValidationHashCode
            )
          "
        >
          Küldés
        </b-button>
      </template>
    </fp-form-modal>
    <b-toast
      v-model="neakConnectionToast"
      title="NEAK kapcsolat"
      solid
      no-auto-hide
      variant="primary"
    >
      <b-spinner small /> Csatlakozás...
    </b-toast>
  </div>
</template>
<script>
import { AuthLogic } from "../../../../Logic/Backend/auth";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
export default {
  name: "NeakReport",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    reportId: Number,
    type: String, //Submit, Change, Acknowledge, Validate, Update
    value: Boolean,
  },
  data() {
    return {
      showNeakLoginForm: null,
      showValidationForm: null,
      visible: this.value,
      loginData: null,
      selectedPurse: null,
      selectedPurseReportType: null,
      transaction: null,
      sentValidationHashCode: null,
      sendValidationCode: null,
      validationType: null,
      validationTypeList: [
        { Value: "Validate", Text: "Érvényesít" },
        { Value: "Invalidate", Text: "Érvénytelenít" },
      ],
      neakConnectionToast: false,
    };
  },
  watch: {
    async value(input) {
      this.visible = input;
      if (input) {
        this.neakConnectionToast = true;
        await this.neakLogin();
        if (this.loginData) {
          if (this.type == "Submit") {
            this.showValidationForm = false;
            this.showNeakLoginForm = true;
          } else if (this.type == "Change") {
            this.showNeakLoginForm = false;
            this.showValidationForm = true;
            await this.reportChange();
          } else if (this.type == "Acknowledge") {
            this.showNeakLoginForm = false;
            this.showValidationForm = true;
            await this.reportAcknowledge();
          } else if (this.type == "Validate") {
            this.showNeakLoginForm = false;
            this.showValidationForm = true;
          }
        } else {
          this.showNeakLoginForm = false;
          this.showValidationForm = false;
        }
        this.neakConnectionToast = false;
      } else {
        this.neakConnectionToast = true;
        this.showNeakLoginForm = false;
        this.showValidationForm = false;
        await this.neakLogout();
        this.neakConnectionToast = false;
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async neakLogin() {
      const loginResponse = await AuthLogic.loginNeak();
      if (!loginResponse.Code) {
        this.loginData = loginResponse;
      } else {
        this.$bvToast.toast(loginResponse.Message, {
          variant: "danger",
          title: "Hiba történt a NEAK-hoz való csatlakozás során!",
          solid: true,
        });
      }
    },
    async neakLogout() {
      if (this.loginData) {
        const logoutResponse = await AuthLogic.logoutNeak({
          neakLoginToken: this.loginData.LoginToken,
        });
        if (!logoutResponse.Code && logoutResponse.Code == 0) {
          this.loginData = null;
        } else {
          this.$bvToast.toast(logoutResponse.Message, {
            variant: "danger",
            title: "Hiba történt a NEAK-tól való lecsatlakozás során!",
            solid: true,
          });
        }
      }
    },
    async reportSubmit() {
      if (this.loginData) {
        const body = {
          LoginToken: this.loginData.LoginToken,
          PurseCode: this.selectedPurse.PurseCode,
          ReportTypeCode: this.selectedPurseReportType.ReportTypeCode,
        };
        const submitResponse = await MedicalDeviceLogic.reportSubmit(
          this.reportId,
          body
        );
        if (!submitResponse.Code) {
          this.transaction = submitResponse;

          this.showNeakLoginForm = false;
          this.showValidationForm = true;
        } else {
          this.$bvToast.toast(submitResponse.Message, {
            variant: "danger",
            title: "Hiba történt a jelentés NEAK-ba való beküldése során!",
            solid: true,
          });
        }
      }
    },
    async reportChange() {
      if (this.loginData) {
        const body = {
          NeakLoginToken: this.loginData.LoginToken,
        };
        const changeResponse = await MedicalDeviceLogic.reportChange(
          this.reportId,
          body
        );
        if (!changeResponse.Code) {
          this.transaction = changeResponse;
        } else {
          this.$bvToast.toast(changeResponse.Message, {
            variant: "danger",
            title: "Hiba történt a jelentés NEAK-ba való javítása során!",
            solid: true,
          });
        }
      }
    },
    async reportAcknowledge() {
      if (this.loginData) {
        const body = {
          NeakLoginToken: this.loginData.LoginToken,
        };
        const acknowledgeResponse = await MedicalDeviceLogic.reportAcknowledge(
          this.reportId,
          body
        );
        if (!acknowledgeResponse.Code || acknowledgeResponse.Code == 0) {
          this.$bvToast.toast("Sikeresen nyugtázta a jelentést", {
            variant: "success",
            title: "Sikeres nyugtázás!",
            solid: true,
          });
        } else {
          this.$bvToast.toast(acknowledgeResponse.Message, {
            variant: "danger",
            title: "Hiba történt a jelentés nyugtázása során!",
            solid: true,
          });
        }
      }
    },
    async reportValidate() {
      if (this.loginData) {
        const body = {
          ValidationType: this.validationType,
          LoginToken: this.loginData.LoginToken,
          ValidationCode: this.sendValidationCode.trim(),
        };
        const validateResponse = await MedicalDeviceLogic.reportValidate(
          this.reportId,
          body
        );
        if (!validateResponse.Code || validateResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen érvényesítette/érvénytelenítette a jelentést!",
            {
              variant: "success",
              title: "Sikeres mentés!",
              solid: true,
            }
          );
          await this.reportUpdate();
        } else {
          this.$bvToast.toast(validateResponse.Message, {
            variant: "danger",
            title:
              "Hiba történt  a jelentés az érvényesítése/érvénytelenítése során!",
            solid: true,
          });
        }
      }
    },
    async reportUpdate() {
      if (this.loginData) {
        const body = {
          NeakLoginToken: this.loginData.LoginToken,
        };
        const updateResponse = await MedicalDeviceLogic.reportUpdate(
          this.reportId,
          body
        );
        if (!updateResponse.Code || updateResponse.Code == 0) {
          this.$emit("refreshiList");
        } else {
          this.$bvToast.toast(updateResponse.Message, {
            variant: "danger",
            title: "Hiba történt  a jelentés státuszának frissítése során!",
            solid: true,
          });
        }
      }
    },
  },
};
</script>
