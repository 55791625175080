<template>
  <!-- TODO:autó kiküldés? -->
  <fp-form-modal size="xl" v-model="visible">
    <template #content>
      <fp-select
        label="Elem típusa"
        :items="entityTypes"
        v-model="selectedEntityType"
      ></fp-select>
      <div v-if="selectedEntityType === 'NotificationTemplate'">
        <fp-table-list :items="templates" :fields="templateListFields">
          <template #SupportedLanguages="row">
            <span v-for="lang in row.item.SupportedLanguages" :key="lang">
              <img
                :alt="$enums.Languages[lang].Text"
                style="margin-right: 5px"
                width="20px"
                :src="require('@/Assets' + $enums.Languages[lang].flag)"
              />
            </span>
          </template>
          <template #SupportedTypes="row">
            <b-icon
              style="margin-right: 5px"
              v-if="row.item.EmailTemplate"
              icon="envelope-fill"
            ></b-icon>
            <b-icon
              style="margin-right: 5px"
              v-if="row.item.SmsTemplate"
              icon="chat-square-text-fill"
            ></b-icon>
            <b-icon
              style="margin-right: 5px"
              v-if="row.item.PushTemplate"
              icon="phone-vibrate"
            ></b-icon>
          </template>
          <template #Options="row">
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="eye-fill"
              @click="viewTemplate(row.item)"
              v-b-tooltip.hover="$t('base.basic.view')"
            />
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="plus-circle"
              @click="
                addItem(row.item, 'NotificationTemplate', row.item.TemplateId)
              "
            />
          </template>
        </fp-table-list>
      </div>
      <view-template-modal
        v-model="showTemplateModal"
        :messages="selectedTemplate"
      />
      <div
        v-if="
          selectedEntityType === $enums.ProjectItemsEntityTypeName.FormSchema
        "
      >
        <fp-table-list
          :items="forms.filter((f) => f.FormCategory === 'Form')"
          :fields="formListFields"
        >
          <template #Languages="row">
            <span v-for="lang in row.item.Languages" :key="lang">
              <img
                :alt="$enums.Languages[lang].Text"
                style="margin-right: 5px"
                width="20px"
                :src="require('@/Assets' + $enums.Languages[lang].flag)"
              />
            </span>
          </template>
          <template #FormCategory="row">
            {{ row.item.FormCategory }}
          </template>
          <template #Title="row">
            {{ getTextByLanguage(row.item.Title) }}
          </template>
          <template #Description="row">
            {{ getTextByLanguage(row.item.Description) }}
          </template>
          <template #Options="row">
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="eye-fill"
              @click="viewForm(row.item)"
              v-b-tooltip.hover="$t('base.basic.view')"
            />
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="plus-circle"
              @click="
                addItem(
                  row.item,
                  $enums.ProjectItemsEntityTypeName.FormSchema,
                  row.item.FormSchemaId
                )
              "
            />
          </template>
        </fp-table-list>
      </div>
      <div v-if="selectedEntityType === 'QuestionnaireSchema'">
        <fp-table-list
          :items="forms.filter((f) => f.FormCategory === 'Questionnaire')"
          :fields="formListFields"
        >
          <template #Languages="row">
            <span v-for="lang in row.item.Languages" :key="lang">
              <img
                :alt="$enums.Languages[lang].Text"
                style="margin-right: 5px"
                width="20px"
                :src="require('@/Assets' + $enums.Languages[lang].flag)"
              />
            </span>
          </template>
          <template #FormCategory="row">
            {{ row.item.FormCategory }}
          </template>
          <template #Title="row">
            {{ getTextByLanguage(row.item.Title) }}
          </template>
          <template #Description="row">
            {{ getTextByLanguage(row.item.Description) }}
          </template>
          <template #Options="row">
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="eye-fill"
              @click="viewForm(row.item)"
              v-b-tooltip.hover="$t('base.basic.view')"
            />
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="plus-circle"
              @click="
                addItem(
                  row.item,
                  $enums.ProjectItemsEntityTypeName.FormSchema,
                  row.item.FormSchemaId
                )
              "
            />
          </template>
        </fp-table-list>
      </div>
      <fp-form-modal size="xl" v-model="showFormModal">
        <template #content>
          <fp-form-schema-builder
            v-if="selectedForm"
            :formSchemaId="selectedForm.FormSchemaId"
            :disabled="true"
          />
        </template>
      </fp-form-modal>
      <div
        v-if="
          selectedEntityType === $enums.ProjectItemsEntityTypeName.FpWorkflow
        "
      >
        <fp-table-list :items="workflows" :fields="workflowListFields">
          <template #Elements="row">
            <div
              v-for="(value, key) in Object.values(row.item.Elements)"
              :key="'workflow-elements-' + key"
            >
              {{ value.Type }}
            </div>
          </template>
          <template #Options="row">
            <b-icon
              class="h4"
              style="cursor: pointer; margin-right: 5px"
              icon="plus-circle"
              @click="
                addItem(
                  row.item,
                  $enums.ProjectItemsEntityTypeName.FpWorkflow,
                  row.item.WorkflowId
                )
              "
            />
          </template>
        </fp-table-list>
      </div>
      <div v-if="selectedEntityType === 'FpTag'">
        <fp-tag-manager
          :tagList="tags"
          @getTagList="$emit('getLists')"
          newTagType="ProjectTag"
        />
        <fp-table-list :items="tags" :fields="tagListFields">
          <template #Tag="row">
            <fp-tag :tag="row.item" :editable="false" />
          </template>
          <template #Options="row">
            <div style="text-align: right">
              <b-icon
                class="h4"
                style="cursor: pointer; margin-right: 5px"
                icon="plus-circle"
                @click="addItem(row.item, 'FpTag', row.item.TagId)"
              />
            </div>
          </template>
        </fp-table-list>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import ViewTemplateModal from "./ViewTemplateModal";

export default {
  name: "ProjectItemForm",
  components: {  ViewTemplateModal },
  model: {
    prop: "showModal",
    event: "change",
  },
  props: {
    workflows: Array,
    tags: Array,
    templates: Array,
    forms: Array,
    showModal: Boolean,
  },
  data() {
    return {
      visible: null,
      selectedEntityType: null,
      entityTypes: [
        this.$enums.ProjectItemsEntityTypeName.FormSchema,
        "QuestionnaireSchema",
        "FpTag",
        "NotificationTemplate",
        this.$enums.ProjectItemsEntityTypeName.FpWorkflow,
      ],
      templateListFields: [
        {
          key: "Name",
          label: this.$t(
            "partner.participantList.communicationTab.templateName"
          ),
        },
        {
          key: "SupportedLanguages",
          label: this.$t(
            "partner.participantList.communicationTab.templateSupportedLanguages"
          ),
        },
        {
          key: "SupportedTypes",
          label: this.$t(
            "partner.participantList.communicationTab.templateMessageTypes"
          ),
        },
        { key: "Options", label: "" },
      ],
      selectedTemplate: null,
      showTemplateModal: false,
      formListFields: [
        { key: "Languages", label: "Elérhető nyelvek" },
        { key: "Title", label: "Cím" },
        { key: "Description", label: "Leírás" },
        { key: "Options", label: "" },
      ],
      selectedForm: null,
      showFormModal: false,
      workflowListFields: [
        { key: "Name", label: "Megnevezés" },
        { key: "Description", label: "Leírás" },
        { key: "Elements", label: "Lépések" },
        { key: "Options", label: "" },
      ],
      tagListFields: [
        { key: "Tag", label: "Címke" },
        { key: "Options", label: "" },
      ],
    };
  },
  watch: {
    showModal(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      if (typeof textObject == "object") {
        const languages = Object.keys(textObject);
        if (languages.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (languages.includes("en")) {
          return textObject.en;
        } else {
          return textObject[languages[0]];
        }
      } else {
        return textObject;
      }
    },
    viewTemplate(template) {
      this.selectedTemplate = template;
      this.showTemplateModal = true;
    },
    viewForm(form) {
      this.selectedForm = form;
      this.showFormModal = true;
    },
    addItem(item, entityType, id) {
      const body = {
        Name: item.Name,
        Description: item.Description,
        NotiTypes: {
          Email: !!item.EmailTemplate,
          Push: !!item.PushTemplate,
          Sms: !!item.SmsTemplate,
        },
        SupportedLanguages: item.SupportedLanguages,
        EntityType: entityType,
        EntityId: id,
        Category: item.FormCategory,
      };
      this.$emit("addItem", body);
    },
  },
};
</script>
