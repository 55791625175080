<template>
  <div>
    <b-button style="margin-top: 10px" @click="$router.go(-1)">{{
      $t("base.basic.back")
    }}</b-button>
    <div v-if="selectedQuestionnaire">
      <fp-panel-card
        v-if="patient"
        style="background: white; width: fit-content; margin: auto"
        :title="$t('healthProfessional.questionnaires.userData')"
      >
        <template #content>
          <div v-if="patient">
            <div style="margin-top: 15px; display: flex; flex-wrap: wrap">
              <div v-for="(tag, index) in patient.Tags" :key="'tag-' + index">
                <fp-tag :tag="tag" />
              </div>
            </div>
            <div>
              <b>{{ $t("healthProfessional.questionnaires.name") }}:</b>
              {{ patient.Name }}
            </div>
            <div>
              <b>{{ $t("healthProfessional.questionnaires.ssn") }}:</b>
              {{ patient.Ssn }}
            </div>
            <div>
              <b>{{ $t("healthProfessional.questionnaires.birthday") }}:</b>
              {{
                $convertDateToString(patient.PatientDateOfBirth, "YYYY-MM-DD")
              }}
            </div>
            <div>
              <b>{{ $t("healthProfessional.questionnaires.address") }}:</b>
              {{
                patient.Address.ZipCode +
                " " +
                patient.Address.City +
                ", " +
                patient.Address.Street +
                " " +
                patient.Address.Building +
                ". " +
                patient.Address.SubBuilding
              }}
            </div>
          </div>
        </template>
      </fp-panel-card>
      <fp-form-schema-builder
        v-if="selectedQuestionnaire"
        :instance="selectedQuestionnaire"
        :disabled="true"
        :scoreable="formEvaluable"
        @closeForm="closeForm"
      />
    </div>
    <div style="text-align: center; margin: 20px; font-size: 25px" v-else>
      <!-- TODO: szótár -->
      A kiválasztott kérdőív nem található!
      <div style="font-size: 20px">A kérdőív törlésre kerülthetett.</div>
    </div>
  </div>
</template>
<script>
import { FormLogic } from "@/Logic/Backend/form";
import { ProjectLogic } from "@/Logic/Backend/project";

export default {
  data() {
    return {
      selectedQuestionnaire: null,
      questionnaireId: null,
      formEvaluable: false,
      patient: null,
    };
  },
  methods: {
    //kiválasztott kérdőív példány kérés
    async getSelectesQuestionannaire() {
      //kérés indítás id alapján
      const questionnaireListResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.questionnaireId,
      });
      //sikeres kérés?
      if (
        questionnaireListResponse &&
        !questionnaireListResponse.Code &&
        questionnaireListResponse.length
      ) {
        //kapott lista első eleme tárolása
        this.selectedQuestionnaire = questionnaireListResponse[0];
        await this.getUserData();
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      if (this.selectedQuestionnaire) {
        this.formEvaluable = [
          this.$enums.QuestionnaireStatus.Filled.Value,
          this.$enums.QuestionnaireStatus.UnderEvaluation.Value,
        ].includes(this.selectedQuestionnaire.Status);
      }
    },
    //értékelés mentés nélkül vissza lépés a listába
    closeForm() {
      this.$router.push({ name: "WebAppSpecialistQuestionnaires" });
    },
    async getUserData() {
      const getUserDataResponse = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      if (!getUserDataResponse.Code) {
        this.patient = getUserDataResponse.find(
          (p) => p.UserId == this.selectedQuestionnaire.SubjectId
        );
      } else {
        this.$bvToast.toast(getUserDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.questionnaires.errorPatientName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //első betöltéskor fut le
  async mounted() {
    //urlben küldött id tárolása
    const taskParams = this.$route.query.taskParams;
    if (taskParams) {
      this.questionnaireId = JSON.parse(taskParams).FormInstanceId;
    } else {
      this.questionnaireId = this.$route.query.Id;
    }
    //nics tárolt id?
    if (!this.questionnaireId) {
      //vissza lépés a listába
      this.$router.push({ name: "WebAppSpecialistQuestionnaires" });
    }
    //oldal cím beállítása
    this.$emit(
      "title-changed",
      this.$t("healthProfessional.questionnaires.waitingTab.evaulating.title")
    );
    //kiválasztott kérdőív példány kérés
    await this.getSelectesQuestionannaire();
  },
};
</script>
