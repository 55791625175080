import TemplateList from "./TemplateList.vue";
import TemplateContent from "./TemplateContent.vue";
import TemplatesManager from "./TemplatesManager.vue";

import TemplateComponents from "./Components";

export default {
  install(Vue) {
    //Modul aktivitás beállítása
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("Template");

    //Components use
    Vue.use(TemplateComponents);

    //Modul komponensek regisztrálása
    Vue.component("fp-template-list", TemplateList);
    Vue.component("fp-template-content", TemplateContent);
    Vue.component("fp-templates-manager", TemplatesManager);
  },
};
