import Form from "@/Views/Common/Form.vue";

/* Partner */
import Partner from "@/Views/Partner/RouterView.vue";
import WebAppPartnerHome from "@/Views/Partner/Home.vue";

/* Partner - Menu */
import WebAppPartnerProfile from "@/Views/Partner/Profile.vue";
import WebAppPartnerLegal from "@/Views/Partner/Legal.vue";
//import WebAppPartnerRegistrationParticipant from "@/Views/Partner/RegistrationParticipant";
import WebAppPartnerParticipantList from "@/Views/Partner/ParticipantList";
import WebAppPartnerTemplates from "@/Views/Partner/Templates";
import WebAppPartnerProjects from "@/Views/Partner/Project";
import WebAppPartnerTasks from "@/Views/Partner/Tasks";
import WebAppPartnerDocuments from "@/Views/Partner/Documents";
import WebAppPartnerQuestionnaires from "@/Views/Partner/Questionnaires";
import WebAppPartnerEvaluatingQuestionnaire from "@/Views/Partner/questionnaire-components/EvaluatingQuestionnaire";
import WebAppPartnerWorkflows from "@/Views/Partner/Workflows";

/* Partner - Footer menu */
import WebAppPartnerResearch from "@/Views/Partner/footer-menu/Research";
import WebAppPartnerLogs from "@/Views/Partner/footer-menu/Logs";

export default [
  {
    path: "/Partner",
    name: "Partner",
    component: Partner,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppPartnerHome",
        components: {
          partner: WebAppPartnerHome,
        },
      },
      {
        path: "Home",
        name: "WebAppPartnerHome",
        components: {
          partner: WebAppPartnerHome,
        },
      },
      /* {
        path: "RegistrationParticipant",
        name: "WebAppPartnerRegistrationParticipant",
        components: {
          partner: WebAppPartnerRegistrationParticipant,
        },
        props: {
          partner: true,
        },
      }, */
      {
        path: "ParticipantList",
        name: "WebAppPartnerParticipantList",
        components: {
          partner: WebAppPartnerParticipantList,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Projects",
        name: "WebAppPartnerProjects",
        components: {
          partner: WebAppPartnerProjects,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Documents",
        name: "WebAppPartnerDocuments",
        components: {
          partner: WebAppPartnerDocuments,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Tasks",
        name: "WebAppPartnerTasks",
        components: {
          partner: WebAppPartnerTasks,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Templates",
        name: "WebAppPartnerTemplates",
        components: {
          partner: WebAppPartnerTemplates,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Questionnaires",
        name: "WebAppPartnerQuestionnaires",
        components: {
          partner: WebAppPartnerQuestionnaires,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Form",
        name: "WebAppPartnerForm",
        components: {
          partner: Form,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "EvaluatingQuestionnaire",
        name: "WebAppPartnerEvaluatingQuestionnaire",
        components: {
          partner: WebAppPartnerEvaluatingQuestionnaire,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Research",
        name: "WebAppPartnerResearch",
        components: {
          partner: WebAppPartnerResearch,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Logs",
        name: "WebAppPartnerLogs",
        components: {
          partner: WebAppPartnerLogs,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Workflows",
        name: "WebAppPartnerWorkflows",
        components: {
          partner: WebAppPartnerWorkflows,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppPartnerProfile",
        components: {
          partner: WebAppPartnerProfile,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Legal",
        name: "WebAppPartnerLegal",
        components: {
          partner: WebAppPartnerLegal,
        },
        props: {
          partner: true,
        },
      },
    ],
    meta: ["Partner", "Administrator", "Technical"],
  },
];
