<template>
  <div class="eh-client-quest">
    <div>
          <div class="eh-flex-container">
            <fp-input
                class="eh-client-documents-filter"
                :label="$t('base.basic.filter')"
                @change="filterQuestionnaireList"
                v-model="filter"
            />
            <fp-select
                class="eh-client-documents-type-filter"
                :label="$t('client.questionnaires.status')"
                :items="Object.values($enums.FormStatus)"
                valueKey="Value"
                @change="filterQuestionnaireList"
                v-model="stateFilter"
            >
              <template #element="element">
                {{ $t(element.Text) }}
              </template>
            </fp-select>
          </div>
      <fp-table-list
        :hasPagination="true"
        :items="filteredQuestionnaireList"
        :fields="questionnaireListFields"
        sortBy="AssignedDate"
      >
        <template #Title="row">
          {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
        </template>
        <template #Description="row">
          {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
        </template>
        <template #ProgressPercentage="row">
          <b-progress :value="row.item.FillingRate" :max="100"></b-progress>
          <div style="text-align: center">
            {{ row.item.FillingRate ? row.item.FillingRate + "%" : "0%" }}
          </div>
        </template>
        <template #AssignedDate="row">
          <div style="white-space: nowrap">
            {{
              $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm")
            }}
          </div>
        </template>
        <template #FilledDate="row">
          <div style="white-space: nowrap">
            {{ $convertDateToString(row.item.FilledDate, "YYYY-MM-DD HH:mm") }}
          </div>
        </template>
        <template #Status="row">
          {{ $t(formStatuses[row.item.Status].Text) }}
        </template>
        <template #OpenQuestionnaire="row">
          <router-link
            :to="{
              name: 'WebAppClientQuestionnaire',
              query: { Id: row.item.FormInstanceId },
            }"
          >
            <b-button>
              {{
                [
                  formStatuses.Filled.Value,
                  formStatuses.Evaluated.Value,
                  formStatuses.UnderEvaluation.Value,
                ].includes(row.item.Status)
                  ? $t("base.basic.view")
                  : $t("base.basic.filling")
              }}
            </b-button>
          </router-link>
        </template>
      </fp-table-list>
    </div>
  </div>
</template>
<script>
import { FormLogic } from "@/Logic/Backend/form";

export default {
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.menu.questionnaires"),
      formStatuses: this.$enums.QuestionnaireStatus,
      questionnaireList: [],
      filteredQuestionnaireList: null,
      questionnaireListFields: [
        { key: "Title", label: this.$t("client.questionnaires.title") },
        {
          key: "Description",
          label: this.$t("client.questionnaires.description"),
        },
        {
          key: "ProgressPercentage",
          label: this.$t("client.questionnaires.percentage"),
        },
        {
          key: "AssignedDate",
          label: this.$t("client.questionnaires.startDateTime"),
          sortable: true,
        },
        {
          key: "FilledDate",
          label: this.$t("client.questionnaires.stopDateTime"),
          sortable: true,
        },
        { key: "Status", label: this.$t("client.questionnaires.status") },
        { key: "OpenQuestionnaire", label: "" },
      ],
      filter: null,
      stateFilter: null,
    };
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterQuestionnaireList() {
      this.filteredQuestionnaireList = this.questionnaireList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterList(
          this.filter,
          this.questionnaireList,
          ["Title", "Description"]
        );
      }
      if (this.stateFilter) {
        this.filteredQuestionnaireList = this.filteredQuestionnaireList.filter(
          (quest) => quest.Status == this.stateFilter
        );
      }
    },
    //kérdőívek lista kérés
    async getList() {
      //kérés indítás
      const questionnaireListResponse = await FormLogic.getFormInstanceHeader({
        FormCategory: "Questionnaire",
        SubjectId: this.$loggedUser.UserId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kérdőívek lista frissítés
        this.questionnaireList = this.filteredQuestionnaireList =
          questionnaireListResponse;
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("client.questionnaires.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal címének beállítása
    this.$store.dispatch("setPageTitle", this.siteTitle);
    //kérdőívek listájának betöltése
    await this.getList();
  },
};
</script>
