<template>
  <div>
    <div style="text-align: center; padding-top: 10px">
      <b-button-group style="margin: auto">
        <b-button
          :variant="isAcceptedShown ? 'info' : 'secondary'"
          @click="ShowAcceptedLegals"
          >Elfogadott nyilatkozatok</b-button
        >
        <b-button
          :variant="isAcceptableShown ? 'info' : 'secondary'"
          @click="ShowAcceptableLegals"
          >Elfogadásra váró nyilatkozatok</b-button
        >
      </b-button-group>
    </div>
    <accepted-legal-list
      :TargetId="TargetId"
      :OwnerId="OwnerId"
      :Visible="isAcceptedShown"
    />
    <acceptable-legal-list
      :TargetId="TargetId"
      :OwnerId="OwnerId"
      :Visible="isAcceptableShown"
    />
  </div>
</template>
<script>
import AcceptableLegalList from "./AcceptableLegalList.vue";
import AcceptedLegalList from "./AcceptedLegalList.vue";
import LegalView from "./LegalView.vue";
export default {
  components: { LegalView, AcceptedLegalList, AcceptableLegalList },
  // TODO: projekt szűrés?
  name: "LegalManager",
  props: {
    TargetId: Number,
    OwnerId: Number,
  },
  data() {
    return {
      isAcceptedShown: true,
      isAcceptableShown: false,
    };
  },
  methods: {
    ShowAcceptedLegals() {
      this.isAcceptableShown = false;
      this.isAcceptedShown = true;
    },
    ShowAcceptableLegals() {
      this.isAcceptableShown = true;
      this.isAcceptedShown = false;
    },
  },
};
</script>