<template>
  <div class="gyik page-background">
    <div>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        <b>
          K: Mit kell tennem, hogy használjam a
          <span style="color: rgb(237, 125, 49);">Fókusz Programot</span>?
        </b>
      </p>
      <p
        class="MsoBodyText"
        style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);"
      >
        V: Csak regisztráció szükséges és utána elérhető a
        <span style="color: rgb(237, 125, 49);">Fókusz Program </span>publikus felülete.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.gyik {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>