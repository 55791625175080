<template>
  <div>
    <div class="eh-static-home">
      <div class="eh-static-home-slide-wrapper">
        <div class="eh-static-home-slide" />
      </div>
      <div class="eh-message-box">
        <p class="eh-greetings">
          <b>Üdvözlünk a <em>Fókusz Program </em>világában!</b>
        </p>
        <p>
          Ha
          <b>egészséges</b> vagy akkor, a <em>Fókusz Program </em>segítségével
          menedzselheted egészséged, kialakult betegséged. Mérheted vitális
          paramétereid, egészségterveket állíthatsz magadnak, célokat tűzhetsz
          ki és követeheted azok teljesülését.
        </p>
        <p>
          Ha valamilyen
          <b>betegségben</b> szenvedsz, akkor a
          <em>Fókusz Program </em>segítségével tarthatod a kapcsolatot az
          orvosaiddal, hozzáférhetsz az egészségügyi intézményben keletkezett
          leleteidhez, orvosi dokumenumaidhoz. A gondozási programok
          segítségével végigviszünk a gyógyuláshoz vezető úton. Gondozóink a
          virtuális térben és telefonhívásokkal akár napi segítséget adnak a
          terápiád menedzselésében. Több mint 60 mérőeszközzel végezhetsz
          méréseket, melyek eredményét egészségnaplóidban orovosaid is követik.
          Gyógyszereidet rögzítheted és annak bevétele előtt értesítéseket
          kapsz.
        </p>
        <p>
          Mindezt itt a
          <em>Fókusz Program </em>felületén online módon!
        </p>
        <p>
          Töltsd le a <em>Fókusz Program </em>applikációt, hogy a „kezedben
          legyen” az egészséged!
        </p>
        <div class="eh-mobile">
          <a
            href="https://play.google.com/store/apps/details?id=com.companyname.fp_application"
            target="_blank"
          >
            <img
              class="eh-home-store-img"
              src="@/Assets/ImgIcons/Logo/AppStore/google-play.png"
            />
          </a>
          <a
            href="https://apps.apple.com/hu/app/f%C3%B3kusz-program/id1532706689?l=hu"
            target="_blank"
          >
            <img
              class="eh-home-store-img"
              src="@/Assets/ImgIcons/Logo/AppStore/app-store.png"
            />
          </a>
        </div>
        <p>Fókuszban az egészség!</p>
      </div>
      <div class="eh-care-health-box">
        <table class="eh-programs-box">
          <tr>
            <td class="eh-programs-title">
              <label>GONDOZÁSI PROGRAMOK</label>
            </td>
          </tr>
          <router-link to="/Cardiology">
            <td class="eh-program-box">
              <div>
                <img
                  class="eh-programs-img"
                  src="@/Assets/OldAssets/Modules/Inactive/icon-hearth-inactive.png"
                />
              </div>
              <label class="eh-static-program-title">Kardiológia</label>
            </td>
          </router-link>
          <router-link to="/Diabetology">
            <td class="eh-program-box">
              <div>
                <img
                  class="eh-programs-img"
                  src="@/Assets/OldAssets/Modules/Inactive/icon-diabetology-inactive.png"
                />
              </div>
              <label class="eh-static-program-title">Diabétesz</label>
            </td>
          </router-link>
          <router-link to="/Depressio">
            <td class="eh-program-box">
              <div>
                <img
                  class="eh-programs-img"
                  src="@/Assets/OldAssets/Modules/Inactive/icon-depi-inactive.png"
                />
              </div>
              <label class="eh-static-program-title">Depresszió</label>
            </td>
          </router-link>
          <router-link to="/Oncology">
            <td class="eh-program-box">
              <div>
                <img
                  class="eh-programs-img"
                  src="@/Assets/OldAssets/Modules/Inactive/icon-oncology-inactive.png"
                />
              </div>
              <label class="eh-static-program-title">Onkológia</label>
            </td>
          </router-link>
        </table>

        <img src="@/Assets/OldAssets/Background/separate-line.png" width="100%" />
        <table class="eh-programs-box">
          <tr>
            <td class="eh-programs-title">
              <label>EGÉSZSÉGPROGRAMOK</label>
            </td>
          </tr>
          <tr>
            <router-link class="eh-program-box" to="/ThrowTheCigarette">
              <td>
                <div>
                  <img
                    class="eh-programs-img"
                    src="@/Assets/OldAssets/Modules/Inactive/icon-disease-inactive.png"
                  />
                </div>
                <label class="eh-static-program-title">Dobd el a cigit!</label>
              </td>
            </router-link>
            <router-link class="eh-program-box" to="/CalorieConsumption">
              <td>
                <div>
                  <img
                    class="eh-programs-img"
                    src="@/Assets/OldAssets/Modules/Inactive/icon-body-inactive.png"
                  />
                </div>
                <label class="eh-static-program-title">Kalóriaégetés</label>
              </td>
            </router-link>
            <router-link class="eh-program-box" to="/EatHealthy">
              <td>
                <div>
                  <img
                    class="eh-programs-img"
                    src="@/Assets/OldAssets/Modules/Inactive/icon-healthplan-inactive.png"
                  />
                </div>
                <label class="eh-static-program-title"
                  >Táplálkozz egészségesen</label
                >
              </td>
            </router-link>
            <router-link class="eh-program-box" to="/ImproveYourMood">
              <td>
                <div>
                  <img
                    class="eh-programs-img"
                    src="@/Assets/OldAssets/Modules/Inactive/icon-mood-inactive.png"
                  />
                </div>
                <label class="eh-static-program-title"
                  >Javíts hangulatodon!</label
                >
              </td>
            </router-link>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    document.title = "Fókusz Program ";
  },
};
</script>
