<template>
  <div>
    <div style="text-align: right">
      <!-- <span class="icon icon-check-all icon-padding"></span>
      <span class="icon icon-uncheck-all icon-padding"></span>
      <span class="icon icon-microphone"></span> -->
     <!--  <img
        v-if="!disableAllSelectable"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet5.png"
        @click="selectAll"
        v-b-tooltip.hover="$t('components.table.selectAll')"
      /> -->
      <fp-svg-icon
        v-if="!disableAllSelectable"
        class="document-table-buttons"
        icon="check-all"
        :size="30"
        v-b-tooltip.hover="$t('components.table.selectAll')"
        @click="selectAll"
      />
      <!-- <img
        v-if="!disableAllUnselectable"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet6.png"
        @click="clearSelected"
        v-b-tooltip.hover
        :title="$t('components.table.unselectAll')"
      /> -->
      <fp-svg-icon
        v-if="!disableAllUnselectable"
        class="document-table-buttons"
        icon="uncheck-all"
        :size="30"
        v-b-tooltip.hover="$t('components.table.unselectAll')"
        @click="clearSelected"
      />
      <input style="display: none" multiple type="file" id="upload-file" />
      <!-- <img
        v-if="!disableUpload && isUploaded"
        @click="startUpload"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet7.png"
        v-b-tooltip.hover
        :title="$t('base.basic.upload')"
      /> -->
      <fp-svg-icon
        v-if="!disableUpload && isUploaded"
        class="document-table-buttons"
        icon="upload"
        :size="30"
        v-b-tooltip.hover="$t('base.basic.upload')"
        @click="startUpload"
      />
      <b-spinner v-if="!disableUpload && !isUploaded" />
      <!-- <img
        v-if="!disableEESZT && EESZTuploaded"
        @click="eesztSync"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet10.png"
        v-b-tooltip.hover
        :title="$t('components.table.eesztSync')"
      /> -->
      <fp-svg-icon
        v-if="!disableEESZT && EESZTuploaded"
        class="document-table-buttons"
        icon="sync"
        :size="30"
        v-b-tooltip.hover="$t('components.table.eesztSync')"
        @click="eesztSync"
      />
      <b-spinner v-if="!disableEESZT && !EESZTuploaded" />
      <!-- <img
        v-if="!disableShare"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet2.png"
        v-b-tooltip.hover
        :title="$t('base.basic.share')"
      /> -->
      <fp-svg-icon
        v-if="!disableShare"
        class="document-table-buttons"
        icon="share"
        :size="30"
        v-b-tooltip.hover="$t('base.basic.share')"
      />
      <!-- <img
        v-if="!disableDictateText"
        class="document-table-buttons"
        src="@/Assets/settings-button/microphone.png"
        v-b-tooltip.hover
        :title="$t('base.basic.speach')"
        v-b-modal.dictate-text-input-modal
      /> -->
      <fp-svg-icon
        v-if="!disableDictateText"
        class="document-table-buttons"
        icon="microphone"
        :size="30"
        v-b-tooltip.hover="$t('base.basic.speach')"
      />
     <!--  <img
        v-if="!disableDownload"
        class="document-table-buttons"
        src="@/Assets/settings-button/muvelet3.png"
        v-b-tooltip.hover
        :title="$t('base.basic.download')"
        @click="downloadDocuments"
      /> -->
      <fp-svg-icon
        v-if="!disableDownload"
        class="document-table-buttons"
        icon="download"
        :size="30"
        @click="downloadDocuments"
        v-b-tooltip.hover="$t('base.basic.download')"
      />
      <fp-svg-icon
        v-if="!disableDelete"
        class="document-table-buttons"
        icon="trash"
        :size="30"
        @click="deletedDocuments"
        v-b-tooltip.hover="$t('base.basic.delete')"
      />
      <!-- TODO: documentum műveleteket kiemelni, hogy base komponens lehessen -->
      <fp-dictate-text-input @onClose="onClose" />
    </div>
    <!-- TODO: validation -->
    <fp-form-modal
      :title="$t('components.table.uploadDoc')"
      v-model="onUploadDocument"
    >
      <template #content>
        <fp-input
          :label="$t('components.table.selectFile')"
          @change="selectDocument"
          :multiple="true"
          type="file"
        />
        <fp-select
          :label="$t('components.table.fileType')"
          :required="true"
          v-model="fileType"
          valueKey="Value"
          :items="Object.values(fileCategories)"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <button class="eh-action-button" @click="chooseFile">
          {{ $t("base.basic.upload") }}
        </button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
export default {
  props: {
    disableDelete: {
      type: Boolean,
      default: false,
    },
    disableDownload: {
      type: Boolean,
      default: false,
    },
    disableDictateText: {
      type: Boolean,
      default: false,
    },
    disableShare: {
      type: Boolean,
      default: false,
    },
    EESZTuploaded: {
      type: Boolean,
      default: true,
    },
    disableEESZT: {
      type: Boolean,
      default: false,
    },
    isUploaded: {
      type: Boolean,
      default: true,
    },
    disableUpload: {
      type: Boolean,
      default: false,
    },
    disableAllUnselectable: {
      type: Boolean,
      default: false,
    },
    disableAllSelectable: {
      type: Boolean,
      default: false,
    },
    uploadByCategory: Boolean,
  },
  data() {
    return {
      onUploadDocument: false,
      fileList: null,
      fileType: null,
      fileCategories: this.$enums.OtherDocumentCategory,
    };
  },
  methods: {
    startUpload() {
      if (this.uploadByCategory) {
        this.onUploadDocument = true;
      } else {
        const inputElement = document.getElementById("upload-file");
        inputElement.click();
        inputElement.addEventListener("input", this.handleFiles, false);
      }
    },
    async handleFiles(args) {
      const fileList = args.target.files;
      if (fileList) {
        this.$emit("onUploadedDocumentList", fileList);
      }
    },
    selectAll() {
      this.$emit("selectAll");
    },
    clearSelected() {
      this.$emit("clearSelected");
    },
    selectDocument(files) {
      this.fileList = files.target.files;
    },
    async chooseFile() {
      if (this.fileList && this.fileType) {
        this.$emit("onUploadedDocumentList", this.fileList, this.fileType);
        this.onUploadDocument = false;
      }
    },
    eesztSync() {
      this.$emit("eesztSync");
    },
    downloadDocuments() {
      this.$emit("downloadDocuments");
    },
    deletedDocuments() {
      this.$emit("deletedDocuments");
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
<style scoped>
.document-table-buttons {
  cursor: pointer;
  /* margin: 5px;
  width: 40px; */
}
</style>
