var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fp-form-modal',{attrs:{"title":_vm.isNew
      ? _vm.$t('partner.newProject.roles.addRole')
      : _vm.$t('partner.newProject.roles.table.fields.permissions')},on:{"hidden":_vm.clearNewRole},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('fp-input',{attrs:{"label":_vm.$t('partner.newProject.roles.table.fields.name'),"disabled":!_vm.isNew && !_vm.permissionEditable},model:{value:(_vm.newRole.RoleName),callback:function ($$v) {_vm.$set(_vm.newRole, "RoleName", $$v)},expression:"newRole.RoleName"}}),_c('b-form-group',{attrs:{"label":_vm.$t('partner.newProject.roles.table.fields.baseRoles'),"disabled":!_vm.isNew && !_vm.permissionEditable}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-baseRoles","options":_vm.baseRoleOptions,"name":"baseRoles"},model:{value:(_vm.newRole.BaseRoles),callback:function ($$v) {_vm.$set(_vm.newRole, "BaseRoles", $$v)},expression:"newRole.BaseRoles"}})],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("partner.newProject.roles.permissions"))+" ")]),_c('fp-select',{attrs:{"label":"Olvasási jogosultságok","type":"multi","disabled":!_vm.isNew && !_vm.permissionEditable,"items":_vm.seeOptions,"valueKey":"Value","textKeyConverter":function (e) {
          return _vm.$t(e.Text);
        }},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_vm._v(" "+_vm._s(_vm.$t(e.Text))+" ")]}}]),model:{value:(_vm.structuredPermissions.See),callback:function ($$v) {_vm.$set(_vm.structuredPermissions, "See", $$v)},expression:"structuredPermissions.See"}}),(_vm.isNew || _vm.permissionEditable)?_c('div',{staticClass:"select-all-permission",on:{"click":_vm.selectAllSeePermission}},[_vm._v(" Összes olvasási jogosultság kijelölése ")]):_vm._e(),_c('fp-select',{attrs:{"label":"Hozzáadási jogosultságok","type":"multi","disabled":!_vm.isNew && !_vm.permissionEditable,"items":_vm.addOptions,"valueKey":"Value","textKeyConverter":function (e) {
          return _vm.$t(e.Text);
        }},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_vm._v(" "+_vm._s(_vm.$t(e.Text))+" ")]}}]),model:{value:(_vm.structuredPermissions.Add),callback:function ($$v) {_vm.$set(_vm.structuredPermissions, "Add", $$v)},expression:"structuredPermissions.Add"}}),(_vm.isNew || _vm.permissionEditable)?_c('div',{staticClass:"select-all-permission",on:{"click":_vm.selectAllAddPermission}},[_vm._v(" Összes hozzáadási jogosultság kijelölése ")]):_vm._e(),_c('fp-select',{attrs:{"label":"Szerkesztési jogosultságok","type":"multi","disabled":!_vm.isNew && !_vm.permissionEditable,"items":_vm.editOptions,"valueKey":"Value","textKeyConverter":function (e) {
          return _vm.$t(e.Text);
        }},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_vm._v(" "+_vm._s(_vm.$t(e.Text))+" ")]}}]),model:{value:(_vm.structuredPermissions.Edit),callback:function ($$v) {_vm.$set(_vm.structuredPermissions, "Edit", $$v)},expression:"structuredPermissions.Edit"}}),(_vm.isNew || _vm.permissionEditable)?_c('div',{staticClass:"select-all-permission",on:{"click":_vm.selectAllEditPermission}},[_vm._v(" Összes szerkesztési jogosultság kijelölése ")]):_vm._e(),_c('fp-select',{attrs:{"label":"Törlési jogosultságok","type":"multi","disabled":!_vm.isNew && !_vm.permissionEditable,"items":_vm.removeOptions,"valueKey":"Value","textKeyConverter":function (e) {
          return _vm.$t(e.Text);
        }},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_vm._v(" "+_vm._s(_vm.$t(e.Text))+" ")]}}]),model:{value:(_vm.structuredPermissions.Remove),callback:function ($$v) {_vm.$set(_vm.structuredPermissions, "Remove", $$v)},expression:"structuredPermissions.Remove"}}),(_vm.isNew || _vm.permissionEditable)?_c('div',{staticClass:"select-all-permission",on:{"click":_vm.selectAllRemovePermission}},[_vm._v(" Összes törlési jogosultság kijelölése ")]):_vm._e(),(_vm.isNew)?_c('b-button',{staticStyle:{"margin-top":"10px"},on:{"click":function($event){return _vm.addRole()}}},[_vm._v(_vm._s(_vm.$t("base.basic.add"))+" ")]):_vm._e(),(!_vm.isNew && !_vm.permissionEditable && !_vm.roleReadonly)?_c('b-button',{on:{"click":function($event){_vm.permissionEditable = true}}},[_vm._v("Szerkesztés")]):_vm._e(),(_vm.permissionEditable)?_c('b-button',{on:{"click":_vm.saveEdit}},[_vm._v("Mentés")]):_vm._e(),(_vm.permissionEditable)?_c('b-button',{on:{"click":_vm.cancelEdit}},[_vm._v("Mégsem")]):_vm._e()]},proxy:true}]),model:{value:(_vm.showNewRole),callback:function ($$v) {_vm.showNewRole=$$v},expression:"showNewRole"}})}
var staticRenderFns = []

export { render, staticRenderFns }