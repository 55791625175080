<template>
  <div>
    <div
      :key="'healthProfessionalProfile-' + forceUpdateKey"
      v-if="personalProfile && professionalProfile"
      style="margin-bottom: 10px"
    >
      <b-row style="margin: 0px" cols="1" align-h="center">
        <!-- Profilkép kezelése -->
        <div v-if="tempClipperImg" style="text-align: center">
          <input style="display: none" type="file" id="profile-picture" />
          <img
            :class="disabled ? 'disbaled-img-profile' : 'img-profile'"
            style="width: 200px; height: 200px; border-radius: 50%"
            id="profile-img"
            :src="tempClipperImg"
            @click="disabled ? '' : choosePhoto()"
          />
          <b-modal
            v-model="showClipperModal"
            ref="clipper-modal"
            hide-header
            hide-footer
          >
            <clipper-fixed
              id="profile-clipper-img"
              :round="true"
              ref="clipper"
              :area="90"
              :src="tempClipperImg"
            ></clipper-fixed>
            <b-row style="margin: 0px" align-h="between">
              <b-button style="margin-top: 10px" @click="closeDialog">{{
                $t("base.basic.cancel")
              }}</b-button>
              <b-button
                variant="warning"
                style="margin-top: 10px"
                @click="getUserPictureResult"
                >{{ $t("base.basic.select") }}</b-button
              >
            </b-row>
          </b-modal>
        </div>
        <!-- Kommunikációs csatorna -->
        <b-row style="margin-top: 20px" align-h="center" align-v="center">
          <label style="margin: 10px 10px"
            >{{ $t("client.profile.personalData.communication") }}:</label
          >
          <b-row align-h="center">
            <!-- Még hiányzik -->
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Email"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.email") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Push"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.push") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Sms"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.sms") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Chat"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.chat") }}</b-form-checkbox
            >
          </b-row>
        </b-row>
        <b-row cols="1" cols-lg="2">
          <!-- Felhasználónév -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="true"
              :label="$t('client.profile.personalData.username')"
              :value="username"
            />
          </div>
          <!-- Név -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('personalProfile.Name')"
              :error="validation.firstError('personalProfile.Name')"
              :disabled="disabled"
              :label="$t('client.profile.personalData.name')"
              v-model="personalProfile.Name"
            />
          </div>
          <!-- Pecsétszám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('healthProfessional.profile.registrationNumber')"
              v-model="professionalProfile.RegistrationNumber"
            />
          </div>
          <!-- Email -->
          <div class="client-profile-inputs">
            <fp-input
              :state="
                !validation.hasError('personalProfile.PersonalContact.Email')
              "
              :error="
                validation.firstError('personalProfile.PersonalContact.Email')
              "
              :disabled="disabled"
              type="email"
              :label="$t('client.profile.personalData.emailAddress')"
              v-model="personalProfile.PersonalContact.Email"
            />
          </div>
          <!-- Telefonszám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.phone')"
              v-model="personalProfile.PersonalContact.Phone"
            />
          </div>
          <!-- Intézmény név -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('healthProfessional.profile.institutionName')"
              v-model="professionalProfile.InstitutionName"
            />
          </div>
          <!-- Intézmény cím -->
          <div class="client-profile-inputs" style="margin-top: 10px">
            <profile-address
              :disabled="disabled"
              v-model="professionalProfile.Address"
            />
          </div>
          <div>
            <!-- Osztály -->
            <div class="client-profile-inputs">
              <fp-input
                :disabled="disabled"
                :label="$t('healthProfessional.profile.wardName')"
                v-model="professionalProfile.WardName"
              />
            </div>
            <!-- ÁNTSZ -->
            <div class="client-profile-inputs">
              <fp-input
                :disabled="disabled"
                :label="$t('healthProfessional.profile.antszCode')"
                v-model="professionalProfile.AntszCode"
              />
            </div>
            <!-- Beosztás -->
            <div class="client-profile-inputs">
              <fp-input
                :disabled="disabled"
                :label="$t('healthProfessional.profile.position')"
                v-model="professionalProfile.Position"
              />
            </div>
          </div>
        </b-row>
      </b-row>
      <div v-if="$activeModules.includes('Prescription')">
        <!-- TODO: participant list tab megjelenjen? egyben vagy külön definiálás? -->
        <eeszt-profile v-model="eesztProfile" :disabled="disabled" />
      </div>
    </div>
    <div v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
const EesztProfile = () => import("./Components/EesztProfile.vue");
import ProfileAddress from "./Components/ProfileAddress.vue";
export default {
  components: { ProfileAddress, EesztProfile },
  name: "HealthProfessionalProfile",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    userDataChanged: Boolean,
    disabled: Boolean,
    clipperImg: String,
    username: String,
  },
  data() {
    return {
      personalProfile: null,
      professionalProfile: null,
      eesztProfile: null,
      defaultPersonal: {
        NotificationSettings: {
          Email: false,
          Push: false,
          Sms: false,
          Chat: false,
        },
        Name: null,
        PersonalContact: {
          Phone: null,
          Email: null,
        },
      },
      defaultProfessional: {
        RegistrationNumber: null,
        Position: null,
        WardId: null,
        InstitutionName: null,
        Address: null,
        WardName: null,
        AntszCode: null,
      },
      tempClipperImg: this.clipperImg,
      showClipperModal: false,
      allowUpdate: false,
      forceUpdateKey: 0,
    };
  },
  validators: {
    //TODO: adat modelben nullára kell állítani a validálandó mezőket (különben hiba lesz)
    "personalProfile.Name": function (value) {
      return this.$validator
        .value(value)
        .minLength(
          5,
          "A 'Név' mezőbe legalább 15 karakter hosszú szöveget kell megadni"
        );
    },
    "personalProfile.PersonalContact.Email": function (value) {
      return this.$validator
        .value(value)
        .email("Az 'Email cím' mező nem valid email címet tartalmaz");
    },
  },
  watch: {
    clipperImg(input) {
      this.tempClipperImg = input;
    },
    personalProfile(input) {
      if (this.allowUpdate) {
        this.$emit("change", {
          PersonalProfile: input,
          HealthProfessionalProfile: this.professionalProfile,
          EesztProfile: this.eesztProfile,
        });
      }
    },
    professionalProfile(input) {
      if (this.allowUpdate) {
        this.$emit("change", {
          PersonalProfile: this.personalProfile,
          HealthProfessionalProfile: input,
          EesztProfile: this.eesztProfile,
        });
      }
    },
    eesztProfile(input) {
      if (this.allowUpdate) {
        this.$emit("change", {
          PersonalProfile: this.personalProfile,
          HealthProfessionalProfile: this.professionalProfile,
          EesztProfile: input,
        });
      }
    },
    userDataChanged(input) {
      if (input) {
        this.setProfileData();
      }
    },
  },
  methods: {
    setProfileData() {
      this.allowUpdate = false;
      var tempProfessional = JSON.parse(
        JSON.stringify(this.defaultProfessional)
      );

      if (this.value && this.value.HealthProfessionalProfile) {
        Object.assign(
          tempProfessional,
          JSON.parse(JSON.stringify(this.value.HealthProfessionalProfile))
        );
      }
      this.professionalProfile = tempProfessional;

      var tempPersonal = JSON.parse(JSON.stringify(this.defaultPersonal));
      if (this.value && this.value.PersonalProfile) {
        Object.assign(
          tempPersonal,
          JSON.parse(JSON.stringify(this.value.PersonalProfile))
        );
      }

      this.personalProfile = tempPersonal;

      var tempEeszt = null;
      if (this.value && this.value.EesztProfile) {
        tempEeszt = JSON.parse(JSON.stringify(this.value.EesztProfile));
      }
      this.eesztProfile = tempEeszt;
      
      this.forceUpdateKey++;
      this.allowUpdate = true;
      this.$emit("update:userDataChanged", false);
    },
    choosePhoto() {
      this.showClipperModal = true;
      const inputElement = document.getElementById("profile-picture");
      inputElement.click();
      inputElement.addEventListener("change", this.handleFiles, false);
    },
    handleFiles(args) {
      const fileList = args.target.files;
      if (fileList) {
        this.tempClipperImg = window.URL.createObjectURL(fileList[0]);
      }
    },
    async getUserPictureResult() {
      this.profileImgCanvas = this.$refs.clipper.clip();
      const img = document.getElementById("profile-img");
      img.src = this.profileImgCanvas.toDataURL("image/jpeg", 1);
      this.$refs["clipper-modal"].hide();
      this.$emit("setProfilePicture", this.profileImgCanvas);
    },
    closeDialog() {
      const imgComp = document.getElementById("client-profil-icon");
      if (imgComp) {
        this.tempClipperImg = imgComp.src;
      }
      this.$refs["clipper-modal"].hide();
    },
  },
  mounted() {
    this.setProfileData();
  },
};
</script>
<style scoped>
.client-profile-inputs {
  padding: 0px 10px;
}
.disbaled-img-profile {
  cursor: default;
}
.img-profile {
  cursor: pointer;
}
</style>
