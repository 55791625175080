<template>
  <div>
    <fp-table-list
      style="margin: 20px 0px"
      :items="projects"
      :fields="projectFields"
    >
      <template #Name="row">{{
        row.item.Research ? getTextByLanguage(row.item.Research.Name) : null
      }}</template>
      <template #Title="row">{{
        row.item.Research
          ? getTextByLanguage(row.item.Research.Description)
          : null
      }}</template>
      <template #ProgressDateRange="row">
        <div v-if="row.item.Research" style="white-space: nowrap">
          {{
            $convertDateToString(row.item.Research.StartDate, "YYYY.MM.DD.") +
              " - " +
              $convertDateToString(row.item.Research.EndDate, "YYYY.MM.DD.")
          }}
        </div></template
      >
      <template #State="row">{{
        $t($enums.ProjectStatus[row.item.State].Text)
      }}</template>
      <template #Operations="row">
        <b-iconstack
          v-if="row.item.Research"
          class="h3"
          @click="$set(row.item, '_showDetails', !row.item._showDetails)"
          style="cursor: pointer"
          v-b-tooltip.hover="
            row.item._showDetails
              ? $t('client.programs.close')
              : $t('client.programs.details')
          "
        >
          <b-icon
            stacked
            :icon="row.item._showDetails ? 'zoom-out' : 'zoom-in'"
            scale="0.5"
          ></b-icon>
          <b-icon stacked icon="file"></b-icon>
        </b-iconstack>
      </template>
      <template #row-details="row">
        <fp-panel-card
          style="background: white"
          :title="getTextByLanguage(row.item.Research.Name)"
          :description="getTextByLanguage(row.item.Research.Description)"
        >
          <template #content>
            <data-sheet :research="row.item.Research" :state="row.item.State" />
          </template>
        </fp-panel-card>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import DataSheet from "@/Views/Client/project-components/DataSheet.vue";
export default {
  components: { DataSheet },
  data() {
    return {
      projects: null,
      projectFields: [
        { key: "Name", label: this.$t("client.programs.projectName") },
        { key: "Title", label: this.$t("client.programs.projectTitle") },
        { key: "ProgressDateRange", label: this.$t("client.programs.period") },
        { key: "State", label: this.$t("client.programs.state") },
        { key: "Operations", label: "" },
      ],
    };
  },
  methods: {
    getTextByLanguage(textObject) {
      if (textObject) {
        const languages = Object.keys(textObject);
        if (languages.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (languages.includes("en")) {
          return textObject.en;
        } else {
          return textObject[languages[0]];
        }
      } else {
        return null;
      }
    },
    async getCareProjects() {
      const getCareProjectsResponse = await ProjectLogic.getProjectDetails({
        Type: "CareProgram",
      });
      if (!getCareProjectsResponse.Code) {
        this.projects = getCareProjectsResponse;
      } else {
        this.$bvToast.toast(getCareProjectsResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("client.programs.errorCareName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getCareProjects();
  },
};
</script>
<style>
.care-programs {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
.care-programs-title {
  color: #668293;
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 20px;
}
.care-program-title {
  cursor: pointer;
  color: #668293;
}
.program-box:hover .care-program-title {
  font-weight: 900;
}
</style>
