<template>
  <div id="write-us-page" class="registration-login-background">
    <div class="page-background-shape page-width">
      <div style="padding-top: 20px">
        <p
          class="MsoBodyText"
          style="
            color: var(--eh-white);
            padding: 6pt 0cm 0cm;
            text-align: justify;
            font-size: 12pt;
            font-family: Calibri, sans-serif;
          "
        >
          Írd meg nekünk kérdésed, véleményed, problémád, javaslatod! Igyekszünk
          gyorsan válaszolni.
        </p>
      </div>
      <b-row align-h="center">
        <!-- <div
          style="width: 300px"
          class="writeus-input e-input-group e-float-input e-outline"
        >
          <input type="text" required />
          <span class="e-float-line"></span>
          <label class="e-float-text">Név</label>
        </div> -->
        <div style="min-width: 300px; padding: 10px">
          <fp-input
            v-model="newEntry.Name"
            textStyle="light"
            label="Név"
          />
        </div>
        <!-- <div
          style="width: 300px"
          class="writeus-input e-input-group e-float-input e-outline"
        >
          <input type="text" required />
          <span class="e-float-line"></span>
          <label class="e-float-text">Email cím</label>
        </div> -->
        <div style="min-width: 300px; padding: 10px">
          <fp-input
            textStyle="light"
            v-model="newEntry.Mail"
            label="Email cím"
            type="email"
          />
        </div>
        <div style="min-width: 300px; padding: 10px">
          <!-- <ejs-dropdownlist
            :dataSource="topics"
            id="dropdownlist"
            cssClass="e-outline"
            placeholder="Témakör"
          ></ejs-dropdownlist> -->
          <fp-select
            textStyle="light"
            v-model="newEntry.Subject"
            label="Témakör"
            :items="topics"
          />
        </div>
      </b-row>
      <div>
        <ejs-richtexteditor
          ref="defaultRTE"
          :height="400"
          v-model="newEntry.Richtext"
        ></ejs-richtexteditor>
      </div>
      <div style="width: 300px; margin: 25px auto 0px">
        <b-button
          @click="sendMessage"
          style="width: 300px"
          class="main-button"
          id="writeus-send-button"
          >Küldés</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
} from "@syncfusion/ej2-vue-richtexteditor";
export default {
  components: {},
  data() {
    return {
      newEntry: {
        Name: null,
        Mail: null,
        Subject: null,
        Richtext: null,
      },
      topics: [
        "Általános",
        "Mobil alkalmazás",
        "Webes program",
        "Ötlet, javaslat",
      ],
    };
  },
  methods: {
    sendMessage() {
      console.log(this.newEntry);
    },
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar],
  },
};
</script>
<style>
/*@import "../../../../Style/syncfusion-input.css";*/
main-button.e-control.e-btn.e-lib {
  width: 300px;
}
#write-us-page .page-width {
  width: 95%;
  margin: auto;
}
/* .writeus-input {
  margin: 25px 50px !important;
} */
.e-richtexteditor.e-rte-tb-expand .e-rte-content {
  border-radius: 0px 0px 10px 10px;
}
</style>