import BloodGlucoseDiaryInput from './BloodGlucoseDiaryInput.vue'
import BloodPressureDiaryInput from './BloodPressureDiaryInput.vue'
import BodyWeightDiaryInput from './BodyWeightDiaryInput.vue'
import CreateHealthGoalFormModal from './CreateHealthGoalFormModal.vue'
import ExerciseDiaryInput from './ExerciseDiaryInput.vue'
import InsulinDiaryInput from './InsulinDiaryInput.vue'
import MedicineDiaryInput from './MedicineDiaryInput.vue'
import MedicinePlanInput from './MedicinePlanInput.vue'
import MoodDiaryInput from './MoodDiaryInput.vue'
import NutritionDiaryInput from './NutritionDiaryInput.vue'
import OxygenSaturationDiaryInput from './OxygenSaturationDiaryInput.vue'
import PulseDiaryInput from './PulseDiaryInput.vue'
import SleepDiaryInput from './SleepDiaryInput.vue'

export default {
    install(Vue) {
        Vue.component('fp-bloodglucose-diary-input', BloodGlucoseDiaryInput);
        Vue.component('fp-bloodpressure-diary-input', BloodPressureDiaryInput);
        Vue.component('fp-bodyweight-diary-input', BodyWeightDiaryInput);
        Vue.component('fp-healthgoal-diary-input', CreateHealthGoalFormModal);
        Vue.component('fp-exercise-diary-input', ExerciseDiaryInput);
        Vue.component('fp-insulin-diary-input', InsulinDiaryInput);
        Vue.component('fp-medicine-diary-input', MedicineDiaryInput);
        Vue.component('fp-medicineplan-diary-input', MedicinePlanInput);
        Vue.component('fp-mood-diary-input', MoodDiaryInput);
        Vue.component('fp-nutrition-diary-input', NutritionDiaryInput);
        Vue.component('fp-oxygensaturation-diary-input', OxygenSaturationDiaryInput);
        Vue.component('fp-pulse-diary-input', PulseDiaryInput);
        Vue.component('fp-sleep-diary-input', SleepDiaryInput);
    },
};
