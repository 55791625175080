import moment from "moment";

export default {
  install(Vue) {
    Vue.prototype.$getChangedObjectElement = getChangedObjectElement;
    Vue.prototype.$convertDateToString = convertDateToString;
    Vue.prototype.$filterList = filterList;
    Vue.prototype.$groupByMostEfficient = groupByMostEfficient;
    Vue.prototype.$setChangedObjectElement = setChangedObjectElement;
    Vue.prototype.$deepObjectCompare = deepObjectCompare;
    Vue.prototype.$getLanguagedText = getLanguagedText;
    Vue.prototype.$checkDuplicates = checkDuplicates;
  },
};

//path alapján visszaadja az adott értéket
//kezeli ha a pathban az egyik szint nem létezik, akkor null-t add vissza
const getChangedObjectElement = (obj, path) => {
  if (!obj) {
    obj = {};
  }
  var changeObject = obj;
  /* convert indexes to properties */
  path = path.replace(/\[(\w+)\]/g, ".$1");
  /* strip a leading dot */
  path = path.replace(/^\./, "");
  /* path feldarabolása listába */
  var pList = path.split(".");
  /* pathok száma */
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    /* a plist i-edik elemét kiemeljük az elem változóba */
    var elem = pList[i];
    /* ha nem létezik a changeObject[elem], akkor létrehozzuk egy üres elemként */
    if (!changeObject[elem]) {
      return null;
    }
    /* a changeObject-nak átadjuk a changeObject[elem] objektumát */
    changeObject = changeObject[elem];
  }
  /* amint végig mentünk a path paramétereken, akkor a kapott objektumnak átadjuk a kapott value-t */
  return changeObject[pList[len - 1]];
};

//globális konvertáló, date-ből stringet készít
const convertDateToString = function (date, format) {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return null;
  }
};

//objektumon belüli szűrés megadott key path tömb alapján, nyelvesített értékeket is figyelembe veszi
//TODO: megvizsgálni
const filterList = function (inputs, list, filterKeyList, currentLang) {
  var tempInputs = [];
  if (!Array.isArray(inputs)) {
    tempInputs = [inputs];
  } else {
    tempInputs = inputs;
  }
  var filteredList = list;
  var arrayFilterKeyList = [];
  tempInputs.forEach((input) => {
    if (input) {
      filteredList = filteredList.filter((listElement) => {
        const normalizedInput = input
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        const filteredElement = filterKeyList.some((key) => {
          var normalizedListElement;
          if (key) {
            const pathArrays = key.split("[]");
            const pathKeys = pathArrays[0].split(".");
            arrayFilterKeyList = pathArrays[1];
            var value = listElement;
            pathKeys.forEach((pathKey) => {
              if (!Array.isArray(value) && value) {
                value = value[pathKey];
              }
            });
          }

          if (value && normalizedInput) {
            if (Array.isArray(value)) {
              const arrayHasValue = filterList(
                inputs,
                value,
                [arrayFilterKeyList.substring(1)],
                currentLang
              );

              return arrayHasValue && arrayHasValue.length ? true : false;
            }
            if (typeof value == "object") {
              //TODO meglévő bekötés
              const langs = Object.keys(value);
              if (langs.includes(currentLang)) {
                value = value[currentLang];
              } else if (langs.includes("en")) {
                value = value.en;
              } else {
                value = value[langs[0]];
              }
            }
            normalizedListElement = value
              .toString()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
            return (
              normalizedInput && normalizedListElement.includes(normalizedInput)
            );
          } else if (normalizedInput == "" || !normalizedInput) {
            return true;
          }
          return false;
        });
        return filteredElement;
      });
    }
  });
  return filteredList;
};

//Objektum csoportotísát
const groupByMostEfficient = function (list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

//path alapján ad értéket az objektumon belül, és ha nem létezik a fa akkor ki építi
const setChangedObjectElement = function (obj, path, value) {
  console.log(obj, path, value);
  if (!obj) {
    obj = {};
  }
  var changeObject = obj;
  /* convert indexes to properties */
  path = path.replace(/\[(\w+)\]/g, ".$1");
  /* strip a leading dot */
  path = path.replace(/^\./, "");
  /* path feldarabolása listába */
  var pList = path.split(".");
  /* pathok száma */
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    /* a plist i-edik elemét kiemeljük az elem változóba */
    var elem = pList[i];
    console.log("elem", elem);
    /* ha nem létezik a changeObject[elem], akkor létrehozzuk egy üres elemként */
    if (!changeObject[elem]) {
      changeObject[elem] = {};
      console.log("changeObject", changeObject);
    }
    /* a changeObject-nak átadjuk a changeObject[elem] objektumát */
    changeObject = changeObject[elem];
    console.log("changeObject", changeObject);
  }
  /* amint végig mentünk a path paramétereken, akkor a kapott objektumnak átadjuk a kapott value-t */
  changeObject[pList[len - 1]] = value;
};

//Két objektumot vizsgál meg, hogy egyelőnk-e
//pl. obj1 = { Name: 'tamás', Age: 34 } obj2 = { Name: 'tamás', Age:34 } ==> true
const deepObjectCompare = function (object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepObjectCompare(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

const isObject = function (object) {
  return object != null && typeof object === "object";
};

//nyelvesített szövegből kiszedi az aktuálisan kiválasztott nyelvű értéket,
//vagy ha azt nem talál akkor angolul szedi ki,
//és ha az sincs akkor az első nyelvesített értéket
//ha nincs nyelvesítés akkor visszaadja az objektumot (ami minden bizonnyal egy érték lesz)
const getLanguagedText = function (textObject, languages) {
  if (textObject && typeof textObject == "object") {
    const lang = languages ? languages : Object.keys(textObject);
    if (lang.includes(this.$i18n.locale)) {
      return textObject[this.$i18n.locale];
    } else if (lang.includes("en")) {
      return textObject.en;
    } else {
      return textObject[lang[0]];
    }
  } else {
    return textObject;
  }
};

const checkDuplicates = function (things, entryKey) {
  if (entryKey) {
    return things.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t[entryKey] === thing[entryKey])
    );
  } else {
    return things.filter(
      (thing, index, self) => index === self.findIndex((t) => t === thing)
    );
  }
};
