<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <div class="col-xl-3 col-md-4" style="margin: 12px 0px 25px">
          <fp-input
            v-model="filterInput"
            :label="$t('base.basic.filter')"
          />

          <div class="eh-listbox-list">
            <div
              class="eh-listbox-list-item"
              v-if="filteredWorkflowList && filteredWorkflowList.length === 0"
            >
              {{ $t("base.noData") }}
            </div>
            <div
              class="eh-listbox-list-item"
              :class="flow.Active ? 'eh-listbox-list-item-active' : ''"
              href="#"
              v-for="flow in filteredWorkflowList"
              :key="flow.WorkflowId"
              :active="flow.Active"
              @click="selectWorkflow(flow)"
            >
              <div>
                <b>{{ flow.Name }}</b> <br />
                {{ flow.Description }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-8" v-if="selectedWorkflow">
          <fp-panel-card style="background: white; margin: 35px 0px">
            <template #content>
              <b>{{ selectedWorkflow.Name }}</b>
              <br />
              {{ selectedWorkflow.Description }}
              <div>
                <fp-select
                  v-for="(hint,
                  name,
                  index) in selectedWorkflow.ParticipantRoles"
                  :key="index"
                  :label="name"
                  :placeholder="hint"
                  :items="userLists[name]"
                  valueKey="UserId"
                  textKey="Username"
                  :searchable="true"
                  @search="filterList(hint, name, $event)"
                  v-model="newInstance.Participants[name]"
                />
              </div>
              <button @click="instantiateWorkflow" class="eh-action-button">
                {{ $t("healthProfessional.workflow.startWorkflow") }}
              </button>
            </template>
          </fp-panel-card>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";
import { WorkflowLogic } from "@/Logic/Backend/workflow";
export default {
  name: "PartnerWorkflow",
  props: {
    project: Object,
  },
  data() {
    return {
      filterInput: null,
      workflowList: null,
      filteredWorkflowList: null,
      selectedWorkflow: null,
      userLists: {},
      newInstance: {
        WorkflowId: null,
        //TODO: validálás
        Participants: {},
      },
    };
  },
  watch: {
    filterInput(input) {
      this.filteredWorkflowList = this.$filterList(input, this.workflowList, [
        "Name",
        "Description",
      ]);
      if (this.selectedWorkflow) {
        this.$set(this.selectedWorkflow, "Active", false);
      }
      this.selectedWorkflow = null;
    },
    async project(input) {
      if (input) {
        await this.getWorkflowList();
      }
    },
  },
  methods: {
    async selectWorkflow(flow) {
      if (this.selectedWorkflow) {
        this.$set(this.selectedWorkflow, "Active", false);
        this.selectedWorkflow = null;
      }
      this.$set(flow, "Active", true);

      await Object.entries(flow.ParticipantRoles).forEach(
        async ([name, hint]) => {
          const tempList = await this.getUserListByHint(
            {
              "-orderby": "Username",
              "-limit": 20,
            },
            name,
            hint
          );
          this.$set(this.userLists, name, tempList);
        }
      );
      this.selectedWorkflow = flow;
    },
    async filterList(hint, name, input) {
      console.log(input);
      if (input.length > 2) {
        const userList = await this.getUserListByHint(
          {
            "Username-contains": input,
            "-orderby": "Username",
          },
          name,
          hint
        );
        this.$set(this.userLists, name, userList);
      } else if (input) {
        this.userLists[name] = null;
        this.$set(this.userLists, name, null);
      } else {
        const userList = await this.getUserListByHint(
          {
            "-orderby": "Username",
            "-limit": 20,
          },
          name,
          hint
        );
        this.$set(this.userLists, name, userList);
      }
    },
    async getUserListByHint(params, name, hint) {
      //TODO: getUser helyett subordinate?
      //a doki mennyire oszthat ki más dokinak, más felhasználónak (kapcsolat nélkül)
      const getUserListResponse = await UserLogic.getUser(params);
      if (!getUserListResponse.Code) {
        var temp = [];
        if (hint.includes("Self")) {
          temp = getUserListResponse;
          if (
            !getUserListResponse.some(
              (user) => user.UserId == this.$loggedUser.UserId
            )
          ) {
            temp.splice(0, 0, this.$loggedUser);
          }
          this.newInstance.Participants[name] = this.$loggedUser.UserId;
        } else if (hint.includes("Patient")) {
          temp = getUserListResponse.filter((user) =>
            user.Roles.includes("Client")
          );
        } else if (hint.includes("Doctor")) {
          temp = getUserListResponse.filter((user) =>
            user.Roles.includes("HealthProfessional")
          );
        }
        return temp;
      } else {
        this.$bvToast.toast(getUserListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.tasks.errorUserName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      return null;
    },
    async getWorkflowList() {
      const getWorkflowListResponse = await WorkflowLogic.getWorkflowSchemaList();
      if (!getWorkflowListResponse.Code) {
        this.workflowList = this.filteredWorkflowList = getWorkflowListResponse.filter(
          (flow) => {
            const projectsWorkflows = this.project.Items.filter(
              (item) =>
                item.EntityTypeName ==
                this.$enums.ProjectItemsEntityTypeName.FpWorkflow
            );
            return projectsWorkflows.find(
              (projectFlow) => projectFlow.EntityId == flow.WorkflowId
            );
          }
        );
      } else {
        this.$bvToast.toast(getWorkflowListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.tasks.errorStartableName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async instantiateWorkflow() {
      this.newInstance.WorkflowId = this.selectedWorkflow.WorkflowId;
      this.newInstance.ProjectId = this.$loggedUser.SelectedProject.ProjectId;
      await this.creatWorkflowIntance();
    },
    async creatWorkflowIntance() {
      const creatWorkflowIntanceResponse = await WorkflowLogic.setInstantiate(
        this.newInstance
      );
      if (!creatWorkflowIntanceResponse.Code) {
        await this.startWorkflow(creatWorkflowIntanceResponse);
      } else {
        this.$bvToast.toast(creatWorkflowIntanceResponse.Message, {
          title: this.$t("requestResponse.workflow.errorCreateInsatnce"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async startWorkflow(workflowInstance) {
      const startWorkflowResponse = await WorkflowLogic.startWorkflow(
        workflowInstance.InstanceId
      );
      if (!startWorkflowResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.workflow.successStart"), {
          title: this.$t("requestResponse.workflow.successStartTitle"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(startWorkflowResponse.Message, {
          title: this.$t("requestResponse.workflow.errorStart"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    if (this.project) {
      await this.getWorkflowList();
    }
    this.$store.dispatch("setPageTitle", this.$t("healthProfessional.menu.workflow"));
  },
};
</script>
