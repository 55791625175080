<template>
  <div class="eh-menu-content">
    <div v-if="project">
      <fp-tabs :lazy="true" :tabs="questionnaireTabs">
        <template #title="Component">{{ Component.Title }}</template>
        <template #content="Component">
          <component
            :is="Component.ComponentName"
            :projectItems="project.Items"
            :managerType="
              $enums.SystemParameters.ParticipantManagerType.Specialist
            "
            :participantListFields="patientListFields"
          />
        </template>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: "SpecialistQuestionnaires",
  props: {
    project: Object,
  },
  data() {
    return {
      questionnaireTabs: [
        {
          ComponentName: "fp-questionnaire-fillable-list",
          Title: this.$t("healthProfessional.questionnaires.own"),
        },
        {
          ComponentName: "fp-form-fillable-list",
          Title: this.$t("healthProfessional.questionnaires.fillableForm"),
        },
        {
          ComponentName: "fp-questionnaire-wait-evaluate-list",
          Title: this.$t(
            "healthProfessional.questionnaires.waitingForEvaluting"
          ),
        },
        {
          ComponentName: "fp-questionnaire-assignable-list",
          Title: this.$t("healthProfessional.questionnaires.assignable"),
        },
      ],
      patientListFields: [
        { key: "Name", label: "Név" },
        { key: "Ssn", label: "TAJ szám" },
        /* { key: "Tags", label: "Címkék" }, */
      ],
    };
  },
  mounted() {
    this.$emit(
      "title-changed",
      this.$t("healthProfessional.menu.questionnaires")
    );
  },
};
</script>
