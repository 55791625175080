export const HuClient = {
  "client": {
      "communication": {
          "receivedNotification": "Beérkezett értesítés"
    },
    "badges": {
      "availableBadges": "Megszerezhető jelvények",
      "recievedBadges": "Megszerzett jelvények"
    },
    "diseaseGuide": {
      "diseaseBNOCode": "BNO kód",
      "diseaseBodyPart": "Testrészhez köthető:",
      "diseaseBodyPartList": "Testrész",
      "diseaseDescription": "Leírás:",
      "diseaseDescriptionPart": "Leírásban szereplő szöveg",
      "diseaseList": "Betegség lista",
      "diseaseName": "Betegség neve",
      "diseaseSex": "Nemhez köthető:",
      "diseaseSexList": "Nem",
      "filteredFields": "Szűrhető mezők",
    },
    "documents": {
      "ehrDocuments": "EHR DOKUMENTUMOK",
      "otherDocuments": "EGYÉB DOKUMENTUMOK",
      "phrDocuments": "PHR DOKUMENTUMOK",
      errorName: 'dokumentum',
      documentType: 'Dokumentum típusa'
    },
    "functionSettings": {
      "chartOrder": "Grafikonok sorrendje",
      "charts": "Grafikonok",
      "chartsSettingFields": {
        "displayName": "Név",
        "enabled": "Bekapcsolva",
        "interval": "Intervallum",
        "size": "Méret"
      },
      "functionFields": {
        "key": "Név",
        "value": "Bekapcsolva"
      },
      "functions": "Funkciók",
      "moduleSettings": "{module} beállítások"
    },
    "healthGoals": {
      "completed": "Teljesítve",
      "completedNumberOfDays": "Teljesített napok száma: {count}",
      "completedRelatedNumberOfDays": "Teljesített összefüggő napok száma: {count}",
      "dailyNumberOfDays": "Intervallumon belül a teljesítés összefüggő napokon keresztül",
      "day": "{count} nap",
      "daysCount": "Napok száma",
      "emptyMessage": "Még nincs megjeleníthető cél",
      "goalDaily": "Napi cél",
      "goalDailyValue": "Napi célérték",
      "goalDailyValuePlaceholder": "Legalább napi célérték",
      "goalDefaultType": "Válassza ki az új cél típusát",
      "goalInterval": "Időszakos cél",
      "goalProperty": "Célérték elérés",
      "goalType": "Cél típusa",
      "goalValue": "Célérték",
      "goalValuePlaceholder": "Tervezett érték",
      "healthGoals": "{name} egészségcélok",
      "intervalMinDayCount": "Intervallumon belül minimális teljesítési napok száma",
      "moduleDefaultType": "Válassza ki az új cél modulját",
      "moduleType": "Modul típusa",
      "newGoal": "{name} egészségcél hozzáadása",
      "numberOfDaysRemaining": "Hátralévő napok száma: {count}",
      "startDate": "Kezdési dátum",
      "startDateErrorMessage": "A dátumot YYYY-MM-DD formátumba kell megadni",
      "status": "Állapot",
      "targetDate": "Cél dátum",
      "targetDateErrorMessage": "A cél dátum YYYY-MM-DD formátumba kell megadni, ami nem lehet korábbi a kezdési dátumnál",
      "targetValue": "Cél érték",
      "timeProgressPercentage": "Időbeli haladás",
      "timestamp": "Hozzáadva",
      "title": "Egészség cél",
      "valueInNormalInterval": "Normál tartományba eső érték",
      "valueProgressPercentage": "Cél érték haladása",
      startValue:'Kezdeti érték',
      startValuePlaceholder:'Induló érték'
    },
    "home": {
      "enabledModules": "Engedélyezett modulok",
      "settingModules": "Modulok szerkesztése"
    },
    "menu": {
      "communication": "Kommunikáció",
      "badges": "Jelvények",
      "diseaseGuide": "Betegség kalauz",
      "documents": "Dokumentumtár",
      "homeButton": "Személyes főoldal",
      "notifications": "Értesítések",
      "profileFillingRate": "Profil kitöltöttsége",
      "profileMedicalData": "Medikai adatok: ",
      "profilePersonalData": "Személyes adatok: ",
      "programs": "Programjaim",
      "questionnaires": "Kérdőívek",
      "serviceFinder": "Szolgáltatás kereső",
      "tasks": "Teendőim",
      appointments: 'Időpontok',
      profile: 'Profilom'
    },
    "moduleSettings": {
      "availableModules": "Elérhető modulok",
      "enabledModules": "Engedélyezett modulok",
      "title": "Modulok szerkesztése"
    },
    "profile": {
      "changePassword": "Jelszó csere",
      "medicalData": {
        "administrationDate": "Beadás dátuma",
        "agentName": "Hatóanyag megnevezése",
        "allergy": "Allergia",
        "appearDate": "Megjelenés dátuma",
        "bloodType": "Vércsoport",
        "bnoCode": "BNO kód",
        "cessationDate": "Megszűnés dátuma",
        "chilhoodDisease": "Gyermekkori betegségek",
        "chronicDisease": "Krónikus megbetegedés",
        "dieseaseName": "Betegség neve",
        "disease": "Betegség",
        "diseaseBySelf": "Betegség szabadkezes megadása",
        "diseaseFromList": "Betegség listából való kiválasztása",
        "diseaseName": "Betegség megnevezése",
        "dose": "Dózis",
        "drugSensitivity": "Gyógyszer érzékenység",
        "endDate": "Befejezés dátuma",
        "exceptionDate": "Kivétel dátuma",
        "familyAnamnesis": "Családi anamnézis",
        "implant": "Implantátum",
        "insertionDate": "Behelyezés dátuma",
        "longtermMedication": "Állandó szedett gyógyszer",
        "medicine": "Gyógyszer",
        "medicineBySelf": "Gyógyszer szabadkezes megadása",
        "medicineFromList": "Gyógyszer listából való kiválasztása",
        "medicineName": "Gyógyszer neve",
        "note": "Megjegyzés",
        "name": "Megnevezés",
        "result": "Eredmény",
        "newAllergy": "Új allergia felvétele",
        "newDisease": "Új betegség felvétele",
        "newImplant": "Új implantátum felvétele",
        "newMedicine": "Új gyógyszer felvétele",
        "newPregnancy": "Új terhesség felvétele",
        "newPreviousProcedure": "Új kezelés felvétele",
        "newSensitivity": "Új hatóanyag felvétele",
        "newVaccination": "Új védőoltás felvétele",
        "noData": "Nincs adat",
        "oenoCode": "Kezelés OENO kódja",
        "outcome": "Kimenetel",
        "package": "Csomagolás",
        "perceptionDate": "Észlelés dátuma",
        "physicalActivity": "Napi fizikai aktivitás",
        "pregnancy": "Várandósság",
        "previousDisease": "Korábbi betegségek",
        "previousProcedure": "Korábbi kezelések",
        "procedure": "Kezelés",
        "procedureBySelf": "Kezelés szabadkezes megadása",
        "procedureFromList": "Kezelés listából való kiválasztása",
        "procedureName": "Kezelés neve",
        "relationshipType": "Rokonságifok",
        "sensitivity": "Hatóanyag",
        "sensitivityBySelf": "Hatóanyag szabadkezes megadása",
        "sensitivityFromList": "Hatóanyag listából való kiválasztása",
        "sensitivityName": "Hatóanyag neve",
        "skinType": "Bőrtípus",
        "startDate": "Kezdés dátuma",
        "stillLasts": "Még tart",
        "stillTaking": "Még szedés alatt",
        "takeEndDate": "Utolsó beszedés dátuma",
        "takeStartDate": "Szedés kezdetének dátuma",
        "title": "Egészségügyi adatok",
        "vaccination": "Védőoltások"
      },
      "personalData": {
        "address": "Cím adatok",
        "birthday": "Születési dátum",
        "birthplace": "Születési hely",
        "chat": "Chat",
        "communication": "Kommunikáció",
        "email": "E-mail",
        "emailAddress": "Email cím",
        "emergencyPerson": "Éresítendő személy adatai",
        "idCard": "Személyigazolvány szám",
        "maritalStatus": "Családi állapot",
        "mother": "Anyja neve",
        "name": "Név",
        "nationality": "Nemzetiség",
        "phone": "Telefonszám",
        "push": "Push",
        "relationshipType": "Rokonságifok",
        "sex": "Nem",
        "sms": "SMS",
        "ssn": "TAJ szám",
        "title": "Személyes adatok",
        "username": "Felhasználónév"
      },
      errorName: 'profil'
    },
    "programs": {
      "carePrograms": "Gondozási programok",
      "healthPrograms": "Egészség programok",
      "period": "Időtartam",
      "projectName": "Projektnév",
      "projectTitle": "Projektcím",
      "state": "Állapot",
      "taskList": "Elvárások listája",
      close:'Összezár',
      details:"Részletek",
      errorCareName:'gondozási program',
      projectGoal: 'Projektcél',
      projectCriteria:"Beválasztási kritériumok",
      description:'Leírás',
      errorHealthName:'egészség program'
    },
    "questionnaires": {
      "description": "Leírás",
      "questionnaire": {
        title: 'Kérdőív',
        "date": "Értékelés időpontja",
        "longDescription": "Hosszú értékelés",
        "note": "Megjegyzés",
        "score": "Eredmény",
        "shortDescription": "Rövid értékelés"
      },
      "percentage": "Kitöltöttség",
      "requiredFieldsNotification": "Ahhoz, hogy beküldhesse a kérdőívet, töltse ki a csillaggal megjelölt kötelező mezőket.",
      "startDateTime": "Hozzáadás dátuma",
      "status": "Állapot",
      "stopDateTime": "Kitöltés dátuma",
      "title": "Cím",
      patient: 'Beteg adatai',
      errorName: 'kérdőív'
    },
    "serviceFinder": {
      "city": "Város",
      "district": "Kerület",
      "hospital": "Kórház",
      "infoAddress": "Cím:",
      "infoEmail": "Email:",
      "infoName": "Név:",
      "infoOpeningHours": "Nyitvatartás:",
      "infoPhone": "Telefon:",
      "information": "Információ",
      "infoWard": "Osztály:",
      "infoWebsite": "Honlap:",
      "pharmacy": "Gyógyszertár",
      "serviceType": "Szolgáltatás típusa",
      "ward": "Osztály"
    },
    appointment: {
      title: 'Cím',
      description: 'Leírás',
      startTime: 'Kezdési időpont',
      endTime: 'Záró időpont',
      location: 'Helyszín',
      filterDescription: 'Keresés címben, leírásban.',
      date: 'Dátum',
      inProgress: 'Folyamatban',
      cancellation: 'Lejelentkezés',
      errorName: 'időpont',
      own: 'Időpontjaim',
      free: 'Szabad időpontok'
    },
    notification: {
      noti: 'Értesítések',
      invitation: 'Meghívók',
      requestedBy: 'Küldő',
      parameters: 'Paraméterek',
      status: 'Állapot',
      errorInvitationName: 'meghívó'
    },
    task: {
      errorName: 'teendő'
    }
  },
}