<template>
  <div>
    <span style="margin-left: 5px">
      <div v-if="params.Title" v-html="params.Title[language]"></div>
    </span>
    <span
      v-if="params.Required && !params.Label"
      style="color: red; font-size: 14px"
    >
      * {{ $t("Base.required") }}
    </span>
    <fp-text-area
      :placeholder="params.PlaceHolder ? params.PlaceHolder[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
      :disabled="!params.Enabled != false || disabled"
      textStyle="dark"
      v-model="inputValue"
    />
  </div>
</template>
<script>
export default {
  name: "Long",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [String, Number],
    disabled: Boolean,
    language: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value ? this.value : this.$getLanguagedText(this.params.DefaultValue);
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
