<template>
  <b-row cols="1" cols-md="2">
    <div class="eh-panel">
      <fp-panel-card :title="$t('partner.home.dailyTask')">
        <template #content>
          <fp-task-table
            :filterDisabled="true"
            :taskList="dailyTaskList"
            :taskListFields="dailyTaskListFields"
          />
        </template>
      </fp-panel-card>
    </div>
    <div
      v-if="
        $loggedUser.SelectedProject && $loggedUser.SelectedProject.ProjectId
      "
      class="eh-panel"
    >
      <fp-panel-card :title="$t('partner.home.projectProgress')">
        <template #content>
          <div v-if="project">
            <div class="eh-progress-text"></div>
            <b>{{ getTextByLanguage(project.Research.Name) }}</b>
            <div v-if="projectProgress" class="eh-progress-text">
              {{ $t("partner.home.timeProgress") }}:
              <strong
                >{{
                  (
                    (projectProgress.DaysPassed / projectProgress.TotalDays) *
                    100
                  ).toFixed(0)
                }}%</strong
              >
            </div>
            <b-progress
              v-if="projectProgress"
              height="40px"
              :value="projectProgress.DaysPassed"
              :max="projectProgress.TotalDays"
            />
            <div
              v-if="projectProgress"
              style="text-align: center; margin-top: 10px"
            >
              {{ $t("partner.home.partnerProgress") }}:
              <strong
                >{{ (projectProgress.OwnProgress * 100).toFixed(0) }}%</strong
              >
            </div>
            <b-progress
              v-if="projectProgress"
              height="40px"
              :value="projectProgress.OwnProgress"
              :max="1"
            />
          </div>
        </template>
      </fp-panel-card>
    </div>
  </b-row>
</template>
<script>
import { UserTaskLogic } from "@/Logic/Backend/user-task";
import { ProjectLogic } from "@/Logic/Backend/project";
import moment from "moment";
export default {
  data() {
    return {
      dailyTaskList: [],
      dailyTaskListFields: [
        { key: "Status", label: this.$t("partner.home.taskStatus") },
        { key: "Type", label: this.$t("partner.home.taskType") },
        {
          key: "Priority",
          label: this.$t("partner.home.taskPriority"),
          sortable: true,
        },
        { key: "Title", label: this.$t("partner.home.taskTitle") },
        {
          key: "Description",
          label: this.$t("partner.home.taskDescription"),
        },
        {
          key: "Deadline",
          label: this.$t("partner.tasks.deadline"),
          sortable: true,
        },
      ],
      projectProgress: null,
    };
  },
  props: {
    project: Object,
  },
  watch: {
    async project() {
      await this.getProjectProgress();
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    async getDailyTasks() {
      var params = {};
      if (this.$loggedUser.SelectedProject) {
        params.ProjectId = this.$loggedUser.SelectedProject.ProjectId;
      }
      params.UserId = this.$loggedUser.UserId;
      const getDailyTasksResponse = await UserTaskLogic.getTasks(params);
      if (!getDailyTasksResponse.Code) {
        this.dailyTaskList = getDailyTasksResponse.filter(
          (task) =>
            ![
              this.$enums.TaskStatus.Completed.Value,
              this.$enums.TaskStatus.Failed.Value,
            ].includes(task.Status) &&
            (task.Deadline == null ||
              moment(task.Deadline).isBefore(moment().endOf("days")))
        );
      } else {
        this.$bvToast.toast(getDailyTasksResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("partner.home.dailyTask"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getProjectProgress() {
      const ownParticipantId = this.getOwnParticipantId();
      const getProjectProgressResponse = await ProjectLogic.getProjectManagerProgress(
        this.project.ProjectId,
        ownParticipantId
      );
      if (!getProjectProgressResponse.Code) {
        this.projectProgress = getProjectProgressResponse;
      } else {
        this.$bvToast.toast(getProjectProgressResponse.Message, {
          title: this.$t("requestResponse.project.errorGetProgress"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    getOwnParticipantId() {
      var temp = null;
      if (this.project) {
        temp = this.project.Participants.find(
          (p) => p.UserId == this.$loggedUser.UserId
        );
      }
      return temp ? temp.UserId : null;
    },
  },
  async mounted() {
    await this.getDailyTasks();
    this.project && this.$loggedUser.SelectedProject
      ? await this.getProjectProgress()
      : "";
    this.$store.dispatch("setPageTitle", this.$t("partner.menu.home"));
  },
};
</script>
