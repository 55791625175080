<template>
  <b-row v-if="doctor" cols="1" cols-md="2">
    <b-col>
      <fp-input
        label="EESZT azonosító"
        v-model="doctor.EesztId"
        @change="changeValue('EesztId', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos ENKK azonosítója"
          :required="true"
          v-model="doctor.EnkkId"
          @change="changeValue('EnkkId', $event)"
          :disabled="disabled"
          :state="!validation.hasError('doctor.EnkkId')"
          :error="validation.firstError('doctor.EnkkId')"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos pecsétszáma"
          v-model="doctor.StampNumber"
          description="EESZT beküldéshez kötelező mező! Vény jelentéshez kötelező mező!"
          @change="changeValue('StampNumber', $event)"
          :disabled="disabled"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos EESZT felhasználóneve"
          v-model="doctor.EesztUsername"
          @change="changeValue('EesztUsername', $event)"
          :disabled="disabled"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos neve"
          v-model="doctor.FullName"
          @change="changeValue('FullName', $event)"
          :disabled="disabled"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <code-name-pair
          label="Vényírási kategória"
          :required="true"
          v-model="doctor.PrescriptionCategory"
          @changeValue="changeValue('PrescriptionCategory', $event)"
          :items="eesztPrescriptionCategoryList"
          listValueKey="EntryId"
          :disabled="disabled"
        >
          <template #element="e">
            {{ e.Name + " (" + e.Code + ")" }}
          </template>
        </code-name-pair>
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény neve"
          description="Felíró orvos egészségügyi szolgáltatójának megnevezése"
          :disabled="disabled"
          @change="changeValue('OrganizationName', $event)"
          v-model="doctor.OrganizationName"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény NNK azonosítója"
          description="Felíró orvos egészségügyi szolgáltatójának 6 jegyű NNK azonosítója"
          :disabled="disabled"
          @change="changeValue('OrganizationNnkId', $event)"
          v-model="doctor.OrganizationNnkId"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény NEAK azonosítója"
          :disabled="disabled"
          @change="changeValue('OrganizationNeakId', $event)"
          v-model="doctor.OrganizationNeakId"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység neve"
          :disabled="disabled"
          @change="changeValue('OrganizationUnitName', $event)"
          v-model="doctor.OrganizationUnitName"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység NNK azonosítója"
          :required="true"
          description="Felíró orvos egészségügyi szolgáltatójának 9-jegyű NNK azonosítója."
          v-model="doctor.OrganizationUnitNnkId"
          @change="changeValue('OrganizationUnitNnkId', $event)"
          :disabled="disabled"
          :state="!validation.hasError('doctor.OrganizationUnitNnkId')"
          :error="validation.firstError('doctor.OrganizationUnitNnkId')"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység NEAK azonosítója"
          description="Felíró orvos Szervezeti egységének NEAK azonosítója"
          :disabled="disabled"
          @change="changeValue('OrganizationUnitNeakId', $event)"
          v-model="doctor.OrganizationUnitNeakId"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos telefonszáma"
          :disabled="disabled"
          @change="changeValue('Phone', $event)"
          v-model="doctor.Phone"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos címe"
          :disabled="disabled"
          @change="changeValue('FullAddress', $event)"
          v-model="doctor.FullAddress"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import CodeNamePair from "./CodeNamePair.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  components: { CodeNamePair },
  name: "FormDoctor",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      changedDoctorValue: {},
      doctor: null,
      defaultDoctor: {
        EesztId: null,
        EnkkId: null,
        StampNumber: null,
        EesztUsername: null,
        FullName: null,
        PrescriptionCategory: null,
        OrganizationEesztId: null,
        OrganizationNnkId: null,
        OrganizationNeakId: null,
        OrganizationName: null,
        OrganizationUnitEesztId: null,
        OrganizationUnitNnkId: null,
        OrganizationUnitNeakId: null,
        OrganizationUnitName: null,
        Phone: null,
        FullAddress: null,
      },
      eesztPrescriptionCategoryList: null,
    };
  },
  watch: {
    value() {
      this.setDoctor();
    },
  },
  validators: {
    "doctor.EnkkId": function (value) {
      if (this.hasDoctorData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "doctor.OrganizationUnitNnkId": function (value) {
      if (this.hasDoctorData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasDoctorData() {
      return Object.values(this.doctor).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    setDoctor() {
      if (this.value) {
        this.doctor = this.value;
      } else {
        this.doctor = JSON.parse(JSON.stringify(this.defaultDoctor));
      }
      this.resetValidation();
    },
    changeValue(key, value) {
      if (this.hasDoctorData) {
        this.changedDoctorValue[key] = value;
        this.$emit("changeValue", this.changedDoctorValue);
      } else {
        this.$emit("changeValue", null);
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    async getBase() {
      const getBaseResponse = await BaseDataLogic.getBaseData(
        this.$enums.BaseName.eeszt_prescription_category.Value,
        {
          ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
          ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
        }
      );
      if (!getBaseResponse.Code) {
        getBaseResponse.sort((a, b) => a.Code - b.Code);
        this.eesztPrescriptionCategoryList = getBaseResponse;
      } else {
        this.eesztPrescriptionCategoryList = [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    await this.getBase();
    this.setDoctor();
  },
};
</script>
