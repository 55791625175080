<template>
  <div v-if="Visible">
    <fp-table-list
      v-if="Visible"
      :items="accpetableLegalList"
      :fields="acceptableLegalListFields"
    >
      <template #Name="row">
        <div style="display: flex">
          <div style="padding-right: 15px">
            <div v-for="(e, index) in row.item.SupportedLanguages" :key="index">
              {{ e }}
            </div>
          </div>
          <div>
            <b>{{ $getLanguagedText(row.item.Title) }}</b>
            <br />
            {{ $getLanguagedText(row.item.Description) }}
            <br />
            <i>Verzió: {{ row.item.LastVersion }}</i>
          </div>
        </div>
      </template>
      <template #Valid="row">
        {{ row.item.Scope }}
        <br />
        {{ $convertDateToString(row.item.ValidFrom, "YYYY-MM-DD") }} -
        {{ $convertDateToString(row.item.ValidTo, "YYYY-MM-DD") }}
      </template>
      <template #Operations="row">
        <b-iconstack
          @click="$set(row.item, '_showLegalDocument', true)"
          font-scale="2"
          style="background: var(--info); border-radius: 7px; cursor: pointer"
        >
          <b-icon stacked icon="eye-fill" style="color: white" scale="0.65" />
        </b-iconstack>
        <fp-form-modal v-model="row.item._showLegalDocument" size="xl">
          <template #content>
            <legal-view :Document="row.item" :Version="row.item.LastVersion" />
          </template>
        </fp-form-modal>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { LegalLogic } from "../../Logic/Backend/legal";
import LegalStatementFormModal from "./LegalStatementFormModal.vue";
import LegalView from "./LegalView.vue";
export default {
  components: { LegalView, LegalStatementFormModal },
  // TODO: projekt szűrés?
  name: "AcceptableLegalList",
  props: {
    TargetId: Number,
    Visible: Boolean,
  },
  data() {
    return {
      showModal: false,
      accpetableLegalList: null,
      acceptableLegalListFields: [
        { key: "Name", label: "Nyilatkozat" },
        { key: "Valid", label: "Érvényes" },
        { key: "Type", label: "Típus" },
        { key: "Operations", label: "" },
      ],
    };
  },
  watch: {
    async TargetId() {
      await this.SetLegalList();
    },
    async OwnerId() {
      await this.SetLegalList();
    },
    async Visible(input) {
      if (input) {
        await this.SetLegalList();
      }
    },
  },
  methods: {
    async GetAcceptableLegalByUser() {
      this.accpetableLegalList = null;
      var response = await LegalLogic.getAcceptableStatementLegalByUser(
        null,
        this.TargetId
      );
      if (!response.Code) {
        this.accpetableLegalList = response;
      } else {
        this.accpetableLegalList = [];
      }
    },
    async GetAcceptableLegal() {
      this.accpetableLegalList = null;
      var response = await LegalLogic.getAcceptableStatementLegal();
      if (!response.Code) {
        this.accpetableLegalList = response;
      } else {
        this.accpetableLegalList = [];
      }
    },
    async SetLegalList() {
      if (this.TargetId != null || this.TargetId != undefined) {
        await this.GetAcceptableLegalByUser();
      } else {
        await this.GetAcceptableLegal();
      }
    },
  },
  async mounted() {
    if (this.Visible) {
      await this.SetLegalList();
    }
  },
};
</script>