<template>
  <div class="page-background">
    <div>
      <div style="text-align: center" class="diary-title">
        {{ $t("module.diaries.insulin.post") }}
      </div>
      <div style="margin: 20px 0px">
        <button class="eh-action-button" @click="goBack">
          {{ $t("base.basic.back") }}
        </button>
        <button
          class="eh-action-button"
          @click="showInsulinDiaryInputModal = true"
        >
          {{ $t("module.diaries.newPost") }}
        </button>
      </div>

      <fp-client-module-diary baseName="insulin" v-model="refreshList" />

      <fp-insulin-diary-input
        @refreshList="refreshList = true"
        v-model="showInsulinDiaryInputModal"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InsulinDiary",
  data() {
    return {
      refreshList: false,
      showInsulinDiaryInputModal: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.$t("module.diaries.insulin.title"));
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
