<template>
  <fp-form-modal v-model="visible">
    <template #content>
      <b-row align-h="center" cols="1">
        <fp-select
          :label="$t('client.healthGoals.goalType')"
          :placeholder="$t('client.healthGoals.goalDefaultType')"
          :items="goalTypes"
          valueKey="Value"
          textKey="Text"
          v-model="newPlan.PlanType"
          @change="$set(newPlan, 'Type', null)"
        />
        <div v-if="newPlan.PlanType">
          <fp-select
            :label="$t('client.healthGoals.moduleType')"
            :placeholder="$t('client.healthGoals.moduleDefaultType')"
            :items="Module.HealthPlans[newPlan.PlanType]"
            v-model="newPlan.Type"
            valueKey="Value"
            :required="true"
            :state="!validation.hasError('newPlan.Type')"
            :error="validation.firstError('newPlan.Type')"
          >
            <template #element="plan">
              {{ $t("module.plans." + newPlan.PlanType + "." + plan.Name) }}
            </template>
          </fp-select>
          <fp-input
            v-if="Module.Name == 'BodyWeight'"
            :placeholder="$t('client.healthGoals.startValuePlaceholder')"
            :label="$t('client.healthGoals.startValue')"
            v-model="newPlan.StartValue"
            :state="!validation.hasError('newPlan.StartValue')"
            :error="validation.firstError('newPlan.StartValue')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.property"
            type="number"
            :required="true"
            :placeholder="$t('client.healthGoals.goalValuePlaceholder')"
            :label="$t('client.healthGoals.goalValue')"
            v-model="newPlan.TargetValue"
            :state="!validation.hasError('newPlan.TargetValue')"
            :error="validation.firstError('newPlan.TargetValue')"
          />
          <fp-input
            v-else-if="checkTargetNormal()"
            :disabled="true"
            :placeholder="$t('client.healthGoals.valueInNormalInterval')"
            :label="$t('client.healthGoals.goalDailyValue')"
          />
          <div v-else>
            <fp-time-picker
              v-if="
                newPlan.Type == 'SleepHours' ||
                newPlan.Type == 'DailySleepHours'
              "
              v-model="newPlanSleepDuration"
              :label="$t('module.diaries.sleep.sleepDurationInput')"
            />
            <fp-input
              v-else
              type="number"
              :required="true"
              :placeholder="$t('client.healthGoals.goalDailyValuePlaceholder')"
              :label="$t('client.healthGoals.goalDailyValue')"
              v-model="newPlan.TargetValue"
              :state="!validation.hasError('newPlan.TargetValue')"
              :error="validation.firstError('newPlan.TargetValue')"
            />
          </div>
          <fp-date-picker
            :required="true"
            :label="$t('client.healthGoals.startDate')"
            v-model="newPlan.StartDate"
            :state="!validation.hasError('newPlan.StartDate')"
            :error="validation.firstError('newPlan.StartDate')"
          />
          <fp-date-picker
            :required="true"
            :label="$t('client.healthGoals.targetDate')"
            v-model="newPlan.TargetDate"
            :state="!validation.hasError('newPlan.TargetDate')"
            :error="validation.firstError('newPlan.TargetDate')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.interval"
            type="number"
            :required="true"
            :label="$t('client.healthGoals.daysCount')"
            :description="$t('client.healthGoals.intervalMinDayCount')"
            v-model="newPlan.MinDayCount"
            :state="!validation.hasError('newPlan.MinDayCount')"
            :error="validation.firstError('newPlan.MinDayCount')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.daily"
            type="number"
            :required="true"
            :label="$t('client.healthGoals.daysCount')"
            :description="$t('client.healthGoals.dailyNumberOfDays')"
            v-model="newPlan.NumberOfDays"
            :state="!validation.hasError('newPlan.NumberOfDays')"
            :error="validation.firstError('newPlan.NumberOfDays')"
          />
          <b-button style="margin-top: 10px" @click="createNewPlan">{{
            $t("base.basic.add")
          }}</b-button>
        </div>
      </b-row>
    </template>
  </fp-form-modal>
</template>
<script>
import moment from "moment";
import { HealthPlanLogic } from "../../../../Logic/Backend/health-plan";
export default {
  name: "CreateHealthGoalFormModal",
  model: {
    prop: "Value",
    event: "change",
  },
  props: {
    Value: Boolean,
    UserId: Number,
    Module: Object,
  },
  data() {
    return {
      visible: false,
      items: null,
      newPlan: {
        PlanType: null,
        Type: null,
        TypeObject: null,
        StartDate: null,
        TargetDate: null,
        TargetValue: null,
        //property (body weight)
        StartValue: 0,
        //interval
        MinDayCount: null,
        //daily
        NumberOfDays: null,
      },
      newPlanSleepDuration: null,
      defaultNewPlan: {
        PlanType: null,
        TypeObject: null,
        Type: null,
        StartDate: null,
        TargetDate: null,
        TargetValue: null,
        //property (body weight)
        StartValue: 0,
        //interval
        MinDayCount: null,
        //daily
        NumberOfDays: null,
      },
      planTypes: {
        [this.$enums.HealthGoalType.interval]: this.$t(
          "client.healthGoals.goalInterval"
        ),
        [this.$enums.HealthGoalType.daily]: this.$t(
          "client.healthGoals.goalDaily"
        ),
        [this.$enums.HealthGoalType.property]: this.$t(
          "client.healthGoals.goalProperty"
        ),
      },
      goalTypes: [],
    };
  },
  watch: {
    visible(input) {
      this.$emit("change", input);
    },
    Value(input) {
      this.visible = input;
      if (input) {
        this.setGoalType();
      } else {
        this.SetValueToDefault();
      }
    },
    Module() {
      this.SetValueToDefault();
    },
    newPlanSleepDuration(input) {
      this.newPlan.TargetValue = moment.duration(input).asHours();
    },
  },
  validators: {
    "newPlan.Type": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newPlan.StartValue": function (value) {
      const moduleName = this.Module.Name;
      return this.$validator.custom(function () {
        if (moduleName == "BodyWeight") {
          if (value < 0) {
            return this.$t("validate.greaterThanZero");
          }
        }
      });
    },
    "newPlan.TargetValue": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newPlan.StartDate": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newPlan.TargetDate, newPlan.StartDate": function (end, start) {
      const validator = this.$validator;
      const localDirT = (path) => this.$t(path);
      if (this.validation.isTouched("newPlan.TargetDate")) {
        return validator.custom(function () {
          if (!validator.isEmpty(start) && !validator.isEmpty(end)) {
            if (moment(start).isSameOrAfter(moment(end))) {
              return localDirT("validate.endDateNotBeforeEqualStart");
            }
          } else if (!validator.isEmpty(end)) {
            return localDirT("validate.requiredField");
          }
        });
      } else {
        return validator.custom(function () {
          if (validator.isEmpty(end)) {
            return localDirT("validate.requiredField");
          }
        });
      }
    },
    "newPlan.MinDayCount, newPlan.PlanType": function (value, type) {
      const validator = this.$validator;
      const validation = this.validation;
      return validator.custom(() => {
        if (type == this.$enums.HealthGoalType.interval) {
          if (validation.isPassed("newPlan.MinDayCount")) {
            if (validator.isEmpty(value)) {
              return this.$t("validate.requiredField");
            }
          } else {
            if (value < 0) {
              return this.$t("validate.greaterThanZero");
            }
          }
        }
      });
    },
    "newPlan.NumberOfDays, newPlan.PlanType": function (value, type) {
      const validator = this.$validator;
      const validation = this.validation;
      return validator.custom(() => {
        if (type == this.$enums.HealthGoalType.daily) {
          if (validation.isPassed("newPlan.NumberOfDays")) {
            if (validator.isEmpty(value)) {
              return this.$t("validate.requiredField");
            } else if (value < 0) {
              return this.$t("validate.greaterThanZero");
            }
          }
        }
      });
    },
  },
  methods: {
    SetValueToDefault() {
      this.newPlan = JSON.parse(JSON.stringify(this.defaultNewPlan));
      this.validation.reset();
    },
    async createNewPlan() {
      this.convertMinDaysToPercentage();
      const valid = await this.$validate();

      if (valid) {
        this.newPlan.UserId = this.UserId;
        const result = await HealthPlanLogic.setHealthPlan(
          this.newPlan.PlanType,
          [this.newPlan]
        );
        if (!result.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleHealthGoal.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );

          this.$emit("SetSelectedPlan", {
            Type: this.newPlan.Type,
            PlanType: this.newPlan.PlanType,
          });

          this.SetValueToDefault();

          this.visible = false;
        } else {
          this.$bvToast.toast(result.Message, {
            title: this.$t("requestResponse.moduleHealthGoal.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    setGoalType() {
      this.goalTypes = [];
      const temp = {
        [this.$enums.HealthGoalType.interval]: this.Module.HealthPlans.interval,
        [this.$enums.HealthGoalType.property]: this.Module.HealthPlans.property,
        [this.$enums.HealthGoalType.daily]: this.Module.HealthPlans.daily,
      };
      Object.keys(temp).forEach((key) => {
        if (temp[key]) {
          this.goalTypes.push({ Value: key, Text: this.planTypes[key] });
        }
      });
    },
    checkTargetNormal() {
      if (this.newPlan.Type) {
        const selectedType = this.Module.HealthPlans[
          this.newPlan.PlanType
        ].find((x) => x.Value == this.newPlan.Type);
        if (selectedType) {
          if (selectedType.Normal) {
            this.newPlan.TargetValue = 0;
          }
          return selectedType.Normal;
        }
      }
      return false;
    },
    convertMinDaysToPercentage() {
      this.newPlan.TargetPercentage =
        (this.newPlan.MinDayCount /
          (moment(this.newPlan.TargetDate).diff(
            moment(this.newPlan.StartDate),
            "days"
          ) +
            1)) *
        100;
    },
  },
  mounted() {
    if (this.visible) {
      this.setGoalType();
    } else {
      this.SetValueToDefault();
    }
  },
};
</script>