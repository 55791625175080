<template>
  <div class="search page-background">
    <h1>Fejlesztés alatt</h1>
  </div>
</template>
<script>
export default {
  components: {
  }
}
</script>
<style>
.search{
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>