<template>
  <div class="eh-partner-project">
    <div>
      <div v-if="project">
        <fp-tabs :tabs="projectCardComponents">
          <template #title="Component">{{ Component.Title }}</template>
          <template #content="Component">
            <component
              :is="Component.ComponentName"
              :createdProjectId="project.ProjectId"
              :project.sync="project"
              :participantList.sync="project.Participants"
              :roleList.sync="project.Roles"
              :items.sync="project.Items"
              :isCreated="true"
              @getList="getSelectedProject"
              :roleReadonly="true"
            />
          </template>
        </fp-tabs>
      </div>
      <div v-else>{{ $t("partner.projects.dontProject") }}</div>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
import { ProjectUiLogic } from "@/Logic/Ui/project";
/* components */
import DataSheet from "@/Modules/Project/DataSheet/DataSheet.vue";
import Roles from "@/Modules/Project/Role/Roles.vue";
import Participants from "@/Modules/Project/Participant/Participants.vue";
import ParticipantProgress from "@/Modules/Project/Participant/ParticipantProgress.vue";

export default {
  name: "WebAppPartnerProjects",
  components: {
    DataSheet,
    Roles,
    Participants,
    ParticipantProgress,
  },
  data() {
    return {
      projectCardComponents: [
        {
          ComponentName: "DataSheet",
          Title: this.$t("partner.projects.dataSheet"),
        },
        {
          ComponentName: "Roles",
          Title: this.$t("partner.projects.roles"),
        },
        {
          ComponentName: "Participants",
          Title: this.$t("partner.projects.participants"),
        },
        {
          ComponentName: "ParticipantProgress",
          Title: "Projekt előrehaladás",
        },
      ],
      project: null,
    };
  },
  methods: {
    exportProject() {
      ProjectUiLogic.exportProject(this.project);
    },
    async getSelectedProject() {
      const getSelectedProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getSelectedProjectResponse.Code) {
        this.project = getSelectedProjectResponse[0];
        //this.specialists = this.getSpecialists();
      } else {
        this.$bvToast.toast(getSelectedProjectResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor lefut egyszer
  async mounted() {
    await this.getSelectedProject();
    //oldal cím beállítása
    this.$store.dispatch("setPageTitle", this.$t("partner.projects.title"));
  },
};
</script>
<style scoped>
#new-fp-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}

#new-fp-modal .modal-content header {
  padding: 0px;
}
</style>
