<template>
    <div class="other page-background">
    <h1>{{$t('base.basic.underDevelopment')}}</h1>
  </div>
</template>

<script>

export default {
  name: "OtherModule",
  data() {
    return {
      name: ""
    };
  },
  props: {
      thisModule: {
          type: Object
      }
  },
  mounted() {
    this.$store.dispatch("setPageTitle", this.thisModule.DisplayName);
    this.$emit("color-change", this.thisModule.Color);
  },
};
</script>
<style>
.other{
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>