<template>
  <div class="legal page-background">
    <div>
      <div>
        <p style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);">
          A
          <a
            class="e-rte-anchor"
            href="www.fokuszprogram.hu"
            title="www.fokuszprogram.hu"
            style="color:rgb(237, 125, 49)"
          >www.fokuszprogram.hu </a>weblap, illetve annak bármely oldala megnyitásával Ön elfogadja az alábbiakban rögzített feltételeket. Kérjük, amennyiben nem ért egyet a feltételekkel, ne nyissa meg weboldalunkat!
        </p>
        <p style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);">Az e-Health Software Solutions Kft. weboldalán megjelenő információk és adatok kizárólag tájékoztatásul szolgálnak. Az e-Health Software Solutions Kft. fenntartja a jogot a rendelkezésre bocsátott információk megváltoztatására vagy módosítására. Az e-Health Software Solutions Kft. törekszik a weblapon fellelhető információk folyamatos frissítésére, azonban azok teljességéért, helytállóságáért, esetleges módosulásáért, hitelességéért felelősséget nem vállal. Az e-Health Software Solutions Kft. weboldalán megjelenő információk és adatok bármilyen módon való felhasználása kizárólag a felhasználó saját felelősségére történhet.</p>
        <p style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);">Az e-Health Software Solutions Kft. honlapjáról linkek segítségével elérhető partnercégek weboldalain szereplő információkat nem ellenőrizzük, ezek elérhetőségéért, tartalmi helyességéért felelősséget nem vállalunk. Az e-Health Software Solutions Kft. nem felel azokért a károkért, veszteségekért, költségekért, amelyek a weboldalakkal kapcsolatban, azok használatából vagy használhatatlanságából, nem megfelelő működéséből, meghibásodásából, számítógépes vírus által, vonal- vagy rendszerhibából, az információtovábbítás esetleges késedelméből, az adatok bárki által történő illetéktelen megváltoztatása következtében, vagy más hasonló okból következnek be.</p>
        <p style="margin: 6pt 0cm 0cm; text-align: justify; font-size: 12pt; font-family: Calibri, sans-serif; color: rgb(0, 0, 0);">Az e-Health Software Solutions Kft. weboldalán megjelenő grafikák, szövegek, képek, információk és egyéb anyagok, illetve azok elrendezése szerzői jogi oltalom alatt állnak, azok felhasználására és felhasználási jogának átruházására kizárólag az e-Health Software Solutions Kft. jogosult.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.legal {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>