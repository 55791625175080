<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <div class="col-xl-3 col-md-4" style="margin: 12px 0px 25px">
          <fp-input v-model="filterInput" :label="$t('base.basic.filter')" />
          <fp-list-box
            :items="filteredWorkflowList"
            :selectedItem="selectedWorkflow"
            :emptyListText="$t('base.noData')"
            @selectingItem="selectWorkflow"
          >
            <template #default="flow">
              <b>{{ flow.Name }}</b> <br />
              {{ flow.Description }}
            </template>
          </fp-list-box>
        </div>
        <fp-create-workflow-instance :selectedWorkflow="selectedWorkflow" />
      </b-row>
    </div>
  </div>
</template>
<script>
import { WorkflowLogic } from "@/Logic/Backend/workflow";
export default {
  name: "SpecialistWorkflows",
  props: {
    project: Object,
  },
  data() {
    return {
      filterInput: null,
      workflowList: null,
      filteredWorkflowList: null,
      selectedWorkflow: null,
    };
  },
  watch: {
    filterInput(input) {
      this.filteredWorkflowList = this.$filterList(input, this.workflowList, [
        "Name",
        "Description",
      ]);
      if (this.selectedWorkflow) {
        this.$set(this.selectedWorkflow, "Active", false);
      }
      this.selectedWorkflow = null;
    },
    async project(input) {
      if (input) {
        await this.getWorkflowList();
      }
    },
  },
  methods: {
    async selectWorkflow(flow) {
      if (this.selectedWorkflow) {
        this.$set(this.selectedWorkflow, "Active", false);
        this.selectedWorkflow = null;
      }
      this.$set(flow, "Active", true);
      this.selectedWorkflow = flow;
    },

    async getWorkflowList() {
      const getWorkflowListResponse =
        await WorkflowLogic.getWorkflowSchemaList();
      if (!getWorkflowListResponse.Code) {
        this.workflowList = this.filteredWorkflowList =
          getWorkflowListResponse.filter((flow) => {
            const projectsWorkflows = this.project.Items.filter(
              (item) =>
                item.EntityTypeName ==
                this.$enums.ProjectItemsEntityTypeName.FpWorkflow
            );
            return projectsWorkflows.find(
              (projectFlow) => projectFlow.EntityId == flow.WorkflowId
            );
          });
      } else {
        this.$bvToast.toast(getWorkflowListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("healthProfessional.tasks.errorStartableName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    if (this.project) {
      await this.getWorkflowList();
    }
    this.$store.dispatch("setPageTitle", this.$t("healthProfessional.menu.workflow"));
  },
};
</script>
