<template>
  <fp-form-modal
    v-if="legals && legals.length"
    size="xl"
    :closable="false"
    v-model="visible"
  >
    <template #header>
      <div>
        {{ "Adatkezelési tájékoztatók " + legalsCurrent + "/" + legalsCount }}
      </div>
      <div style="text-align: center; font-size: 25px; font-weight: bold">
        {{ getTextByLanguage(legals[legalsCurrent - 1].Title) }}
      </div>
      <div style="text-align: center; font-size: 20px">
        {{ getTextByLanguage(legals[legalsCurrent - 1].Description) }}
      </div>
    </template>
    <template #content>
      <b-progress
        style="
          border-radius: 0;
          margin-top: -17px;
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: 20px;
        "
        variant="info"
        :value="legalsCurrent"
        :max="legalsCount"
      />
      <!-- TODO: required acceptableStatements bekötés? -->
      <div
        id="gdpr-content"
        style="text-align: justify"
        v-html="getTextByLanguage(getCurrentLegalLastVersion.Text)"
      ></div>
      <div
        v-for="(
          check, index
        ) in getCurrentLegalLastVersion.AcceptableStatements"
        :key="
          'document-' + legals[legalsCurrent - 1].DocumentId + 'check-' + index
        "
      >
        <b-check :state="checkAccept" v-model="check.Value">
          <p>
            <em>
              {{ getTextByLanguage(check.Text) }}
              <span v-if="check.Required" class="text-danger">*</span>
            </em>
          </p>
        </b-check>
      </div>
      <div>
        <b-button @click="acceptGDPR" :disabled="!checkAccept"
          >Elfogadom{{
            legalsCount != legalsCurrent ? ", következő" : ""
          }}</b-button
        >
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { LegalLogic } from "../../Logic/Backend/legal";
export default {
  name: "GDPR_modal",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    legals: Array,
  },
  data() {
    return {
      visible: this.value,
      acceptFirst: false,
      acceptSecond: false,
      legalsCount: 0,
      legalsCurrent: 1,
      legalsAccepted: [],
    };
  },
  watch: {
    value(input) {
      this.visible = input;
      this.legalsCount = this.legals.length;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  computed: {
    getCurrentLegalLastVersion() {
      if (this.legals && this.legals.length) {
        const currentLegal = this.legals[this.legalsCurrent - 1];
        const currentLastVersion = currentLegal.ContentVersions.find(
          (v) => v.Version == currentLegal.LastVersion
        );
        if (currentLastVersion) {
          return currentLastVersion;
        } else {
          const legalVersions = currentLegal.ContentVersions.map(
            (c) => c.Version
          );
          const latestVersionNumber = Math.max(...legalVersions);
          return currentLegal.ContentVersions.find(
            (v) => v.Version == latestVersionNumber
          );
        }
      }
      return null;
    },
    checkAccept() {
      return !this.getCurrentLegalLastVersion.AcceptableStatements.some(
        (x) => !x.Value
      );
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    async acceptGDPR() {
      const acceptLegalResponse = LegalLogic.createStatementLegal({
        DocumentId: this.legals[this.legalsCurrent - 1].DocumentId,
        FromDevice: "Pc",
        AcceptedStatementIndexes: [
          ...Array(
            this.getCurrentLegalLastVersion.AcceptableStatements.length
          ).keys(),
        ],
      });

      if (!acceptLegalResponse.Code) {
        if (this.legalsCount != this.legalsCurrent) {
          this.legalsCurrent++;
          document.getElementById("fp-form-modal").scrollTo(0, 0);
        } else {
          this.$emit("change", false);
          this.$emit("acceptGDPR");
        }
      } else {
        //TODO: hiba
      }
    },
    rejectGDPR() {
      this.$emit("change", false);
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 25px;
}
h2 {
  font-size: 20px;
}
</style>
