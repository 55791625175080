<template>
  <div>
    <static-header />
    <router-view style="width: 95%; margin: auto" name="static" />
    <router-view name="customStatic" />
  </div>
</template>
<script>
import StaticHeader from "../../../Modules/Header/StaticHeader.vue";
export default {
  components: {
    StaticHeader,
  },
};
</script>