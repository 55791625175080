export const DeEnums = {
  enums: {
    default: "Nicht spezifiziert",
    RelationshipType: {
      Mother: "Mutter",
      GrandMother: "Großmutter",
      Father: "Vater",
      GrandFather: "Großvater",
      Sister: "Schwester",
      Brother: "Bruder",
    },
    UserRole: {
      Client: "Kunde",
      HealthProfessional: "Fachmann/-frau im Gesundheitswesen",
      Partner: "Partner",
      Supervisor: "Supervisor",
      Administrator: "Administrator",
      Technical: "Techniker",
    },
    UserFlag: {
      Inactive: "Inaktiv",
      MustChangePassword:
        "Passwortänderung für die erste Anmeldung erforderlich",
      NoLogin: "Kann mich nicht einloggen",
      Impersonated: "Arzt verwaltet das Profil",
    },
    CareType: {
      Inpatient: "Stationär",
      Outpatient: "Ambulant",
    },
    FormCategory: {
      General: "Allgemein",
      Project: "Projekt",
    },
    DocumentStatus: {
      Created: "Erstellt",
      Uploaded: "Hochgeladen",
    },
    DocumentCategory: {
      Ehr: "EHR-Dokument",
      Phr: "PHR-Dokument",
      PersonalDocument: "Persönliches Dokument",
      Contract: "Vertrag",
      Permit: "Lizenz",
      Technical: "Technisch",
      Other: "Anderes Dokument",
    },
    FormStatus: {
      Active: "Aktiv",
      Inactive: "Inaktiv",
      InProgress: "Ausfüllen",
      UnderEvaluation: "In Bewertung",
      Filled: "Ausgefüllt",
      Evaluated: "Bewertet",
    },
    AuthenticationResult: {
      Success: "Erfolgreich",
      Failure: "Nicht erfolgreich",
    },
    DataOperation: {
      Create: "Anfertigung",
      Read: "Lesen",
      Update: "Hochladen",
      LogicalDelete: "Logische Löschung",
      PhisicalDelete: "Physische Löschung",
    },
    Sex: {
      Male: "Männlich",
      Female: "Weiblich",
      Both: "Bisexuell",
      Unknown: "Unbekannt",
    },
    MaritalStatus: {
      Married: "Verheiratet",
      Single: "Alleinstehend",
      Divorced: "Geschieden",
      Widowed: "Verwitwet",
    },
    DataSource: {
      FocusProgram: "Fokusprogramm",
      HealthKit: "HealthKit",
      GoogleFit: "GoogleFit",
      UserInput: "Nutzereingabe",
      Vitadock: "Vitadock",
      Ogyei: "OGYEI",
      Eeszt: "EESZT",
      Aeek: "OKFÖ",
    },
    BloodType: {
      Zp: "0-",
      Zm: "0+",
      Ap: "A+",
      Am: "A-",
      Bp: "B+",
      Bm: "B-",
      ABp: "AB+",
      ABm: "AB-",
    },
    Circumstances: {
      Rest: "Ruhezustand",
      LightExercise: "Bewegung mit geringer Intensität",
      HeavyExercise: "Bewegung mit hoher Intensität",
      Unknown: "Unbekannte Umstände",
    },
    SkinType: {
      TypeI: "Type I",
      TypeII: "Type II",
      TypeIII: "Type III",
      TypeIV: "Type IV",
      TypeV: "Type V",
      TypeVI: "Type VI",
    },
    PhysicalActivity: {
      ExtremelyInactive: "Extrem inaktiv",
      Sedentary: "Arbeit im Sitzen",
      ModeratelyActive: "Moderat aktiv",
      VigorouslyActive: "Sehr aktiv",
      ExtremelyActive: "Extrem aktiv",
    },
    ChartInterval: {
      Day: "Tag",
      Week: "Woche",
      Month: "Monat",
      Year: "Jahr",
    },
    ChartSize: {
      Small: "Klein",
      Medium: "Mittel",
      Large: "Groß",
    },
    MedicineTimingType: {
      Once: "Einmal",
      OnDaysOfWeek: "Tage der Woche",
      Daily: "Täglich",
      EveryTwoDays: "jeden zweiten Tag",
      EveryThreeDays: "jeden dritten Tag",
      EveryFourDays: "jeden vierten Tag",
      EveryFiveDays: "jeden fünften Tag",
      EverySixDays: "jeden sechsten Tag",
      Weekly: "Wöchentlich",
      Biweekly: "jede zweite Woche",
      Monthly: "Monatlich",
    },
    DaysOfWeek: {
      Monday: "Montag",
      Tuesday: "Dienstag",
      Wednesday: "Mittwoch",
      Thursday: "Donnerstag",
      Friday: "Freitag",
      Saturday: "Samstag",
      Sunday: "Sonntag",
    },
    MealRelation: {
      BeforeMeal: "Vor dem Essen",
      AfterMeal: "Nach dem Essen",
      Unknown: "Der Zeitpunkt der Mahlzeit ist unbekannt",
    },
    MealQuantity: {
      Low: "Kleine Nahrungsmenge",
      Medium: "Mittlere Nahrungsmenge",
      High: "Hohe Nahrungsaufnahme",
      Unknown: "Unbekannte Menge an verzehrten Lebensmitteln",
    },
    MealName: {
      Breakfast: "Frühstück",
      Elevenses: "Jause",
      Lunch: "Mittagessen",
      Dinner: "Abendessen",
      Snack: "Snack",
    },
    Unit: {
      Pieces: "Stück",
      Gram: "Gramm",
      Kilogram: "Kilogramm",
      Milliliter: "Milliliter",
      Deciliter: "Deziliter",
      Liter: "Liter",
    },
    ProjectBaseRole: {
      Doctor: "Fachmann/-frau im Gesundheitswesen",
      Manager: "Partner",
      Patient: "Kunde",
    },
    ProjectPermission: {
      AddDoctor:
        "Fügen Sie dem Projekt einen neuen Benutzer im Gesundheitswesen hinzu.",
      AddDoctorRelation:
        "Bauen Sie eine neue Beziehung zu einem Mitglied des medizinisches Fachpersonals im Projekt auf.",
      AddItem: "Fügen Sie dem Projekt ein Element hinzu",
      AddManager: "Fügen Sie dem Projekt einen neuen Benutzer hinzu.",
      AddManagerRelation:
        "Erstellen Sie eine neue Verbindung mit einem Nutzer ",
      AddPatient: "Fügen Sie dem Projekt einen neuen Benutzer als Kunde hinzu.",
      AddPatientRelation:
        "Erstellen Sie eine neue Verbindung mit einem Client-Benutzer.",
      AddSubordinateDoctor:
        "Fügen Sie dem Projekt einen neuen untergeordneten Benutzer des Gesundheitswesens hinzu.",
      AddSubordinateDoctorRelation:
        "Stellen Sie eine Beziehung zu einem untergeordneten Projektteilnehmer in der Rolle eines Gesundheitsexperten her.",
      AddSubordinateManager:
        "Fügen Sie dem Projekt einen neuen untergeordneten Benutzer hinzu.",
      AddSubordinateManagerRelation:
        "Stellen Sie eine Beziehung zu einem untergeordneten Projektteilnehmer her.",
      AddSubordinatePatient:
        "Fügen Sie dem Projekt einen neuen untergeordneten Kunden hinzu.",
      AddSubordinatePatientRelation:
        "Stellen Sie eine Beziehung zu einem untergeordneten Projektteilnehmer in der Kundenrolle her.",
      RemoveDoctor:
        "Entfernen eines Benutzers aus dem medizinischem Fachpersonal des Projektes.",
      RemoveItem: "Element aus dem Projekt entfernen",
      RemoveManager: "Entfernen eines Nutzers aus dem Projekt",
      RemovePatient: "Client-Benutzer aus dem Projekt entfernen",
      RemoveSubordinate: "Einen Slave-Benutzer aus dem Projekt entfernen",
      RemoveSubordinateRelation:
        "Entfernen einer untergeordneten Beziehung aus dem Projekt",
      SeeDoctorProgress:
        "Zeigen Sie den Fortschritt der Benutzer in der Rolle des medizinischen Fachpersonals an",
      SeeDoctors:
        "Benutzer mit der Rolle des medizinischen Fachpersonals anzeigen",
      SeeManagerProgress: "Sehen Sie sich den Fortschritt von Nutzern an",
      SeeManagers: "Nutzer anzeigen",
      SeeOwnProgress: "Meinen Fortschritt anzeigen",
      SeePatientProgress: "Zeigen Sie den Fortschritt von Kunden an.",
      SeePatients: "Zeigen Sie Kunden an.",
      SeeSubordinateProgress:
        "Zeigen Sie den Fortschritt der untergeordneten Teilnehmer an.",
      EditBaseData: "Projektstammdaten bearbeiten",
      EditResearch: "Projektforschungsplan bearbeiten",
      RemoveRelation: "Entfernen einer Verbindung aus dem Projekt",
      SeeItems: "Projektelemente anzeigen",
      SeeResearch: "Forschungsplan anzeigen",
    },
    ProjectTaskType: {
      CompleteWorkflow: "Führen Sie den Vorgang aus.",
      HaveDoctorCount:
        "Beziehen Sie medizinisches Fachpersonal in das Projekt ein.",
      HavePatientCount: "Beziehen Sie Kunden in das Projekt ein.",
      HaveSubordinateCount: "Registrieren Sie Benutzer im Projekt.",
    },
    TaskPriority: {
      High: "Hoch",
      Low: "Niedrig",
      Medium: "Mittel",
      Urgent: "Dringend",
    },
    TaskStatus: {
      Added: "Hinzugefügt",
      Completed: "Abgeschlossen",
      Failed: "Gescheitert",
      InProgress: "In Bearbeitung",
      Overdue: "Abgelaufen",
    },
    TaskType: {
      AddToDiary: "Tagebucheintrag",
      DoManual: "Manuell",
      EvaluateForm: "Fragebogenauswertung",
      TakeMedicine: "Medikamenteneinnahme",
      AcquireBadge: "Verdiene ein Lob",
      FillForm: "Ausfüllen des Fragebogens",
    },
    WorkflowStep: {
      NotificationTemplateStep: "Eine E-Mail senden",
      EndStep: "ENDE",
      NotificationStep: "Benachrichtigung senden",
      StartStep: "START",
      JoinControlInstance: "Verbindungspunkt",
      ForkControlInstance: "Verzweigungspunkt",
    },
    WorkflowTask: {
      DiaryTask: "Messen oder Tagebucheintrag",
      FillFormTask: "Ausfüllen eines Formulars oder Fragebogens",
      ManualTask: "Einzigartige Aufgabe",
      BadgeTask: "Verdiene ein Lob.",
      SubWorkflowTask: "Teilprozessausführung",
    },
    ProjectStatus: {
      Planned: "Geplant",
      Running: "Laufend",
      Finished: "Fertig",
      InProgress: "In Bearbeitung",
    },
    ProjectPrivacy: {
      DoubleBlind: "Doppelblindstudie",
      Blind: "Einfachblindstudie",
      None: "Offene Studie",
    },
    InvitationStatus: {
      Pending: "Ausstehend",
      Accepted: "Akzeptiert",
      Rejected: "Abgelehnt",
    },
    InvitationRelationshipType: {
      Project: "Kontaktaufnahme durch Projektarzt.",
      Chosen: "Kontaktaufnahme durch Wahlarzt.",
      GeneralPracticioner: "Kontaktaufnahme durch Hausarzt.",
    },
    DoctorPatientRelationshipType: {
      GeneralPracticioner: "Hausarzt",
      Chosen: "Arzt der Wahl",
      Project: "Projektarzt",
    },
    EmailPriority: {
      NonUrgent: "Nicht dringend",
      Normal: "Normal",
      Urgent: "Dringend",
    },
    UserInterfaceElement: {
      Questionnaire: 'Fragebogen',
      NotificationTemplate: 'Benachrichtigungsvorlage',
      Workflow: 'Arbeitsabläufe',
      UserTask: 'Benutzeraufgaben',
      Document: 'Dokumentbibliothek',
      Appointment: 'Termine',
      Meeting: 'Treffen',
      ServiceSearch: "Servicesuche",
      Covid19Pass: 'Covid-Pass',
      TravelGuide: 'Reiseführer (mobil)',
      HealthGuide: 'Gesundheitsleitfaden',
      //TODO: szótár
      Badge: "Badge",
      Communication: "Communication",
      CommunicationLog: "Communication log",
      Diagram: "Diagram",
      Form: "Form",
      MedicalDevicePrescription: "Medical device prescription",
      OperationLog: "Operation log",
      PatientDocument: "Patient document",
      PatientDiagram: "Patient diagram",
      PatientMedicalProfile: "Patient medical profile",
      PatientPersonalProfile: "Patient personal profile",
      VirtualResearchArea: "Virtual research area",
    },
    ModuleName: {
      BodyWeight: 'Testtömeg modul',
      Nutrition: 'Táplálkozás modul',
      Exercise: 'Testmozgás modul',
      Cardiology: 'Kardiológiai modul',
      Diabetology: 'Diabetológiai modul',
      Oncology: 'Onkológiai modul',
      Pulmonology: 'Pulmonológiai modul',
      Depression: 'Depresszió modul',
      Asthma: 'Asztma modul',
      Medicine: 'Gyógyszer modul',
      BodyTemperature: "Testhőmérséklet modul",
    },
    FunctionName: {
      BloodGlucose: "Vércukorszint napló",
      BloodPressure: "Vérnyomás napló",
      BodyWeight: "Testtömeg napló",
      Ecg: "EKG napló",
      Exercise: "Mozgás napló",
      Insulin: "Inzulin napló",
      Medicine: "Gyógyszer napló",
      Mood: "Kedély napló",
      Nutrition: "Táplálkozás napló",
      OxygenSaturation: "Véroxigénszint napló",
      Pulse: "Pulzus napló",
      RibcageMeter: "Bordakosármérés napló",
      Sleep: "Alvás napló",
    },
  },
};
