<template>
  <div v-if="documentVersion">
    <div
      id="gdpr-content"
      style="text-align: justify"
      v-html="$getLanguagedText(documentVersion.Text)"
    ></div>
    <div
      v-for="(check, index) in documentVersion.AcceptableStatements"
      :key="
        'document-' +
        Document.DocumentId +
        '-version-' +
        documentVersion.Version +
        '-check-' +
        index
      "
    >
      <b-check :disabled="Disabled" v-model="statementChecks[index]">
        <p>
          <em>
            {{ $getLanguagedText(check.Text) }}
            <span v-if="check.Required" class="text-danger">*</span>
          </em>
        </p>
      </b-check>
    </div>
    <div v-if="!Disabled">
      <b-button :disabled="checkAccept" @click="AcceptLegal"
        >Elfogadom</b-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "LegalView",
  props: {
    Document: Object,
    AccpetedIndexes: Array,
    Version: Number,
    Disabled: { type: Boolean, default: true },
  },
  data() {
    return {
      documentVersion: null,
      statementChecks: [],
    };
  },
  watch: {
    Document() {
      this.GetDocumentVersion();
    },
    Version() {
      this.GetDocumentVersion();
    },
    AccpetedIndexes() {
      this.SetAcceptedIndexes();
    },
  },
  computed: {
    checkAccept() {
      var requiredChecks = [];
      this.documentVersion.AcceptableStatements.forEach((ch, index) => {
        if (ch.Required) {
          requiredChecks.push(this.statementChecks[index]);
        }
      });
      return requiredChecks.some((x) => !x);
    },
  },
  methods: {
    AcceptLegal() {
      var acceptedStatement = [];
      this.statementChecks.forEach((ch, index) => {
        if (ch) {
          acceptedStatement.push(index);
        }
      });
      this.$emit("AcceptedStatements", acceptedStatement);
      this.statementChecks = [];
    },
    GetDocumentVersion() {
      this.documentVersion = this.Document.ContentVersions.find(
        (v) => v.Version == this.Version
      );
    },
    SetAcceptedIndexes() {
      if (!this.statementChecks) {
        if (this.documentVersion) {
          this.statementChecks = new Array(
            this.documentVersion.AcceptableStatements.length
          ).fill(false);
        } else {
          this.statementChecks = [];
        }
      }
      if (this.AccpetedIndexes) {
        this.AccpetedIndexes.forEach((index) => {
          this.statementChecks[index] = true;
        });
      }
    },
  },
  mounted() {
    this.GetDocumentVersion();
    this.SetAcceptedIndexes();
  },
};
</script>