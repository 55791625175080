<template>
  <fp-form-modal
    hide-footer
    id="exercise-input-modal"
    :title="$t('module.diaries.exercise.addNewPost')"
    v-model="showInputModal"
  >
    <template #content>
      <b-form :class="theme">
        <b-row cols="1" cols-sm="2">
          <div style="padding: 5px">
            <fp-select
              :items="filterExercises"
              v-model="selectedExercise"
              valueKey="key"
              textKey="key"
              :label="$t('module.diaries.exercise.exerciseType')"
              :searchable="true"
              @search="searchExercise"
            />
          </div>
          <div style="padding: 5px">
            <fp-select
              :label="$t('module.diaries.exercise.intensity')"
              :items="intensityList"
              valueKey="value"
              textKey="text"
              v-model="newEntry.ExerciseId"
            />
          </div>
        </b-row>

        <fp-date-time-picker
          :label="$t('module.diaries.exercise.startDate')"
          v-model="newEntry.MeasuredAt"
        />

        <fp-time-picker
          :label="$t('module.diaries.exercise.duration')"
          v-model="newEntry.Duration"
        />

        <fp-input
          type="number"
          :state="!validation.hasError('newEntry.CaloriesBurned')"
          :error="validation.firstError('newEntry.CaloriesBurned')"
          :label="$t('module.diaries.exercise.burnedCalories')"
          :description="$t('module.diaries.exercise.burnedCaloriesDescription')"
          :placeholder="$t('module.diaries.exercise.burnedCaloriesPlaceholder')"
          v-model="newEntry.CaloriesBurned"
        />

        <fp-input
          type="number"
          :state="!validation.hasError('newEntry.Distance')"
          :error="validation.firstError('newEntry.Distance')"
          :label="$t('module.diaries.exercise.distance')"
          :description="$t('module.diaries.exercise.distanceDescription')"
          :placeholder="$t('module.diaries.exercise.distancePlaceholder')"
          v-model="newEntry.Distance"
        />

        <fp-input
          type="number"
          :state="!validation.hasError('newEntry.StepCount')"
          :error="validation.firstError('newEntry.StepCount')"
          :label="$t('module.diaries.exercise.steps')"
          :description="$t('module.diaries.exercise.stepsDescription')"
          :placeholder="$t('module.diaries.exercise.stepsPlaceholder')"
          v-model="newEntry.StepCount"
        />

        <fp-input
          type="number"
          :state="!validation.hasError('newEntry.FloorCount')"
          :error="validation.firstError('newEntry.FloorCount')"
          :label="$t('module.diaries.exercise.floors')"
          :description="$t('module.diaries.exercise.floorsDescription')"
          :placeholder="$t('module.diaries.exercise.floorsPlaceholder')"
          v-model="newEntry.FloorCount"
        />

        <div style="text-align: right">
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>

<script>
import moment from "moment";
import { DiaryLogic } from "../../../../Logic/Backend/diary";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";

export default {
  name: "ExerciseDiaryInput",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    TargetId: Number,
  },
  data() {
    return {
      modalData: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
      },
      newEntry: {
        ExerciseId: null,
        Duration: null,
        CaloriesBurned: null,
        Distance: null,
        StepCount: null,
        FloorCount: null,
        SourceName: "UserInput",
        MeasuredAt: moment().format(),
      },
      exercises: {},
      filterExercises: null,
      exersiseTypes: [],
      selectedExercise: null,
      isValidInputs: {},
      showInputModal: null,
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.CaloriesBurned": function (value) {
      return this.$validator.value(value).greaterThan(0);
    },
    "newEntry.Distance": function (value) {
      return this.$validator.value(value).greaterThan(0);
    },
    "newEntry.StepCount": function (value) {
      return this.$validator.value(value).greaterThan(0);
    },
    "newEntry.FloorCount": function (value) {
      return this.$validator.value(value).greaterThan(0);
    },
  },
  computed: {
    intensityList() {
      if (this.selectedExercise) {
        const intensitiesByExercise = this.filterExercises.find(
          (e) => e.key == this.selectedExercise
        );
        if (intensitiesByExercise) {
          const intensityValueKey = intensitiesByExercise.value.map((e) => {
            return {
              text: this.$getLanguagedText(e.Intensity),
              value: e.ExerciseId,
            };
          });
          return intensityValueKey;
        } else {
          return [
            {
              value: null,
              text: this.$t("base.noData"),
            },
          ];
        }
      } else {
        return [
          {
            value: null,
            text: this.$t("module.diaries.exercise.typeDefault"),
          },
        ];
      }
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const response = await this.setDiaryByUser([this.newEntry]);
        if (!response.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(response.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async setDiaryByUser(data) {
      if (this.TargetId) {
        return await DiaryLogic.addDiaryEntryToTarget(
          "exercise",
          this.TargetId,
          data
        );
      } else {
        return await DiaryLogic.addDiaryEntry("exercise", data);
      }
    },
    searchExercise(input) {
      if (input && input.length > 2) {
        this.filterExercises = this.$filterList(input, this.exercises, ["key"]);
      } else if (input) {
        this.filterExercises = [];
      } else {
        this.filterExercises = this.exercises;
      }
    },
    async getList() {
      const result = await BaseDataLogic.getBaseData("exercise");
      if (!result.Code) {
        const tempMap = this.$groupByMostEfficient(
          result,
          (element) => element.Name[this.$i18n.locale]
        );
        const tempArray = [];
        tempMap.forEach((value, key) =>
          tempArray.push({ key: key, value: value })
        );
        this.exercises = this.filterExercises = tempArray.sort((a, b) =>
          a.key.localeCompare(b.key)
        );
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.basic.errorGetExerciseTypeList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //TODO: kiemelés, modal megnyitás után fusson le
  async mounted() {
    await this.getList();
  },
};
</script>
<style></style>
