<template>
  <div style="display: flex; margin-bottom: 10px">
    <div
      v-if="
        $productSettings[managerType].ManageUser.CanCreateParticipant &&
        userProjectPermissions &&
        userProjectPermissions.includes(addPatientPermission)
      "
    >
      <fp-svg-icon
        v-b-tooltip.hover="'Új felhasználó regisztrációja és beléptetése a projektbe'"
        @click="showRegistration = true"
        style="cursor: pointer"
        icon="register-user"
      />
      <fp-create-user-to-project
        v-model="showRegistration"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
        :project="project"
        :projectBaseRoles="
          $productSettings[managerType].ParticipantList.ParticipantsBaseRoles
        "
        :userRoles="$productSettings[managerType].ParticipantList.UserRoles"
      />
    </div>
    <div
      v-if="
        $productSettings[managerType].ManageUser.CanAddParticipantToProject &&
        userProjectPermissions &&
        userProjectPermissions.includes(addPatientRelationPermission)
      "
    >
      <fp-svg-icon
        v-b-tooltip.hover="'Regisztrált felhasználó beléptetése a projektbe'"
        @click="showAddToProjectModal = true"
        style="cursor: pointer"
        icon="add-user-from-list"
      />
      <fp-create-project-participant
        v-model="showAddToProjectModal"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
        :project="project"
        :baseRoles="
          $productSettings[managerType].ParticipantList.ParticipantsBaseRoles
        "
      />
    </div>
    <div
      v-if="
        $productSettings[managerType].ManageUser.CanCreateProjectRelation &&
        userProjectPermissions &&
        userProjectPermissions.includes(addSubordinatePatientPermission)
      "
    >
      <fp-svg-icon
        v-b-tooltip.hover="
          'Regisztrált, projekten szereplő felhasználó kapcsolat felvétele'
        "
        @click="showCreateRelationModal = true"
        style="cursor: pointer"
        icon="add-user-relation"
      />
      <fp-create-project-relation
        v-model="showCreateRelationModal"
        :baseRoles="
          $productSettings[managerType].ParticipantList.ParticipantsBaseRoles
        "
        :project="project"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "NewParticipantToList",
  props: {
    project: Object,
    managerType: String,
  },
  data() {
    return {
      showCreateRelationModal: false,
      showAddToProjectModal: false,
      showRegistration: false,
      addPatientPermission: "AddPatient",
      addSubordinatePatientPermission: "AddSubordinatePatient",
      addPatientRelationPermission: "AddPatientRelation",
    };
  },
  computed: {
    userProjectPermissions() {
      if (this.$store.state.loggedUser) {
        return this.$store.state.loggedUser.ProjectPermissions;
      }
      return null;
    },
  },
};
</script>
