export default{
    BmiCategory:{
        Unknown: 'Nem ismert', 
        ExtremelyUnderweight: 'Nagyon sovány', 
        Underweight: 'Sovány', 
        Normal: 'Normál', 
        Overweight: 'Túlsúlyos', 
        Obese: 'Kövér', 
        ExtremelyObese: 'Nagyon kövér'
    }
}