<template>
  <div class="eh-static-pages eh-page-about">
    <div>
      <div>
        <p>
          A <em>Fókusz Programot</em> azért valósítottuk meg, hogy hasznos
          segítség legyen az egészségmegőrzés és betegségkezelés területén.
        </p>
        <p>
          A <em>Fókusz Programmal</em> egy nagy álmunk teljesült. Szakembereink
          több mint 10 éves egészségügyben eltöltött tapasztalatat és több éves
          fejlesztése eredménye vált valóra.
        </p>
        <p>
          Büszkén jelethetjük ki, hogy <b>megvan</b> és <b>itt van</b>, hiszen
          ezt olvasod most és reméljük funkcióit nem csak kipróbálod, de akár
          napi szinten használni is fogod.
        </p>
      </div>

      <hr />

      <h3>Csatlakoztatott eszközök</h3>
      <div>
        <div>
          <p>
            Az egészség megőrzéséhez és a betegség követéséhez elengedhetetlen
            ma már a megfelelő mérőeszköz használata. A mérőeszközzel szemben
            támasztott elvárások a pontosság mellett kiegészültek a kor
            vívmányaival, például azzal, hogy a mért adatot eltárolja és
            megjelenítse applikációban, vagy weboldalon. Sok gyártó rendelkezik
            ilyen megoldással, azonban kevés az, a megoldás, mely több
            konkurrens mérőműszer gyártó mérőeszközének adatát egy helyen
            jeleníti meg.
          </p>
          <p>
            Mi a
            <span style="color: rgb(237, 125, 49);"> Fókusz Program </span
            >megálmodói és létrehozói arra törekszünk, hogy minél több
            mérőeszköz elérhető legyen az alkalmazásunkban és ne csak egy gyártó
            eszközét ajánljuk. Ezért nem kell lecserélned kedvenc
            mérőeszközödet, hanem csak kiválasztani a listából, melyiket kívánod
            használni.
          </p>
          <p>
            Ezzel a módszerrel kívánjuk a <em>Fókusz Programot</em> gyártó
            független gyűjtő platformként üzemeltetni. Nálunk választhatsz, hogy
            vérnyomásodat, vércukrodat, pulzusodat, EKG-dat, oxigénszaturációd
            melyik mérőműszer segítségével kívánod mérni. Ne feledd több, mint
            40 eszköz áll rendelkezésedre. A méréseid eredményei azonnal
            megjelenjenek a Fókusz Program webes felületén és az applikációban
            lévő egészségnaplókban.
          </p>
          <div>
            <p>Nézd meg, hogy milyen eszközök közül választhatsz és válassz!</p>
          </div>
          <br />
        </div>
        <div class="row">
          <div class="col">
            <ul>
              Órák
              <li>Amazfit Bip [S, U] (Apple Health / Google Fit)</li>
              <li>Apple Watch (Apple Health)</li>
              <li>Fossil Sport (Google Fit)</li>
              <li>Garmin Approach Series (Apple Health)</li>
              <li>Garmin D2 Series (Apple Health)</li>
              <li>Garmin Descent Series (Apple Health)</li>
              <li>Garmin Enduro (Apple Health)</li>
              <li>Garmin Epix (Apple Health)</li>
              <li>Garmin Fenix Series (Apple Health)</li>
              <li>Garmin Forerunner Series (Apple Health)</li>
              <li>Garmin Instinct Series (Apple Health)</li>
              <li>Garmin Legacy Series (Apple Health)</li>
              <li>Garmin Lily (Apple Health)</li>
              <li>Garmin MARQ Collection (Apple Health)</li>
              <li>Garmin Quatix Series (Apple Health)</li>
              <li>Garmin Swim 2 (Apple Health)</li>
              <li>Garmin Tactix Series (Apple Health)</li>
              <li>Garmin Venu Series (Apple Health)</li>
              <li>Garmin Vivoactive Series (Apple Health)</li>
              <li>Garmin Vivofit Series (Apple Health)</li>
              <li>Garmin Vivomove Series (Apple Health)</li>
              <li>Garmin Vivosmart Series (Apple Health)</li>
              <li>Garmin Vivosport Series (Apple Health)</li>
              <li>Honor Magic Watch 2 (Google Fit)</li>
              <li>Mobvoi TicWatch Series (Apple Health / Google Fit)</li>
              <li>Omron HeartGuide Blood Pressure Monitor (Apple Health / Google Fit)</li>
              <li>Polar A Series (Apple Health / Google Fit)</li>
              <li>Polar Grit Series (Apple Health / Google Fit)</li>
              <li>Polar Ignite Series (Apple Health / Google Fit)</li>
              <li>Polar M Series (Apple Health / Google Fit)</li>
              <li>Polar Pacer Pro Series (Apple Health / Google Fit)</li>
              <li>Polar Pacer Series (Apple Health / Google Fit)</li>
              <li>Polar Unite Series (Apple Health / Google Fit)</li>
              <li>Polar Vantage Series (Apple Health / Google Fit)</li>
              <li>Samsung Galaxy Watch 3 (Apple Health / Google Fit)</li>
              <li>Samsung Galaxy Watch 4 (Apple Health / Google Fit)</li>
              <li>Withings Move (Apple Health / Google Fit)</li>
              <li>Withings ScanWatch (Apple Health / Google Fit)</li>
            </ul>

            
          </div>

          <div class="col">
            <ul>
              Mérlegek
              <li>
                Arboleaf Body Composition Smart Scale (Apple Health / Google
                Fit)
              </li>
              <li>Eufy Smart Scale series (Apple Health / Google Fit)</li>
              <li>FitIndex Bluetooth Scale (Apple Health / Google Fit)</li>
              <li>Garmin Index (Apple Health)</li>
              <li>Medisana BS connect series (Apple Health / Google Fit)</li>
              <li>Polar Balance (Google Fit)</li>
              <li>Omron Viva (Apple Health / Google Fit)</li>
              <li>Quardio QardioBase 2 (Apple Health / Google Fit)</li>
              <li>Withings Body Cardio (Apple Health / Google Fit)</li>
              <li>Withings Body+ (Apple Health / Google Fit)</li>
              <li>Withings WS-50 (Apple Health / Google Fit)</li>
            </ul>

            <ul>
              Karkötők
              <li>Huawei Band 2 Pro (Google Fit)</li>
              <li>Huawei Band 3 Pro (Google Fit)</li>
              <li>Huawei/Honor Band 4 (Apple Health / Google Fit)</li>
              <li>Huawei/Honor Band 5 (Apple Health / Google Fit)</li>
              <li>Huawei/Honor Band 6 (Apple Health / Google Fit)</li>
              <li>Nike Fuelband (Apple Health)</li>
              <li>Withings Pulse HR (Apple Health)</li>
              <li>Xiaomi Mi Band 3 (Apple Health / Google Fit)</li>
              <li>Xiaomi Mi Band 4 (Apple Health / Google Fit)</li>
              <li>Xiaomi Mi Band 5 (Apple Health / Google Fit)</li>
              <li>Xiaomi Mi Band 6 (Apple Health / Google Fit)</li>
            </ul>

            <ul>
              Gyűrűk
              <li>Motiv Smart Ring (Apple Health / Google Fit)</li>
              <li>Oura Ring (Apple Health / Google Fit)</li>
            </ul>

            <ul>
              Mellpántok
              <li>Garmin HRM-Dual (Apple Health)</li>
              <li>Garmin HRM-Pro (Apple Health)</li>
              <li>Polar H10 (Apple Health / Google Fit)</li>
              <li>Polar Verity Sense (Apple Health / Google Fit)</li>
              <li>Wahoo Tickr X (Apple Health)</li>
            </ul>

            <ul>
              Hőmérők
              <li>Omron Eco Temo Intelli IT (Apple Health / Google Fit)</li>
            </ul>
          </div>

          <div class="col">
            <ul>
              Vérnyomásmérők
              <li>iHealth Clear (Apple Health)</li>
              <li>iHealth Ease (Apple Health)</li>
              <li>iHealth Sense (Apple Health)</li>
              <li>iHealth Track (Apple Health)</li>
              <li>iHealth View (Apple Health)</li>
              <li>Medisana BPW 300 connect (Apple Health / Google Fit)</li>
              <li>Medisana BU 530 connect (Apple Health / Google Fit)</li>
              <li>Medisana BU 540 connect (Apple Health / Google Fit)</li>
              <li>Medisana BU 570 connect (Apple Health / Google Fit)</li>
              <li>Omron 10 Series Wireless (Apple Health / Google Fit)</li>
              <li>Omron 5 Series Wireless (Apple Health / Google Fit)</li>
              <li>Omron 7 Series Wireless (Apple Health / Google Fit)</li>
              <li>Omron Complete Wireless (Apple Health / Google Fit)</li>
              <li>Omron Evolv Wireless (Apple Health / Google Fit)</li>
              <li>Omron Gold Wireless (Apple Health / Google Fit)</li>
              <li>Omron Platinum Wireless (Apple Health / Google Fit)</li>
              <li>Omron Silver Wireless (Apple Health / Google Fit)</li>
              <li>QardioArm (Apple Health / Google Fit)</li>
              <li>Withings BPM Connect (Apple Health / Google Fit)</li>
              <li>Withings BPM Core (Apple Health / Google Fit)</li>
            </ul>

            <ul>
              Vércukormérők
              <li>Medisana MediTouch 2 connect (Apple Health / Google Fit)</li>
              <li>One Drop Chrome (Apple Health)</li>
            </ul>

            <ul>
              Véroxigénszint mérők
              <li>iHealth Air (Apple Health)</li>
              <li>Jumper Wireless (Apple Health)</li>
              <li>Masimo MightySat (Apple Health)</li>
              <li>Medisana PM 100 (Apple Health / Google Fit)</li>
              <li>Omron P300 Intelli IT (Apple Health / Google Fit)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ABoutFP",
};
</script>
