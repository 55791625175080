<template>
  <div>
    <button
      style="margin: 20px 10px"
      class="eh-action-button"
      @click="showMedicinePlanInputModal = true"
    >
      {{ $t("module.plans.medicine.addNewPlan") }}
    </button>
    <fp-table-list
      :hasPagination="true"
      :hasPerPageCounter="true"
      :items="medicinePlan"
      :fields="medicinePlanFields"
      sortBy="StartDate"
      :sortDesc="true"
    >
      <template #TimingType="row">
        <div v-if="row.item.DaysOfWeek">
          {{ $t(row.item.TimingType.Text) }}
          <br />
          {{ convertDayListToString(row.item.DaysOfWeek) }}
        </div>
        <div v-else>
          {{ $t(row.item.TimingType.Text) }}
        </div>
      </template>
      <template #Delete="row">
        <b-icon class="h3" icon="trash" @click="deleteDiary(row.item)" />
      </template>
    </fp-table-list>
    <MedicinePlanInput
      @change-medicine-plan-list="changeMedicinePlanList"
      v-model="showMedicinePlanInputModal"
      :TargetId="TargetId"
    />
  </div>
</template>
<script>
import moment from "moment";
import MedicinePlanInput from "./Components/Inputs/MedicinePlanInput.vue";
import { MedicinePlanLogic } from "../../Logic/Backend/medicine-plan";

export default {
  name: "MedicinePlan",
  components: {
    MedicinePlanInput,
  },
  props: {
    TargetId: Number,
  },
  data() {
    return {
      medicineTimingType: this.$enums.MedicineTimingType,
      daysOfWeek: this.$enums.DaysOfWeek,
      medicinePlan: null,
      medicinePlanFields: [
        { key: "Icon", label: "" },
        {
          key: "MedicineName",
          label: this.$t("module.plans.medicine.medicineName"),
        },
        {
          key: "TimingType",
          label: this.$t("module.plans.medicine.timingType"),
        },
        {
          key: "Morning",
          label: this.$t("module.plans.medicine.morning"),
        },
        {
          key: "Noon",
          label: this.$t("module.plans.medicine.noon"),
        },
        {
          key: "Evening",
          label: this.$t("module.plans.medicine.evening"),
        },
        {
          key: "Night",
          label: this.$t("module.plans.medicine.night"),
        },
        {
          key: "StartDate",
          label: this.$t("module.plans.medicine.startDate"),
          sortable: true,
        },
        {
          key: "EndDate",
          label: this.$t("module.plans.medicine.endDate"),
        },
        { key: "Delete", label: "" },
      ],
      showMedicinePlanInputModal: false,
    };
  },
  watch: {
    async TargetId() {
      await this.getMedicinePlanList();
    },
  },
  methods: {
    convertDayListToString(daylist) {
      const temp = [];
      daylist.forEach((day) => {
        temp.push(this.$t(this.daysOfWeek[day].Text));
      });
      return temp.join(", ");
    },
    async changeMedicinePlanList(isChange) {
      if (isChange) {
        await this.getMedicinePlanList();
      }
    },
    async getMedicinePlanList() {
      if (this.TargetId) {
        await this.getTargetUsersMedicinePlanList();
      } else {
        await this.getOwnMedicinePlanList();
      }
    },
    async getOwnMedicinePlanList() {
      const result = await MedicinePlanLogic.getMedicinePlanList();
      if (!result.Code) {
        this.medicinePlan = this.convertMedicinePlanList(result);
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.medicinePlan.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getTargetUsersMedicinePlanList() {
      const result = await MedicinePlanLogic.getMedicinePlanListByUser(
        this.TargetId
      );
      if (!result.Code) {
        this.medicinePlan = this.convertMedicinePlanList(result);
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.medicinePlan.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    convertMedicinePlanList(data) {
      //console.log(data)
      var temp = [];
      data.forEach((x) => {
        var item = {
          PlanId: x.PlanId,
          GoalId: x.GoalId,
          MedicineName: this.$getLanguagedText(x.MedicineName),
          Morning: this.getQuantityByPartOfDay(x, "Morning"), //array.find
          Noon: this.getQuantityByPartOfDay(x, "Noon"),
          Evening: this.getQuantityByPartOfDay(x, "Evening"),
          Night: this.getQuantityByPartOfDay(x, "Night"),
          TimingType: this.medicineTimingType[x.Timing.Recurrence],
          DaysOfWeek: x.Timing.DaysOfWeek
            ? x.Timing.DaysOfWeek /* .forEach(
                (day) => (day = this.$t(this.daysOfWeek[day].Text))
              ) */
            : null,
          EndDate: moment(x.EndDate).format("YYYY-MM-DD"),
          StartDate: moment(x.StartDate).format("YYYY-MM-DD"),
        };
        temp.push(item);
      });
      return temp;
    },
    getQuantityByPartOfDay(item, partOfDay) {
      var time = item.Timing.Times.find((x) => x.PartOfDay == partOfDay);
      if (time) {
        return time.Quantity;
      }
      return 0;
    },
    async deleteMedicinePlan(plan) {
      console.log(plan);
      if (
        confirm(
          "Biztosan törölni szeretné a " +
            plan.MedicineName +
            " gyógyszerhez tartozó szedési tervét?"
        )
      ) {
        const deleteResponse = await this.deleteMedicinePlanByUser(plan.PlanId);
        if (!deleteResponse.Code) {
          this.$bvToast.toast("Sikeresen törölte a gyógyszerszedési tervet!", {
            title: this.$t("requestResponse.medicinePlan.successDelete"),
            variant: "info",
            solid: true,
            AutoHideDelay: 10000,
          });
          await this.getMedicinePlanList();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            title: this.$t("requestResponse.medicinePlan.errorDelete"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 50000,
          });
        }
      }
    },
    async deleteMedicinePlanByUser(planId) {
      if (this.TargetId) {
        return await MedicinePlanLogic.deleteMedicinePlanByTarget(
          planId,
          this.TargetId
        );
      } else {
        return await MedicinePlanLogic.deleteMedicinePlan(planId);
      }
    },
  },
  async mounted() {
    await this.getMedicinePlanList();
    //this.$emit("title-changed", this.$t("module.plans.medicine.title"));
  },
};
</script>
