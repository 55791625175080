<template>
  <div>
    <fp-table-list
      v-if="Visible"
      :items="accpetedLegalList"
      :fields="acceptedLegalListFields"
    >
      <template #Name="row">
        <div style="display: flex">
          <div style="padding-right: 15px">
            <div
              v-for="(e, index) in row.item.Document.SupportedLanguages"
              :key="index"
            >
              {{ e }}
            </div>
          </div>
          <div>
            <b>{{ $getLanguagedText(row.item.Document.Title) }}</b>
            <br />
            {{ $getLanguagedText(row.item.Document.Description) }}
            <br />
            <i>Verzió: {{ row.item.Document.LastVersion }}</i>
          </div>
        </div>
      </template>
      <template #Valid="row">
        {{ row.item.Document.Scope }}
        <br />
        {{ $convertDateToString(row.item.Document.ValidFrom, "YYYY-MM-DD") }} -
        {{ $convertDateToString(row.item.Document.ValidTo, "YYYY-MM-DD") }}
      </template>
      <template #Accepted="row">
        {{ $convertDateToString(row.item.Statement.AcceptedAt, "YYYY-MM-DD") }}
        <br />
        {{ row.item.Statement.FromDevice }}
        <br />
        <i>Verzió: {{ row.item.Statement.Version }}</i>
      </template>
      <template #Operations="row">
        <!-- Megtekintés -->
        <b-iconstack
          @click="$set(row.item, '_showLegalDocument', true)"
          font-scale="2"
          style="background: var(--info); border-radius: 7px; cursor: pointer"
        >
          <b-icon stacked icon="eye-fill" style="color: white" scale="0.65" />
        </b-iconstack>
        <fp-form-modal v-model="row.item._showLegalDocument" size="xl">
          <template #content>
            <legal-view
              :Document="row.item.Document"
              :AccpetedIndexes="row.item.Statement.AcceptedStatementIndexes"
              :Version="row.item.Statement.Version"
            />
          </template>
        </fp-form-modal>
        <!-- Visszavonás -->
        <b-iconstack v-if="!TargetId && OwnerId && row.item.Document.ManualRevoke"
          @click="CancelLegalStatement(row.item.Statement.AcceptedStatementId)"
          font-scale="2"
          style="background: var(--danger); border-radius: 7px; cursor: pointer"
        >
          <b-icon stacked icon="x" style="color: white" scale="0.95" />
        </b-iconstack>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { LegalLogic } from "../../Logic/Backend/legal";
import LegalView from "./LegalView.vue";
export default {
  components: { LegalView },
  // TODO: projekt szűrés?
  name: "AcceptedLegalList",
  props: {
    TargetId: Number,
    OwnerId: Number,
    Visible: Boolean,
  },
  data() {
    return {
      accpetedLegalList: null,
      acceptedLegalListFields: [
        { key: "Name", label: "Nyilatkozat" },
        { key: "Valid", label: "Érvényes" },
        { key: "Document.Type", label: "Típus" },
        { key: "Accepted", label: "Elfogadva" },
        { key: "Operations", label: "" },
      ],
    };
  },
  watch: {
    async TargetId() {
      await this.GetLegalList();
    },
    async OwnerId() {
      await this.GetLegalList();
    },
    async Visible(input) {
      if (input) {
        await this.GetLegalList();
      }
    },
  },
  methods: {
    async CancelLegalStatement(statementId) {
      if (
        confirm("Biztosan vissza vonja a korábban elfogadott nyilatkozatot?")
      ) {
        var result = await LegalLogic.deleteStatementLegal(null, statementId);
        if (!result.Code || result.Code == 0) {
          await this.GetLegalList();
          //TODO: sikeres
        } else {
          //TODO: hiba
        }
      }
    },
    SetLegalList(statement) {
      return statement.map((s) => {
        const doc = this.legalDocumentList.find(
          (d) => d.DocumentId == s.LegalDocumentId
        );
        return {
          Document: doc,
          Statement: s,
        };
      });
    },
    async GetAcceptedLegalByUser() {
      this.accpetedLegalList = null;
      var response = await LegalLogic.getAcceptedStatementLegalByUser(
        { OwnerId: this.TargetId },
        this.TargetId
      );
      if (!response.Code) {
        this.accpetedLegalList = this.SetLegalList(response);
      } else {
        this.accpetedLegalList = [];
      }
    },
    async GetAcceptedLegal() {
      this.accpetedLegalList = null;
      var response = await LegalLogic.getAcceptedStatementLegal({
        OwnerId: this.OwnerId,
      });
      if (!response.Code) {
        this.accpetedLegalList = this.SetLegalList(response);
      } else {
        this.accpetedLegalList = [];
      }
    },
    async GetLegalDocuments() {
      var response = await LegalLogic.getLegal();
      if (!response.Code) {
        this.legalDocumentList = response;
      } else {
        this.legalDocumentList = [];
      }
    },
    async GetLegalList() {
      await this.GetLegalDocuments();
      if (this.TargetId != null || this.TargetId != undefined) {
        await this.GetAcceptedLegalByUser();
      } else {
        await this.GetAcceptedLegal();
      }
    },
  },
  async mounted() {
    if (this.Visible) {
      await this.GetLegalList();
    }
  },
};
</script>