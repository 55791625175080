<template>
  <fp-form-modal
    v-model="showNewParticipantRelationship"
    @hidden="clearNewRelationship"
    :title="
      $t('partner.participantList.participantList.addNewParticipantToProject')
    "
  >
    <template #content>
      <fp-select
        style="margin: -10px 0 15px"
        :label="$t('partner.participantList.newParticipantForm.username')"
        :searchable="true"
        v-model="newRelationship.UserId"
        valueKey="UserId"
        textKey="Username"
        :items="userList"
        @search="searchUserByName"
      />
      <!-- 
        lehet beteget is hozzáadni?
        :items="
          projectRoleList.filter(
            (role) =>
              !(
                role.BaseRoles.length == 1 && role.BaseRoles.includes('Patient')
              )
          )
        " -->
      <fp-select
        :label="$t('partner.participantList.newParticipantForm.projectRole')"
        :items="projectRoleList"
        valueKey="RoleName"
        textKey="RoleName"
        v-model="newRelationship.RoleName"
      />
      <fp-select
        v-if="superiorList"
        type="multi"
        label="Felettesek"
        :searchable="true"
        :items="filteredParticipantList"
        valueKey="UserId"
        textKey="Username"
        v-model="newRelationship.SuperiorIds"
        @search="filterParticipants"
      >
      </fp-select>
      <fp-input
        :label="$t('partner.participantList.newParticipantForm.note')"
        v-model="newRelationship.Remark"
      />
      <b-button @click="addParticipant()">{{ $t("base.basic.add") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "@/Logic/Backend/user";

export default {
  name: "NewParticipantForm",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    projectRoleList: Array,
    superiorList: Array,
  },
  data() {
    return {
      showNewParticipantRelationship: this.visible,
      emptyNewRelationship: {
        UserId: null,
        RoleName: null,
        SuperiorIds: [],
        TagIds: [],
        Parameters: {},
        Remark: "",
      },
      newRelationship: {
        UserId: null,
        RoleName: null,
        SuperiorIds: [],
        TagIds: [],
        Parameters: {},
        Remark: "",
      },
      selectedUser: null,
      userList: null,
      selectedRoleName: null,
      filteredParticipantList: null,
    };
  },
  watch: {
    visible(value) {
      this.showNewParticipantRelationship = value;
    },
    showNewParticipantRelationship(input) {
      this.$emit("change", input);
    },
    superiorList(input) {
      this.filteredParticipantList = input;
    },
  },
  methods: {
    filterParticipants(input) {
      this.filteredParticipantList = this.$filterList(
        input,
        this.superiorList,
        ["Username"]
      );
    },
    //új résztvevő felvétele a projektbe
    async addParticipant() {
      console.log(this.newRelationship);
      this.$emit("addParticipant", this.newRelationship);

      //új beteg kapcsolat modal megjelenítő változó false-ra állítása
      this.showNewParticipantRelationship = false;
      //új kapcsolat tároló ürítése
      this.newRelationship = JSON.parse(
        JSON.stringify(this.emptyNewRelationship)
      );
      //felhasználó lista ürítése
      this.userList = [];
    },
    //felhasználó lista szűrése felhasználónév szerint
    async searchUserByName(e) {
      //a kereső input több mint 2 karakterből áll?
      if (e && e.length >= 3) {
        //igen, felhasználó lista kérés input alapján
        await this.getUserList({
          "Username-contains": e,
        });
      } else {
        //nem, első 10 felhasználó lista kérés
        await this.getUserList({
          "-limit": 10,
        });
      }
    },
    //felhasználó lista kérés
    async getUserList(params) {
      //kérés indítás megadott params alapján
      const userListResponse = await UserLogic.getUser(params);
      //sikeres kérés?
      if (!userListResponse.Code) {
        //igen, kapott lista tárolása
        this.userList = userListResponse;
      } else {
        this.$bvToast.toast(userListResponse.Message, {
          title: this.$t("base.error"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //beteg hozzáadása űrlap ürítése
    clearNewRelationship() {
      this.newRelationship = JSON.parse(
        JSON.stringify(this.emptyNewRelationship)
      );
    },
  },
  async mounted() {
    //felhasználó lista kérés
    await this.getUserList({ "-limit": 10 });
  },
};
</script>
