<template>
  <div>
    <b-button v-if="isCreated && !isEditable" @click="isEditable = true"
      >Szerkesztés
    </b-button>
    <b-button v-if="isCreated && isEditable" @click="setEdit">Mentés </b-button>
    <b-button v-if="isCreated && isEditable" @click="cancelEdit"
      >Mégsem
    </b-button>
    <div>
      <div style="text-align: center; margin-top: 20px" class="h3">
        Felületi elem beállítások
      </div>
      <fp-table-list
        v-if="enabledUiElementsRow && enabledUiElementsValues"
        :bordered="true"
        :items="enabledUiElementsRow"
        :fields="enabledUiElementsFields"
      >
        <template #head()="scope">
          <div class="text-nowrap">
            {{ $t("enums.UserInterfaceElement." + scope.field.key) }}
          </div>
        </template>
        <template
          v-for="element in userInterfaceElementKeys"
          v-slot:[element]="row"
        >
          <div :key="element" style="text-align: center">
            <b-check
              size="lg"
              :checked="
                enabledUiElementsValues[row.item.type]
                  ? enabledUiElementsValues[row.item.type].includes(element)
                  : row.item[element]
              "
              :disabled="!isEditable && isCreated"
              @change="
                changeEnabledUiElementsValue(row.item.type, element, $event)
              "
            />
          </div>
        </template>
      </fp-table-list>
    </div>
    <div>
      <div style="text-align: center; margin-top: 20px" class="h3">
        Modul beállítások
      </div>
      <fp-table-list
        v-if="enabledModulesRow && enabledModulesValues"
        :bordered="true"
        :items="enabledModulesRow"
        :fields="enabledModulesFields"
      >
        <template #head()="scope">
          <div class="text-nowrap">
            {{ $t("enums.ModuleName." + scope.field.key) }}
          </div>
        </template>
        <template v-for="element in moduleNameKeys" v-slot:[element]="row">
          <div :key="element" style="text-align: center">
            <b-check
              size="lg"
              :checked="
                enabledModulesValues[row.item.type]
                  ? enabledModulesValues[row.item.type].includes(element)
                  : row.item[element]
              "
              :disabled="!isEditable && isCreated"
              @change="
                changeEnabledModulesValue(row.item.type, element, $event)
              "
            />
          </div>
        </template>
      </fp-table-list>
    </div>
    <div>
      <div style="text-align: center; margin-top: 20px" class="h3">
        Modul napló beállítások
      </div>
      <fp-table-list
        v-if="enabledFunctionsRow && enabledFunctionsValues"
        :bordered="true"
        :items="enabledFunctionsRow"
        :fields="enabledFunctionsFields"
      >
        <template #head()="scope">
          <div class="text-nowrap">
            {{ $t("enums.FunctionName." + scope.field.key) }}
          </div>
        </template>
        <template v-for="element in functionNameKeys" v-slot:[element]="row">
          <div :key="element" style="text-align: center">
            <b-check
              size="lg"
              :checked="
                enabledFunctionsValues[row.item.type]
                  ? enabledFunctionsValues[row.item.type].includes(element)
                  : row.item[element]
              "
              :disabled="!isEditable && isCreated"
              @change="
                changeEnabledFunctionsValue(row.item.type, element, $event)
              "
            />
          </div>
        </template>
      </fp-table-list>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/Logic/Backend/project";
export default {
  name: "EnabledElements",
  data() {
    return {
      //engedélyeztt elemek táblázat formátumba
      enabledUiElementsRow: null,
      enabledModulesRow: null,
      enabledFunctionsRow: null,
      //enegdélyezhető elemek enum listái
      userInterfaceElementKeys: Object.keys(this.$enums.UserInterfaceElement),
      moduleNameKeys: Object.keys(this.$enums.ModuleName),
      functionNameKeys: Object.keys(this.$enums.FunctionName),
      //engelyézett elemek táblázathoz mező beállítások
      enabledUiElementsFields: [
        {
          key: "type",
          label: "Típus",
          stickyColumn: true,
          isRowHeader: true,
          variant: "light",
        },
        ...Object.keys(this.$enums.UserInterfaceElement),
      ],
      enabledModulesFields: [
        {
          key: "type",
          label: "Típus",
          stickyColumn: true,
          isRowHeader: true,
          variant: "light",
        },
        ...Object.keys(this.$enums.ModuleName),
      ],
      enabledFunctionsFields: [
        {
          key: "type",
          label: "Típus",
          stickyColumn: true,
          isRowHeader: true,
          variant: "light",
        },
        ...Object.keys(this.$enums.FunctionName),
      ],
      //engedélyezett elemek feltöltés formátuma
      enabledModulesValues: null,
      enabledUiElementsValues: null,
      enabledFunctionsValues: null,
      isEditable: false,
      enabledUiElementsDefault: null,
      enabledModulesDefault: null,
      enabledFunctionsDefault: null,
    };
  },
  props: {
    enabledUiElements: Object,
    enabledModules: Object,
    enabledFunctions: Object,
    isImpotedEnabledObjects: Boolean,
    isCreated: Boolean,
    createdProjectId: Number,
  },
  watch: {
    enabledUiElementsValues(input) {
      this.$emit("update:enabledUiElements", input);
    },
    enabledModulesValues(input) {
      this.$emit("update:enabledModules", input);
    },
    enabledFunctionsValues(input) {
      this.$emit("update:enabledFunctions", input);
    },
    isImpotedEnabledObjects(input) {
      if (input) {
        this.setEnabledUiElements();
        this.setEnabledModules();
        this.setEnabledFunctions();
        this.$emit("update:isImpotedEnabledObjects", false);
      }
    },
    isEditable(input) {
      if (input) {
        this.$emit("editingTab", true);
      } else {
        this.$emit("editingTab", false);
      }
    },
  },
  methods: {
    setRowsValue() {
      const clientUI = { type: "Client" };
      const healthUI = { type: "HealthProfessional" };
      const partnerUI = { type: "Partner" };
      this.userInterfaceElementKeys.forEach((key) => {
        clientUI[key] = null;
        healthUI[key] = null;
        partnerUI[key] = null;
      });
      this.enabledUiElementsRow = [clientUI, healthUI, partnerUI];

      const clientModules = { type: "Client" };
      const healthModules = { type: "HealthProfessional" };
      const partnerModules = { type: "Partner" };
      this.moduleNameKeys.forEach((key) => {
        clientModules[key] = null;
        healthModules[key] = null;
        partnerModules[key] = null;
      });
      this.enabledModulesRow = [clientModules, healthModules, partnerModules];

      const clientFunctions = { type: "Client" };
      const healthFunctions = { type: "HealthProfessional" };
      const partnerFunctions = { type: "Partner" };
      this.functionNameKeys.forEach((key) => {
        clientFunctions[key] = null;
        healthFunctions[key] = null;
        partnerFunctions[key] = null;
      });
      this.enabledFunctionsRow = [clientFunctions, healthFunctions, partnerFunctions];
    },
    //Változik a felületi elem beállítás egyik értéke
    changeEnabledUiElementsValue(type, key, input) {
      this.enabledUiElementsValues = this.changeValues(
        this.enabledUiElementsValues,
        type,
        key,
        input
      );
    },
    //Változik a modul beállítás egyik értéke
    changeEnabledModulesValue(type, key, input) {
      this.enabledModulesValues = this.changeValues(
        this.enabledModulesValues,
        type,
        key,
        input
      );
    },
    //Változik a modul beállítás egyik értéke
    changeEnabledFunctionsValue(type, key, input) {
      this.enabledFunctionsValues = this.changeValues(
        this.enabledFunctionsValues,
        type,
        key,
        input
      );
    },
    //Változás convertálása kimeneti formátumra (engedélyezett elem tömb kezelése)
    changeValues(values, type, key, input) {
      if (!values) {
        values = { Client: [], HealthProfessional: [], Partner: [] };
      }
      if (!values[type].includes(key) && input) {
        values[type].push(key);
      } else if (values[type].includes(key) && !input) {
        values[type].splice(values[type].indexOf(key), 1);
      }
      return values;
    },
    //kapott érték alapján felületi elemek beállítása
    setEnabledUiElements() {
      this.enabledUiElementsDefault = JSON.parse(
        JSON.stringify(this.enabledUiElements)
      );
      this.enabledUiElementsValues = JSON.parse(
        JSON.stringify(this.enabledUiElements)
      );
    },
    //kapott érték alapján modulok beállítása
    setEnabledModules() {
      this.enabledModulesDefault = JSON.parse(
        JSON.stringify(this.enabledModules)
      );
      this.enabledModulesValues = JSON.parse(
        JSON.stringify(this.enabledModules)
      );
    },
    //kapott érték alapján modulok beállítása
    setEnabledFunctions() {
      this.enabledFunctionsDefault = JSON.parse(
        JSON.stringify(this.enabledFunctions)
      );
      this.enabledFunctionsValues = JSON.parse(
        JSON.stringify(this.enabledFunctions)
      );
    },

    async setEdit() {
      const body = {
        EnabledUiElements: this.enabledUiElementsValues,
        EnabledModules: this.enabledModulesValues,
        EnabledFunctions: this.enabledFunctionsValues,
      };
      console.log("SET_BODY", body);
      //TODO: rossz
      const setEditResponse = await ProjectLogic.setProject(
        body,
        this.createdProjectId
      );
      if (!setEditResponse.Code) {
        //TODO: sikeres
        this.isEditable = false;
        this.enabledUiElementsDefault = JSON.parse(
          JSON.stringify(this.enabledUiElementsValues)
        );
        this.enabledModulesDefault = JSON.parse(
          JSON.stringify(this.enabledModulesValues)
        );
        this.enabledFunctionsDefault = JSON.parse(
          JSON.stringify(this.enabledFunctionsValues)
        );
      } else {
        //TODO: hiba
      }
    },
    cancelEdit() {
      this.enabledUiElementsValues = JSON.parse(
        JSON.stringify(this.enabledUiElementsDefault)
      );
      this.enabledModulesValues = JSON.parse(
        JSON.stringify(this.enabledModulesDefault)
      );
      this.enabledFunctionsValues = JSON.parse(
        JSON.stringify(this.enabledFunctionsDefault)
      );
      this.isEditable = false;
    },
  },
  beforeDestroy() {
    this.cancelEdit();
  },
  mounted() {
    this.setRowsValue();
    this.setEnabledUiElements();
    this.setEnabledModules();
    this.setEnabledFunctions();
    console.log("UI_ELEMENTS", this.enabledUiElements);
    console.log("MODULES", this.enabledModules);
    console.log("FUNCTIONS", this.enabledFunctions);
    console.log("PROJECT_ID", this.createdProjectId);
  },
};
</script>
